import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
// Customizable Area Start
import { Formik } from "formik";
import Select from 'react-select'
import { Link } from 'react-router-dom'
import * as yup from "yup";
import { TextField } from '@material-ui/core';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

export default class Competencies extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    competenciesSchema = () => {
        return yup.object().shape({
            pqObservations: yup
                .string()
                .min(3, "Minimum 3 characters are required")
                .max(500, "Maximum 500 characters are allowed.")
                .matches(/^[a-zA-Z ]+$/, "Only letters are allowed.")
                .required("Purple Quarter Observation is required."),
            executiveSummary: yup
                .string()
                .min(3, "Minimum 3 characters are required")
                .max(500, "Maximum 500 characters are allowed.")
                .matches(/^[a-zA-Z ]+$/, "Only letters are allowed.")
                .required("Executive Summary is required."),
        });
    }

    handleTextFieldArea = () => {
        let textarea: any = document.getElementById('text-area-compentencies');
        let limit = 250
        textarea.oninput = function () {
            textarea.style.height = "";
            textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
        };
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        {console.log(this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.pq_observations, 'test')}
                        <div className="mb-4 mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    pqObservations: this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.pq_observations ? this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.pq_observations : "",
                                    executiveSummary: this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.executive_summary ? this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.executive_summary : "",
                                }}
                                // validationSchema={this.competenciesSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    this.handleCompetencies(values);
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <p className="heading-head">{window.location.href.includes('Edit-user') ? 'Edit Candidate' : 'Add New Candidate'}</p>
                                            <p className="heading"><b>Competencies</b></p>
                                            <div>
                                                <div className="mt-1 mb-3">
                                                <label className="my-label">Core Competencies</label>
                                                  
                                                    <Select isMulti styles={customStyles} options={this.state.comptencieOptions} name="coreCompetencies" placeholder={this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.core_competency.length ? this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.core_competency.map((x: any) => { return `${x.name} x ` }) : ""} onChange={(selectedOpion) => this.handleSelect(selectedOpion, 'coreCompetencies')} />
                                                    {this.state.coreCompetenciesError !== null && <div className="error">{this.state.coreCompetenciesError}</div>}

                                                </div>
                                                <div className="mt-1 mb-3">
                                                <label className="my-label">Coding Proficiency</label>
                                                 
                                                    <Select styles={customStyles} options={this.state.codingProficiencyOptions} name="codingProficiency" placeholder={this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.coding_proficiency?.length > 0 ? this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.coding_proficiency : ""} onChange={(selectedOpion) => this.handleSelect(selectedOpion, 'codingProficiency')} />
                                                    {this.state.codingProficiencyError !== null && <div className="error">{this.state.codingProficiencyError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    
                                                <label className="my-label">  Type of Technologist</label>
                                                 
                                                  
                                                    <Select styles={customStyles} options={this.state.technologiestOptions} name="technologistType" placeholder={this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.type_of_technologist ? this.state.currentCandidateData?.attributes?.competency?.data?.attributes?.type_of_technologist : ""} onChange={(selectedOpion) => this.handleSelect(selectedOpion, 'technologistType')} />
                                                    {this.state.technologistTypeError !== null && <div className="error">{this.state.technologistTypeError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                <label className="my-label"> Purple Quarter Observation</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                       // label="PQ Observation"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.pqObservations}
                                                        name="pqObservations"
                                                        type="text"
                                                    />
                                                    {errors.pqObservations && touched.pqObservations ? <div className="error">{errors.pqObservations}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                <label className="my-label">Executive Summary</label>
                                                    <TextField
                                                        multiline
                                                        rows={5}
                                                        id="outlined-secondary"
                                                       // label="Executive Summary"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control input-field"
                                                        name="executiveSummary"
                                                        value={values.executiveSummary}
                                                    />
                                                    {/* <textarea id="text-area-compentencies" onChange={(e) => { handleChange(e); this.handleTextFieldArea() }} style={{ height: '108px' }} value={values.executiveSummary} className="form-control input-field" name="executiveSummary" placeholder="Executive Summary" /> */}
                                                    {errors.executiveSummary && touched.executiveSummary ? <div className="error">{errors.executiveSummary}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-step row">
                                            <div className="row col-9">

                                                <Link to={window.location.href.includes('Edit-user') ? "/BasicDetails/Edit-user" : "/BasicDetails"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/DomainKnowledge/Edit-user" : "/DomainKnowledge"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/FamilyDetails/Edit-user" : "/FamilyDetails"} className="step-bar col-2"></Link>

                                            </div>
                                            <div className="row col-3">
                                                <button className="btn candidate-button" type="submit">Save And Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>

                        </div >
                    </div >
                </div >
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
