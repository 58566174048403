import React from "react";
import ReactToPrint from "react-to-print";
const firstPage = require('../../../components/src/agreement-page.png');
const fullPage = require('../../../components/src/full-page-image.png');
const pq_logo = require('../../../components/src/purpleQuarter.svg');


export function PreviewAgreementPdf(closeModal, values) {
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2]
  };

  return (
    <div className="previewAgreement">
      <div onClick={closeModal()} className="btn btn-sm close-button" > Close</div>
      
      <div className="">
        <ReactToPrint
          trigger={() => <div className="btn btn-sm download-button" type="submit">Download
          </div>}
          // "@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }", 
          pageStyle='@page { margin: 9mm !important;} @media print { body { -webkit-print-color-adjust: exact; } }'
          
          content= {() =>{
            const tableStat = document.getElementById('preview-aggrement-ref');
            const PrintElem = document.createElement('div');
            const footer = 
              `<div class="page-footer"><img src="${pq_logo}" alt="" class="pq-capsule-footer-agreement"/></div>`;
            PrintElem.innerHTML = footer;
            PrintElem.appendChild(tableStat);
            return PrintElem;
          }}
        />
      </div>

      <div id="preview-aggrement-ref" className="page" options={options}>
        <div className="full-page-aggrement-firstpage full-page">
          <img src={firstPage} style={{width: '100%'}}/>
        </div>
        <div className="full-page-aggrement full-page" style={{marginBottom:'35px'}}>
          <h1 style={{color: '#a122a8', textAlign: 'center', marginBottom: '10px', fontSize: '60px'}}>SERVICE AGREEMENT</h1>

          <div className="service-agreement">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: values?.service_agreement }} />
            </div>
          </div>
        </div>

        <div className="full-page-aggrement" >
          <h4 style={{color:'#a122a8', marginBottom: '20px'}}>NOW IN CONSIDERATION OF THE PREMISES HEREINAFTER CONTAINED, IT IS HEREBY AGREED BETWEEN PARTIES AS FOLLOWS:</h4>
          <div className="assignment-sub-title">1. Scope of Services</div>
            <br/>
            <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.scope_of_services }} />
            <br/>
        </div>
        <div className="full-page-aggrement">
          <div className="assignment-sub-title">2. Term and Engagement</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.term_and_engagement }} />
          <br/>

        <div className="assignment-sub-title">3 Commercials</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.commercials }} />
          <br/>

        <div className="assignment-sub-title">4 Independent contractor</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.independentContractorData }} />
          <br/>
        </div>

        <div className="full-page-aggrement">
          <div className="assignment-sub-title">5 Warranties</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.warrantiesData }} />
          <br/>

          <div className="assignment-sub-title">6 Indemnity</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.indemnityData }} />
          <br/>

          <div className="assignment-sub-title">7 Limitation of Liability </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.limitationOfLiabilityData }} />
          <br/>
          
          <div className="assignment-sub-title">8 Confidentiality </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.confidentialityData }} />
          <br/>

          <div className="assignment-sub-title">9 Termination </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.terminationData }} />
          <br/>

          <div className="assignment-sub-title">10 Consequences of Termination </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.consequencesOfTerminationData }} />
          <br/>

          <div className="assignment-sub-title">11 SETTLEMENT OF DISPUTE AND JURISDICTION </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.settlementOfDisputeAndJurisdictionData }} />
          <br/>

          <div className="assignment-sub-title">12 NOTICES </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.noticesData }} />
          <br/>

          <div className="assignment-sub-title">13 Miscellaneous </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.miscellaneousData }} />
          <br/>

            <table className="misslaneous-sign-section">
              <tr>
                {/* <th style={{padding:'0px 60px 0px'}}>Executed by the within named Undersigned by "{values.billingOrganizationName}" through signatory</th> */}
                <th style={{padding:'0px 60px 0px'}}>Executed by the within named Undersigned by "PurpleLabs Search Private Limited" through signatory</th>
                <th style={{padding:'0px 60px 0px'}}>Agreed and accepted by the within named “{values.billingOrganizationName}” through its authorized signatory</th>
              </tr>
              <br/>
              <tr>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th style={{padding:'0px 60px 0px'}}>Name: <span style={{fontSize:'12px', fontWeight:'normal'}}>Sourabh Joshi</span> 
                  <br/> Designation: <span style={{fontSize:'12px', fontWeight:'normal'}}>VP Finance</span><br/>
                    Date: <br/>
                  </th>

                    <th style={{padding:'0px 60px 0px'}}>Name: 
                    <br/>Designation:  <br/>
                    Date: <br/></th>

              </tr>
            </table>

          <h4 className="assignment-sub-title" style={{textAlign: 'center', color: '#a122a8', marginTop: '1135px'}}>Schedule A </h4>
          <br/>
          <table className="schedule-address">
            <tr style={{backgroundColor: 'lightgray'}}>
              <th>Service Description</th>
              <th>Fee Per Postion</th>
            </tr>
            <tr>
              <th>Tech Head</th>
              <th>{parseInt(values?.scheduleFixedAmount)} {values?.scheduleFeeType == 'Fixed %' ? "%": " Amount "} {(values?.scheduleFeeType != 'Fixed %' &&  values?.currencyOptions?.length &&  `(${values?.currencyOptions[parseInt(values?.currency_id) -1]?.label})` )} of Total CTC of first year <br/>(all cash components apart from stocks)</th>
            </tr>
          </table>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.scheduleAData?.split('cash components apart from stocks)')[1] }} />
          <br/>

        {/* Billing */}
        
        <div className="page-table-agreement">
          <h5 className="assignment-sub-title" style={{textAlign: 'center',marginTop:'200px' , color: '#a122a8'}}><u>Billing Information - To Be Completed By Client </u></h5>
            <br/>
            <b>Client detalis required for raising the invoice:</b>
            <br/>
            <table className="billing-address">
              <tr>
                <th>Company</th>
                <th>{values.billingOrganizationName}</th>
              </tr>
              <tr>
                <th>Billing Address</th>
                <th>{values?.billingOrganizationAddress1 + ' '+ values?.billingOrganizationAddress2+ values?.billingOrganizationCity+ ', '+ values?.billingOrganizationPincode}</th>
              </tr>

              <tr>
                <th>Shipping Address</th>
                <th>{values?.billingOrganizationShippingAddress1 +' '+ values?.billingOrganizationShippingAddress2 + ' '  + values?.billingOrganizationShippingCity+ ', '+ values?.billingOrganizationShippingPincode}</th>
              </tr>

              <tr>
                <th>GST No.</th>
                <th>{values.billingOrganizationinvoiceGSTNumber}</th>
              </tr>
            </table>
            
            <br/>
            <b>Account Payable Contact:</b>
            <br/>
            <table className="billing-address">
              <tr>
                <th>Name/Title</th>
                <th>{values.paybleTitle || values.paybleName} </th>
              </tr>
              <tr>
                <th>Email</th>
                <th>{values.paybleEmail}</th>
              </tr>
              <tr>
                <th>Phone</th>
                <th>{values.payblePhoneNumber}</th>
              </tr>

              </table>
        </div>

          <h5 className="assignment-sub-title" style={{textAlign: 'center', color: '#a122a8', marginTop: '280px'}}>Schedule B </h5><br/>
          <h5 className="assignment-sub-title" style={{textAlign: 'center', color: '#a122a8'}}>NON-DISCLOSURE AGREEMENT</h5>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.disclosureAgreementCompanyData }} />
      
          <div className="assignment-sub-title">2. Return Of Confidential Information </div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.returnOfConfidentialData }} />
          <br/>

          <div className="assignment-sub-title">3. Obligations</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.obligationData }} />
          <br/>


          <div className="assignment-sub-title">4. Non-Promotion</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.nonPromotionData }} />
          <br/>
                    
          <div className="assignment-sub-title">5. Dispute Resolution & Governing Law</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.disputeResolutionData }} />
          <br/>
          <div className="assignment-sub-title">6. Injunctive relief</div>
          <br/>
          <div className="assignment-description" dangerouslySetInnerHTML={{ __html: values?.injunctiveReliefData }} />
          <br/>
          <br/>

          <table className="misslaneous-sign-section">
              <tr>
                <th style={{padding:'0px 60px 0px'}}>Executed by the within named Undersigned by "PurpleLabs Search Private Limited" through signatory</th>
                <th style={{padding:'0px 60px 0px'}}>Agreed and accepted by the within named “{values.billingOrganizationName}” through its authorized signatory</th>
              </tr>
              <br/>
              <tr>
                <th></th>
                <th></th>
              </tr>
              <tr>
              <th style={{padding:'0px 60px 0px'}}>Name: <span style={{fontSize:'12px', fontWeight:'normal'}}>Sourabh Joshi</span> 
                  <br/> Designation: <span style={{fontSize:'12px', fontWeight:'normal'}}>VP Finance</span><br/>
                    Date: <br/>
                  </th>

                    <th style={{padding:'0px 60px 0px'}}>Name: 
                    <br/>Designation:  <br/>
                    Date: <br/></th>

              </tr>
            </table>

        </div>
        <div className="full-page-aggrement-firstpage full-page">
          <img className="last-page-resume" src={fullPage}/>
        </div>
      </div>

    </div >
  );
}

