import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import AuditTrailController, {
  Props,
  configJSON
} from "./AuditTrailController";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
import './styles.css'
import Select from 'react-select'
import { TextField } from '@material-ui/core';
import Modal from 'react-modal'
import { format } from "path";
import moment from 'moment'
import ReactToPrint from "react-to-print";
//@ts-ignore
const { PreviewCompanyPdf } = require("./previewAuditLogs");
//@ts-ignore
import Avatar from "@material-ui/core/Avatar"
import CircularProgress from "@material-ui/core/CircularProgress";

export default class AuditTrail extends AuditTrailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  handleSelect = (selectedOption: any, type: any) => {

    this.setState({
      isFilterStarted: true
    })

    if (type === 'entity') {
      console.log(selectedOption, 'asdf')
      if (selectedOption?.value) {
        this.setState({
          entityType: selectedOption,
        })
      }
    }

    if (type === 'changeType') {
      if (selectedOption?.value) {
        this.setState({
          changeType: selectedOption,
        })
      }
    }

    if (type === 'changedBy') {
      if (selectedOption?.value) {
        this.setState({
          changedBy: selectedOption
        })
      }
    }

    if (type === 'date') {
      this.setState({
        date: selectedOption?.target.value
      })
    }

    if (type === 'description') {
      this.setState({
        description: selectedOption?.target.value
      })
    }
    setTimeout(() => { this.filterAudit() }, 500)
  }

  downloadPdf = () => {

    //@ts-ignore
    var divContents = document.getElementById("download-log").innerHTML;
    var printWindow = window.open('', '', 'height=auto,width=auto');
    //@ts-ignore
    printWindow.document.write('<html><head><title>Audit Log</title>');
    //@ts-ignore
    printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"><link href="./styles.css"/></head><body >');
    //@ts-ignore
    printWindow.document.write(divContents);
    //@ts-ignore
    printWindow.document.write('</body></html>');
    //@ts-ignore
    printWindow.document.close();
    //@ts-ignore
    printWindow.print();
  }

  closeModal = () => {
    this.setState({ downloadPreviewModal: false })
  }

  generateCompanyPdf = () => {

    const options = {
      orientation: 'landscape',
      unit: 'in',
    };


    return <Modal
      onRequestClose={() => this.setState({ downloadPreviewModal: false })}
      isOpen={this.state?.downloadPreviewModal}
      contentLabel=""
      className=""
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          zIndex: 999999999999,
        },
        content: {
          color: ''
        }
      }}
    >
      <>
        {PreviewCompanyPdf(() => this.closeModal, this.state.filterAuditLogs)}
      </>
    </Modal >
  }

  filterAudit = () => {

    this.setState({
      filterAuditLogs: this.state.auditLogs.filter((y: any) => {
        return (this.state.entityType.value == 'All' ? true : y.attributes?.entity === this.state.entityType.value) && (this.state.changeType.value === 'All' ? true : y.attributes?.change_type === this.state.changeType.value) && (this.state.changedBy.value == 'All' ? true : y.attributes?.changed_by === this.state.changedBy.value) && (this.state.date == "" ? true : (y.attributes?.date === moment(this.state.date).format('MM-DD-YYYY'))) && (this.state.description == "" ? true : y.attributes.description.toLowerCase().includes(this.state.description.toLowerCase()))
      })
    })
  }

  openLogModal = () => {
    return <Modal
      onRequestClose={() => this.setState({ viewLogModal: false })}
      isOpen={this.state.viewLogModal}
      contentLabel=""
      className="view-log"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-10">
              {/* <label className="task-label">View Task</label> */}
            </div>
            <div className="col-2">
              <button className="btn cross-btn" onClick={() => { this.setState({ viewLogModal: false }) }}><b>X</b></button>
            </div>
          </div>
          <div className="add-task-form col-12">
            <div className="row col-12">
              <div className="row col-6">
                <div className="col-6 mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Entity :</label></div>
                <div className="col-6 mt-2 mb-3"><label className="my-label">{this.state.selectedLog?.attributes?.entity}</label></div>
              </div>
              <div className="row col-6">
                <div className="col-6 mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Date :</label></div>
                <div className="col-6 mt-2 mb-3"><label className="my-label">{this.state.selectedLog?.attributes?.date}</label></div>
              </div>
            </div>
            <div className="row col-12">
              <div className="row col-6">
                <div className="col-6 mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Change Type :</label></div>
                <div className="col-6 mt-2 mb-3"><label className="my-label">{this.state.selectedLog?.attributes?.change_type}</label></div>
              </div>
              <div className="row col-6">
                <div className="col-6 mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Changed By :</label></div>
                <div className="col-6 mt-2 mb-3">
                  {/* <div className="my-label row">
                    <div className="col-6">
                      <Avatar alt={this.state.selectedLog?.attributes?.changed_by} src={this.state.selectedLog?.attributes?.image !== null ? this.state.selectedLog?.attributes?.image : '/static/images/avatar/1.jpg'} />
                    </div>
                    <div className="col-6">
                      <label style={{ marginTop: '5px' }}>
                        {this.state.selectedLog?.attributes?.changed_by}
                      </label>
                    </div>
                  </div> */}
                  <label className="my-label">{this.state.selectedLog?.attributes?.changed_by}</label>
                </div>
              </div>
            </div>
            <div className="row col-12">

              <div className="col-3 mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Description :</label></div>
              <div className="col-9 mt-2 mb-3" style={{ paddingLeft: '0px' }}> <label className="my-label">{this.state.selectedLog?.attributes?.description}</label></div>

            </div>
          </div>
        </form>
      </div>

    </Modal>
  }

  openCandidateModal = () => {
    return <Modal
      onRequestClose={() => this.setState({ viewCandidateModal: false })}
      isOpen={this.state.viewCandidateModal}
      contentLabel=""
      className="view-candidate-log"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          height: '500',
          overflow: 'scroll'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">

          <div className="add-task-form col-12">
            <div style={{ textAlign: 'center' }} >
              {
                <img src={this.state.editCandidateProfilePicture} className="profile-image" />
              }
            </div>
            <div style={{ textAlign: 'center' }} className="mt-2 mb-3">
              <input type='file' id="img" className="round-inputfield" onChange={(e) => console.log(e)} style={{ display: 'none' }} />
              <label htmlFor="img" style={{ cursor: 'pointer' }}>{this.state.editCandidateName}</label>
              {/* {this.state.fileUploadError !== null && <div className="error">{this.state.fileUploadError}</div>} */}
            </div>
            <div>
              <div className="row col-12">
                <div className="col-4">
                  <div className="mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Designation</label></div>
                </div>
                <div className="col-8">
                  <div className="mt-2 mb-3">
                    <input type="text" className="round-inputfield" value={this.state.editCandidateDesignation}
                      onChange={(e) => this.setState({
                        editCandidateDesignation: e.target.value
                      })}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
              <div className="row col-12">
                <div className=" col-4">
                  <div className="mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Team</label></div>
                </div>
                <div className=" col-8">
                  <div className="mt-2 mb-3">
                    <Select
                      options={[
                        { value: 'Sales', label: 'Sales' },
                        { value: 'Delivery', label: 'Delivery' },
                        { value: 'Finance', label: 'Finance' },
                      ]}
                      // @ts-ignore
                      placeholder={'All'}
                      onChange={(e) => this.setState({
                        editCandidateTeam: e
                      })}
                      styles={customStylesSelect}
                      value={this.state.editCandidateTeam}
                    />
                  </div>
                </div>
              </div>
              <div className="row col-12">
                <div className=" col-4">
                  <div className="mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Email ID</label></div>
                </div>
                <div className=" col-8">
                  <div className="mt-2 mb-3">
                    <input type="text" className="round-inputfield" value={this.state.editCandidateEmail}
                      onChange={(e) => this.setState({
                        editCandidateEmail: e.target.value
                      })}
                      style={{ width: '100%' }}
                    />

                  </div>
                </div>
              </div>
              <div className="row col-12">
                <div className="col-4">
                  <div className="mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Reporting To</label></div>
                </div>
                <div className=" col-8">
                  <div className="mt-2 mb-3">
                    <input type="text" className="round-inputfield" value={this.state.editCandidateReportingTo}
                      onChange={(e) => this.setState({
                        editCandidateReportingTo: e.target.value
                      })}
                      style={{ width: '100%' }}
                    />

                  </div>
                </div>
              </div>
              <div className="row col-12">
                <div className="col-4">
                  <div className="mt-2 mb-3"><label style={{ color: '#8f92a1' }}>Contact No</label></div>
                </div>
                <div className="col-8">
                  <div className="mt-2 mb-3">
                    <input type="text" className="round-inputfield" value={this.state.editCandidateContact}
                      onChange={(e) => this.setState({
                        editCandidateContact: e.target.value
                      })}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
              <div className="row col-12">
                <div className="col-4">
                  <div className="mt-2 mb-3">
                    <label style={{ color: '#8f92a1' }}>Role</label>
                  </div>
                </div>
                <div className="col-8">
                  <div className="mt-2 mb-3">
                    <Select
                      options={[
                        { value: 'Super Admin', label: 'Super Admin' },
                        { value: 'Admin', label: 'Admin' },
                        { value: 'User', label: 'User' },
                      ]}
                      // @ts-ignore
                      placeholder={'All'}
                      onChange={(e) => this.setState({
                        editCandidateRole: e
                      })}

                      styles={customStylesSelect}
                      value={this.state.editCandidateRole}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row" >
              {/* <div className="col-1"/> */}
              <div className="col-6" >
                <button type="button" onClick={() => { this.setState({ viewCandidateModal: false }) }} className="btn cancel-modal-button">Cancel</button>
              </div>
              {/* <div className="col-1"/> */}
              <div className="col-6">
                <button type="button" className="btn candidate-save-button">Save</button>
              </div>

            </div>
          </div>
        </form>
      </div>

    </Modal>
  }

  auditTrail() {
    return (
      <div className="mb-4 mt-4" style={{ fontSize: '14px' }}>
        {
          this.openLogModal()
        }
        {
          this.generateCompanyPdf()
        }
        <div className="row" style={{ margin: 0 }}>
          <div className="col-4">
          </div>
          <div className="col-4" style={{ alignItems: 'center', textAlign: 'center' }}>
            <h1>Audit Log</h1>
          </div>
          <div className="col-4" style={{ marginTop: '20px', alignItems: 'center', textAlign: 'center' }} >
            <button className="btn candidate-button" type="button" onClick={() => this.setState({ downloadPreviewModal: true })}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download m-1" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
              Download Log
            </button>
          </div>
        </div>
        <div>
          <div className="mb-3 mt-5 first-box">
            <div className="row" >
              <div className="col-2 m-2">
                <label className="my-label">Entity</label>
                <Select
                  options={[
                    { value: 'All', label: 'All' },
                    { value: 'Agreement', label: 'Agreement' },
                    { value: 'Candidate', label: 'Candidate' },
                    { value: 'Job', label: 'Job' },
                    { value: 'PqCapsule', label: 'Purple Quarter Capsule' },
                  ]}
                  // @ts-ignore
                  placeholder={'All'}
                  onChange={(e) => {
                    this.handleSelect(e, 'entity')
                  }}
                  styles={customStyles}
                value={this.state.entityType}
                />
              </div>

              <div className="col-2 m-2">
                <label className="my-label">Date</label><br />

                <TextField
                  id="outlined-secondary"
                  // label="PQ Observation"
                  variant="outlined"
                  //@ts-ignore
                  type={!this.state.isFilterStarted && "text"}
                  color="primary"
                  onChange={(e) => {
                    this.handleSelect(e, 'date');

                  }}
                  onFocus={(e: any) => e.target.type = 'date'}
                  // onChange={handleChange}
                  className="form-control input-field text-field-input"
                  // value={values.pqObservations}
                  name="pqObservations"
                  value={this.state.date}
                // style={{ width: '10px' }}

                />
              </div>

              <div className="col-2 m-2">
                <label className="my-label">Change Type</label>
                <Select
                  options={[
                    { value: 'All', label: 'All' },
                    { value: 'Creation', label: 'Creation' },
                    { value: 'Edit', label: 'Edit' },
                    { value: 'Delete', label: 'Delete' }
                  ]}
                  // @ts-ignore
                  placeholder={'All'}
                  onChange={(e) => {
                    this.handleSelect(e, 'changeType')
                  }}
                  styles={customStyles}
                  value={this.state.changeType}

                />
              </div>

              <div className="col-2 m-2">
                <label className="my-label">Description</label>
                <TextField
                  id="outlined-secondary"
                  // label="PQ Observation"
                  variant="outlined"
                  color="primary"
                  onChange={(e) => {
                    this.handleSelect(e, 'description')
                  }}
                  className="form-control input-field text-field-input"
                  // value={values.pqObservations}
                  name="pqObservations"
                  type="text"
                  value={this.state.description}

                />
              </div>

              <div className="col-2 m-2">
                <label className="my-label">Changed by</label>
                <Select
                  options={this.state.empArray}
                  // @ts-ignore
                  placeholder={'All'}
                  onChange={(e) => {
                    this.handleSelect(e, 'changedBy')
                  }}
                  styles={customStyles}
                  value={this.state.changedBy}

                />
              </div>

              <div className="col" style={{ marginTop: '2%' }}>
                {
                  this.state.isFilterStarted &&
                  <button type="button" className="candidate-button btn"
                    onClick={() => {
                      this.setState({
                        entityType: { value: 'All', label: 'All' },
                        changedBy: { label: 'All', value: 'All' },
                        description: "",
                        changeType: { label: 'All', value: 'All' },
                        date: "",
                        isFilterStarted: false
                      })
                      this.getAuditLogs()
                    }}
                  >
                    X
                  </button>
                }

              </div>
            </div>
          </div>

          <div id="download-log" className="mt-2 mb-5 first-box-2">
            {this.state.filterAuditLogs?.slice(0).reverse().map((x: any) => {
              return (
                <div className="row audit-log-ui" style={{ paddingTop: '20px', marginLeft: '2%' }}>
                  <div className="col-2 m-2">
                    <label className="my-label">{x?.attributes?.entity === "PqCapsule" ? 'Purple Quarter Capsule' : x?.attributes?.entity}</label>
                  </div>
                  <div className="col-2 mt-2">
                    <label className="my-label">{x?.attributes?.date}</label>
                  </div>
                  <div className="col-2 m-2">
                    <label className="my-label change-type" style={x?.attributes?.change_type === 'Creation' ? {
                      backgroundColor: '#d7fbe8',
                      color: '#009345',
                      paddingTop: '5px'
                    } : x?.attributes?.change_type === 'Edit' ? {
                      backgroundColor: '#fdf7d7',
                      color: '#c4a200',
                      paddingTop: '5px'
                    } : {

                      backgroundColor: '#ffdcdc',
                      color: '#c70202',
                      paddingTop: '5px'
                    }}>{x?.attributes?.change_type}</label>
                  </div>
                  <div className="col-2 m-2">
                    <label className="my-label" style={{ lineBreak: 'auto' }}>{x?.attributes?.description}</label>
                  </div>
                  <div className="col-3 m-2">
                    <div className="my-label row">
                      <div className="col-4">
                        <Avatar alt={x?.full_name} src={x?.attributes?.image !== null ? x?.attributes?.image : '/static/images/avatar/1.jpg'} />
                      </div>
                      <div className="col-6">
                        <label style={{ marginTop: '5px' }}>
                          {x?.attributes?.changed_by}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="" style={{
                    width: '0%', cursor: 'pointer', marginLeft: '-5%',
                    marginTop: '1%'
                  }}>
                    <svg onClick={() => this.setState({ viewLogModal: true, selectedLog: x })} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="grey" className="bi bi-eye-fill" viewBox="0 0 16 16">
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </div>
                </div>
              )
            })}

            {
              this.state?.auditLogs?.length > 0 && this.state?.filterAuditLogs?.length === 0 &&
              <div className="" style={{ paddingTop: '20px', marginLeft: '2%', textAlign: 'center' }}>
                {/* <CircularProgress /> */}
                No Records Found
              </div>
            }
            {

              this.state?.auditLogs?.length === 0 &&
              <div className="" style={{ paddingTop: '20px', marginLeft: '2%', textAlign: 'center' }}>
                {
                  this.state.loader ?
                    <CircularProgress />
                    :
                    "No Data Found"
                }

              </div>
            }

          </div>
        </div>

      </div >
    )
  }

  teamVisibility() {
    return (
      <div className="mb-4 mt-4">
        {
          this.openCandidateModal()
        }
        <div>
          <div className="mb-3 mt-5 second-box">
            <div className="row" style={{ paddingTop: '20px', marginLeft: '2%' }}>
              <div className="col-3 m-1">
                <label className="my-label">Name</label>
              </div>
              {/* <div className="col-2 m-1">
                <label className="my-label">Designation</label>
              </div> */}
              {/* <div className="col-1 m-1">
                <label className="my-label">Team</label>
              </div> */}
              <div className="col-3 m-1">
                <label className="my-label">Email ID</label>
              </div>
              <div className="col-2 m-1">
                <label className="my-label">Contact No</label>
              </div>
              <div className="col-2 m-1">
                <label className="my-label">Role</label>
              </div>
              <div className="col-1 m-1">
                <label className="my-label">Action</label>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="mb-3 mt-5 second-box2">
              {
                this.state.filterTeamVisibility?.admin_users?.slice(0).reverse().map((x: any) => {
                  return (
                    <div className="row" style={{ paddingTop: '20px', marginLeft: '2%' }}>
                      <div className="col-3 m-1" style={{ textAlign: 'center', alignItems: 'center' }}>
                        <div className="my-label row" >
                          <div className="col-3">
                            <Avatar alt={x?.full_name} src={x?.profile_picture !== null ? x?.profile_picture : '/static/images/avatar/1.jpg'} />
                          </div>
                          <div className="col-9">
                            <label style={{ marginTop: '5px' }}>
                              {x.full_name ? (x.full_name.length < 16 ? x.full_name : `${x.full_name.slice(0, 14)}..`) : '-'}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-2 m-1">
                        <label style={{ color: '#8f92a1' }}>{x.designation ? x.designation : '-'}</label>
                      </div> */}
                      {/* <div className="col-1 m-1">
                        <label style={{ color: '#8f92a1' }}>{x.team ? x.team : '-'}</label>
                      </div> */}
                      <div className="col-3 m-1">
                        <label style={{ color: '#8f92a1' }}>{x.email ? x.email : '-'}</label>
                      </div>
                      <div className="col-2 m-1">
                        <label style={{ color: '#8f92a1' }}>{x.full_phone_number ? x.full_phone_number : '-'}</label>
                      </div>
                      <div className="col-2 m-1">
                        <label className="my-label">{x.role === 'super_admin' ? 'Super Admin' : '-'}</label>
                      </div>
                      <div className="col-1 m-1" style={{ cursor: 'pointer' }}>
                        <svg onClick={() => this.setState({
                          viewCandidateModal: true, selectedCandidate: x,

                          editCandidateRole: x.role,
                          editCandidateContact: x.full_phone_number,
                          editCandidateReportingTo: 'Role',
                          editCandidateEmail: x.email,
                          editCandidateTeam: x.team,
                          editCandidateDesignation: x.designation,
                          editCandidateName: x.full_name,
                          editCandidateProfilePicture: x.profile_picture

                        })} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey" className="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                      </div>
                    </div>
                  )
                })
              }
              {
                this.state.filterTeamVisibility?.accounts?.data?.slice(0).reverse().map((x: any) => {
                  return (
                    <div className="row" style={{ paddingTop: '20px', marginLeft: '2%' }}>
                      <div className="col-3 m-1">
                        <div className="my-label row">
                          <div className="col-3">
                            <Avatar alt={x?.attributes?.full_name} src={x?.attributes?.profile_picture !== null ? x?.attributes?.profile_picture : '/static/images/avatar/1.jpg'} />
                          </div>
                          <div className="col-9">
                            <label style={{ marginTop: '5px' }}>
                              {x?.attributes?.full_name ? (x?.attributes?.full_name.length < 16 ? x?.attributes?.full_name : `${x?.attributes?.full_name.slice(0, 14)}..`) : '-'}
                            </label>
                          </div>
                        </div>

                      </div>
                      {/* <div className="col-2 m-1">
                        <label style={{ color: '#8f92a1' }}>{x?.attributes?.designation ? x?.attributes?.designation : '-'}</label>
                      </div> */}
                      {/* <div className="col-1 m-1">
                        <label style={{ color: '#8f92a1' }}>{x?.attributes?.team ? x?.attributes?.team : '-'}</label>
                      </div> */}
                      <div className="col-3 m-1">
                        <label style={{ color: '#8f92a1' }}>{x?.attributes?.email ? x?.attributes?.email : '-'}</label>
                      </div>
                      <div className="col-2 m-1">
                        <label style={{ color: '#8f92a1' }}>{x?.attributes?.full_phone_number ? x?.attributes?.full_phone_number : '-'}</label>
                      </div>
                      <div className="col-2 m-1">
                        <label className="my-label">{x?.attributes?.role ? x?.attributes?.role : '-'}</label>
                      </div>
                      <div className="col-1 m-1" style={{ cursor: 'pointer' }}>
                        <svg onClick={() => this.setState({
                          viewCandidateModal: true, selectedCandidate: x,

                          editCandidateRole: x?.attributes?.role,
                          editCandidateContact: x?.attributes?.full_phone_number,
                          editCandidateReportingTo: 'Role',
                          editCandidateEmail: x?.attributes?.email,
                          editCandidateTeam: x?.attributes?.team,
                          editCandidateDesignation: x?.attributes?.designation,
                          editCandidateName: x?.attributes?.full_name,
                          editCandidateProfilePicture: x?.attributes?.profile_picture
                        })} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey" className="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                      </div>
                    </div>
                  )
                })
              }
              {
                this.state?.teamVisible?.length > 0 && this.state?.filterTeamVisibility?.length === 0 &&
                <div className="" style={{ paddingTop: '20px', marginLeft: '2%', textAlign: 'center' }}>
                  {/* <CircularProgress /> */}
                  No Records Found
                </div>
              }
              {

                this.state?.teamVisible?.length === 0 &&
                <div className="" style={{ paddingTop: '20px', marginLeft: '2%', textAlign: 'center' }}>
                  {
                    this.state.loader ?
                      <CircularProgress />
                      :
                      "No Data Found"
                  }
                </div>
              }
            </div>
          </div>

        </div>

      </div >
    )
  }

  handleSearch = (text: any) => {

    if (window.location.pathname === '/auditLogs') {
      this.setState({
        filterAuditLogs: this.state.auditLogs?.filter((ele: any) => {
          return ele.attributes?.entity?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.date?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.change_type?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.description?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.changed_by?.toLowerCase().includes(text?.toLowerCase())
        })
      })
    }
    if (window.location.pathname === '/teamVisibility') {
      this.setState({
        filterTeamVisibility: {
          "accounts": {
            "data": this.state.teamVisible?.accounts?.data?.filter((ele: any) => {
              return ele.attributes?.fullName?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.email?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.full_phone_number?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.role?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.designation?.toLowerCase().includes(text?.toLowerCase())
            })
          },
          "admin_users": this.state.teamVisible?.admin_users?.filter((ele: any) => {
            return ele?.fullName?.toLowerCase().includes(text?.toLowerCase()) || ele?.email?.toLowerCase().includes(text?.toLowerCase()) || ele?.full_phone_number?.toLowerCase().includes(text?.toLowerCase()) || ele?.role?.toLowerCase().includes(text?.toLowerCase()) || ele?.designation?.toLowerCase().includes(text?.toLowerCase())
          })

        }
      })
    }
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="wrapper">
        <SideNav data={this.state.currentUser} />
        <div className="kanban-content companies-content" >
          <TopNav data={this.state.currentUser}
            handleSearchText={(text: any) => this.handleSearch(text)}
          />
          {
            //@ts-ignore
            this.props?.page === "auditLogs" ? this.auditTrail() : this.teamVisibility()
          }
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
const customStylesSelect = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: '179px'
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',

    fontWeight: '500',
    // This line disable the blue border
    boxShadow: '0 !important',
    boderRadius: '10px',
    width: '100%',
    border: '1px solid',
    '&:hover': {
      border: '1px solid !important',
      borderRadius: '5px !important'
    },
    borderRadius: '5px !important'
  })
}
// Customizable Area End
