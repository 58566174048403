Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TeamBuilder";
exports.labelBodyText = "TeamBuilder Body";

exports.btnExampleTitle = "CLICK ME";
exports.deletePQApiMethod = "DELETE";
exports.putAPiMethod = "PUT";
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user"
exports.savePQCapusleAPI = "bx_block_pq_capsule/pq_capsules"
exports.getPQCapsuleFormsList = "bx_block_pq_capsule/pq_capsules"
exports.currencyApiEndPoint = "bx_block_candidate/candidates/get_currency"
exports.getCompanies = "bx_block_company/companies/get_companies"

// Customizable Area End