import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";

// Customizable Area Start
import DashboardController, { Props } from "./DashboardController.web";
import './dashboard.css'
//@ts-ignore
import Dropzone from 'react-dropzone'
import { documentIcon, uploadIcon } from "./assets";
import { Link } from 'react-router-dom'
// Customizable Area End


export default class CandidateDocuments extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.getCandidateDocument()
        // Customizable Area End
    }

    // Customizable Area Start
    viewFile = (data: any) => {
        const link = document.createElement('a');
        link.href = data.file !== null && data.file || data.image !== null && data.image
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    downloadFile = (data: any) => {

        var link = data.file !== null && data.file || data.image !== null && data.image;

        var name = data.name

        fetch(link, {
            method: 'GET',
            headers: {
                'Content-Disposition': `attachment; filename=${name}`
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${name}`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                //@ts-ignore
                link.parentNode.removeChild(link);
            });
    }




    openDropDownModalUI = (data: any) => {
        return <ul className="ellipsis-dropdown-ul">
            <li className="ellipsis-dropdown-li" onClick={() => this.viewFile(data)}  >View Document</li>
            <li className="ellipsis-dropdown-li" onClick={() => this.downloadFile(data)} >Download</li>
            <li className="ellipsis-dropdown-li" onClick={() => this.deleteDocument()} >Delete</li>
        </ul >
    }
    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4">
                            <div className="view-rectangle">
                                <p className="heading-head">Candidate Documents</p>
                                {/* //@ts-ignore */}
                                <div className="upload-container">
                                    <Dropzone onDrop={(acceptedFiles: any) => this.setState({
                                        candidateDocument: acceptedFiles[0]
                                    })}>
                                        {({ getRootProps, getInputProps }: any) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <div style={{ textAlign: 'center', marginTop: '33px' }}>
                                                        <img src={uploadIcon} />
                                                        <p style={{ marginTop: '5px', color: '#b006ae' }}>
                                                            {
                                                                this.state.candidateDocument !== "" ?
                                                                    this.state.candidateDocument.name
                                                                    :
                                                                    "Drag and Drop or Browse to upload"
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                {
                                    this.state.candidateDocumentError !== "" && (
                                        <p style={{ marginTop: '5px', color: '' }}>{this.state.candidateDocumentError}</p>
                                    )
                                }
                                <div style={{ textAlign: 'center', marginTop: '33px' }}>
                                    <button type="button" onClick={() => this.candidateFileUpload()} className="btn raise-button">Upload</button>
                                </div>

                                <div>
                                    <br />
                                    <br />
                                    {/* Table Heading */}
                                    <div className="row" style={{ textAlign: 'left', backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '15px' }}>
                                        <div className="capsuleForm-display col-3"><b>NAME</b></div>
                                        <div className="capsuleForm-display col-3"><b>UPDATED BY</b></div>
                                        <div className="capsuleForm-display col-3"><b>TIMESTAMP</b></div>
                                        <div className="capsuleForm-display col-3"><b>...</b></div>
                                    </div>
                                    {/* Table Data */}
                                    {this.state?.candidateDocumentArray?.length > 0 && this.state?.candidateDocumentArray?.map((ele: any) => {
                                        return (
                                            <div id={ele?.id} className="row" style={{ padding: '10px' }}>
                                                <div className="col-3" title={ele.attributes.name}><img style={{ marginTop: '-15px' }} src={documentIcon} /><label style={{
                                                    marginLeft: '13px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    width: '80%'
                                                }}>{ele.attributes.name}</label></div>
                                                <div className="col-3 ">{ele.attributes.created_by}</div>
                                                <div className="col-3">{ele.attributes.timestamp}</div>
                                                <div className="col-3">
                                                    <div className="col-2 ellipsis-item"
                                                        onClick={() => {
                                                            this.setState(
                                                                {
                                                                    isDropDownOpenId: this.state?.isDropDownOpenId === ele?.id ? null : ele?.id,
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                        <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                        <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                                                        {this.state?.isDropDownOpenId === ele?.id && this.openDropDownModalUI(ele.attributes)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                                <br />
                                <div style={{ textAlign: 'center' }}>
                                    <Link to="/candidateCreateProfile" className="btn candidate-button" type="button">Save And Continue</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area End
