import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
import Select from 'react-select'
import { TextField } from '@material-ui/core';
import './styles.css'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ArcElement
} from 'chart.js';
//@ts-ignore
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Chart, Pie } from 'react-chartjs-2';
// @ts-ignore
import BarChart from './BarChart.js';
// @ts-ignore
import PieCharts from './PieCharts.js'

import GraphicalChartsController, {
  Props,
  configJSON
} from "./GraphicalChartsController";

export default class GraphicalCharts extends GraphicalChartsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearch = (text: any) => {
    console.log(text)
  }
  handleSelect = (selectedOption: any, type: any) => {
    console.group(selectedOption, type)
  }

  chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [10, 20, 50, 40, 80, 60, 10],

        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderRadius: 25
      },
      {
        label: 'Dataset 2',
        data: [10, 20, 50, 40, 80, 60, 10],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Dataset 1',
        data: [10, 20, 50, 40, 80, 60, 10],

        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
        data: [10, 20, 50, 40, 80, 60, 10],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }

  chartOptions = {
    // responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: 'Crelate Like App',
      },
      dataLabels: {
        color: 'white',
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: 'bold'
        },
        formatter: Math.round
      },
      aspectRatio: 5 / 3,
      layout: {
        padding: {
          top: 24,
          right: 16,
          bottom: 0,
          left: 8
        }
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          hoverRadius: 7,
          radius: 5
        }
      },
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      }
    }
  }




  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="wrapper">
        <SideNav data={this.state.currentUser} />
        <div className="kanban-content companies-content" >
          <TopNav data={this.state.currentUser}
            handleSearchText={(text: any) => this.handleSearch(text)}
          />
          <div className="mb-4 mt-4" style={{ fontSize: '14px' }}>
            <div className="row" style={{ margin: 0 }}>
              <div className="col-4">
              </div>
              <div className="col-4" />
              <div className="col-4" style={{ marginTop: '20px', alignItems: 'center', textAlign: 'center' }} >
                <button className="btn candidate-button" type="button" onClick={() => console.log("Download Log")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download m-1" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                  Download Log
                </button>
              </div>
            </div>
            <div>
              <div className="mb-3 mt-5 first-box">
                <div className="row col-12">
                  <div className="col-3 m-2">
                    <label className="my-label">Mandate Name</label>
                    <Select
                      options={[]}
                      // @ts-ignore
                      placeholder={'All'}
                      onChange={(e) => {
                        this.handleSelect(e, 'entity')
                      }}
                      styles={customStyles}
                    // value={this.state.entityType}
                    />
                  </div>
                  <div className="col-3 m-2">
                    <label className="my-label">Time Period</label>
                    <Select
                      options={[]}
                      // @ts-ignore
                      placeholder={'All'}
                      onChange={(e) => {
                        this.handleSelect(e, 'entity')
                      }}
                      styles={customStyles}
                    // value={this.state.entityType}
                    />
                  </div>
                  <div className="col-2 m-2">
                    <label className="my-label">Start Date</label><br />

                    <TextField
                      id="outlined-secondary"
                      // label="PQ Observation"
                      variant="outlined"
                      //@ts-ignore
                      type={!this.state.isFilterStarted && "text"}
                      color="primary"
                      onChange={(e) => {
                        this.handleSelect(e, 'date');

                      }}
                      onFocus={(e: any) => e.target.type = 'date'}
                      // onChange={handleChange}
                      className="form-control input-field text-field-input"
                      // value={values.pqObservations}
                      name="pqObservations"
                      // value={this.state.date}
                      style={{ backgroundColor: '#f3f6f8' }}

                    />
                  </div>
                  <div className="col-2 m-2">
                    <label className="my-label">End Date</label><br />

                    <TextField
                      id="outlined-secondary"
                      // label="PQ Observation"
                      variant="outlined"
                      //@ts-ignore
                      type={!this.state.isFilterStarted && "text"}
                      // color="primary"
                      onChange={(e) => {
                        this.handleSelect(e, 'date');

                      }}
                      onFocus={(e: any) => e.target.type = 'date'}
                      // onChange={handleChange}
                      className="form-control input-field text-field-input"
                      // value={values.pqObservations}
                      name="pqObservations"
                      // value={this.state.date}
                      style={{ background: '#f3f6f8' }}


                    />
                  </div>
                </div>
              </div>
              <div className="mb-3 mt-5 first-box">
                <div className="row col-12 p-4">
                  <div className="col-4" >
                    <div className="card details-card">
                      <div className="card-body">
                        <h5 className="card-title details-title">Profiles Shared</h5>
                        <div className="col-12 row">
                          <div className="col-6">
                            <p className="card-text details-text">36</p>
                          </div>
                          <div className="col-6" style={{ textAlign: 'right' }}>
                            <p className="card-text details-info">36</p>
                          </div>
                        </div>
                        <label>Last Month</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4" >
                    <div className="card details-card">
                      <div className="card-body">
                        <h5 className="card-title details-title">Candidates Assigned</h5>
                        <div className="col-12 row">
                          <div className="col-6">
                            <p className="card-text details-text">36</p>
                          </div>
                          <div className="col-6" style={{ textAlign: 'right' }}>
                            <p className="card-text details-info">36</p>
                          </div>
                        </div>
                        <label>Last Month</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4" >
                    <div className="card details-card">
                      <div className="card-body">
                        <h5 className="card-title details-title">Candidates in Round 1</h5>
                        <div className="col-12 row">
                          <div className="col-6">
                            <p className="card-text details-text">36</p>
                          </div>
                          <div className="col-6" style={{ textAlign: 'right' }}>
                            <p className="card-text details-info">36</p>
                          </div>
                        </div>
                        <label>Last Month</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-12 p-4">
                  <div className="col-4" >
                    <div className="card details-card">
                      <div className="card-body">
                        <h5 className="card-title details-title">Candidates in Round 2</h5>
                        <div className="col-12 row">
                          <div className="col-6">
                            <p className="card-text details-text">36</p>
                          </div>
                          <div className="col-6" style={{ textAlign: 'right' }}>
                            <p className="card-text details-info">36</p>
                          </div>
                        </div>
                        <label>Last Month</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4" >
                    <div className="card details-card">
                      <div className="card-body">
                        <h5 className="card-title details-title">Candidates in Round 3</h5>
                        <div className="col-12 row">
                          <div className="col-6">
                            <p className="card-text details-text">36</p>
                          </div>
                          <div className="col-6" style={{ textAlign: 'right' }}>
                            <p className="card-text details-info">36</p>
                          </div>
                        </div>
                        <label>Last Month</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4" >
                    <div className="card details-card">
                      <div className="card-body">
                        <h5 className="card-title details-title">Candidates in Round 4</h5>
                        <div className="col-12 row">
                          <div className="col-6">
                            <p className="card-text details-text">36</p>
                          </div>
                          <div className="col-6" style={{ textAlign: 'right' }}>
                            <p className="card-text details-info">36</p>
                          </div>
                        </div>
                        <label>Last Month</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 mt-5 first-box">
                {
                  // this.state.loader &&

                  <Bar
                    //@ts-ignore
                    options={this.chartOptions}
                    data={this.chartData}
                  />
                }

              </div>
              <div className="mb-3 mt-5 first-box">
                {/* <Chart type='bar' data={this.chartData2} /> */}
                <BarChart />
              </div>
              <div className="mb-3 mt-5 first-box">
                <div className="col-12 row">
                  <div className="col-6">
                    <PieCharts />

                  </div>
                  <div className="col-6">
                    <PieCharts />
                  </div>

                </div>
              </div>
            </div>

          </div >
        </div>

      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,

    height: '40px',
    background: "#f3f6f8",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
const customStylesSelect = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: '179px'
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: "#f3f6f8",
    fontColor: '#727F88',

    fontWeight: '500',
    // This line disable the blue border
    boxShadow: '0 !important',
    boderRadius: '10px',
    width: '100%',
    border: '1px solid',
    '&:hover': {
      border: '1px solid !important',
      borderRadius: '5px !important'
    },
    borderRadius: '5px !important'
  })
}
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ArcElement,
  ChartDataLabels
);
// Customizable Area End
