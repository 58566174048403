import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import RichTextEditor from 'react-rte';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  currentUser: any;
  companyName: any
  companyBackgroundColor: any
  companyBackgroundImage: any
  companyTagline: any
  isPreviewCompanyDetails: boolean
  CompanyFoundedIn: any
  CompanyHeaderquarter: any
  CompanyFoundingMember: any
  totalFunding: any
  lastFundingRoundMoney: any
  lastFundingRoundMonth: any
  lastFundingRoundYear: any
  companyStage: any
  companyValuation: any
  numberOfEmployees: any
  companyLogoImage: any
  companyVisionText: any
  visionImage: any
  companyMissionText: any
  missionImage: any
  moreFounders: any
  problemStatementText: any
  problemStatmentImage: any
  overViewText: any
  overViewImage: any
  moreProducts: any
  moreKeyMetrics: any
  businessRoadMapText: any
  techLandScapeText: any
  techChallangesText: any
  techOrgText: any
  hrLevel1: any
  hrLevel2: any
  hrLevel3: any
  hrLevel4: any
  moreDeisgnations: any
  moreNames: any
  cultureDnaText: any
  snapshotBackgroundColor: any
  fundingBackgroundColor: any
  founderBackgroundColor: any
  productBackgroundColor: any
  keyMetricsBackgroundColor: any
  bussinessroadBackgroundColor: any
  techBackgroundColor: any
  techLandBackgroundColor: any
  techChallengeBackgroundColor: any
  techRoadMapBackgroundColor: any
  techOrgBackgroundColor: any
  CMEBackgroundColor: any
  interviewBackgroundColor: any
  cultureBackgroundColor: any
  confindentialBackgroundColor: any
  fundingAmount: any
  fundingRoundYear: any
  fundingRoundMonth: any
  fundingRoundSeries: any
  fundingInvestor: any
  foundersDesignation: any
  founderPeopleImage: any
  foundersbriefBackground: any
  founderslinkdinProfileLink: any
  foundersName: any
  foundersName0: any
  foundersName1: any
  foundersName2: any
  foundersName3: any
  productText: any
  productName: any
  productBackgroundImage: any
  productBackgroundImage0: any
  productBackgroundImage1: any
  productBackgroundImage2: any
  productBackgroundImage3: any
  keyMetricsText: any
  keyMetricsBackgroundImage: any
  bussinessroadBackgroundImage: any
  techBackgroundImage: any
  techLandBackgroundImage: any
  techChallengeBackgroundImage: any
  techRoadMapText: any
  techRoadMapBackgroundImage: any
  hierarchyLevelName: any
  hierarchyLevelPosition: any
  hierarchyLevelName2: any
  hierarchyLevelPosition2: any
  hierarchyLevelName3: any
  hierarchyLevelPosition3: any
  hierarchyLevelName1: any
  hierarchyLevelPosition1: any
  hierarchyLevelName0: any
  hierarchyLevelPosition0: any
  currentDesignation: any
  currentReportingTo: any
  currentLocation: any
  interViewPanelName: any
  interviewPanelImage: any
  linkdinProfileLink: any
  interViewRoundName: any
  cultureBackgroundImage: any
  confindentialText: any
  vectorBackgroundColor: any
  vectorElement: any
  CMEText: any
  problemStatementColor: any
  overViewColor: any
  isSavePQCapsule: boolean
  pqCapsuleFormList: any
  isDropDownOpenId: any
  isShowPQListDropDown: any
  fundingRounds: any
  productText0: any
  founderPeopleImage0: any
  founderPeopleImage1: any
  founderPeopleImage2: any
  founderPeopleImage3: any
  previewPQForm: any
  thisYear: any
  selectedYear: any
  currencyOptions: any
  currency_id: any
  countAddMoreFoundersRound: number
  countAddMoreProduct: number
  countKeyMatrics: number,
  countInterViewPanel: number,
  cmeCountPanel: number,
  foundersDesignation1: any,
  foundersDesignation2: any,
  foundersDesignation3: any,
  foundersDesignation0: any,
  briefBackground0: any
  briefBackground1: any
  briefBackground2: any
  briefBackground3: any
  linkdinProfileLine0: any,
  linkdinProfileLine1: any,
  linkdinProfileLine2: any,
  linkdinProfileLine3: any,
  productName0: any,
  productName1: any,
  productName2: any,
  productName3: any,
  productText1: any,
  productText2: any,
  productText3: any,
  keyMatricText0: any,
  keyMatricText1: any,
  keyMatricText2: any,
  keyMatricText3: any,
  keyMatricText4: any,
  keyMatricText5: any,
  keyMatricText6: any,
  keyMatricText7: any,
  keyMatricText8: any,
  keyMatricText9: any,
  keyMatricText10: any,
  keyMatricText11: any,


  keyMetricsBackgroundImage0: any,
  keyMetricsBackgroundImage1: any,
  keyMetricsBackgroundImage2: any,
  keyMetricsBackgroundImage3: any,
  keyMetricsBackgroundColor0: any,
  keyMetricsBackgroundColor1: any,
  keyMetricsBackgroundColor2: any,
  keyMetricsBackgroundColor3: any,
  keyMetricsBackgroundColor4: any,
  keyMetricsBackgroundColor5: any,
  keyMetricsBackgroundColor6: any,
  keyMetricsBackgroundColor7: any,
  keyMetricsBackgroundColor8: any,
  keyMetricsBackgroundColor9: any,
  keyMetricsBackgroundColor10: any,
  interviewPanelImage0: any
  interviewPanelImage1: any
  interviewPanelImage2: any
  interviewPanelImage3: any
  linkdinProfileLink0: any
  linkdinProfileLink1: any
  linkdinProfileLink2: any
  linkdinProfileLink3: any
  interViewRoundName0: any,
  interViewRoundName1: any,
  interViewRoundName2: any,
  interViewRoundName3: any,
  interViewPanelName0: any
  interViewPanelName1: any
  interViewPanelName2: any
  interViewPanelName3: any
  currentDesignation0: any,
  currentDesignation1: any,
  currentDesignation2: any,
  currentDesignation3: any,
  currentReportingTo0: any,
  currentReportingTo1: any,
  currentReportingTo2: any,
  currentReportingTo3: any,
  currentLocation0: any,
  currentLocation1: any,
  currentLocation2: any,
  currentLocation3: any,
  designationText0: any,
  designationText1: any
  designationText2: any,
  designationText3: any
  keyMatricImage0: any,
  keyMatricImage1: any,
  keyMatricImage2: any,
  keyMatricImage3: any,
  fundingInvestor0: any,
  fundingInvestor1: any,
  fundingInvestor2: any,
  fundingInvestor3: any,
  fundingRoundMonth0: any,
  fundingRoundMonth1: any,
  fundingRoundMonth2: any,
  fundingRoundMonth3: any,
  fundingAmount0: any,
  fundingAmount1: any,
  fundingAmount2: any,
  fundingAmount3: any,
  currency_id0: any,
  currency_id1: any,
  currency_id2: any,
  currency_id3: any,
  fundingRoundSeries0: any,
  fundingRoundSeries1: any,
  fundingRoundSeries2: any,
  fundingRoundSeries3: any,
  fundingRoundSeriesCount: number,
  HierarchyLevel1Count: number,
  HierarchyLevel2Count: number,
  HierarchyLevel3Count: number,
  HierarchyLevel4Count: number,

  hierarchyLevel2Name0: any
  hierarchyLevel2Name1: any
  hierarchyLevel2Name2: any
  hierarchyLevel2Name3: any
  hierarchyLevel2Name: any,
  hierarchyLevel2Position: any
  hierarchyLevel2Position0: any
  hierarchyLevel2Position1: any
  hierarchyLevel2Position2: any
  hierarchyLevel2Position3: any

  hierarchyLevel3Name0: any,
  hierarchyLevel3Name1: any,
  hierarchyLevel3Name2: any,
  hierarchyLevel3Name3: any,
  hierarchyLevel3Name: any,
  hierarchyLevel3Position: any,
  hierarchyLevel3Position0: any,
  hierarchyLevel3Position1: any,
  hierarchyLevel3Position2: any,
  hierarchyLevel3Position3: any,

  hierarchyLevel4Name0: any,
  hierarchyLevel4Name1: any,
  hierarchyLevel4Name2: any,
  hierarchyLevel4Name3: any,
  hierarchyLevel4Name: any,
  hierarchyLevel4Position: any,
  hierarchyLevel4Position0: any,
  hierarchyLevel4Position1: any,
  hierarchyLevel4Position2: any,
  hierarchyLevel4Position3: any,
  interViewlinkdinProfileLink0: any
  interViewlinkdinProfileLink1: any
  interViewlinkdinProfileLink2: any
  interViewlinkdinProfileLink3: any
  interViewlinkdinProfileLink: any
  waitForSaveDataLoader: any
  editPQCapsuleForm: boolean
  CMEBackgroundColor0: any,
  CMEBackgroundColor1: any,
  CMEBackgroundColor2: any,
  CMEBackgroundColor3: any,
  CME_ID: any
  presentEditForm: any

  fundingEditID0: any
  fundingEditID1: any
  fundingEditID2: any
  fundingEditID3: any
  fundingEditID4: any

  foundingEditID0: any,
  foundingEditID1: any,
  foundingEditID2: any,
  foundingEditID3: any,
  foundingEditID4: any,

  productEditID0: any,
  productEditID1: any,
  productEditID2: any,
  productEditID3: any,
  productEditID4: any,

  keyMatricsEditID0: any,
  keyMatricsEditID1: any,
  keyMatricsEditID2: any,
  keyMatricsEditID3: any,
  keyMatricsEditID4: any,
  keyMatricsEditID5: any,
  keyMatricsEditID6: any,
  keyMatricsEditID7: any,
  keyMatricsEditID8: any,
  keyMatricsEditID9: any,
  keyMatricsEditID10: any,
  keyMatricsEditID11: any,

  hierarcyLevel1EditID0: any,
  hierarcyLevel1EditID1: any,
  hierarcyLevel1EditID2: any,
  hierarcyLevel1EditID3: any,
  hierarcyLevel1EditID4: any,

  hierarcyLevel2EditID0: any,
  hierarcyLevel2EditID1: any,
  hierarcyLevel2EditID2: any,
  hierarcyLevel2EditID3: any,
  hierarcyLevel2EditID4: any,

  hierarcyLevel3EditID0: any,
  hierarcyLevel3EditID1: any,
  hierarcyLevel3EditID2: any,
  hierarcyLevel3EditID3: any,
  hierarcyLevel3EditID4: any,

  hierarcyLevel4EditID0: any,
  hierarcyLevel4EditID1: any,
  hierarcyLevel4EditID2: any,
  hierarcyLevel4EditID3: any,
  hierarcyLevel4EditID4: any,

  hierarcyLevelsID1: any,
  hierarcyLevelsID2: any,
  hierarcyLevelsID3: any,
  hierarcyLevelsID4: any,
  hierarcyLevelsID5: any,

  interviewPanelEditID0: any,
  interviewPanelEditID1: any,
  interviewPanelEditID2: any,
  interviewPanelEditID3: any,
  interviewPanelEditID4: any,
  companiesData: any

  snapShotHeading: any
  snapShotHeadingColor: any
  fundingHeading: any
  fundingHeadingColor: any
  foundersHeading: any
  foundersHeadingColor: any
  visionHeading: any
  visionHeadingColor: any
  missionHeading: any
  missionHeadingColor: any
  problemStatementHeading: any
  problemStatementHeadingColor: any
  overviewHeading: any
  overviewHeadingColor: any
  productsHeading: any
  productsHeadingColor: any
  keyMetricsHeading: any
  keyMetricsHeadingColor: any
  businessRoadMapHeading: any
  businessRoadMapHeadingColor: any
  techStackHeading: any
  techStackHeadingColor: any
  techLandScapeHeading: any
  techLandScapeHeadingColor: any
  techChallengesHeading: any
  techChallengesHeadingColor: any
  roadMapHeading: any
  roadMapHeadingColor: any
  techOrgHeading: any
  techOrgHeadingColor: any
  currentMandateHeading: any
  currentMandateHeadingColor: any
  interviewPanelHeading: any
  interviewPanelHeadingColor: any
  cultureDnaHeading: any
  cultureDnaHeadingColor: any
  confidentialityHeading: any
  confidentialityHeadingColor: any
  confirmModal: any
  deleteId: any
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCurrentUserApiCallId: string = "";
  savePQCapsuleApiCallId: string = "";
  getPQCapsuleFormList: string = "";
  currencyApiCallId: string = "";
  deletePQCapusuleForm: string = "";
  editPQCapusuleApiCallId: String = ""
  POSTApproveAPICall: String = "";
  GetCompaniesApiCallId: String = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentUser: '',
      companyName: '',
      companyBackgroundColor: '',
      companyBackgroundImage: '',
      companyTagline: '',
      isPreviewCompanyDetails: false,
      CompanyFoundedIn: '',
      CompanyHeaderquarter: '',
      CompanyFoundingMember: '',
      totalFunding: '',
      lastFundingRoundMoney: '',
      lastFundingRoundMonth: '',
      lastFundingRoundYear: '',
      companyStage: '',
      companyValuation: '',
      numberOfEmployees: '',
      companyLogoImage: '',
      productName: '',
      productText: RichTextEditor.createEmptyValue(),
      productText0: RichTextEditor.createEmptyValue(),
      companyVisionText: RichTextEditor.createEmptyValue(),
      productBackgroundImage: RichTextEditor.createEmptyValue(),
      keyMetricsText: RichTextEditor.createEmptyValue(),
      visionImage: '',
      companyMissionText: RichTextEditor.createEmptyValue(),
      missionImage: '',
      moreFounders: [],
      problemStatementText: RichTextEditor.createEmptyValue(),
      problemStatmentImage: '',
      overViewText: RichTextEditor.createEmptyValue(), //for Rich Text editor
      overViewImage: '',
      moreProducts: [],
      moreKeyMetrics: [],
      businessRoadMapText: RichTextEditor.createEmptyValue(),
      techLandScapeText: RichTextEditor.createEmptyValue(),
      techChallangesText: RichTextEditor.createEmptyValue(),
      techOrgText: RichTextEditor.createEmptyValue(),
      techRoadMapText: RichTextEditor.createEmptyValue(),
      hrLevel1: [],
      hrLevel2: [],
      hrLevel3: [],
      hrLevel4: [],
      moreDeisgnations: [],
      moreNames: [],
      cultureDnaText: RichTextEditor.createEmptyValue(),
      snapshotBackgroundColor: '',
      fundingBackgroundColor: '',
      founderBackgroundColor: '',
      productBackgroundColor: '',
      keyMetricsBackgroundColor: '',
      bussinessroadBackgroundColor: '',
      techBackgroundColor: '',
      techLandBackgroundColor: '',
      techChallengeBackgroundColor: '',
      techRoadMapBackgroundColor: '',
      techOrgBackgroundColor: '',
      CMEBackgroundColor: '',
      CMEBackgroundColor0: "",
      CMEBackgroundColor1: "",
      CMEBackgroundColor2: "",
      CMEBackgroundColor3: "",

      interviewBackgroundColor: '',
      cultureBackgroundColor: '',
      confindentialBackgroundColor: '',
      fundingAmount: '',
      fundingRoundYear: '',
      fundingRoundMonth: '',
      fundingRoundSeries: '',
      fundingInvestor: '',
      foundersDesignation: '',
      founderPeopleImage: '',
      foundersbriefBackground: '',
      founderslinkdinProfileLink: '',
      foundersName: '',
      keyMetricsBackgroundImage: '',
      bussinessroadBackgroundImage: '',
      techBackgroundImage: '',
      techLandBackgroundImage: '',
      techChallengeBackgroundImage: '',
      techRoadMapBackgroundImage: '',
      interviewPanelImage: '',
      cultureBackgroundImage: '',
      hierarchyLevelName: '',
      hierarchyLevelPosition: "",
      hierarchyLevelName2: '',
      hierarchyLevelPosition2: "",
      hierarchyLevelName3: '',
      hierarchyLevelPosition3: "",
      hierarchyLevelName1: '',
      hierarchyLevelPosition1: "",
      hierarchyLevelPosition0: "",
      hierarchyLevelName0: '',
      currentDesignation: "",
      currentReportingTo: "",
      currentLocation: "",
      interViewPanelName: "",
      linkdinProfileLink: "",
      confindentialText: "",
      vectorElement: "",
      vectorBackgroundColor: "",
      interViewRoundName: '',
      CMEText: RichTextEditor.createEmptyValue(),
      problemStatementColor: '',
      overViewColor: '',
      fundingRounds: [],
      founderPeopleImage0: "",
      founderPeopleImage1: "",
      founderPeopleImage2: "",
      founderPeopleImage3: "",
      isSavePQCapsule: false,
      pqCapsuleFormList: [],
      isDropDownOpenId: null,
      isShowPQListDropDown: false,
      previewPQForm: '',
      thisYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      currencyOptions: [],
      currency_id: '',
      countAddMoreFoundersRound: -1,
      countAddMoreProduct: -1,
      countKeyMatrics: -1,
      countInterViewPanel: -1,
      cmeCountPanel: -1,
      foundersName0: "",
      foundersName1: "",
      foundersName2: "",
      foundersName3: "",
      foundersDesignation0: "",
      foundersDesignation1: "",
      foundersDesignation2: "",
      foundersDesignation3: "",
      briefBackground0: "",
      briefBackground1: "",
      briefBackground2: "",
      briefBackground3: "",
      linkdinProfileLine0: "",
      linkdinProfileLine1: "",
      linkdinProfileLine2: "",
      linkdinProfileLine3: "",
      productName0: "",
      productName1: "",
      productName2: "",
      productName3: "",
      productText1: RichTextEditor.createEmptyValue(),
      productText2: RichTextEditor.createEmptyValue(),
      productText3: RichTextEditor.createEmptyValue(),
      keyMatricText0: RichTextEditor.createEmptyValue(),
      keyMatricText1: RichTextEditor.createEmptyValue(),
      keyMatricText2: RichTextEditor.createEmptyValue(),
      keyMatricText3: RichTextEditor.createEmptyValue(),
      keyMatricText4: RichTextEditor.createEmptyValue(),
      keyMatricText5: RichTextEditor.createEmptyValue(),
      keyMatricText6: RichTextEditor.createEmptyValue(),
      keyMatricText7: RichTextEditor.createEmptyValue(),
      keyMatricText8: RichTextEditor.createEmptyValue(),
      keyMatricText9: RichTextEditor.createEmptyValue(),
      keyMatricText10: RichTextEditor.createEmptyValue(),
      keyMatricText11: RichTextEditor.createEmptyValue(),
      keyMetricsBackgroundImage0: "",
      keyMetricsBackgroundImage1: "",
      keyMetricsBackgroundImage2: "",
      keyMetricsBackgroundImage3: "",
      keyMetricsBackgroundColor0: "",
      keyMetricsBackgroundColor1: "",
      keyMetricsBackgroundColor2: "",
      keyMetricsBackgroundColor3: "",
      interviewPanelImage0: "",
      interviewPanelImage1: "",
      interviewPanelImage2: "",
      interviewPanelImage3: "",
      linkdinProfileLink0: "",
      linkdinProfileLink1: "",
      linkdinProfileLink2: "",
      linkdinProfileLink3: "",
      interViewRoundName0: "",
      interViewRoundName1: "",
      interViewRoundName2: "",
      interViewRoundName3: "",
      interViewPanelName0: "",
      interViewPanelName1: "",
      interViewPanelName2: "",
      interViewPanelName3: "",
      currentDesignation0: "",
      currentDesignation1: "",
      currentDesignation2: "",
      currentDesignation3: "",
      currentReportingTo0: "",
      currentReportingTo1: "",
      currentReportingTo2: "",
      currentReportingTo3: "",
      currentLocation0: "",
      currentLocation1: "",
      currentLocation2: "",
      currentLocation3: "",
      designationText0: RichTextEditor.createEmptyValue(),
      designationText1: RichTextEditor.createEmptyValue(),
      designationText2: RichTextEditor.createEmptyValue(),
      designationText3: RichTextEditor.createEmptyValue(),
      keyMatricImage0: "",
      keyMatricImage1: "",
      keyMatricImage2: "",
      keyMatricImage3: "",
      fundingInvestor0: "",
      fundingInvestor1: "",
      fundingInvestor2: "",
      fundingInvestor3: "",
      fundingRoundMonth0: "",
      fundingRoundMonth1: "",
      fundingRoundMonth2: "",
      fundingRoundMonth3: "",
      fundingAmount0: "",
      fundingAmount1: "",
      fundingAmount2: "",
      fundingAmount3: "",
      currency_id0: "",
      currency_id1: "",
      currency_id2: "",
      currency_id3: "",
      fundingRoundSeries0: '',
      fundingRoundSeries1: '',
      fundingRoundSeries2: '',
      fundingRoundSeries3: '',
      fundingRoundSeriesCount: -1,
      HierarchyLevel1Count: -1,
      HierarchyLevel2Count: -1,
      HierarchyLevel3Count: -1,
      HierarchyLevel4Count: -1,
      hierarchyLevel2Name0: "",
      hierarchyLevel2Name1: "",
      hierarchyLevel2Name2: "",
      hierarchyLevel2Name3: "",
      hierarchyLevel2Name: "",
      hierarchyLevel2Position: "",
      hierarchyLevel2Position0: "",
      hierarchyLevel2Position1: "",
      hierarchyLevel2Position2: "",
      hierarchyLevel2Position3: "",
      hierarchyLevel3Name0: "",
      hierarchyLevel3Name1: "",
      hierarchyLevel3Name2: "",
      hierarchyLevel3Name3: "",
      hierarchyLevel3Name: "",
      hierarchyLevel3Position: "",
      hierarchyLevel3Position0: "",
      hierarchyLevel3Position1: "",
      hierarchyLevel3Position2: "",
      hierarchyLevel3Position3: "",
      hierarchyLevel4Name0: "",
      hierarchyLevel4Name1: "",
      hierarchyLevel4Name2: "",
      hierarchyLevel4Name3: "",
      hierarchyLevel4Name: "",
      hierarchyLevel4Position: "",
      hierarchyLevel4Position0: "",
      hierarchyLevel4Position1: "",
      hierarchyLevel4Position2: "",
      hierarchyLevel4Position3: "",
      interViewlinkdinProfileLink0: "",
      interViewlinkdinProfileLink1: "",
      interViewlinkdinProfileLink2: "",
      interViewlinkdinProfileLink3: "",
      interViewlinkdinProfileLink: "",
      productBackgroundImage0: "",
      productBackgroundImage1: "",
      productBackgroundImage2: "",
      productBackgroundImage3: "",
      waitForSaveDataLoader: "",
      editPQCapsuleForm: false,
      CME_ID: '',
      presentEditForm: "",

      fundingEditID0: "",
      fundingEditID1: "",
      fundingEditID2: "",
      fundingEditID3: "",
      fundingEditID4: "",

      foundingEditID0: "",
      foundingEditID1: "",
      foundingEditID2: "",
      foundingEditID3: "",
      foundingEditID4: "",

      productEditID0: "",
      productEditID1: "",
      productEditID2: "",
      productEditID3: "",
      productEditID4: "",

      keyMatricsEditID0: "",
      keyMatricsEditID1: "",
      keyMatricsEditID2: "",
      keyMatricsEditID3: "",
      keyMatricsEditID4: "",
      keyMatricsEditID5: "",
      keyMatricsEditID6: "",
      keyMatricsEditID7: "",
      keyMatricsEditID8: "",
      keyMatricsEditID9: "",
      keyMatricsEditID10: "",
      keyMatricsEditID11: "",

      hierarcyLevel1EditID0: "",
      hierarcyLevel1EditID1: "",
      hierarcyLevel1EditID2: "",
      hierarcyLevel1EditID3: "",
      hierarcyLevel1EditID4: "",

      hierarcyLevel2EditID0: "",
      hierarcyLevel2EditID1: "",
      hierarcyLevel2EditID2: "",
      hierarcyLevel2EditID3: "",
      hierarcyLevel2EditID4: "",

      hierarcyLevel3EditID0: "",
      hierarcyLevel3EditID1: "",
      hierarcyLevel3EditID2: "",
      hierarcyLevel3EditID3: "",
      hierarcyLevel3EditID4: "",

      hierarcyLevel4EditID0: "",
      hierarcyLevel4EditID1: "",
      hierarcyLevel4EditID2: "",
      hierarcyLevel4EditID3: "",
      hierarcyLevel4EditID4: "",

      interviewPanelEditID0: "",
      interviewPanelEditID1: "",
      interviewPanelEditID2: "",
      interviewPanelEditID3: "",
      interviewPanelEditID4: "",

      hierarcyLevelsID1: "",
      hierarcyLevelsID2: "",
      hierarcyLevelsID3: "",
      hierarcyLevelsID4: "",
      hierarcyLevelsID5: "",

      keyMetricsBackgroundColor4: "",
      keyMetricsBackgroundColor5: "",
      keyMetricsBackgroundColor6: "",
      keyMetricsBackgroundColor7: "",
      keyMetricsBackgroundColor8: "",
      keyMetricsBackgroundColor9: "",
      keyMetricsBackgroundColor10: "",
      companiesData: [],

      snapShotHeading: "",
      snapShotHeadingColor: "",
      fundingHeading: "",
      fundingHeadingColor: "",
      foundersHeading: "",
      foundersHeadingColor: "",
      visionHeading: "",
      visionHeadingColor: "",
      missionHeading: "",
      missionHeadingColor: "",
      problemStatementHeading: "",
      problemStatementHeadingColor: "",
      overviewHeading: "",
      overviewHeadingColor: "",
      productsHeading: "",
      productsHeadingColor: "",
      keyMetricsHeading: "",
      keyMetricsHeadingColor: "",
      businessRoadMapHeading: "",
      businessRoadMapHeadingColor: "",
      techStackHeading: "",
      techStackHeadingColor: "",
      techLandScapeHeading: "",
      techLandScapeHeadingColor: "",
      techChallengesHeading: "",
      techChallengesHeadingColor: "",
      roadMapHeading: "",
      roadMapHeadingColor: "",
      techOrgHeading: "",
      techOrgHeadingColor: "",
      currentMandateHeading: "",
      currentMandateHeadingColor: "",
      interviewPanelHeading: "",
      interviewPanelHeadingColor: "",
      cultureDnaHeading: "",
      cultureDnaHeadingColor: "",
      confidentialityHeading: "",
      confidentialityHeadingColor: "",
      confirmModal: false,
      deleteId: ''
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        //get companies 
        if (apiRequestCallId === this.GetCompaniesApiCallId) {

          this.setState({
            companiesData: responseJson?.companies?.map((x: any) => {
              return {
                value: x.id, label: x.name
              }
            })
          })
        }
        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            currentUser: responseJson?.data?.attributes,
          })
        }

        if (apiRequestCallId === this.savePQCapsuleApiCallId) {
          alert(responseJson?.pq_capsule ? 'Data saved successfully' : ' Some error occur');
          this.setState({ isSavePQCapsule: false, waitForSaveDataLoader: false })
          window.location.reload();
        }

        if (apiRequestCallId === this.getPQCapsuleFormList) {
          this.setState({ pqCapsuleFormList: responseJson?.data })
        }
        // get currencyData 
        if (apiRequestCallId === this.currencyApiCallId) {
          this.setState({
            currencyOptions: responseJson?.currency?.map((x: any) => {
              return {
                label: x.currency_symbol, value: x.id
              }
            })
          })
        }
        // delete the pq form 
        if (apiRequestCallId === this.deletePQCapusuleForm) {
          if (responseJson.message) {
            alert("Deleted Successfully");
            window.location.reload();
          }
        }
        //Edit
        if (apiRequestCallId === this.editPQCapusuleApiCallId) {
          if (responseJson.message || responseJson.data) {
            alert("Updated Successfully");
            window.location.reload();
          } else {
            alert("Some Error Occur");
            this.setState({ waitForSaveDataLoader: false })
          }

        }

        //Approve PQ-capsule
        if (apiRequestCallId === this.POSTApproveAPICall) {
          if (responseJson.updated_approval_date) {
            alert("Card is Approved Successfully")
          } else {
            alert("Some error occur")
          }
        }

      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getCurrentUser();
    await this.getPQCapsuleForms();
    await this.getCurrency()
    await this.getCompanies()

    if (window.localStorage.getItem('scroll') === "down") {
      this.scrollBottom()
    }
    document.addEventListener("mousedown", this.handleClickOutside);


  }

  // @ts-ignore
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e?: any) => {
    // @ts-ignore
    if (document.getElementById("drop-downDashboard") && !document.getElementById("drop-downDashboard").contains(e.target)) {
      this.setState({ isDropDownOpenId: null });
    }
  };

  scrollBottom() {
    //@ts-ignore
    document.getElementById("agreement-company-page").scrollIntoView()
    window.localStorage.removeItem('scroll')
  }

  handleSelect = (selectedOption: any, type: any) => {
    if (type === 'companyName') {
      this.setState({
        companyName: selectedOption.value
      })
    }
  }


  //getCompanies
  getCompanies = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCompaniesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompanies
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCurrency

  getCurrency = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currencyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currencyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  //getCurrentUser
  getCurrentUser = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSubmit = (e?: any) => {
    e?.preventDefault()
    this.setState({ waitForSaveDataLoader: true })
    var formData = new FormData();


    this.state.editPQCapsuleForm ? formData.append('company_id', this.state.companiesData.filter((ele: any) => ele?.label === this.state?.companyName)[0]?.value) : formData.append('company_id', this.state.companyName);
    this.state.companyLogoImage?.file && formData.append('logo', this.state.companyLogoImage?.file || "");
    formData.append('tagline', this.state.companyTagline);
    // formData.append('tagline_heading_name', this.state.visionHeading);
    // formData.append('tagline_heading_color', this.state.visionHeadingColor);
    this.state.companyBackgroundImage?.file && formData.append('tagline_image', this.state.companyBackgroundImage?.file || "");
    formData.append('vision', this.state.companyVisionText?.toString('html'));
    formData.append('vision_heading_name', this.state.visionHeading);
    formData.append('vision_heading_color', this.state.visionHeadingColor);

    this.state.visionImage?.file && formData.append('vision_image', this.state.visionImage?.file || "");
    formData.append('mission', this.state.companyMissionText?.toString('html'));
    formData.append('mission_heading_name', this.state.missionHeading);
    formData.append('mission_heading_color', this.state.missionHeadingColor);

    this.state.missionImage?.file && formData.append('mission_image', this.state.missionImage?.file || "");
    formData.append('problem_statement', this.state.problemStatementText?.toString('html'));
    formData.append('problem_statement_color_heading_name', this.state.problemStatementHeading);
    formData.append('problem_statement_color_heading_color', this.state.problemStatementHeadingColor);

    this.state.problemStatmentImage?.file && formData.append('problem_statement_image', this.state.problemStatmentImage?.file || "");
    formData.append('overview', this.state.overViewText?.toString('html'));
    formData.append('overview_color_heading_name', this.state.overviewHeading);
    formData.append('overview_color_heading_color', this.state.overviewHeadingColor);

    this.state.overViewImage?.file && formData.append('overview_image', this.state.overViewImage?.file || "");
    formData.append('business_roadmap', this.state.businessRoadMapText?.toString('html'));
    formData.append('business_roadmap_color_heading_name', this.state.businessRoadMapHeading);
    formData.append('business_roadmap_color_heading_color', this.state.businessRoadMapHeadingColor);

    this.state.bussinessroadBackgroundImage?.file && formData.append('business_roadmap_image', this.state.bussinessroadBackgroundImage?.file || "");
    this.state.techBackgroundImage?.file && formData.append('tech_stack_image', this.state.techBackgroundImage?.file || "");
    this.state.techBackgroundColor && formData.append('tech_stack_color', this.state.techBackgroundColor || "");


    formData.append('tech_stack_color_heading_name', this.state.techStackHeading);
    formData.append('tech_stack_color_heading_color', this.state.techStackHeadingColor);


    formData.append('tech_landscape', this.state.techLandScapeText?.toString('html'));
    formData.append('tech_landscape_color_heading_name', this.state.techLandScapeHeading);
    formData.append('tech_landscape_color_heading_color', this.state.techLandScapeHeadingColor);

    this.state.techLandBackgroundImage?.file && formData.append('tech_landscape_image', this.state.techLandBackgroundImage?.file || "");
    formData.append('tech_challenges', this.state.techChallangesText?.toString('html'));
    formData.append('tech_challenges_color_heading_name', this.state.techChallengesHeading);
    formData.append('tech_challenges_color_heading_color', this.state.techChallengesHeadingColor);

    this.state.techChallengeBackgroundImage?.file && formData.append('tech_challenge_image', this.state.techChallengeBackgroundImage?.file || "");
    formData.append('tech_roadmap', this.state.techRoadMapText?.toString('html'));
    formData.append('tech_roadmap_heading_name', this.state.roadMapHeading);
    formData.append('tech_roadmap_heading_color', this.state.roadMapHeadingColor);

    this.state.techRoadMapBackgroundImage?.file && formData.append('tech_roadmap_image', this.state.techRoadMapBackgroundImage?.file || "");
    formData.append('tech_org', this.state.techOrgText?.toString('html'));
    formData.append('tech_org_heading_name', this.state.techOrgHeading);
    formData.append('tech_org_heading_color', this.state.techOrgHeadingColor);


    formData.append('culture_dna', this.state.cultureDnaText?.toString('html'));
    formData.append('culture_dna_heading_name', this.state.cultureDnaHeading);
    formData.append('culture_dna_heading_color', this.state.cultureDnaHeadingColor);

    this.state.cultureBackgroundImage?.file && formData.append('culture_dna_image', this.state.cultureBackgroundImage?.file || "");
    formData.append('confidentiality', this.state.confindentialText);
    formData.append('confidentiality_heading_name', this.state.confidentialityHeading);
    formData.append('confidentiality_heading_color', this.state.confidentialityHeadingColor);




    this.state.companyBackgroundColor && formData.append('color', this.state.companyBackgroundColor);
    formData.append('problem_statement_color', this.state.problemStatementColor);
    formData.append('overview_color', this.state.overViewColor);
    this.state.bussinessroadBackgroundColor && formData.append('business_roadmap_color', this.state.bussinessroadBackgroundColor);
    this.state.techLandBackgroundColor && formData.append('tech_landscape_color', this.state.techLandBackgroundColor);
    this.state.techChallengeBackgroundColor && formData.append('tech_challenges_color', this.state.techChallengeBackgroundColor);
    formData.append('tech_roadmap_color', this.state.techRoadMapBackgroundColor);
    formData.append('tech_roadmap_color_heading_name', this.state.techRoadMapBackgroundColor);
    formData.append('tech_roadmap_color_heading_color', this.state.techRoadMapBackgroundColor);
    formData.append('tech_org_color', this.state.techOrgBackgroundColor);
    formData.append('tech_org_color_heading_name', this.state.techOrgBackgroundColor);
    formData.append('tech_org_color_heading_color', this.state.techOrgBackgroundColor);
    formData.append('culture_dna_color', this.state.cultureBackgroundColor);
    formData.append('culture_dna_color_heading_name', this.state.cultureDnaHeading);
    formData.append('culture_dna_color_heading_color', this.state.cultureDnaHeadingColor);
    formData.append('confidentiality_color', this.state.confindentialBackgroundColor);
    formData.append('confidentiality_color_heading_name', this.state.confidentialityHeading);
    formData.append('confidentiality_color_heading_color', this.state.confidentialityHeadingColor);
    formData.append('vector_element', this.state.vectorElement);
    formData.append('confidentiality_vector_color', this.state.vectorBackgroundColor);

    formData.append('snapshot_attributes[founded_in]', this.state.CompanyFoundedIn);
    formData.append('snapshot_attributes[headquarters]', this.state.CompanyHeaderquarter);
    formData.append('snapshot_attributes[founding_members]', this.state.CompanyFoundingMember);
    formData.append('snapshot_attributes[total_funding]', this.state.totalFunding);
    formData.append('snapshot_attributes[last_funding_round]', this.state.lastFundingRoundMoney);
    formData.append('snapshot_attributes[funding_date]', this.state.lastFundingRoundMonth); //lastfundingRoundYear
    formData.append('snapshot_attributes[company_stage]', this.state.companyStage);
    formData.append('snapshot_attributes[valuation]', this.state.companyValuation);
    formData.append('snapshot_attributes[number_of_employees]', this.state.numberOfEmployees);
    formData.append('snapshot_attributes[color]', this.state.snapshotBackgroundColor);
    formData.append('snapshot_attributes[heading_name]', this.state.snapShotHeading);
    formData.append('snapshot_attributes[heading_color]', this.state.snapShotHeadingColor);

    // Funding
    formData.append('fundings_attributes[0][funding_date]', this.state.fundingRoundMonth); //fundingRoundYear
    formData.append('fundings_attributes[0][funding_amount]', this.state.fundingAmount);
    formData.append('fundings_attributes[0][currency_id]', this.state.currency_id);
    formData.append('fundings_attributes[0][funding_round]', this.state.fundingRoundSeries);
    formData.append('fundings_attributes[0][investor]', this.state.fundingInvestor);
    formData.append('funding_color', this.state.fundingBackgroundColor);
    formData.append('fundings_attributes[0][heading_name]', this.state.fundingHeading);
    formData.append('fundings_attributes[0][heading_color]', this.state.fundingHeadingColor);
    this.state.editPQCapsuleForm && formData.append('fundings_attributes[0][id]', this.state.fundingEditID0);

    if (this.state.fundingAmount0) {
      formData.append('fundings_attributes[1][funding_date]', this.state.fundingRoundMonth0); //fundingRoundYear
      formData.append('fundings_attributes[1][funding_amount]', this.state.fundingAmount0);
      formData.append('fundings_attributes[1][currency_id]', this.state.currency_id0);
      formData.append('fundings_attributes[1][funding_round]', this.state.fundingRoundSeries0);
      formData.append('fundings_attributes[1][investor]', this.state.fundingInvestor0);
      this.state.editPQCapsuleForm && this.state.fundingEditID1 && formData.append('fundings_attributes[1][id]', this.state.fundingEditID1);
    }

    if (this.state.fundingAmount1) {
      formData.append('fundings_attributes[2][funding_date]', this.state.fundingRoundMonth1); //fundingRoundYear
      formData.append('fundings_attributes[2][funding_amount]', this.state.fundingAmount1);
      formData.append('fundings_attributes[2][currency_id]', this.state.currency_id1);
      formData.append('fundings_attributes[2][funding_round]', this.state.fundingRoundSeries1);
      formData.append('fundings_attributes[2][investor]', this.state.fundingInvestor1);
      this.state.editPQCapsuleForm && this.state.fundingEditID2 && formData.append('fundings_attributes[2][id]', this.state.fundingEditID2);
    }

    if (this.state.fundingAmount2) {
      formData.append('fundings_attributes[3][funding_date]', this.state.fundingRoundMonth2); //fundingRoundYear
      formData.append('fundings_attributes[3][funding_amount]', this.state.fundingAmount2);
      formData.append('fundings_attributes[3][currency_id]', this.state.currency_id2);
      formData.append('fundings_attributes[3][funding_round]', this.state.fundingRoundSeries2);
      formData.append('fundings_attributes[3][investor]', this.state.fundingInvestor2);
      this.state.editPQCapsuleForm && this.state.fundingEditID3 && formData.append('fundings_attributes[3][id]', this.state.fundingEditID3);
    }

    if (this.state.fundingAmount3) {
      formData.append('fundings_attributes[4][funding_date]', this.state.fundingRoundMonth3); //fundingRoundYear
      formData.append('fundings_attributes[4][funding_amount]', this.state.fundingAmount3);
      formData.append('fundings_attributes[4][currency_id]', this.state.currency_id3);
      formData.append('fundings_attributes[4][funding_round]', this.state.fundingRoundSeries3);
      formData.append('fundings_attributes[4][investor]', this.state.fundingInvestor3);
      this.state.editPQCapsuleForm && this.state.fundingEditID4 && formData.append('fundings_attributes[4][id]', this.state.fundingEditID4);
    }

    // Founders section
    formData.append('founders_attributes[0][name]', this.state.foundersName);
    formData.append('founders_attributes[0][designation]', this.state.foundersDesignation);
    formData.append('founders_attributes[0][background]', this.state.foundersbriefBackground);
    formData.append('founders_attributes[0][linkedin_profile_link]', this.state.founderslinkdinProfileLink);
    formData.append('founder_color', this.state.founderBackgroundColor);
    formData.append('founders_attributes[0][heading_name]', this.state.foundersHeading);
    formData.append('founders_attributes[0][heading_color]', this.state.foundersHeadingColor);
    this.state.editPQCapsuleForm && formData.append('founders_attributes[0][id]', this.state.foundingEditID0);
    this.state.founderPeopleImage?.file && formData.append('founders_attributes[0][founder_image]', this.state.founderPeopleImage?.file || "");

    if (this.state.foundersName0) {
      formData.append('founders_attributes[1][name]', this.state.foundersName0);
      formData.append('founders_attributes[1][designation]', this.state.foundersDesignation0);
      formData.append('founders_attributes[1][background]', this.state.briefBackground0);
      formData.append('founders_attributes[1][linkedin_profile_link]', this.state.linkdinProfileLink0);
      this.state.editPQCapsuleForm && formData.append('founders_attributes[1][id]', this.state.foundingEditID1);
      this.state.founderPeopleImage0?.file && formData.append('founders_attributes[1][founder_image]', this.state.founderPeopleImage0?.file || "");
    }

    if (this.state.foundersName1) {
      formData.append('founders_attributes[2][name]', this.state.foundersName1);
      formData.append('founders_attributes[2][designation]', this.state.foundersDesignation1);
      formData.append('founders_attributes[2][background]', this.state.briefBackground1);
      formData.append('founders_attributes[2][linkedin_profile_link]', this.state.linkdinProfileLink1);
      this.state.editPQCapsuleForm && this.state.foundingEditID2 && formData.append('founders_attributes[2][id]', this.state.foundingEditID2);
      this.state.founderPeopleImage1?.file && formData.append('founders_attributes[2][founder_image]', this.state.founderPeopleImage1?.file || "");
    }

    if (this.state.foundersName2) {
      formData.append('founders_attributes[3][name]', this.state.foundersName2);
      formData.append('founders_attributes[3][designation]', this.state.foundersDesignation2);
      formData.append('founders_attributes[3][background]', this.state.briefBackground2);
      formData.append('founders_attributes[3][linkedin_profile_link]', this.state.linkdinProfileLink2);
      this.state.editPQCapsuleForm && this.state.foundingEditID3 && formData.append('founders_attributes[3][id]', this.state.foundingEditID3);
      this.state.founderPeopleImage2?.file && formData.append('founders_attributes[3][founder_image]', this.state.founderPeopleImage2?.file || "");
    }

    if (this.state.foundersName3) {
      formData.append('founders_attributes[4][name]', this.state.foundersName3);
      formData.append('founders_attributes[4][designation]', this.state.foundersDesignation3);
      formData.append('founders_attributes[4][background]', this.state.briefBackground3);
      formData.append('founders_attributes[4][linkedin_profile_link]', this.state.linkdinProfileLink3);
      this.state.editPQCapsuleForm && this.state.foundingEditID3 && formData.append('founders_attributes[4][id]', this.state.foundingEditID4);
      this.state.founderPeopleImage3?.file && formData.append('founders_attributes[4][founder_image]', this.state.founderPeopleImage3?.file || "");
    }

    // PQ capsule
    this.state.productName0 && formData.append('pq_capsule_products_attributes[1][name]', this.state.productName0);
    this.state.productName0 && formData.append('pq_capsule_products_attributes[1][product_description]', this.state.productText0?.toString('html'));
    this.state.productName1 && formData.append('pq_capsule_products_attributes[2][name]', this.state.productName1);
    this.state.productName1 && formData.append('pq_capsule_products_attributes[2][product_description]', this.state.productText1?.toString('html'));
    this.state.productName2 && formData.append('pq_capsule_products_attributes[3][name]', this.state.productName2);
    this.state.productName2 && formData.append('pq_capsule_products_attributes[3][product_description]', this.state.productText2?.toString('html'));
    this.state.productName3 && formData.append('pq_capsule_products_attributes[4][name]', this.state.productName3);
    this.state.productName3 && formData.append('pq_capsule_products_attributes[4][product_description]', this.state.productText3?.toString('html'));
    formData.append('pq_capsule_products_attributes[0][name]', this.state.productName);
    formData.append('pq_capsule_products_attributes[0][product_description]', this.state.productText?.toString('html'));
    formData.append('pq_capsule_product_color', this.state.productBackgroundColor);
    formData.append('pq_capsule_products_attributes[0][heading_name]', this.state.productsHeading);
    formData.append('pq_capsule_products_attributes[0][heading_color]', this.state.productsHeadingColor);

    this.state.productBackgroundImage?.file && formData.append('pq_capsule_products_attributes[0][pq_capsule_product_image]', this.state.productBackgroundImage?.file || "");

    this.state.productName0 && this.state.productBackgroundImage0?.file && formData.append('pq_capsule_products_attributes[1][pq_capsule_product_image]', this.state.productBackgroundImage0?.file || "");
    this.state.productName1 && this.state.productBackgroundImage1?.file && formData.append('pq_capsule_products_attributes[2][pq_capsule_product_image]', this.state.productBackgroundImage1?.file || "");
    this.state.productName2 && this.state.productBackgroundImage2?.file && formData.append('pq_capsule_products_attributes[3][pq_capsule_product_image]', this.state.productBackgroundImage2?.file || "");
    this.state.productName3 && this.state.productBackgroundImage3?.file && formData.append('pq_capsule_products_attributes[4][pq_capsule_product_image]', this.state.productBackgroundImage3?.file || "");

    if (this.state.editPQCapsuleForm) {
      this.state.productEditID0 && formData.append('pq_capsule_products_attributes[0][id]', this.state.productEditID0);
      this.state.productEditID1 && formData.append('pq_capsule_products_attributes[1][id]', this.state.productEditID1);
      this.state.productEditID2 && formData.append('pq_capsule_products_attributes[2][id]', this.state.productEditID2);
      this.state.productEditID3 && formData.append('pq_capsule_products_attributes[3][id]', this.state.productEditID3);
      this.state.productEditID4 && formData.append('pq_capsule_products_attributes[4][id]', this.state.productEditID4);
    }

    // Key metrics

    //color
    formData.append('key_metrics_attributes[0][heading_name]', this.state.keyMetricsHeading);
    formData.append('key_metrics_attributes[0][heading_color]', this.state.keyMetricsHeadingColor);
    formData.append('key_metrics_attributes[0][back_color]', this.state.keyMetricsBackgroundColor);
    formData.append('key_metrics_attributes[1][back_color]', this.state.keyMetricsBackgroundColor0);
    formData.append('key_metrics_attributes[2][back_color]', this.state.keyMetricsBackgroundColor1);
    formData.append('key_metrics_attributes[3][back_color]', this.state.keyMetricsBackgroundColor2);
    formData.append('key_metrics_attributes[4][back_color]', this.state.keyMetricsBackgroundColor3);
    formData.append('key_metrics_attributes[5][back_color]', this.state.keyMetricsBackgroundColor4);
    formData.append('key_metrics_attributes[6][back_color]', this.state.keyMetricsBackgroundColor5);
    formData.append('key_metrics_attributes[7][back_color]', this.state.keyMetricsBackgroundColor6);
    formData.append('key_metrics_attributes[8][back_color]', this.state.keyMetricsBackgroundColor7);
    formData.append('key_metrics_attributes[9][back_color]', this.state.keyMetricsBackgroundColor8);
    formData.append('key_metrics_attributes[10][back_color]', this.state.keyMetricsBackgroundColor9);
    formData.append('key_metrics_attributes[11][back_color]', this.state.keyMetricsBackgroundColor10);

    this.state.keyMetricsBackgroundImage?.file && formData.append('key_metric_image', this.state.keyMetricsBackgroundImage?.file || "");

    this.state.keyMetricsText.toString('html') && formData.append('key_metrics_attributes[0][key_metrics]', this.state.keyMetricsText.toString('html'));
    this.state.keyMatricText0.toString('html') && formData.append('key_metrics_attributes[1][key_metrics]', this.state.keyMatricText0.toString('html'));
    this.state.keyMatricText1.toString('html') && formData.append('key_metrics_attributes[2][key_metrics]', this.state.keyMatricText1.toString('html'));
    this.state.keyMatricText2.toString('html') && formData.append('key_metrics_attributes[3][key_metrics]', this.state.keyMatricText2.toString('html'));
    this.state.keyMatricText3.toString('html') && formData.append('key_metrics_attributes[4][key_metrics]', this.state.keyMatricText3.toString('html'));
    this.state.keyMatricText4.toString('html') && formData.append('key_metrics_attributes[5][key_metrics]', this.state.keyMatricText4.toString('html'));
    this.state.keyMatricText5.toString('html') && formData.append('key_metrics_attributes[6][key_metrics]', this.state.keyMatricText5.toString('html'));
    this.state.keyMatricText6.toString('html') && formData.append('key_metrics_attributes[7][key_metrics]', this.state.keyMatricText6.toString('html'));
    this.state.keyMatricText7.toString('html') && formData.append('key_metrics_attributes[8][key_metrics]', this.state.keyMatricText7.toString('html'));
    this.state.keyMatricText8.toString('html') && formData.append('key_metrics_attributes[9][key_metrics]', this.state.keyMatricText8.toString('html'));
    this.state.keyMatricText9.toString('html') && formData.append('key_metrics_attributes[10][key_metrics]', this.state.keyMatricText9.toString('html'));
    this.state.keyMatricText10.toString('html') && formData.append('key_metrics_attributes[11][key_metrics]', this.state.keyMatricText10.toString('html'));
    // this.state.keyMatricText11.toString('html') && formData.append('key_metrics_attributes[11][key_metrics]', this.state.keyMatricText11.toString('html'));

    if (this.state.editPQCapsuleForm) {
      this.state.keyMatricsEditID0 && formData.append('key_metrics_attributes[0][id]', this.state.keyMatricsEditID0)
      this.state.keyMatricsEditID1 && formData.append('key_metrics_attributes[1][id]', this.state.keyMatricsEditID1)
      this.state.keyMatricsEditID2 && formData.append('key_metrics_attributes[2][id]', this.state.keyMatricsEditID2)
      this.state.keyMatricsEditID3 && formData.append('key_metrics_attributes[3][id]', this.state.keyMatricsEditID3)
      this.state.keyMatricsEditID4 && formData.append('key_metrics_attributes[4][id]', this.state.keyMatricsEditID4)
      this.state.keyMatricsEditID5 && formData.append('key_metrics_attributes[5][id]', this.state.keyMatricsEditID5)
      this.state.keyMatricsEditID6 && formData.append('key_metrics_attributes[6][id]', this.state.keyMatricsEditID6)
      this.state.keyMatricsEditID7 && formData.append('key_metrics_attributes[7][id]', this.state.keyMatricsEditID7)
      this.state.keyMatricsEditID8 && formData.append('key_metrics_attributes[8][id]', this.state.keyMatricsEditID8)
      this.state.keyMatricsEditID9 && formData.append('key_metrics_attributes[9][id]', this.state.keyMatricsEditID9)
      this.state.keyMatricsEditID10 && formData.append('key_metrics_attributes[10][id]', this.state.keyMatricsEditID10)
      this.state.keyMatricsEditID11 && formData.append('key_metrics_attributes[11][id]', this.state.keyMatricsEditID11)
    }

    // CME 
    formData.append('current_mandate_expectations_attributes[0][designation]', this.state.currentDesignation);
    this.state.editPQCapsuleForm && formData.append('current_mandate_expectations_attributes[0][id]', this.state.CME_ID);
    formData.append('current_mandate_expectations_attributes[0][reporting_to]', this.state.currentReportingTo);
    formData.append('current_mandate_expectations_attributes[0][location]', this.state.currentLocation);
    formData.append('current_mandate_expectations_attributes[0][expectation]', this.state.CMEText?.toString('html'));
    formData.append('current_mandate_expectations_attributes[0][color]', this.state.CMEBackgroundColor);
    formData.append('current_mandate_expectations_attributes[0][heading_name]', this.state.currentMandateHeading);
    formData.append('current_mandate_expectations_attributes[0][heading_color]', this.state.currentMandateHeadingColor);


    if (this.state.currentDesignation0) {
      formData.append('current_mandate_expectations_attributes[1][designation]', this.state.currentDesignation0);
      formData.append('current_mandate_expectations_attributes[1][color]', this.state.CMEBackgroundColor0);
      formData.append('current_mandate_expectations_attributes[1][reporting_to]', this.state.currentReportingTo0);
      formData.append('current_mandate_expectations_attributes[1][location]', this.state.currentLocation0);
      formData.append('current_mandate_expectations_attributes[1][expectation]', this.state.designationText0?.toString('html'));
    }

    if (this.state.currentDesignation1) {
      formData.append('current_mandate_expectations_attributes[2][color]', this.state.CMEBackgroundColor1);
      formData.append('current_mandate_expectations_attributes[2][designation]', this.state.currentDesignation1);
      formData.append('current_mandate_expectations_attributes[2][reporting_to]', this.state.currentReportingTo1);
      formData.append('current_mandate_expectations_attributes[2][location]', this.state.currentLocation1);
      formData.append('current_mandate_expectations_attributes[2][expectation]', this.state.designationText1?.toString('html'));

    }

    if (this.state.currentDesignation2) {
      formData.append('current_mandate_expectations_attributes[3][designation]', this.state.currentDesignation2);
      formData.append('current_mandate_expectations_attributes[3][color]', this.state.CMEBackgroundColor2);
      formData.append('current_mandate_expectations_attributes[3][reporting_to]', this.state.currentReportingTo2);
      formData.append('current_mandate_expectations_attributes[3][location]', this.state.currentLocation2);
      formData.append('current_mandate_expectations_attributes[3][expectation]', this.state.designationText2?.toString('html'));

    }

    if (this.state.currentDesignation3) {
      formData.append('current_mandate_expectations_attributes[4][color]', this.state.CMEBackgroundColor3);
      formData.append('current_mandate_expectations_attributes[4][designation]', this.state.currentDesignation3);
      formData.append('current_mandate_expectations_attributes[4][reporting_to]', this.state.currentReportingTo3);
      formData.append('current_mandate_expectations_attributes[4][location]', this.state.currentLocation3);
      formData.append('current_mandate_expectations_attributes[4][expectation]', this.state.designationText3?.toString('html'));
    }

    // interview panel
    formData.append('interview_panels_attributes[0][name]', this.state.interViewPanelName);
    formData.append('interview_panels_attributes[0][linkedin_profile_link]', this.state.interViewlinkdinProfileLink);
    formData.append('interview_panels_attributes[0][round_name]', this.state.interViewRoundName);
    this.state.interviewPanelImage?.file && formData.append('interview_panels_attributes[0][interview_panel_image]', this.state.interviewPanelImage?.file || "" || "");
    formData.append('interview_panel_color', this.state.interviewBackgroundColor);
    formData.append('interview_panels_attributes[0][heading_name]', this.state.interviewPanelHeading);
    formData.append('interview_panels_attributes[0][heading_color]', this.state.interviewPanelHeadingColor);


    if (this.state.interViewPanelName0) {
      formData.append('interview_panels_attributes[1][name]', this.state.interViewPanelName0);
      formData.append('interview_panels_attributes[1][linkedin_profile_link]', this.state.interViewlinkdinProfileLink0);
      formData.append('interview_panels_attributes[1][round_name]', this.state.interViewRoundName0);
      this.state.interviewPanelImage0?.file && formData.append('interview_panels_attributes[1][interview_panel_image]', this.state.interviewPanelImage0?.file || "");

    }

    if (this.state.interViewPanelName1) {
      formData.append('interview_panels_attributes[2][name]', this.state.interViewPanelName1);
      formData.append('interview_panels_attributes[2][linkedin_profile_link]', this.state.interViewlinkdinProfileLink1);
      formData.append('interview_panels_attributes[2][round_name]', this.state.interViewRoundName1);
      this.state.interviewPanelImage1?.file && formData.append('interview_panels_attributes[2][interview_panel_image]', this.state.interviewPanelImage1?.file || "");

    }

    if (this.state.interViewPanelName2) {
      formData.append('interview_panels_attributes[3][name]', this.state.interViewPanelName2);
      formData.append('interview_panels_attributes[3][linkedin_profile_link]', this.state.interViewlinkdinProfileLink2);
      formData.append('interview_panels_attributes[3][round_name]', this.state.interViewRoundName2);
      this.state.interviewPanelImage2?.file && formData.append('interview_panels_attributes[3][interview_panel_image]', this.state.interviewPanelImage2?.file || "");

    }

    if (this.state.interViewPanelName3) {
      formData.append('interview_panels_attributes[4][name]', this.state.interViewPanelName3);
      formData.append('interview_panels_attributes[4][linkedin_profile_link]', this.state.interViewlinkdinProfileLink3);
      formData.append('interview_panels_attributes[4][round_name]', this.state.interViewRoundName3);
      this.state.interviewPanelImage3?.file && formData.append('interview_panels_attributes[4][interview_panel_image]', this.state.interviewPanelImage3?.file || "");
    }

    if (this.state.editPQCapsuleForm) {
      formData.append('interview_panels_attributes[0][id]', this.state.interviewPanelEditID0);
      this.state.interviewPanelEditID1 && formData.append('interview_panels_attributes[1][id]', this.state.interviewPanelEditID1);
      this.state.interviewPanelEditID2 && formData.append('interview_panels_attributes[2][id]', this.state.interviewPanelEditID2);
      this.state.interviewPanelEditID3 && formData.append('interview_panels_attributes[3][id]', this.state.interviewPanelEditID3);
      this.state.interviewPanelEditID4 && formData.append('interview_panels_attributes[4][id]', this.state.interviewPanelEditID4);
    }

    //@ts-ignore
    formData.append('hierarchies_attributes[0][level]', 1);
    formData.append('hierarchies_attributes[0][hierarchy_members_attributes][0][name]', this.state.hierarchyLevelName);
    formData.append('hierarchies_attributes[0][hierarchy_members_attributes][0][position]', this.state.hierarchyLevelPosition);

    if (this.state.hierarchyLevelName0) {
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][1][name]', this.state.hierarchyLevelName0);
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][1][position]', this.state.hierarchyLevelPosition0);
    }

    if (this.state.hierarchyLevelName1) {
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][2][name]', this.state.hierarchyLevelName1);
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][2][position]', this.state.hierarchyLevelPosition1);
    }

    if (this.state.hierarchyLevelName2) {
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][3][name]', this.state.hierarchyLevelName2);
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][3][position]', this.state.hierarchyLevelPosition2);
    }

    if (this.state.hierarchyLevelName3) {
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][4][name]', this.state.hierarchyLevelName3);
      formData.append('hierarchies_attributes[0][hierarchy_members_attributes][4][position]', this.state.hierarchyLevelPosition3);
    }

    //@ts-ignore
    formData.append('hierarchies_attributes[1][level]', 2);

    if (this.state.hierarchyLevel2Name) {
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][0][name]', this.state.hierarchyLevel2Name);
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][0][position]', this.state.hierarchyLevel2Position);
    }

    if (this.state.hierarchyLevel2Name0) {
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][1][name]', this.state.hierarchyLevel2Name0);
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][1][position]', this.state.hierarchyLevel2Position0);
    }

    if (this.state.hierarchyLevel2Name1) {
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][2][name]', this.state.hierarchyLevel2Name1);
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][2][position]', this.state.hierarchyLevel2Position1);
    }

    if (this.state.hierarchyLevel2Name2) {
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][3][name]', this.state.hierarchyLevel2Name2);
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][3][position]', this.state.hierarchyLevel2Position2);
    }

    if (this.state.hierarchyLevel2Name3) {
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][4][name]', this.state.hierarchyLevel2Name3);
      formData.append('hierarchies_attributes[1][hierarchy_members_attributes][4][position]', this.state.hierarchyLevel2Position3);
    }

    //@ts-ignore
    formData.append('hierarchies_attributes[2][level]', 3);

    if (this.state.hierarchyLevel3Name) {
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][0][name]', this.state.hierarchyLevel3Name);
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][0][position]', this.state.hierarchyLevel3Position);
    }

    if (this.state.hierarchyLevel3Name0) {
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][1][name]', this.state.hierarchyLevel3Name0);
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][1][position]', this.state.hierarchyLevel3Position0);
    }

    if (this.state.hierarchyLevel3Name1) {
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][2][name]', this.state.hierarchyLevel3Name1);
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][2][position]', this.state.hierarchyLevel3Position1);
    }

    if (this.state.hierarchyLevel3Name2) {
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][3][name]', this.state.hierarchyLevel3Name2);
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][3][position]', this.state.hierarchyLevel3Position2);
    }

    if (this.state.hierarchyLevel3Name3) {
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][4][name]', this.state.hierarchyLevel3Name3);
      formData.append('hierarchies_attributes[2][hierarchy_members_attributes][4][position]', this.state.hierarchyLevel3Position3);
    }

    //@ts-ignore
    formData.append('hierarchies_attributes[3][level]', 4);

    if (this.state.hierarchyLevel4Name) {
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][0][name]', this.state.hierarchyLevel4Name);
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][0][position]', this.state.hierarchyLevel4Position);
    }

    if (this.state.hierarchyLevel4Name0) {
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][1][name]', this.state.hierarchyLevel4Name0);
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][1][position]', this.state.hierarchyLevel3Position0);
    }


    if (this.state.hierarchyLevel4Name1) {
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][2][name]', this.state.hierarchyLevel4Name1);
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][2][position]', this.state.hierarchyLevel4Position1);
    }


    if (this.state.hierarchyLevel4Name2) {
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][3][name]', this.state.hierarchyLevel4Name2);
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][3][position]', this.state.hierarchyLevel4Position2);
    }

    if (this.state.hierarchyLevel4Name3) {
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][4][name]', this.state.hierarchyLevel4Name3);
      formData.append('hierarchies_attributes[3][hierarchy_members_attributes][4][position]', this.state.hierarchyLevel4Position3);
    }

    // //@ts-ignore
    // formData.append('hierarchies_attributes[4][level]', 4);
    // if (this.state.hierarchyLevel4Name) {
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][0][name]', this.state.hierarchyLevel4Name);
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][0][position]', this.state.hierarchyLevel4Position);
    // }

    // if (this.state.hierarchyLevel4Name0) {
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][1][name]', this.state.hierarchyLevel4Name0);
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][1][position]', this.state.hierarchyLevel4Position0);
    // }

    // if (this.state.hierarchyLevel4Name1) {
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][2][name]', this.state.hierarchyLevel4Name1);
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][2][position]', this.state.hierarchyLevel4Position1);
    // }

    // if (this.state.hierarchyLevel4Name2) {
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][3][name]', this.state.hierarchyLevel4Name2);
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][3][position]', this.state.hierarchyLevel4Position2);
    // }

    // if (this.state.hierarchyLevel4Name3) {
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][4][name]', this.state.hierarchyLevel4Name3);
    //   formData.append('hierarchies_attributes[4][hierarchy_members_attributes][4][position]', this.state.hierarchyLevel4Position3);
    // }

    if (this.state.editPQCapsuleForm) {
      formData.append('hierarchies_attributes[0][id]', this.state.hierarcyLevelsID1);
      formData.append('hierarchies_attributes[1][id]', this.state.hierarcyLevelsID2);
      formData.append('hierarchies_attributes[2][id]', this.state.hierarcyLevelsID3);
      formData.append('hierarchies_attributes[3][id]', this.state.hierarcyLevelsID4);
      // formData.append('hierarchies_attributes[4][id]', this.state.hierarcyLevelsID5);

      this.state.hierarcyLevel1EditID0 && formData.append('hierarchies_attributes[0][hierarchy_members_attributes][0][id]', this.state.hierarcyLevel1EditID0);
      this.state.hierarcyLevel1EditID1 && formData.append('hierarchies_attributes[0][hierarchy_members_attributes][1][id]', this.state.hierarcyLevel1EditID1);
      this.state.hierarcyLevel1EditID2 && formData.append('hierarchies_attributes[0][hierarchy_members_attributes][2][id]', this.state.hierarcyLevel1EditID2);
      this.state.hierarcyLevel1EditID3 && formData.append('hierarchies_attributes[0][hierarchy_members_attributes][3][id]', this.state.hierarcyLevel1EditID3);
      this.state.hierarcyLevel1EditID4 && formData.append('hierarchies_attributes[0][hierarchy_members_attributes][4][id]', this.state.hierarcyLevel1EditID4);

      this.state.hierarcyLevel2EditID0 && formData.append('hierarchies_attributes[1][hierarchy_members_attributes][0][id]', this.state.hierarcyLevel2EditID0);
      this.state.hierarcyLevel2EditID1 && formData.append('hierarchies_attributes[1][hierarchy_members_attributes][1][id]', this.state.hierarcyLevel2EditID1);
      this.state.hierarcyLevel2EditID2 && formData.append('hierarchies_attributes[1][hierarchy_members_attributes][2][id]', this.state.hierarcyLevel2EditID2);
      this.state.hierarcyLevel2EditID3 && formData.append('hierarchies_attributes[1][hierarchy_members_attributes][3][id]', this.state.hierarcyLevel2EditID3);
      this.state.hierarcyLevel2EditID4 && formData.append('hierarchies_attributes[1][hierarchy_members_attributes][4][id]', this.state.hierarcyLevel2EditID4);

      this.state.hierarcyLevel3EditID0 && formData.append('hierarchies_attributes[2][hierarchy_members_attributes][0][id]', this.state.hierarcyLevel3EditID0);
      this.state.hierarcyLevel3EditID1 && formData.append('hierarchies_attributes[2][hierarchy_members_attributes][1][id]', this.state.hierarcyLevel3EditID1);
      this.state.hierarcyLevel3EditID2 && formData.append('hierarchies_attributes[2][hierarchy_members_attributes][2][id]', this.state.hierarcyLevel3EditID2);
      this.state.hierarcyLevel3EditID3 && formData.append('hierarchies_attributes[2][hierarchy_members_attributes][3][id]', this.state.hierarcyLevel3EditID3);
      this.state.hierarcyLevel3EditID4 && formData.append('hierarchies_attributes[2][hierarchy_members_attributes][4][id]', this.state.hierarcyLevel3EditID4);

      this.state.hierarcyLevel4EditID0 && formData.append('hierarchies_attributes[3][hierarchy_members_attributes][0][id]', this.state.hierarcyLevel4EditID0);
      this.state.hierarcyLevel4EditID1 && formData.append('hierarchies_attributes[3][hierarchy_members_attributes][1][id]', this.state.hierarcyLevel4EditID1);
      this.state.hierarcyLevel4EditID2 && formData.append('hierarchies_attributes[3][hierarchy_members_attributes][2][id]', this.state.hierarcyLevel4EditID2);
      this.state.hierarcyLevel4EditID3 && formData.append('hierarchies_attributes[3][hierarchy_members_attributes][3][id]', this.state.hierarcyLevel4EditID3);
      this.state.hierarcyLevel4EditID4 && formData.append('hierarchies_attributes[3][hierarchy_members_attributes][4][id]', this.state.hierarcyLevel4EditID4);
    }


    const headers = {
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    if (this.state.editPQCapsuleForm) {
      this.editPQCapusuleApiCallId = requestMessage.messageId
    } else {
      this.savePQCapsuleApiCallId = requestMessage.messageId;
    }

    if (this.state.editPQCapsuleForm) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.savePQCapusleAPI + '/' + this.state.presentEditForm
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.savePQCapusleAPI
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.editPQCapsuleForm ? configJSON.putAPiMethod : configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getPQCapsuleForms = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPQCapsuleFormList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPQCapsuleFormsList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Delete PQ capsule form
  deletePQCapsule = (pqFormID: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePQCapusuleForm = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPQCapsuleFormsList + '/' + pqFormID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePQApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //Approve PQ Capsule form
  ApprovePQCapsule = (pqCapusuleId: any) => {
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var formData = new FormData();

    // @ts-ignore
    let fullDate = `${day}/${month}/${year}`;

    formData.append("approval_date", fullDate);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.POSTApproveAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_pq_capsule/pq_capsules/' + pqCapusuleId + '/update_approval_date'
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Customizable Area End

}
