import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
// Customizable Area Start
import { Formik } from "formik";
import Select from 'react-select';
import { Link } from 'react-router-dom'
import * as yup from "yup";
import moment from 'moment'
import { TextField } from '@material-ui/core';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

export default class FamilyDetails extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    familyDetailsSchema = () => {
        return yup.object().shape({
            dob: yup
                .date()
                .required("DOB is required."),
        });
    }

    handleTextFieldArea = () => {
        let textarea: any = document.getElementById('text-area-compentencies');
        let limit = 250
        textarea.oninput = function () {
            textarea.style.height = "";
            textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
        };
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    dob: this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.date_of_birth ? moment(this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.date_of_birth).format('DD-MM-YYYY') : "",
                                    spouse: this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.spouse_name ? this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.spouse_name : "",
                                    other: this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.other_details ? this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.other_details : "",
                                }}
                                validationSchema={this.familyDetailsSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    this.handleFamily(values);
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <p className="heading-head">{window.location.href.includes('Edit-user') ? 'Edit Candidate' : 'Add New Candidate'}</p>
                                            <label className="my-label">   Family Details</label>
                                            {/* <p className="heading"><b>Family Details</b></p> */}

                                            <div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">   Total number of family members</label>

                                                    {/* Total number of family members */}
                                                    <Select styles={customStyles} options={this.state.familyNumberoptions} placeholder={this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.total_member ? this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.total_member : ""} onChange={(selectedOptin) => this.handleSelect(selectedOptin, 'familyNumbers')} />
                                                    {this.state.familyNumbersError !== null && <div className="error">{this.state.familyNumbersError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">   Marital Status</label>

                                                    <Select styles={customStyles} options={this.state.martialStatusOptions} onChange={(selectedOptin) => this.handleSelect(selectedOptin, 'maritialStatus')} placeholder={this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.marital_status ? this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.marital_status === 'single' || this.state.currentCandidateData?.attributes?.family_detail?.data?.attributes?.marital_status === 'Single' ? "Single" : "Married" : ""} />
                                                    {this.state.maritialStatusError !== null && <div className="error">{this.state.maritialStatusError}</div>}
                                                </div>
                                                {
                                                    this.state.maritialStatus?.value === 'married' || this.state.maritialStatus?.value === 'Married' ?
                                                        <div className="mt-1 mb-3">
                                                            <label className="my-label">Name of Spouse</label>
                                                            <TextField
                                                                id="outlined-secondary"
                                                                // label='Name of Spouse'
                                                                variant="outlined"
                                                                color="primary"
                                                                onChange={handleChange}
                                                                className="form-control input-field text-field-input"
                                                                value={values.spouse}
                                                                name="spouse"
                                                                type="text"
                                                            />
                                                            {/* <input value={values.spouse} onChange={handleChange} className="form-control input-field" name="spouse" type="text" placeholder="Name of Spouse" /> */}
                                                            {errors.spouse && touched.spouse ? <div className="error">{errors.spouse}</div> : null}
                                                        </div>
                                                        :
                                                        null

                                                }
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Date of Birth</label>
                                                    <TextField
                                                        value={values.dob}
                                                        onChange={handleChange}
                                                        className="form-control input-field"
                                                        name="dob"
                                                        id="outlined-secondary"
                                                        // label="Date of Birth"
                                                        variant="outlined"
                                                        color="primary"
                                                        type="text"
                                                        InputProps={{ inputProps: { max: '31-12-9999', format: 'DD-MM-YYYY' } }}
                                                        //@ts-ignore
                                                        onFocus={(e) => e.target.type = 'date'}
                                                        
                                                        //@ts-ignore
                                                        onBlur={(e) => e.target.type = 'text'}

                                                    />
                                                    {errors.dob && touched.dob ? <div className="error">{errors.dob}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Other Details</label>
                                                    <TextField
                                                        multiline
                                                        rows={5}
                                                        id="outlined-secondary"
                                                        // label="Other Details"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control input-field"
                                                        name="other"
                                                        value={values.other}
                                                    />
                                                    {/* <textarea style={{ height: '108px' }} value={values.other} id="text-area-compentencies" onChange={(e) => { handleChange(e); this.handleTextFieldArea() }} className="form-control input-field" name="other" placeholder="Other Details" /> */}
                                                    {errors.other && touched.other ? <div className="error">{errors.other}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-step row">
                                            <div className="row col-9">
                                                <Link to={window.location.href.includes('Edit-user') ? "/BasicDetails/Edit-user" : "/BasicDetails"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/DomainKnowledge/Edit-user" : "/DomainKnowledge"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/FamilyDetails/Edit-user" : "/FamilyDetails"} className="step-bar step-bar-active col-2"></Link>

                                            </div>
                                            <div className="row col-3">
                                                <button className="btn candidate-button" type="submit">Save And Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
