import React from "react";
import GoogleMapReact from "google-map-react";
// import pin from "./pin.png";
import { Link } from "react-router-dom";
import { fitBounds } from 'google-map-react';

const markerStyle = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -100%)",
  height:'20px',
  width: '20px',
};

const bounds = {
  se: {
    lat: -40.01038826014866,
    lng: -200.6525866875
  },
  nw: {
    lat: 81.698335045970396,
    lng: -167.0217273125
  }
};

const size = {
  width: 440, // Map width in pixels
  height: 180, // Map height in pixels
};

const {center, zoom} = fitBounds(bounds, size);

class SimpleMap extends React.Component {
  static defaultProps = {
    center :{
      lat: 21.192059,
      lng: 78.945831
    },
    zoom: 1
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA16d9FJFh__vK04jU1P64vnEpPc3jenec"
          }}
          defaultCenter={this.props.center}
          defaultZoom={1}
        >
          {this.props.locations.map(item => {
            if (item.address.length !== 0) {
              return item.address.map(i => {
                return (
                  <Link to={"/" + item.name} key={i.id} lat={i.lat} lng={i.lng}>
                    <img style={markerStyle} src={'https://www.clipartmax.com/png/middle/319-3194654_red-pin-icon-png.png'} alt="pin" />
                  </Link>
                );
              });
            }
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
