import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string | null;
  email: string | null;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  loading: boolean;
  passwordView: boolean;
  loginError: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  emailAccountLoginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      loading: false,
      passwordView: false,
      loginError: null
    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  handlePasswordView = () => {
    this.setState({
      passwordView: !this.state.passwordView
    })
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }


  handleLogin = (values: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const data = {
      email: values.email,
      password: values.password
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailAccountLoginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ loading: true });
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {


        if (apiRequestCallId === this.emailAccountLoginApiCallId) {
          if (responseJson?.status === 500) {
            this.setState({
              loginError: "Invalid Credentials"
            })
          } else {
            if (responseJson && responseJson.meta && responseJson.meta.token) {
              runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
              this.saveLoggedInUserData(responseJson);
              //Adding rememberme credientials
              if (this.state.checkedRememberMe) {
                window.localStorage.setItem('userEmailId', responseJson.data.attributes.email);
              } else {
                if (!window.localStorage.getItem('userEmailId'))
                  window.localStorage.removeItem('userPassword');
              }

              this.props.history.push('/Dashboard')
              if (!responseJson.data.attributes.activated) {
                // this.goToHome();
              } else {
                const msg: Message = new Message(
                  // getName(MessageEnum.AccoutResgistrationSuccess)
                  getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
                );

                msg.addData(
                  getName(MessageEnum.NavigationPropsMessage),
                  this.props
                );

                this.send(msg);
              }
            } else {
              //Check Error Response
              this.setState({
                loginError: responseJson?.errors[0]?.pin ? responseJson?.errors[0]?.pin : "Please Try Again..."
              })
              this.parseApiErrorResponse(responseJson);
              // this.sendLoginFailMessage();
            }
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({ loading: false });
          }


        }
      }
    }

    // Customizable Area End
  }

}