import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import SideNav from "../../../components/src/SideNav.web";
import Modal from "react-modal";
import TopNav from "../../../components/src/TopNav.web";
// @ts-ignore
import Board from "react-trello";
import './kanban.css';
import { Link } from "react-router-dom";
import { bell, task } from "./assets";
import { Input, TextField } from '@material-ui/core';
import KanbanBoardController, {
  Props,
  configJSON
} from "./KanbanBoardController";
import Select from 'react-select'
//@ts-ignore
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";

export default class KanbanBoard extends KanbanBoardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleBoardDragEnd = (cardId: any, sourceLaneId: any, targetLaneId: any, position: any, cardDetails: any) => {
    this.updateCardOnDrag(cardDetails.id, cardDetails.laneId, cardDetails?.attributes?.job_id);
  }

  onCardAdd = (card: any, laneId: any) => {
    console.log(card, laneId)
  }

  addMoreJobTitles = () => {
    //@ts-ignore
    let ele = document.getElementsByName('registerJobOpening')[0]?.value;

    if ((this.state.addJobClick + 1) > (ele - 1)) {
      this.setState({ jobAddError: 'Max Jobs' });
      return
    }
    this.setState({ addJobClick: this.state.addJobClick + 1 })

    if ((this.state.elements.length + 1) <= (ele - 1)) {
      this.state.elements?.push(
        <>
          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">
                Job Title
              </label>
              <TextField
                id="outlined-secondary"
                // label="Job Title"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name={`registerJobTitle${this.state.addJobClick + 1}`}
                type="text"
                required
              />
            </div>
            <div className='col-6'>
              <label className="my-label">
                Locations
              </label>
              <TextField
                id="outlined-secondary"
                // label="Locations"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name={`registerJobLocation${this.state.addJobClick + 1}`}
                type="text"
                required
              />
            </div>
          </div>
          <div className="row mt-2 mb-3">
            {/* <input type="text" placeholder="No of Openings" className="form-control input-field" name="registerJobOpening" /> */}
            <div className="col-12">
              <label className="my-label">
                Number of Interview Rounds
              </label>
              <select onChange={(e) => this.newJob(e)} placeholder="" id="registerJobInterviewRounds" name={`registerJobInterviewRounds${this.state.addJobClick + 1}`} className="form-control mt-2 mb-3 input-field" required>
                {/* <option value={1} selected>1</option> */}
                {[ 'Select..', 1, 2, 3, 4, 5].map(ele => <option value={ele}>{ele}</option>)}
              </select>
            </div>
          </div>
        </>
      )
    }
  }

  calculateDate = (e: any, ele?: any) => {
    let dateCount = parseInt(ele ? e : e?.target?.value)
    let startDate = moment(ele ? ele.target.value : this.state.registerJobStartDate).format('DD-MM-YYYY')
    this.setState({
      registerJobExclusivityPeriod: ele ? e : e.target.value,
      registerJobExclusivityEndDate: moment(startDate, "DD-MM-YYYY").add(dateCount, 'days').format('DD-MM-YYYY')
    })
  }

  openAddNewJobModal = () => {
    return <Modal
      isOpen={this.state.isShowJobModal}
      ariaHideApp={false}
      contentLabel=""
      className="mytask-add-job"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'Black'
        }
      }}
    >
      <form onSubmit={(e) => this.callSaveJobDescription(e)}>
        <div className="row">
          <div className="col-11">
            <label className="task-label">Add New Job</label>
          </div>
          <div className="col-1">
            <button className="btn cross-btn" onClick={() => { this.setState({ isShowJobModal: false, jobAddError: false, elements: [], addJobClick: 0, registerJobStartDate: '', registerJobExclusivityPeriod: '', registerJobExclusivityEndDate: "", registerJobOpening: 0 }) }}>X</button>
          </div>
        </div>
        <div className="add-job-form">
          <div className="mt-2 mb-3">
            <label className="my-label">Company</label>
            <Select styles={customStyles}
              options={this.state.companiesData}
              className="selectField-company"
              placeholder=""
              onChange={(selectedOption) => this.handleSelect(selectedOption, 'registerJobCompany')}
            />
            {/* 
            <TextField
              id="outlined-secondary"
              label="Company"
              variant="outlined"
              color="primary"
              onChange={(e) => this.newJob(e)}
              className="form-control input-field text-field-input"
              name="registerJobCompany"
              type="text"
              required
            /> */}
          </div>
          <div>
            {/* <input type="text" placeholder="No of Openings" className="form-control input-field" name="registerJobOpening" /> */}
            <label className="my-label">Number of Openings</label>
            <select onChange={(e) => this.newJob(e)} placeholder="" id="registerJobOpening" name="registerJobOpening" className="form-control mt-2 mb-3 input-field" required>
              {/* <option value="">No of Openings </option> */}
              {/* <option value={1} selected>1</option> */}
              {['Select..' ,1, 2, 3, 4, 5].map(ele => <option value={ele}>{ele}</option>)}
            </select>
          </div>

          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">Job Title</label>
              <TextField
                id="outlined-secondary"
                // label="Job Title"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobTitle"
                type="text"
                required
              />
            </div>
            <div className='col-6'>
              <label className="my-label">Locations</label>
              <TextField
                id="outlined-secondary"
                // label="Locations"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobLocation"
                type="text"
                required
              />
            </div>
          </div>
          <div>
            {/* <input type="text" placeholder="No of Openings" className="form-control input-field" name="registerJobOpening" /> */}

            <label className="my-label">Number of Interview Rounds</label>
            <select onChange={(e) => this.newJob(e)} placeholder="" id="registerJobInterviewRounds" name="registerJobInterviewRounds" className="form-control mt-2 mb-3 input-field" required>
              {/* <option value={1} selected>1</option> */}
              {['Select..' ,1, 2, 3, 4, 5].map(ele => <option value={ele}>{ele}</option>)}
            </select>
          </div>

          {this.state.elements && (
            <div id="addExtraJobFields">{this.state.elements}</div>
          )}
          <div className="btn" style={{ textAlign: 'left' }} onClick={() => this.addMoreJobTitles()}>+ Add job Title</div>

          <div className="row mt-2 mb-3">
            <div className='col-4'>
              <label className="my-label">
                Exclusive Start Date
              </label>
              <input type="text" style={{ fontSize: '14px', height: '44px', fontWeight: "bold" }} onChange={(e => { setTimeout(this.calculateDate(this.state.registerJobExclusivityPeriod, e), 100); this.newJob(e) })} onFocus={(e) => e.target.type = 'date'} max ="9999-12-31" className="form-control input-field" placeholder="" name="registerJobStartDate" required />
            </div>
            <div className='col-4'>
              <label className="my-label">
                Exclusivity Period(in days)
              </label>

              <TextField
                id="outlined-secondary"
                // label="Exclusivity Period(in days)"
                variant="outlined"
                color="primary"
                onChange={(e => this.calculateDate(e, false))}
                className="form-control input-field text-field-input"
                name="registerJobExclusivityPeriod"
                type="number"
                value={this.state.registerJobExclusivityPeriod}
                required
              />
            </div>
            <div className='col-4'>
              <label className="my-label">
                Exclusivity End Date
              </label>
              <TextField
                id="outlined-secondary"
                label=""
                variant="outlined"
                color="primary"
                className="form-control input-field text-field-input"
                type="text"
                value={this.state.registerJobExclusivityEndDate && this.state.registerJobExclusivityEndDate}
                inputProps={
                  { readOnly: true, }
                }
              />
              {/* <label style={{ fontSize: '14px', height: '44px', paddingTop: '13px' }} className="form-control input-field">{this.state.registerJobExclusivityPeriod > 0 ? this.state.registerJobExclusivityEndDate : "Exclusive End Date"}</label> */}
            </div>
          </div>
          <div className="mt-2 mb-3">
            <label className="my-label">
              Website
            </label>
            <TextField
              id="outlined-secondary"
              // label="Website"
              variant="outlined"
              color="primary"
              onChange={(e) => this.newJob(e)}
              className="form-control input-field text-field-input"
              name="registerJobWebsite"
              type="text"
              required
            />
          </div>
          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">
                Decision Maker Name
              </label>
              <TextField
                id="outlined-secondary"
                // label="Decision Maker Name"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobDecisionMaker"
                type="text"
                required
              />
            </div>
            <div className='col-6'>
              <label className="my-label">
                Decision Maker Email ID
              </label>
              <TextField
                id="outlined-secondary"
                // label="Decision Maker Email ID"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobDesigerMakerEmail"
                type="email"
                required
              />
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">
                SPOC Name
              </label>
              <TextField
                id="outlined-secondary"
                // label="SPOC Name"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobSOPName"
                type="text"
                required
              />

            </div>
            <div className='col-6'>
              <label className="my-label">
                SPOC Email ID
              </label>
              <TextField
                id="outlined-secondary"
                // label="SPOC Email ID"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobSOPEmail"
                type="email"
                required
              />

            </div>
          </div>
          <div className="mt-2 mb-2">
            <label className="my-label">
              Description
            </label>
            <TextField
              multiline
              rows={5}
              id="outlined-secondary registerNewJobDescription"
              // label="Description"
              variant="outlined"
              color="primary"
              onChange={e => { this.newJob(e); this.handleTextFieldArea('outlined-secondary registerNewJobDescription') }}
              className="form-control input-field"
              name="registerJobDescription"
            />
            {/* <textarea onChange={(e => { this.newJob(e); this.handleTextFieldArea('registerJobDescription') })} className="form-control input-field" style={{ height: '108px' }} placeholder="Description" id="registerJobDescription" name="registerJobDescription"  ></textarea> */}
          </div>
        </div>
        <div>{this.state.jobAddError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>{this.state.jobAddError !== 'Email' ? (this.state.jobAddError === 'Max Jobs' ? '*No More Job Title can be added' : '*All Fields are required') : '*Enter Vaid Email address'}</div> : ''}</div>
        <div className="center-item-button" style={{ marginBottom: '35px', marginTop: '15px' }}>
          <button className="btn save-button2" type="submit">Save</button>
        </div>
      </form>

    </Modal >
  }

  viewJobModal = () => {
    return <Modal
      isOpen={this.state.viewJobModal}
      ariaHideApp={false}
      contentLabel=""
      className="mytask-add-job"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          color: 'Black'
        }
      }}
    >
      <form>
        <div className="row">
          <div className="col-11">
            <label className="task-label">View Job</label>
          </div>
          <div className="col-1">
            <button type="button" className="btn cross-btn" onClick={() => { this.setState({ viewJobModal: false, registerJobOpening: 0 }); this.getJobsData(true) }}>X</button>
          </div>
        </div>
        <div className="add-job-form">
          <label className="my-label">
            Company
          </label>
          <div className="mt-2 mb-3">
            <Select styles={customStyles}
              options={this.state.companiesData}
              placeholder={this.state.registerJobCompany}
              onChange={(selectedOption) => this.handleSelect(selectedOption, 'registerJobCompany')}
            />
            {/* <TextField
              id="outlined-secondary"
              label="Company"
              variant="outlined"
              color="primary"
              onChange={(e) => this.newJob(e)}
              className="form-control input-field text-field-input"
              name="registerJobCompany"
              type="text"
              required
              value={this.state.registerJobCompany}
            /> */}
          </div>
          <div>
            {/* <input type="text" placeholder="No of Openings" className="form-control input-field" name="registerJobOpening" /> */}

            {/* <select onChange={(e) => this.newJob(e)} placeholder={this.state.registerJobOpening} id="registerJobOpening" name="registerJobOpening" className="form-control mt-2 mb-3 input-field" required>
              <option value="">No of Openings </option>
              {[1, 2, 3, 4, 5].map(ele => <option value={ele}>{ele}</option>)}
            </select> */}
          </div>

          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">
                Job Title
              </label>
              <TextField
                id="outlined-secondary"
                // label="Job Title"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobTitle"
                type="text"
                required
                value={this.state.registerJobTitle}

              />
            </div>
            <div className='col-6'>
              <label className="my-label">
                Locations
              </label>
              <TextField
                id="outlined-secondary"
                // label="Locations"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobLocation"
                type="text"
                required
                value={this.state.registerJobLocation}
              />
            </div>
          </div>
          <div>
            {/* <input type="text" placeholder="No of Openings" className="form-control input-field" name="registerJobOpening" /> */}

            {/* <select onChange={(e) => this.newJob(e)} id="registerJobInterviewRounds" name="registerJobInterviewRounds" className="form-control mt-2 mb-3 input-field" required>
              {[this.state.registerJobInterviewRounds, 1, 2, 3, 4, 5].map(ele => <option value={ele}>{ele}</option>)}
            </select> */}
          </div>

          {this.state.elements && (
            <div id="addExtraJobFields">{this.state.elements}</div>
          )}
          {/* <div className="btn" style={{ textAlign: 'left' }} onClick={() => this.addMoreJobTitles()}>+ Add job Title</div> */}

          <div className="row mt-2 mb-3">
            <div className='col-4'>
              <label className="my-label">
                Exclusive Start Date
              </label>
              <TextField
                id="outlined-secondary"
                // label="Exclusive Start Date"
                variant="outlined"
                color="primary"
                value={this.state.registerJobStartDate}
                type="text"
                style={{ fontSize: '14px', height: '44px' }}
                onChange={(e => this.newJob(e))}
                name="registerJobStartDate"
                className="form-control input-field text-field-input"
                //@ts-ignore
                onFocus={(e) => e.target.type = 'date'}
                //@ts-ignore
                onBlur={(e) => e.target.type = 'text'}
                required />
            </div>
            <div className='col-4'>
              <label className="my-label">
                Exclusivity Period(in days)
              </label>
              <TextField
                id="outlined-secondary"
                // label="Exclusivity Period(in days)"
                variant="outlined"
                color="primary"
                onChange={(e => this.calculateDate(e, false))}
                className="form-control input-field text-field-input"
                name="registerJobExclusivityPeriod"
                type="number"
                required
                value={this.state.registerJobExclusivityPeriod}
              />
            </div>
            <div className='col-4'>
              <label className="my-label">
                Exclusivity End Date
              </label>
              <TextField
                id="outlined-secondary"
                // label="Exclusivity End Date"
                variant="outlined"
                color="primary"
                className="form-control input-field text-field-input"
                type="text"
                value={this.state.registerJobExclusivityEndDate && this.state.registerJobExclusivityEndDate}
                inputProps={
                  { readOnly: true, }
                }
              />
              {/* <label style={{ fontSize: '14px', height: '44px', paddingTop: '13px' }} className="form-control input-field">{this.state.registerJobExclusivityEndDate && this.state.registerJobExclusivityEndDate}</label> */}
            </div>
          </div>
          <div className="mt-2 mb-3">
            <label className="my-label">
              Website
            </label>
            <TextField
              id="outlined-secondary"
              // label="Website"
              variant="outlined"
              color="primary"
              onChange={(e) => this.newJob(e)}
              className="form-control input-field text-field-input"
              name="registerJobWebsite"
              type="text"
              required
              value={this.state.registerJobWebsite}
            />
          </div>
          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">
                Decision Maker Name
              </label>
              <TextField
                id="outlined-secondary"
                // label="Decision Maker Name"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobDecisionMaker"
                type="text"
                required
                value={this.state.registerJobDecisionMaker}
              />
            </div>
            <div className='col-6'>
              <label className="my-label">
                Descision Make Email ID
              </label>
              <TextField
                id="outlined-secondary"
                // label="Decision Maker Email ID"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobDesigerMakerEmail"
                type="email"
                required
                value={this.state.registerJobDesigerMakerEmail}
              />
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className='col-6'>
              <label className="my-label">
                SPOC Name
              </label>
              <TextField
                id="outlined-secondary"
                // label="SPOC Name"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobSOPName"
                type="text"
                required
                value={this.state.registerJobSOPName}
              />

            </div>
            <div className='col-6'>
              <label className="my-label">
                SPOC Email ID
              </label>
              <TextField
                id="outlined-secondary"
                // label="SPOC Email ID"
                variant="outlined"
                color="primary"
                onChange={(e) => this.newJob(e)}
                className="form-control input-field text-field-input"
                name="registerJobSOPEmail"
                type="email"
                required
                value={this.state.registerJobSOPEmail}
              />

            </div>
          </div>
          <div className="mt-2 mb-2">
            <label className="my-label">
              Description
            </label>
            <TextField
              multiline
              rows={5}
              id="outlined-secondary registerJobDescription"
              // label="Description"
              variant="outlined"
              color="primary"
              onChange={e => { this.newJob(e); this.handleTextFieldArea('outlined-secondary registerJobDescription') }}
              className="form-control input-field"
              name="registerJobDescription"
              value={this.state.registerJobDescription}
            />
            {/* <textarea onChange={(e => { this.newJob(e); this.handleTextFieldArea('registerJobDescription') })} className="form-control input-field" style={{ height: '108px' }} placeholder="Description" id="registerJobDescription" name="registerJobDescription"  ></textarea> */}
          </div>
        </div>
        <div>{this.state.jobAddError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>{this.state.jobAddError !== 'Email' ? (this.state.jobAddError === 'Max Jobs' ? '*No More Job Title can be added' : '*All Fields are required') : '*Enter Vaid Email address'}</div> : ''}</div>
        <div className="center-item-button" style={{ marginBottom: '35px', marginTop: '15px' }}>
          <button className="btn save-button2" type="button" onClick={() => this.updateSelectedJob()}>Update</button>
        </div>
      </form>

    </Modal >
  }

  handleAddCandidate = (candidateId: any) => {
    this.setState({ noCandidateSelectedError: false });
    if (!this.state.selectedArrayIds.includes(candidateId)) {
      this.setState({ selectedArrayIds: [...this.state.selectedArrayIds, candidateId] })
    } else {
      let selectedArrayList;
      selectedArrayList = this.state.selectedArrayIds.filter((ele: any) => ele !== candidateId);
      this.setState({ selectedArrayIds: selectedArrayList });
    }
  }

  filterSearchResults = (e: any) => {
    this.setState({ searchText: e.target.value })
  }
 
  getSelectionArray = () => {
    let data = this.state.listOfCandidatesOfSelectedJob?.filter((x: any) => {
      return x.id === this.state.presentJobIdSelected && this.state.presentJobIdSelected
    }).map((y: any) => y?.attributes?.candidates?.data)

    return data && data[0]
  }

  getCandidatesList = () => {
    return (this.state.searchText?.length ? this.state.allCandidateList?.filter((y: any) => {
      return !this.getSelectionArray()?.map((x: any) => x.id)?.includes(y.id)
    }).filter((item: any) => {
      return ((item?.attributes?.full_name?.toLowerCase()?.includes(this.state?.searchText.toLowerCase())
        || item?.attributes?.email?.toLowerCase().includes(this.state.searchText.toLowerCase())
        || item?.attributes?.current_location?.toLowerCase().includes(this.state?.searchText.toLowerCase())
        || item?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.designation?.toLowerCase().includes(this.state.searchText.toLowerCase())
      )
      )
    }) : this.state.allCandidateList?.filter((y: any) => {
      return !this.getSelectionArray()?.map((x: any) => x.id)?.includes(y.id)
    })

    )
  }
  
  openCandidateModal = () => {
    return <Modal
      isOpen={this.state.isCandidateModal}
      ariaHideApp={false}
      contentLabel=""
      className="mytask-add-candidates"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-11">
              <label className="task-label-candidate">Allocate Candidates to</label><label className="task-label" style={{ color: '#3756f0' }}>{this.state.selectedDashboardFilter}</label>
            </div>
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ isCandidateModal: false, noCandidateSelectedError: false, searchText: "", selectedArrayIds: [] }) }}>X</button>
            </div>
          </div>
          <div>
            <input type="text" className="header-searchField2" placeholder="Search Here" onChange={(e) => this.filterSearchResults(e)} />
          </div>
          <div className="row allocate-candidate-section" style={{ maxHeight: '393px', marginTop: '10px', padding:'10px' }}>
            {this.getCandidatesList()?.length > 0 && this.getCandidatesList()?.map((ele: any) =>
              <div id={ele.id} className={`col-3 add-candidates-ui ${this.state.selectedArrayIds?.length > 0 && this.state.selectedArrayIds.includes(ele.id) ? 'selected-candidate' : ''}`} onClick={() => this.handleAddCandidate(ele.id)}>
                <div className="row">
                  <div className="col-4 ">
                    <img src={ele.attributes?.profile_picture || require('../../../components/src/img_avatar.png')} style={{ height: '60px', width: '60px' }} />
                  </div>
                  <div className="col-8" style={{ color: 'black' }}>
                    <span>{ele?.attributes?.full_name}</span>
                    <br />
                    <div style={{ fontSize: '12px' }}>{ele?.attributes?.email}</div>
                    <div style={{ fontSize: '12px' }}>{ele?.attributes?.current_location}</div>
                    <div style={{ fontSize: '12px', color: 'blue' }}> {ele?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.designation}</div>
                  </div>

                </div>
              </div>
            )}
            {
              this.getCandidatesList()?.length === 0 && (
                <div style={{ color: 'red' }}>Candidate Not Found</div>
              )
            }
          </div>
          <div>{this.state.noCandidateSelectedError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>

          <div style={{ paddingBottom: '35px' }} >
            <div className="center-item-button">
              <button
                className="btn save-button2"
                type="button"
                onClick={() => {
                  if (!this.state.selectedArrayIds.length)
                    this.setState({ noCandidateSelectedError: true })
                  else {
                    this.setState({ noCandidateSelectedError: false })
                    this.handleAddCandidateTojob();
                  }
                }}
              >Add</button>
            </div>
          </div>
        </form>
      </div>

    </Modal >
  }

  handleTaskChange = (event: any) => {
    event && event.preventDefault();
    const target: any = event.target;
    const value: any = target.value;
    const name: any = target.name;
    //@ts-ignore
    this.setState({ ...this.state, [name]: value });
  }

  callSaveTaskApi = (type: any) => {
    if (type === 'save') {
      if (this.state.taskSubject.length && this.state.taskAssign.length && this.state.taskDate.length) {
        this.handleAddTask();
        this.setState({ createTaskError: false })
      } else {
        this.setState({ createTaskError: true })
      }
    } else {
      this.handleUpdateTask();
    }
  }

  callSaveJobDescription = (e: any) => {
    e.preventDefault();

    if (!!this.state.registerJobTitle?.length && !!this.state.registerJobLocation?.length && !!this.state.registerJobExclusivityEndDate?.length && !!this.state.registerJobDescription.length && !!this.state.registerJobWebsite?.length && !!this.state.registerJobDecisionMaker?.length && !!this.state.registerJobDesigerMakerEmail?.length && !!this.state.registerJobSOPName?.length && !!this.state.registerJobSOPEmail?.length) {
      //validating email IDs
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.registerJobDesigerMakerEmail) && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.registerJobSOPEmail))) {
        this.setState({ jobAddError: 'Email' });
        return;
      }
      this.saveNewJob();
      this.setState({ jobAddError: false })
    }
    else {
      this.setState({ jobAddError: true })
    }
  }

  callAddReminderApi = () => {
    if (this.state.reminderSubject?.length && this.state.reminderDate?.length && this.state.reminderTime?.length) {
      this.setState({ reminderError: false });
      this.handleAddReminder();
    } else {
      this.setState({ reminderError: true })
    }
  }

  updateReminderApi = () => {
    if (this.state.reminderSubject.length && this.state.reminderDate.length && this.state.reminderTime.length) {
      this.setState({ reminderError: false });
      this.handleUpdateReminder();
    } else {
      this.setState({ reminderError: true })
    }
  }

  handleTextFieldArea = (selectedComponent: any) => {
    let textarea: any = document.getElementById(selectedComponent);
    let limit = 150
    textarea.oninput = function () {
      textarea.style.height = "";
      textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
    };
  }

  openMyTaskModal = () => {
    return <Modal
      isOpen={this.state.isShowMyTaskModal}
      contentLabel=""
      className="mytask-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-11">
              <label className="task-label">Create New Task</label>
            </div>
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ isShowMyTaskModal: false, createTaskError: false, taskSubject: '', taskCardId: '', taskDate: '', taskComment: '', taskAssign: '' }) }}><b>X</b></button>
            </div>
          </div>
          <div className="add-task-form col-12">
            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Subject</label>
              <TextField
                id="outlined-secondary"
                // label="Subject"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleTaskChange(e)}
                className="form-control input-field text-field-input"
                name="taskSubject"
                value={this.state.taskSubject}
              />
              {/* <input type="text" className="form-control input-field" style={{ height: '44px' }} placeholder="Subject" name="taskSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
            </div>
            <div className="mt-2 mb-2 row p-3">
              <div className="col-6">
                <label className="my-label">Date</label>
                <input type="text" placeholder="Date" 
                max ="9999-12-31"
                style={{ height: '44px' }} onFocus={(e) => e.target.type = 'date'} className="form-control input-field" min={new Date().toISOString().split('T')[0]} name="taskDate" onChange={(e) => this.handleTaskChange(e)} />
              </div>
              <div className="col-6">
                {/* <b>Assign</b> */}
                <label className="my-label">Assing To</label>
                <Select styles={customStyles} options={this.state.candidatesData} placeholder="" onChange={(selectedOption) => this.handleSelect(selectedOption, 'taskAssign')} />
              </div>
            </div>

            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Add Comments</label>
              <TextField
                multiline
                rows={5}
                id="outlined-secondary add-Task-comments"
                // label="Add Comments"
                variant="outlined"
                color="primary"
                onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea("outlined-secondary add-Task-comments") }}
                className="form-control input-field"
                name="taskComment"
                value={this.state.taskComment}

              />
              {/* <textarea placeholder="Add Comments" className="form-control input-field" name="taskComment" style={{ height: '108px' }} id="add-Task-comments" onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea("add-Task-comments") }} /> */}
            </div>
          </div>
          <div>{this.state.createTaskError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
          <div className="center-item-button">
            <button className="btn save-button" type="button" onClick={() => this.callSaveTaskApi('save')}>Save</button>
          </div>
        </form>
      </div>
    </Modal >
  }

  openReminderModal = () => {
    return <Modal
      isOpen={this.state.isShowReminderModal}
      contentLabel=""
      className="mytask-reminder"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <form noValidate autoComplete="off">
        <div className="row">
          <div className="col-11">
            <label className="task-label">Add New Reminder</label>
          </div>
          <div className="col-1">
            <button className="btn cross-btn" onClick={() => { this.setState({ isShowReminderModal: false, reminderError: false }) }}><b>X</b></button>
          </div>
        </div>
        <div className="add-job">
          <div className="mt-2 p-3">
            <label className="my-label">Subject</label>
            <TextField
              id="outlined-secondary"
              // label="Subject"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleTaskChange(e)}
              className="form-control input-field text-field-input"
              name="reminderSubject"
              value={this.state.reminderSubject}
            />

          </div>
          <div className="row mt-1  p-3">
            <div className="col-6">
              <label className="my-label">Date</label>
              <input type="text" placeholder="Date" max ="9999-12-31" onFocus={(e) => e.target.type = 'date'} className="form-control input-field" name="reminderDate" min={new Date().toISOString().split('T')[0]} onChange={(e) => this.handleTaskChange(e)} />
            </div>
            <div className="col-6">
              <label className="my-label">Add Time</label>
              <input type="time" className="form-control input-field" placeholder="Time" name="reminderTime" onChange={(e) => this.handleTaskChange(e)} />
            </div>
          </div>
          <div className="mt-2 p-3">
            <label className="my-label">Add Comments</label>
            <TextField
              multiline
              rows={5}
              id="outlined-secondary reminderComment"
              // label="Add Comments"
              variant="outlined"
              color="primary"
              onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea("outlined-secondary reminderComment") }}
              className="form-control input-field"
              name="reminderComment"
              value={this.state.reminderComment}
            />
            {/* <textarea style={{ height: '108px' }} className="form-control input-field" placeholder="Add comments" name="reminderComment" id="reminderComment" onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea('reminderComment'); }} /> */}
          </div>
        </div>
        <div>{this.state.reminderError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
        <div className="center-item-button">
          <button className="btn save-button" type="button" onClick={this.callAddReminderApi}>Save</button>
        </div>
      </form>
    </Modal>
  }

  viewTaskModal = () => {
    return <Modal
      isOpen={this.state.viewTaskModal}
      contentLabel=""
      className="mytask-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-11">
              <label className="task-label">View Task</label>
            </div>
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ viewTaskModal: false, createTaskError: false, taskSubject: '', taskCardId: '', taskDate: '', taskComment: '', taskAssign: '' }) }}><b>X</b></button>
            </div>
          </div>
          <div className="add-task-form col-12" style={{marginTop: "-20px"}}>
            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Subject</label>
              <TextField
                id="outlined-secondary"
                // label="Subject"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleTaskChange(e)}
                className="form-control input-field text-field-input"
                name="taskSubject"
                value={this.state.taskSubject}
              />
              {/* <input type="text" className="form-control input-field" style={{ height: '44px' }} placeholder="Subject" value={this.state.taskSubject} name="taskSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
            </div>
            <div className="mt-2 mb-2 row p-3">
              <div className="col-6">
                <label className="my-label">Date</label>
                <input type="date" max ="9999-12-31" value={this.state.taskDate} placeholder="Date" style={{ height: '44px' }} className="form-control input-field" min={new Date().toISOString().split('T')[0]} name="taskDate" onChange={(e) => this.handleTaskChange(e)} />
              </div>
              <div className="col-6">
                <label className="my-label">Assigned To</label>
                <Select styles={customStyles} options={this.state.candidatesData} placeholder={this.state.assigneeName} onChange={(selectedOption) => this.handleSelect(selectedOption, 'taskAssign')} />
              </div>
            </div>

            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Add Comments</label>
              <TextField
                multiline
                rows={5}
                id="outlined-secondary"
                // label="Add Comments"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleTaskChange(e)}
                className="form-control input-field"
                name="taskComment"
                value={this.state.taskComment}
              />
              {/* <textarea placeholder="Add Comments" className="form-control input-field" value={this.state.taskComment} name="taskComment" style={{ height: '108px' }} onChange={(e) => this.handleTaskChange(e)} /> */}
            </div>
          </div>
          <div>{this.state.createTaskError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
          <div className="">
            <div className="flex">
              <button className="btn save-button-1 " type="button" onClick={() => this.callSaveTaskApi('update')}>Update</button>
              <button className="btn save-button-2 " type="button" onClick={() => this.markAsCompleteTask(this.state.taskCardId)}>Mark as Completed</button>
            </div>
          </div>
        </form>
      </div>
    </Modal >
  }

  viewReminderModal = () => {
    return <Modal
      isOpen={this.state.viewReminderModal}
      contentLabel=""
      className="mytask-reminder"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <form noValidate autoComplete="off">
        <div className="row">
          <div className="col-11">
            <label className="task-label">View Reminder</label>
          </div>
          <div className="col-1">
            <button className="btn cross-btn" onClick={() => { this.setState({ viewReminderModal: false, reminderError: false, reminderComment: '', reminderDate: '', reminderId: '', reminderSubject: '', reminderTime: '' }) }}><b>X</b></button>
          </div>
        </div>
        <div className="add-job">
          <div className="mt-2 mb-3 p-3">
            <label className="my-label">Subject</label>
            <TextField
              id="outlined-secondary"
              // label="Subject"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleTaskChange(e)}
              className="form-control input-field text-field-input"
              name="reminderSubject"
              value={this.state.reminderSubject}
            />
            {/* <input type="text" value={this.state.reminderSubject} className="form-control input-field" placeholder="Subject" name="reminderSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
          </div>
          <div className="row mt-2 mb-3 p-3">
            <div className="col-6">
              <label className="my-label">Date</label>
              <input type="date" max ="9999-12-31" value={this.state.reminderDate} placeholder="Date" onFocus={(e) => e.target.type = 'date'} className="form-control input-field" name="reminderDate" min={new Date().toISOString().split('T')[0]} onChange={(e) => this.handleTaskChange(e)} />
            </div>
            <div className="col-6">
              <label className="my-label">Time</label>
              <input type="text" onBlur={(e) => e.target.type = 'text'} onFocus={(e) => e.target.type = 'time'} className="form-control input-field" placeholder={this.tConvert(this.state.reminderTime?.split('+')[0]).replace(":00", " ")} name="reminderTime" onChange={(e) => this.handleTaskChange(e)} />
            </div>
          </div>
          <div className="mt-2 mb-3 p-3">
            <label className="my-label">Add Comments</label>
            <TextField
              multiline
              rows={5}
              id="outlined-secondary"
              // label="Add Comments"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleTaskChange(e)}
              className="form-control input-field"
              name="reminderComment"
              value={this.state.reminderComment}
            />
            {/* <textarea className="form-control input-field" value={this.state.reminderComment} placeholder="Add comments" name="reminderComment" id="reminderComment" onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea('reminderComment'); }} /> */}
          </div>
        </div>
        <div>{this.state.reminderError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
        <div className="center-item-button">
          <button className="btn save-button" type="button" onClick={this.updateReminderApi}>Update</button>
        </div>
      </form>
    </Modal>
  }

  changeStatusModal = () => {
    return <Modal
      isOpen={this.state.changeStatusModal}
      contentLabel=""
      className="mytask-reminder"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          color: 'lightsteelblue'
        }
      }}
    >
      <form noValidate autoComplete="off">
        {/*  @ts-ignore */}
        <center>
          <div className="row">
            <div className="col-11 mt-2">
              <label className="task-label">Change Candidate Status</label>
            </div>
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ changeStatusModal: false }) }}><b>X</b></button>
            </div>
          </div>
          <div className="add-job" style={{ height: 'auto' }}>
            <div className="row col-12" style={{ marginTop: '5%', textAlign: 'left' }}>
              {
                this.state.candidateStatuTypes?.map((x: any) => {
                  return (
                    <div className="col-6">
                      <input
                        key={x.id}
                        name="status" type="checkbox" value={x.id} onChange={() => this.setState({ statusId: x.id })} checked={this.state.statusId === x.id ? true : false} />
                      <label style={{
                        color: 'black', margin: '9px', textTransform: 'capitalize'
                      }}>{x.name}</label>
                    </div>
                  )
                })
              }

            </div>
          </div>
          {/*  @ts-ignore */}
        </center>
        <div className="row" style={{ marginLeft: '8%', marginTop: '50px' }}>
          <div className="col-6">
            <button className="btn save-button-status" type="button" onClick={() => this.setState({ changeStatusModal: false })}>Cancel</button>
          </div>
          <div className="col-6">
            <button className="btn save-button-status" type="button" onClick={() => this.updateCandidateStatusApi()}>Save</button>
          </div>
        </div>
      </form>
    </Modal>
  }

  tConvert(time: any) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  updateJobModalState = () => {
    this.setState({ isShowJobModal: true })
  }

  openDropdownModal = (profileId: number) => {
    this.setState({
      isJobSubModalOpenId: this.state.isJobSubModalOpenId === profileId ? null : profileId
    })
  }

  openDropDownModalUI = (cardInfo: any, type: any) => {
    if (type === 'jobs') {
      // @ts-ignore
      return <ul className="ellipsis-dropdown-ul" id="drop-downDashboard">
        <li className="ellipsis-dropdown-li" onClick={() => {
          this.setState({
            viewJobModal: true,
            presentJobIdSelected: cardInfo.id
          })
          this.getCandidateJobStatus()
          this.getCurrentJobDetail(cardInfo.id)
        }
        }>
          Job Details
        </li>
        <li className="ellipsis-dropdown-li" onClick={() => {
          this.setCompanyView(cardInfo)
        }
        }>
          Company details
        </li>

        <li onClick={() => { this.setState({ isCandidateSection: true, presentJobIdSelected: cardInfo.id, selectedDashboardFilter: cardInfo?.attributes.company_name }); this.getCandidatesOfSelectedJob(cardInfo?.id) }}
        >Candidate List</li>
      </ul >
    } else {
      // @ts-ignore
      return <ul className="ellipsis-dropdown-ul"  id="drop-downDashboard">
        <li className="ellipsis-dropdown-li">
          <Link to={`CandidateProfile?id=${cardInfo.id}`}>Candidate Profile</Link>
        </li>
        <li onClick={() => {
          this.setState({
            changeStatusModal: true,
            candidateId: cardInfo.id
          })
        }}>Change Status</li>
      </ul>
    }

  }

  openCanidatesCommentModal = () => {
    return <Modal isOpen={this.state.isshowCandidateComment} className="candidate-comments">
      <h4>Comments</h4>
      <div style={{ float: 'inherit', marginTop: '-40px' }}>
        <button className="btn cross-btn" onClick={() => { this.setState({ isshowCandidateComment: false }) }}><b>X</b></button>
      </div>
      <br />
      <hr />
      <div className="comment-listing">
        {this.state.candiateCommentsList.length > 0 && this.state?.candiateCommentsList?.map((ele: any) => <div className="comments-list">
          <h6>{ele.attributes.comment_by}</h6>
          <p>{ele.attributes.comment}</p>

          <div style={{ display: 'flex' }}>
            <b style={{ width: '60%' }}>{ele.attributes.comment_to}</b>
            <span>{ele.attributes.date}</span>
          </div>
          <hr className="comment-line" />
          <br />
        </div>)}
      </div>

    </Modal>
  }

  handleJobCardSearch = (text: any) => {
    // if (text.length  ) {
      this.setState({
        presentKanbanBoardData: !this.state.isCandidateSection ? this.state.JobData?.filter((ele: any) => {
          return ele.attributes?.title?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.company_name?.toLowerCase().includes(text?.toLowerCase())}) 
          : this.state.listOfCandidatesOfSelectedJob?.filter((ele: any) => {
            return ele.attributes?.full_name?.toLowerCase().includes(text?.toLowerCase()) || ele?.attributes?.current_location?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.created?.toLowerCase().includes(text?.toLowerCase()) || ele?.attributes?.current_employment_detail?.company_name?.toLowerCase().includes(text?.toLowerCase())
          })
      })
    // }
  }

  // Customizable Area End

  render() {
    // presentSection === 'All Candidates' ? candidateSectionLane : jobsectionLane
    let Card = (cardInfo: any) =>
      !this.state.isCandidateSection ?
        <div
          className="dashboard-cards"
          id={cardInfo?.id}
        >
          <div className="card-title"
            onClick={() => {
              this.setState({ viewJobModal: true, presentJobIdSelected: cardInfo.id, selectedDashboardFilter: cardInfo.attributes.company_name }); this.getCandidatesOfSelectedJob(cardInfo.id)
              this.getCandidateJobStatus()
              this.getCurrentJobDetail(cardInfo.id)
            }}

          >{cardInfo?.attributes?.company_name ? cardInfo?.attributes?.company_name : '-'}</div>
          {/* ellipsis */}
          <div className="col-2 ellipsis-item-dashboard"
            onClick={() => {this.openDropdownModal(cardInfo.id)}}
          >
            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
            { this.state.isJobSubModalOpenId === cardInfo.id && this.openDropDownModalUI(cardInfo, 'jobs')}
          </div>

          <div onClick={() => {
            this.setState({
              viewJobModal: true,
              presentJobIdSelected: cardInfo.id
            })
            this.getCandidateJobStatus()
            this.getCurrentJobDetail(cardInfo.id)
          }
          }>
            <div>{cardInfo?.attributes?.title?.length > 16 ? `${cardInfo?.attributes?.title.slice(0, 13)}...` : cardInfo?.attributes?.title ? cardInfo?.attributes?.title : '-'}
            </div>
            <div className="card-date">{cardInfo?.attributes?.created}</div>
            <br />
            {(cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[0] || cardInfo?.attributes?.candidates?.data.length > 5) && <img className="dashboard-small-img" src={cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[0] || require('../../../components/src/img_avatar.png')} />}

            {(cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[1] || cardInfo?.attributes?.candidates?.data.length > 5) && <img className="dashboard-small-img" src={cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[1] || require('../../../components/src/img_avatar.png')} />}

            {(cardInfo?.attributes?.candidates?.data.length > 5 || cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[2]) && <img className="dashboard-small-img" src={cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[2] || require('../../../components/src/img_avatar.png')} />}

            {(cardInfo?.attributes?.candidates?.data.length > 5 || cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[3]) && <img className="dashboard-small-img" src={cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[3] || require('../../../components/src/img_avatar.png')} />}

            {(cardInfo?.attributes?.candidates?.data.length > 5 || cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[4]) && <img className="dashboard-small-img" src={cardInfo?.attributes?.profile_picture && cardInfo?.attributes?.profile_picture[4] || require('../../../components/src/img_avatar.png')} />}

            {(cardInfo?.attributes?.candidates?.data.length - 5) > 0 && <span className="dashboard-small-img-last">{(cardInfo?.attributes?.candidates?.data.length - 5) > 0 ? `+ ${cardInfo?.attributes?.candidates?.data.length - 5}` : 0}</span>}
          </div>
        </div>
        :
        <div
          className="dashboard-candidate-card row"
          id={cardInfo?.id}
        >
          <div style={{ 'display': 'flex' }} className="col-10" onClick={() => window.location.href = `CandidateProfile?id=${cardInfo?.id}`}>
            <img className="dashboard-candidate-img" src={cardInfo?.attributes?.profile_picture || require('../../../components/src/img_avatar.png')} />
            <div>{cardInfo?.attributes?.full_name}</div>
          </div>
          <div className="card-title-candidate" onClick={() => window.location.href = `CandidateProfile?id=${cardInfo?.id}`}>
            <span style={{ 'color': 'blue' }}>
              {cardInfo?.attributes?.current_employment_detail?.designation?.length > 15 ? `${cardInfo?.attributes?.current_employment_detail?.designation.slice(0, 13)}..` : cardInfo?.attributes?.current_employment_detail?.designation}
            </span>, in {cardInfo?.attributes?.current_location?.length > 10 ? `${cardInfo?.attributes?.current_location.slice(0, 8)}..` : cardInfo?.attributes?.current_location}
          </div>

          <div className="col-2 ellipsis-dashboard-candidate"
            onClick={() => this.openDropdownModal(cardInfo.id)}
          >
            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
            {this.state.isJobSubModalOpenId === cardInfo.id && this.openDropDownModalUI(cardInfo, 'candidates')}
          </div>

          <div style={{ 'display': 'flex', 'marginLeft': '60px', 'marginTop': '-6px' }} onClick={() => window.location.href = `CandidateProfile?id=${cardInfo?.id}`}>
            <div style={{ 'color': 'blue', 'fontWeight': 'bold' }}>{cardInfo?.attributes?.current_employment_detail?.company_name?.length > 12 ? `${cardInfo?.attributes?.current_employment_detail?.company_name.slice(0, 10)}..` : cardInfo?.attributes?.current_employment_detail?.company_name}</div>
            <div className="card-candidate-date">{cardInfo?.attributes?.created}</div>
          </div>
          <img className="card-candidate-file-icon" onClick={() => { this.getCandidateComments(cardInfo?.id) }} src={require('../../../components/src/file-icon.png')} />
        </div>


    function tConvert(time: any) {
      // Check correct time format and split into components
      time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }

    const getDateFormat = (date: any) => {
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];;
      var now = new Date(date);
      return (months[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear())
    }

    let cardStyle = {
      "width": 'auto',
      "height": 'auto',
      "backgroundColor": "#F9F9FA",
      "marginTop": "10px",
      "marginBottom": "30px",
      "borderRadius": "10px",
    }

    let lanes = (!!this.state.jobStatusType?.length && !!this.state.candidateStatuTypes?.length) ?
      (this.state.isCandidateSection ? this.state.candidateStatuTypes : this.state.jobStatusType)?.map((ele: any, index: number) =>
      ({
        id: ele.id,
        title: ele.name[0].toUpperCase() + ele.name.slice(1),
        // @ts-ignore
        cards: (!!this?.props?.companySection && !this?.state?.isCandidateSection) ? this.state.presentKanbanBoardData?.filter((value: any) => value?.attributes?.company_name == this.props?.presentcompanyName)?.filter((item: any) => (item.attributes.status === ele.name) || (item?.attributes?.job_status_name === ele.name))
          : this.state.presentKanbanBoardData?.filter((item: any) => (item.attributes.status === ele.name) || (item?.attributes?.job_status_name === ele.name)),
        style: cardStyle,
        disallowAddingCard: index !== 0
      })) : [];

    let boardData = { lanes };
    let laneFooter: any = document.getElementsByClassName('sc-idOiZg bKvNWh');
    laneFooter[0] ? (laneFooter[0].innerHTML = `<div id="add-new-job">➕ Add New Job</div>`) : '';

    return (
      // Customizable Area Start
      <div>
        {/* @ts-ignore */}
        <div className={!!this.props.companySection ? 'company-page-wrapper' : 'wrapper'}>
          {/* @ts-ignore */}
          {!this.props.companySection && <SideNav data={this.state.currentUser} />}
          {/* @ts-ignore */}
          <div className="kanban-content" >
            {/* @ts-ignore */}
            {this.openMyTaskModal()}
            {this.openReminderModal()}
            {this.openAddNewJobModal()}
            {this.openCandidateModal()}
            {this.viewTaskModal()}
            {this.viewReminderModal()}
            {this.viewJobModal()}
            {this.changeStatusModal()}
            {this.openCanidatesCommentModal()}
            {/* @ts-ignore */}
            {!this.props.companySection && <div>
              <TopNav data={this.state.currentUser} handleSearchText={(text: any) => this.handleJobCardSearch(text)} myModal={() => this.setState({ isShowMyTaskModal: !this.state.isShowMyTaskModal })} />

              <div className="row col-12">
                <div className="col-6 row">
                  <div className="col-6" >
                    <div className="dashboard-text1">
                      <p style={{ "fontSize": '15px', 'marginRight': '3px' }}> Show:</p>
                      {this.state.isCandidateSection ? this.state.selectedDashboardFilter : "All Jobs"}
                      {this.state.isCandidateSection && <div onClick={() => {
                        this.setState({ isCandidateSection: false, presentKanbanBoardData: this.state.JobData });
                      }}>&nbsp; X</div>}
                    </div>
                    <br />
                    {this.state.isCandidateSection ? <div className="dashboard-text">
                      <p style={{ "fontSize": '15px', 'marginRight': '5px' }}>Show:<span style={{ 'fontWeight': 'bold', 'color': 'black', marginLeft: '2px' }}>All Candidates</span> </p></div>
                      : ''}
                  </div>
                </div>


                {/* Add task and reminder sections */}
                <div className="col-1">
                  <div className="view-base">
                    <p className="quick-actions">Quick Actions</p>
                    <div className="view-base-btn">
                      <Link to='/user-tasks'>
                        <div className="view_Rectangle1" >
                          <div className='icon-font'>
                            <img className="icon-task" src={task} />
                            My Task
                          </div>
                        </div>
                      </Link>
                      <Link to='/reminder'>
                        <div className="view_Rectangle2" >
                          <div className='icon-font'>
                            <img className="icon-bell" src={bell} />
                            Reminders
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row dashboard-text2">
                <div className="">
                  {!this.state.isCandidateSection ?
                    <p onClick={() => this.setState({ isShowJobModal: true })} className="button-addModal" >+ Add New Job</p>
                    :
                    <p onClick={() => this.setState({ isCandidateModal: true })} className="button-addModal">+ Add Candidates</p>
                  }
                </div>
              </div>
            </div>}

            <div className="row col-12" style={{ backgroundColor: '#f9f9fa' }}>
              {/* @ts-ignore */}
              <div className={this.props.companySection ? `${this.state.jobLoader ? 'loading-section col-12' : 'col-12'}` : `${this.state.jobLoader ? 'loading-section col-9' : 'col-9'}`} style={{ backgroundColor: 'white' }}>
                {!this.state.jobLoader ? <>
                  {/* @ts-ignore */}
                  {<Board className={!this.props.companySection ? `kanban-board` : `company-page-board`}
                    data={boardData}
                    components={{ Card }}

                    style={{ flexDirection: 'column', backgroundColor: '#ffffff', maxWidth: '100%', overFlow: 'scroll' }}

                    disallowAddingCard={true}
                    // editable={true}
                    onCardAdd={this.onCardAdd}
                    handleDragEnd={this.handleBoardDragEnd}
                  />}
                </> : <CircularProgress />}
              </div>
              {/* @ts-ignore */}
              {!this.props.companySection && <div className="col-3">
                {<div className="task-reminder-section mb-2">
                  <div className="task-section">
                    <div className="reminder-section-start">
                      <span className="reminder-heading">My Tasks</span>
                      <span className="reminder-add-modal" onClick={() => this.setState({ isShowMyTaskModal: true })}>ADD</span>
                    </div>
                    {
                      this?.state?.tasksData?.length > 0 ? (
                        <div className="cards-list">
                          {this.state?.tasksData?.filter((ele: any) => ele.attributes.status !== 'complete').map((ele: any) =>
                            <div className="reminder-card" onClick={() => {
                              this.setState({ viewTaskModal: true })
                              this.setState({
                                taskCardId: ele.id,
                                taskSubject: ele?.attributes?.subject,
                                taskComment: ele.attributes.task_comment,
                                taskDate: ele.attributes.task_date,
                                taskAssign: ele.attributes.assignee_id,
                                assigneeName: ele.attributes.assigned_to ? ele.attributes.assigned_to : ele.attributes.assign_to_id === ele.attributes.assignee_id ? 'Myself' : ''
                              })
                            }}>
                              <h6 className="reminder-sub" title={ele?.attributes?.subject} >{ele?.attributes?.subject.length > 12 ? `${ele?.attributes?.subject.slice(0, 10)}..` : ele?.attributes?.subject}</h6>
                              <div className="card-task-date">{getDateFormat(ele.attributes.task_date)}</div><br />
                              <div className="reminder-subsection">
                                <span className="reminder-date">Created by: {ele.attributes.assignee}</span><br />
                                <span className="reminder-time">Assigned to: {ele.attributes.assigned_to ? ele.attributes.assigned_to : ele.attributes.assign_to_id === ele.attributes.assignee_id ? 'Myself' : ''}</span><br />
                              </div>
                              <p className="reminder-comment">{`${ele.attributes.task_comment.toString().length > 100 ? `${ele.attributes.task_comment.slice(0, 100)} ...` : ele.attributes.task_comment}`}</p>

                            </div>
                          )}
                        </div>
                      ) : <div className="no-task">{'No Task '}</div>
                    }

                  </div>
                  <div className="reminder-section">
                    <div className="reminder-section-start">
                      <span className="reminder-heading">Reminder</span>
                      <span className="reminder-add-modal" onClick={() => this.setState({ isShowReminderModal: true })}>ADD</span>
                    </div>
                    {
                      this.state?.allRemindersList?.length > 0 ? (
                        <div className="cards-list">
                          {this.state?.allRemindersList.map((ele: any) =>
                            <div className="reminder-card" onClick={() => {
                              this.setState({
                                viewReminderModal: true,
                                reminderSubject: ele?.attributes?.subject,
                                reminderDate: ele.attributes.reminder_date,
                                reminderTime: ele.attributes.reminder_time,
                                reminderComment: ele.attributes.reminder_comment,
                                reminderId: ele.id
                              })

                            }}>
                              <h6 className="reminder-sub">{ele?.attributes?.subject?.length > 70 ? `${ele?.attributes?.subject.slice(0, 69)}...` : ele?.attributes?.subject}</h6>
                              <div className="reminder-subsection">
                                <span className="reminder-date">{getDateFormat(ele.attributes.reminder_date)}</span><br />
                                <span className="reminder-time">Time:{tConvert(ele.attributes.reminder_time?.split('+')[0]).replace(":00", " ")}</span><br />
                              </div>
                              <p className="reminder-comment">{`${ele?.attributes?.reminder_comment?.toString()?.length > 100 ? `${ele.attributes?.reminder_comment.slice(0, 100)} ...` : ele?.attributes?.reminder_comment}`}</p>

                            </div>
                          )}
                        </div>

                      ) : <div className="no-task">{'No Reminder'}</div>
                    }
                  </div>
                </div>}
              </div>}
            </div>
          </div>
        </div>
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
  option: (provided: any) => ({
    ...provided,
    color: 'black'
  }),
}
// Customizable Area End
