import React from "react";

//Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback
} from "react-native";

import { Formik } from "formik";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";

import Select from 'react-select'
import RichTextEditor from 'react-rte';
import './teambuilder.css'
import { TextField } from '@material-ui/core';
import TeamBuilderController, { Props } from "./TeamBuilderController";
//@ts-ignore
const { PreviewCompanyPdf } = require("./previewCompanyPdf");
import Modal from "react-modal";
import { linkdinIcon, uploadIcon } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

//Customizable Area End

export default class TeamBuilder extends TeamBuilderController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  //Customizable Area Start
  //Customizable Area End
  handleChange = (event: any, isSelectFields: any = false) => {
    let name: any = '';
    let target: any = '';
    let value: any = '';

    if (isSelectFields) {
      name = isSelectFields;
      value = event.value;
    } else {
      event && event.preventDefault();
      target = event.target;
      value = target.value;
      name = target.name;
    }

    //@ts-ignore
    this.setState({ ...this.state, [name]: value });
  }

  handleFileChange = (event: any) => {
    let name: any = '';
    let target: any = '';
    let value: any = '';
    event && event.preventDefault();
    target = event.target;
    value = {
      file: target.files[0],
      fileName: target.files[0].name
    }
    name = target.name;
    //@ts-ignore
    this.setState({ [name]: value });
  }

  updateCompanyModal = () => {
    this.setState({ isPreviewCompanyDetails: !this.state?.isPreviewCompanyDetails })
  }

  genrateCompanyPdf = () => {
    return <Modal
      isOpen={this.state?.isPreviewCompanyDetails}
      contentLabel=""
      className=""
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          zIndex: 999999999999,
        },
        content: {
          color: ''
        }
      }}
    >
      {PreviewCompanyPdf(() => this.updateCompanyModal, this.state?.previewPQForm, this.state.currencyOptions)}
    </Modal >
  }

  handleTextFieldArea = () => {
    let textarea: any = document.getElementById('text-area-confidential');
    let limit = 400;
    textarea.oninput = function () {
      textarea.style.height = "";
      textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
    };
  }

  handleSectionHide = (hideSection: any) => {
    let displaySection = document.getElementById(hideSection);

    if (displaySection) {
      //@ts-ignore
      document.getElementById(hideSection).style.display = displaySection?.style?.display === 'none' ? '' : 'none'
    }
  }

  changeSymbol = (hideSection: any, hide: any) => {
    let displaySection = document.getElementById(hide);

    var add = `<h1 style={cursor: 'pointer'} className="compress-section">+</h1>`
    var remove = `<h1 style={cursor: 'pointer'} className="compress-section">—</h1>`
    //@ts-ignore
    document.getElementById(hide).style.display = displaySection?.style?.display === 'none' ? document.getElementById(hideSection).innerHTML = remove : document.getElementById(hideSection).innerHTML = add

  }

  makeTextEditorHide = () => {
    //close rich Text Editor sections
    let editorEle = document.getElementsByClassName('text-editor');
    if (this.state?.isSavePQCapsule) {
      for (let i = 0; i < editorEle.length; i++) {
        // @ts-ignore
        document.getElementsByClassName('text-editor')[i].style.display = document.getElementsByClassName('text-editor')[i]?.style?.display === 'none' ? '' : 'none';
      }
    }
  }

  showSaveModal = () => {
    // hide textEditors
    this.makeTextEditorHide();

    var today: any = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    return <Modal
      isOpen={this.state?.isSavePQCapsule}
      contentLabel=""
      className="save-pq-capsule-modalheight"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          zIndex: 99999999999999999,
        },
        content: {
        }
      }}
    >
      <div className="row">
        <div className="col-10" style={{ textAlign: 'center' }}>
          <h3 >Save Purple Quarter Capsule</h3>
        </div>
        <div className="col-2" style={{ textAlign: 'end' }}>
          <button className="btn cross-btn"
            onClick={() => {
              this.setState({ isSavePQCapsule: false });
              // hide textEditors
              this.makeTextEditorHide();
            }}><b>X</b></button>
        </div>
      </div>
      <div className="add-task-form col-12">
        <div className="mt-2 mb-2 p-3">
          <div style={{ height: '44px', color: 'black', backgroundColor: 'transparent !importanat' }} className="form-control input-field" >{this.state.editPQCapsuleForm ? this.state.companyName : this.state.companiesData?.filter((ele: any) => ele.value === this.state?.companyName)[0]?.label} - Purple Quarter Capsule -{today}</div>
          <br />
          <div className="col-12">
            <div style={{ height: '44px' }} className="form-control input-field" >{today}</div>
          </div>
          <br />
          <div className="col-12">
            <div style={{ height: '44px' }} className="form-control input-field">{this.state?.currentUser?.first_name}</div>
          </div>
        </div>
      </div>
      <div className="col-12" style={{ marginLeft: '21%' }}>
        {!this.state?.waitForSaveDataLoader ?
          <button className="btn save-button-1 float-left" type="button" onClick={() => this.handleSubmit()}>Save</button> :
          <div style={{ marginLeft: '20%' }}><CircularProgress /></div>
        }
      </div>
    </Modal>

  }

  addMoreFounders = (presentCount: number) => {
    let key = `founderPeopleImage${presentCount}`
    //@ts-ignore

    let newValue = <div style={{ marginTop: '30px' }} id="founderRoundSectionForm">
      <div className="row mt-2 mb-3">
        <p className="relocation-status">Name</p>
        <div className="col-6">
          <label className="my-label">Enter Name</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`foundersName${presentCount}`}
            // @ts-ignore
            value={this.state[`foundersName${presentCount}`]}
            type="text"
          />
        </div>
        <div className="col-6">
          <label className="my-label">Enter Designation</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Designation"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`foundersDesignation${presentCount}`}
            // @ts-ignore
            value={this.state[`foundersDesignation${presentCount}`]}
            type="text"
          />
        </div>
      </div>
      <div className="row mt-2 mb-3">
        <div className="col-8 row">
          <div className="col-5">
            <label className="btn-upload">
              {/* @ts-ignore */}
              <input type='file' accept="image/*" name={`founderPeopleImage${presentCount}`} placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
              <button type="button" className="btn">
                <img src={uploadIcon} className="upload-icon" />
                Upload Image</button>
            </label>
            <br />
            <span className="file-type">File Type: jpg/jpeg/png</span>
          </div>
          {/* @ts-ignore */}
          <div className="col-5 file-label"><i> {this.state[key]?.fileName !== undefined ? this.state[key]?.fileName : "No Image Uploaded"}</i></div>
        </div>
      </div>
      <div className="mt-2 mb-3">
        <label className="my-label">Brief Background</label>
        <TextField
          id="outlined-secondary"
          // label="Brief Background"
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`briefBackground${presentCount}`}
          // @ts-ignore
          value={this.state[`briefBackground${presentCount}`]}
          type="text"
        />
      </div>
      <div className="mt-2 mb-3">
        <label className="my-label">Linkdin Profile Link</label>
        <TextField
          id="outlined-secondary"
          // label="Linkedin Profile Link"
          style={{ marginLeft: '20px', width: '96%' }}
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input linked-inputfield"
          name={`linkdinProfileLink${presentCount}`}
          // @ts-ignore
          value={this.state[`linkdinProfileLink${presentCount}`]}
          type="text"
        />
        <img src={linkdinIcon} className="linkdin-icon" />

      </div>
    </div>

    return newValue
  }

  addFundingRounds = (presentCount: number) => {

    let newValue = <div className="row" style={{ marginTop: '30px' }}>
      <div className="row">
        <div className="col-4" >
          <label className="my-label">Funding Date</label>
          <input type="text"
            style={{ fontSize: '14px', height: '44px', margin: '4px' }}
            onChange={(e => this.handleChange(e))}
            onFocus={(e) => e.target.type = 'date'}
            max="9999-12-31"
            className="form-control input-field"
            // placeholder="Funding Round Date"
            // @ts-ignore
            value={this.state[`fundingRoundMonth${presentCount}`] ? this.state[`fundingRoundMonth${presentCount}`] : ""}
            name={`fundingRoundMonth${presentCount}`}
          />
        </div>
        <div className="col-2" />

        <div className="col-6">
          {/* <p className="relocation-status">Funding Amount</p> */}
          <label className="my-label">Funding Amount</label>
          <div className="row">
            <div className="col-9">
              <TextField
                id="outlined-secondary"
                // label="Funding Amount"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleChange(e)}
                className="form-control input-field text-field-input"
                name={`fundingAmount${presentCount}`}
                // @ts-ignore
                value={this.state[`fundingAmount${presentCount}`]}
                type="text"
                required
                style={{ width: '136%' }}
              />
            </div>
            <div className="col-4 funding-currency-pq">
              <Select
                options={this.state?.currencyOptions}
                // @ts-ignore
                placeholder={this.state.currencyOptions && this.state.currencyOptions?.filter((ele: any) => ele.value === this.state[`currency_id${presentCount}`])[0]?.label || '$'}
                onChange={(e) => this.handleChange(e, `currency_id${presentCount}`)}
                name={`currency_id${presentCount}`}
                styles={customStyle1}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-4" style={{ marginTop: '20px' }}>
        {/* <p className="relocation-status">Funding Round</p> */}
        <label className="my-label">Funding Round</label>
        <Select
          options={[{ value: 'Seed Funding', label: 'Seed Funding' }, { value: 'Series A', label: 'Series A' }, { value: 'Series B', label: 'Series B' }, { value: 'Series C', label: 'Series C' }]}
          className=""
          // @ts-ignore
          placeholder={this.state[`fundingRoundSeries${presentCount}`] || 'Select Series'}
          onChange={(e) => this.handleChange(e, `fundingRoundSeries${presentCount}`)}
          name={`fundingRoundSeries${presentCount}`}
          styles={customStyles}
        />
      </div>
      <div className="col-2" />
      <div className="col-6" style={{ marginTop: '20px' }}>
        {/* <p className="relocation-status">Investors</p> */}
        <label className="my-label">Investors</label>
        <TextField
          id="outlined-secondary"
          // label="Enter Names seprated by comma"
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input mb-3"
          name={`fundingInvestor${presentCount}`}
          // @ts-ignore
          value={this.state[`fundingInvestor${presentCount}`]}
          type="text"
          required
        />
      </div>
    </div>

    return newValue;
  }

  addMoreProducts = (presentCount: number) => {
    let textString = `productText${presentCount}`;
    let imageKey = `productBackgroundImage${presentCount}`;

    //@ts-ignore
    let newValue = <div style={{ marginTop: '30px' }}>
      <div className="col-12 mt-2 mb-3">
        <label className="my-label">Product Name</label>
        <TextField
          id="outlined-secondary"
          // label="Product Name"
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`productName${presentCount}`}
          // @ts-ignore
          value={this.state[`productName${presentCount}`]}
          type="text"
          required
        />
      </div>
      <RichTextEditor
        //@ts-ignore
        value={this.state[textString]}
        //@ts-ignore
        onChange={(value) => this.setState({ [textString]: value })}
        placeholder="Enter Product Description (Limit : 250)"
        className="mt-2 mb-3 text-editor"
      />
      {/* @ts-ignore */}
      {(this.state[textString]?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state[textString]?.toString('markdown').replace('\n', '#').length - 1)}/250</div>}

      <div className="col-8 row">
        <div className="col-5">
          <label className="btn-upload">
            <input type='file' accept="image/*" name={imageKey} placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
            <button type="button" className="btn">
              <img src={uploadIcon} className="upload-icon" />

              Upload Image</button>
          </label>
          <br />
          <span className="file-type">File Type: jpg/jpeg/png</span>
        </div>
        {/* @ts-ignore */}
        <div className="col-5 file-label"><i> {this.state[imageKey]?.fileName !== undefined ? this.state[imageKey]?.fileName : "No Image Uploaded"}</i></div>
      </div>
      {/* <div className="text-editor-image" /> */}
    </div>

    return newValue;
  }

  addMorKeyMetrics = (presentCount: number) => {
    let textString = `keyMatricText${presentCount}`;
    let key = `keyMetricsBackgroundColor${presentCount}`;

    let newValue = <div style={{ marginTop: '30px' }}>
      <RichTextEditor
        //@ts-ignore
        value={this.state[textString]}
        //@ts-ignore
        onChange={(value) => this.setState({ [textString]: value })}
        placeholder="Enter Text(Text limit : 50 words)"
        className="mt-2 mb-3 text-editor"
      />
      {/* @ts-ignore */}
      {(this.state[textString]?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state[textString]?.toString('markdown').replace('\n', '#').length - 1)}/50</div>}

      {/* <div className="text-editor-image" /> */}
      <div className="col-2">
        <div>
          <label htmlFor={key} className="color-pallete-label">Color Picker</label>

          <input
            id={key}
            // @ts-ignore
            value={this.state[key] || ""}
            type="color"
            className="color-pallete col-6"
            name={key} onChange={(e) => this.handleChange(e)}
            required /><br />
        </div>
      </div>

    </div>

    return newValue;
  }

  hrLevel1 = (presentCount: number) => {
    let newValue = <div id="hierarchyLevel1" style={{ marginTop: '30px' }}>
      <div className="row mt-2 mb-3">
        <div className="col-6">
          <label className="my-label">Enter Name</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`hierarchyLevelName${presentCount}`}
            // @ts-ignore
            value={this.state[`hierarchyLevelName${presentCount}`]}
            type="text"
            required
          />
        </div>
        <div className="col-6">
          <label className="my-label">Enter Position</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Position"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`hierarchyLevelPosition${presentCount}`}
            // @ts-ignore
            value={this.state[`hierarchyLevelPosition${presentCount}`]}
            type="text"
            required
          />
        </div>
      </div>
    </div>

    return newValue
  }

  hrLevel2 = (presentCount: number) => {
    let newValue = <div id="hierarchyLevel2" style={{ marginTop: '30px' }}>
      <div className="row mt-2 mb-3">
        <div className="col-6">
          <label className="my-label">Enter Name</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`hierarchyLevel2Name${presentCount}`}
            // @ts-ignore
            value={this.state[`hierarchyLevel2Name${presentCount}`] || ""}
            type="text"
            required
          />
        </div>
        <div className="col-6">
          <label className="my-label">Enter Position</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Position"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`hierarchyLevel2Position${presentCount}`}
            // @ts-ignore
            value={this.state[`hierarchyLevel2Position${presentCount}`] || ""}
            type="text"
            required
          />
        </div>
      </div>
    </div>

    return newValue
  }

  hrLevel3 = (presentCount: number) => {
    let newValue = <div id="hierarchyLevel3" style={{ marginTop: '30px' }}>
      <div className="row mt-2 mb-3">
        <div className="col-6">
          <label className="my-label">Enter Name</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`hierarchyLevel3Name${presentCount}`}
            // @ts-ignore
            value={this.state[`hierarchyLevel3Name${presentCount}`]}
            type="text"
            required
          />
        </div>
        <div className="col-6">
          <label className="my-label">Enter Position</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Position"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`hierarchyLevel3Position${presentCount}`}
            // @ts-ignore
            value={this.state[`hierarchyLevel3Position${presentCount}`]}
            type="text"
            required
          />
        </div>
      </div>
    </div>

    return newValue
  }

  hrLevel4 = (presentCount: number) => {
    let newValue = <div id="hierarchyLevel4" style={{ marginTop: '30px' }}>
      <div className="row mt-2 mb-3">
        <div className="col-6">
          <label className="my-label">Enter Name</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            // @ts-ignore
            value={this.state[`hierarchyLevel4Name${presentCount}`]}
            name={`hierarchyLevel4Name${presentCount}`}
            type="text"
            required
          />
        </div>
        <div className="col-6">
          <label className="my-label">Enter Position</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Position"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            // @ts-ignore
            value={this.state[`hierarchyLevel4Position${presentCount}`]}
            name={`hierarchyLevel4Position${presentCount}`}
            type="text"
            required
          />
        </div>
      </div>
    </div>

    return newValue
  }

  addMoreDesignations = (presentCount: number) => {
    let textString = `designationText${presentCount}`

    let newValue = <div id="currenntMandateExpectations" style={{ marginTop: '30px' }}>
      <div className="col-12 mt-2 mb-3">
        <label className="my-label">Enter Designation</label>
        <TextField
          id="outlined-secondary"
          // label="Enter Designation
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`currentDesignation${this.state?.moreDeisgnations.length}`}
          // @ts-ignore
          value={this.state[`currentDesignation${this.state?.moreDeisgnations.length}`]}
          type="text"
          required
        />
      </div>
      <div className="col-12 mt-2 mb-3">
        <label className="my-label">Directly Reporting to</label>
        <TextField
          id="outlined-secondary"
          // label="Directly Reporting to"
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`currentReportingTo${this.state?.moreDeisgnations.length}`}
          // @ts-ignore
          value={this.state[`currentReportingTo${this.state?.moreDeisgnations.length}`]}
          type="text"
          required
        />
      </div>
      <div className="col-12 mt-2 mb-3">
        <label className="my-label">Enter Location</label>
        <TextField
          id="outlined-secondary"
          // label="Enter Location"
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`currentLocation${this.state?.moreDeisgnations.length}`}
          // @ts-ignore
          value={this.state[`currentLocation${this.state?.moreDeisgnations.length}`]}
          type="text"
          required
        />
      </div>

      <RichTextEditor
        //@ts-ignore
        value={this.state[textString]}
        //@ts-ignore
        onChange={(value) => this.setState({ [textString]: value })}
        placeholder="Enter Expectations (Text Limit : 1300)"
        className="mt-2 mb-3 text-editor"
      />
      {/* @ts-ignore */}
      {(this.state[textString]?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state[textString]?.toString('markdown').replace('\n', '#').length - 1)}/1300</div>}
      {/* <div className="text-editor-image" /> */}

      <div className="col-6">
        <div>
          <label htmlFor="CMEBackgroundColor" className="color-pallete-label">Color Picker</label>
          <input id="CMEBackgroundColor" type="color" className="color-pallete col-6" value={this.state?.CMEBackgroundColor ? this.state?.CMEBackgroundColor : ""} name="CMEBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
        </div>
      </div>

    </div>

    return newValue;
  }

  addInterviewPanelMore = (presentCount: number) => {
    let key = `interviewPanelImage${presentCount}`;
    const interviewRounds = [{ value: 'Exploratory Chat', label: 'Exploratory Chat' },
    { value: 'Technical Discussion', label: 'Technical Discussion' },
    { value: 'Leadership Discussion', label: 'Leadership Discussion' },
    { value: 'Board Meeting', label: 'Board Meeting' }];

    let newValue = <div style={{ marginTop: '30px' }}>
      <br />
      <div className="row mt-2 mb-3">
        <div className="col-6">
          <label className="my-label">Enter Name</label>
          <TextField
            id="outlined-secondary"
            // label="Enter Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name={`interViewPanelName${presentCount}`}
            // @ts-ignore
            value={this.state[`interViewPanelName${presentCount}`]}
            type="text"
            required
          />
        </div>
        <div className="col-6 row">
          <div className="col-6">
            <label className="btn-upload">
              {/* @ts-ignore */}
              <input type='file' accept="image/*" name={`interviewPanelImage${presentCount}`}
                placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
              <button type="button" className="btn">
                <img src={uploadIcon} className="upload-icon" />

                Upload Image</button>
            </label>
            <span className="file-type">File Type: jpg/jpeg/png</span>
          </div>

          {/* @ts-ignore */}
          <div className="col-5 file-label"><i> {this.state[key]?.fileName !== undefined ? this.state[key]?.fileName : "No Image Uploaded"}</i></div>

        </div>
      </div>
      <div className="mt-2 mb-3">
        <label className="my-label">Linkedin Profile Link</label>
        <TextField
          id="outlined-secondary"
          // label="Linkedin Profile Link"
          style={{ marginLeft: '20px', width: '96%' }}
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`interViewlinkdinProfileLink${presentCount}`}
          // @ts-ignore
          value={this.state[`interViewlinkdinProfileLink${presentCount}`]}
          type="text"
          required
        />
        <img src={linkdinIcon} className="linkdin-icon" />

      </div>
      <div className="mt-2 mb-3">
        {/* <TextField
          id="outlined-secondary"
          label="Round Name"
          variant="outlined"
          color="primary"
          onChange={(e) => this.handleChange(e)}
          className="form-control input-field text-field-input"
          name={`interViewRoundName${presentCount}`}
          // @ts-ignore
          value={this.state[`interViewRoundName${presentCount}`]}
          type="text"
          required
        /> */}

        <div className="mt-2 mb-3">
          <div className="col-4">
            {/* <p className="relocation-status">Select Invterview Round</p> */}
            <label className="my-label">Select Interview Round</label>

            <Select
              // @ts-ignore
              options={interviewRounds}
              className=""
              // @ts-ignore
              placeholder={this.state[`interViewRoundName${presentCount}`] || ''}
              onChange={(e) => this.handleChange(e, `interViewRoundName${presentCount}`)}
              // @ts-ignore
              value={this.state[`interViewRoundName${presentCount}`]}
              // @ts-ignore
              value={this.state[`interViewRoundName${presentCount}`]}
              styles={customStyles}
            />
          </div>
        </div>
      </div>

    </div>

    return newValue;
  }

  handleEditPQForm = (presentEditFormId?: any) => {
    //  Scrool to top of page
    // @ts-ignore
    document.getElementById('pq-capsule-top').scrollTo(0, 0);

    this.setState({ editPQCapsuleForm: true });
    let pdFormData = this.state?.previewPQForm;

    this.setState({

      //colors
      visionHeading: pdFormData.vision_heading_name,
      visionHeadingColor: pdFormData.vision_heading_color,
      missionHeading: pdFormData.mission_heading_name,
      missionHeadingColor: pdFormData.mission_heading_color,
      problemStatementHeading: pdFormData.problem_statement_color_heading_name,
      problemStatementHeadingColor: pdFormData.problem_statement_color_heading_color,
      overviewHeading: pdFormData.overview_color_heading_name,
      overviewHeadingColor: pdFormData.overview_color_heading_color,
      businessRoadMapHeading: pdFormData.business_roadmap_color_heading_name,
      businessRoadMapHeadingColor: pdFormData.business_roadmap_color_heading_color,
      techStackHeading: pdFormData.tech_stack_color_heading_name,
      techStackHeadingColor: pdFormData.tech_stack_color_heading_color,
      techLandScapeHeading: pdFormData.tech_landscape_color_heading_name,
      techLandScapeHeadingColor: pdFormData.tech_landscape_color_heading_color,
      techChallengesHeading: pdFormData.tech_challenges_color_heading_name,
      techChallengesHeadingColor: pdFormData.tech_challenges_color_heading_color,
      roadMapHeading: pdFormData.tech_roadmap_heading_name,
      roadMapHeadingColor: pdFormData.tech_roadmap_heading_color,
      techOrgHeading: pdFormData.tech_org_heading_name,
      techOrgHeadingColor: pdFormData.tech_org_heading_color,
      cultureDnaHeading: pdFormData.culture_dna_color_heading_name,
      cultureDnaHeadingColor: pdFormData.culture_dna_color_heading_color,
      confidentialityHeading: pdFormData.confidentiality_color_heading_name,
      confidentialityHeadingColor: pdFormData.confidentiality_color_heading_color,

      //company
      companyName: pdFormData.name,
      companyBackgroundColor: pdFormData.color,
      companyBackgroundImage: pdFormData.tagline_image,
      companyTagline: pdFormData.tagline,
      companyLogoImage: pdFormData.logo,
      presentEditForm: presentEditFormId,

      // snapshot
      snapshotBackgroundColor: pdFormData.snapshot?.data.attributes?.color,
      CompanyFoundedIn: pdFormData.snapshot?.data.attributes?.founded_in,
      CompanyHeaderquarter: pdFormData.snapshot?.data.attributes?.headquarters,
      CompanyFoundingMember: pdFormData.snapshot?.data.attributes?.founding_members,
      totalFunding: pdFormData.snapshot?.data.attributes?.total_funding,
      lastFundingRoundMoney: pdFormData.snapshot?.data.attributes?.last_funding_round,
      lastFundingRoundMonth: pdFormData.snapshot?.data.attributes?.funding_date,
      lastFundingRoundYear: '',
      companyStage: pdFormData.snapshot?.data.attributes?.company_stage,
      companyValuation: pdFormData.snapshot?.data.attributes?.valuation,
      numberOfEmployees: pdFormData.snapshot?.data.attributes?.number_of_employees,
      snapShotHeading: pdFormData.snapshot?.data.attributes?.heading_name,
      snapShotHeadingColor: pdFormData.snapshot?.data.attributes?.heading_color,

      // product
      productBackgroundColor: pdFormData.pq_capsule_product_color,
      productsHeading: pdFormData.pq_capsule_products?.data[0]?.attributes?.heading_name,
      productsHeadingColor: pdFormData.pq_capsule_products?.data[0]?.attributes?.heading_color,
      productBackgroundImage: pdFormData.pq_capsule_products?.data[0]?.attributes?.pq_capsule_product_image,
      productBackgroundImage0: pdFormData.pq_capsule_products?.data[1]?.attributes?.pq_capsule_product_image,
      productBackgroundImage1: pdFormData.pq_capsule_products?.data[2]?.attributes?.pq_capsule_product_image,
      productBackgroundImage2: pdFormData.pq_capsule_products?.data[3]?.attributes?.pq_capsule_product_image,
      productBackgroundImage3: pdFormData.pq_capsule_products?.data[4]?.attributes?.pq_capsule_product_image,
      productName: pdFormData.pq_capsule_products?.data[0]?.attributes?.name,
      productName0: pdFormData.pq_capsule_products?.data[1]?.attributes?.name,
      productName1: pdFormData.pq_capsule_products?.data[2]?.attributes?.name,
      productName2: pdFormData.pq_capsule_products?.data[3]?.attributes?.name,
      productName3: pdFormData.pq_capsule_products?.data[4]?.attributes?.name,
      productText: RichTextEditor.createValueFromString(pdFormData.pq_capsule_products?.data[0]?.attributes?.product_description, 'html') || "",
      productText0: RichTextEditor.createValueFromString(pdFormData.pq_capsule_products?.data[1]?.attributes?.product_description || "", 'html') || "",
      productText1: RichTextEditor.createValueFromString(pdFormData.pq_capsule_products?.data[2]?.attributes?.product_description || "", 'html') || "",
      productText2: RichTextEditor.createValueFromString(pdFormData.pq_capsule_products?.data[3]?.attributes?.product_description || "", 'html') || "",
      productText3: RichTextEditor.createValueFromString(pdFormData.pq_capsule_products?.data[4]?.attributes?.product_description || "", 'html') || "",

      countAddMoreProduct: pdFormData.pq_capsule_products?.data[4]?.attributes?.name ? 3 : pdFormData.pq_capsule_products?.data[3]?.attributes?.name ? 2 : pdFormData.pq_capsule_products?.data[2]?.attributes?.name ? 1 : pdFormData.pq_capsule_products?.data[1]?.attributes?.name ? 0 : -1,

      productEditID0: pdFormData.pq_capsule_products?.data[0]?.id,
      productEditID1: pdFormData.pq_capsule_products?.data[1]?.id,
      productEditID2: pdFormData.pq_capsule_products?.data[2]?.id,
      productEditID3: pdFormData.pq_capsule_products?.data[3]?.id,
      productEditID4: pdFormData.pq_capsule_products?.data[4]?.id,

      //vision
      companyVisionText: RichTextEditor.createValueFromString(pdFormData.vision, 'html') || "",
      visionImage: pdFormData.vision_image,

      // keymatrix
      keyMetricsHeading: pdFormData.key_metrics?.data[0]?.attributes?.heading_name,
      keyMetricsHeadingColor: pdFormData.key_metrics?.data[0]?.attributes?.heading_color,
      keyMetricsBackgroundColor: pdFormData.key_metrics?.data[0]?.attributes?.back_color,
      keyMetricsBackgroundColor0: pdFormData.key_metrics?.data[1]?.attributes?.back_color,
      keyMetricsBackgroundColor1: pdFormData.key_metrics?.data[2]?.attributes?.back_color,
      keyMetricsBackgroundColor2: pdFormData.key_metrics?.data[3]?.attributes?.back_color,
      keyMetricsBackgroundColor3: pdFormData.key_metrics?.data[4]?.attributes?.back_color,
      keyMetricsBackgroundColor4: pdFormData.key_metrics?.data[5]?.attributes?.back_color,
      keyMetricsBackgroundColor5: pdFormData.key_metrics?.data[6]?.attributes?.back_color,
      keyMetricsBackgroundColor6: pdFormData.key_metrics?.data[7]?.attributes?.back_color,
      keyMetricsBackgroundColor7: pdFormData.key_metrics?.data[8]?.attributes?.back_color,
      keyMetricsBackgroundColor8: pdFormData.key_metrics?.data[9]?.attributes?.back_color,
      keyMetricsBackgroundColor9: pdFormData.key_metrics?.data[10]?.attributes?.back_color,
      keyMetricsBackgroundColor10: pdFormData.key_metrics?.data[11]?.attributes?.back_color,

      keyMetricsText: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[0]?.attributes?.key_metrics, 'html') || "",
      keyMatricText0: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[1]?.attributes?.key_metrics, 'html') || "",
      keyMatricText1: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[2]?.attributes?.key_metrics, 'html') || "",
      keyMatricText2: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[3]?.attributes?.key_metrics, 'html') || "",
      keyMatricText3: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[4]?.attributes?.key_metrics, 'html') || "",
      keyMatricText4: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[5]?.attributes?.key_metrics, 'html') || "",
      keyMatricText5: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[6]?.attributes?.key_metrics, 'html') || "",
      keyMatricText6: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[7]?.attributes?.key_metrics, 'html') || "",
      keyMatricText7: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[8]?.attributes?.key_metrics, 'html') || "",
      keyMatricText8: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[9]?.attributes?.key_metrics, 'html') || "",
      keyMatricText9: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[10]?.attributes?.key_metrics, 'html') || "",
      keyMatricText10: RichTextEditor.createValueFromString(pdFormData.key_metrics?.data[11]?.attributes?.key_metrics, 'html') || "",

      keyMetricsBackgroundImage: pdFormData.key_metrics_image,

      keyMatricsEditID0: pdFormData.key_metrics?.data[0]?.id,
      keyMatricsEditID1: pdFormData.key_metrics?.data[1]?.id,
      keyMatricsEditID2: pdFormData.key_metrics?.data[2]?.id,
      keyMatricsEditID3: pdFormData.key_metrics?.data[3]?.id,
      keyMatricsEditID4: pdFormData.key_metrics?.data[4]?.id,
      keyMatricsEditID5: pdFormData.key_metrics?.data[5]?.id,
      keyMatricsEditID6: pdFormData.key_metrics?.data[6]?.id,
      keyMatricsEditID7: pdFormData.key_metrics?.data[7]?.id,
      keyMatricsEditID8: pdFormData.key_metrics?.data[8]?.id,
      keyMatricsEditID9: pdFormData.key_metrics?.data[9]?.id,
      keyMatricsEditID10: pdFormData.key_metrics?.data[10]?.id,
      keyMatricsEditID11: pdFormData.key_metrics?.data[11]?.id,

      countKeyMatrics: pdFormData.key_metrics?.data[11]?.attributes?.key_metrics.length > 1 ? 10 :
        pdFormData.key_metrics?.data[10]?.attributes?.key_metrics.length > 1 ? 9 :
          pdFormData.key_metrics?.data[9]?.attributes?.key_metrics.length > 1 ? 8 :
            pdFormData.key_metrics?.data[8]?.attributes?.key_metrics.length > 1 ? 7 :
              pdFormData.key_metrics?.data[7]?.attributes?.key_metrics.length > 1 ? 6 :
                pdFormData.key_metrics?.data[6]?.attributes?.key_metrics.length > 1 ? 5 :
                  pdFormData.key_metrics?.data[5]?.attributes?.key_metrics.length > 1 ? 4 :
                    pdFormData.key_metrics?.data[4]?.attributes?.key_metrics.length > 1 ? 3 :
                      pdFormData.key_metrics?.data[3]?.attributes?.key_metrics.length > 1 ? 2 :
                        pdFormData.key_metrics?.data[2]?.attributes?.key_metrics.length > 1 ? 1 :
                          pdFormData.key_metrics?.data[1]?.attributes?.key_metrics.length > 1 ? 0 : -1,

      //mission
      companyMissionText: RichTextEditor.createValueFromString(pdFormData.mission, 'html') || "",
      missionImage: pdFormData.mission_image,

      //Problem statement
      problemStatementText: RichTextEditor.createValueFromString(pdFormData.problem_statement, 'html') || "",
      problemStatmentImage: pdFormData.problem_statement_image,
      problemStatementColor: pdFormData.problem_statement_color,

      //overView
      overViewText: RichTextEditor.createValueFromString(pdFormData.overview, 'html') || "",
      overViewImage: pdFormData.overview_image,
      overViewColor: pdFormData.overview_color,

      //bussiness RoadMap
      businessRoadMapText: RichTextEditor.createValueFromString(pdFormData.business_roadmap, 'html') || "",
      bussinessroadBackgroundColor: pdFormData.business_roadmap_color,
      bussinessroadBackgroundImage: pdFormData.business_roadmap_image,

      //Tech land scap
      techLandScapeText: RichTextEditor.createValueFromString(pdFormData.tech_landscape, 'html') || "",
      techLandBackgroundImage: pdFormData.tech_landscape_image,
      techLandBackgroundColor: pdFormData.tech_landscape_color,

      // tech challenges
      techChallangesText: RichTextEditor.createValueFromString(pdFormData.tech_challenges, 'html') || "",
      techChallengeBackgroundColor: pdFormData.tech_challenges_color,
      techChallengeBackgroundImage: pdFormData.tech_challenge_image,

      //tech org
      techOrgText: RichTextEditor.createValueFromString(pdFormData.tech_org, 'html') || "",
      techOrgBackgroundColor: pdFormData.tech_org_color,

      //tech roadmap
      techRoadMapText: RichTextEditor.createValueFromString(pdFormData.tech_roadmap, 'html'),
      techRoadMapBackgroundImage: pdFormData.tech_roadmap_image,
      techRoadMapBackgroundColor: pdFormData.tech_roadmap_color,

      //culture
      cultureDnaText: RichTextEditor.createValueFromString(pdFormData.culture_dna, 'html'),
      cultureBackgroundImage: pdFormData.culture_dna_image,
      cultureBackgroundColor: pdFormData.culture_dna_color,

      techBackgroundImage: "",
      techBackgroundColor: pdFormData.tech_stack_color,

      //confidentiality
      confindentialBackgroundColor: pdFormData.confidentiality_color,
      confindentialText: pdFormData.confidentiality,

      //Funding
      fundingHeading: pdFormData.fundings.data[0]?.attributes?.heading_name,
      fundingHeadingColor: pdFormData.fundings.data[0]?.attributes?.heading_color,
      fundingBackgroundColor: pdFormData.funding_color,
      fundingAmount: pdFormData.fundings.data[0]?.attributes?.funding_amount,
      fundingAmount0: pdFormData.fundings.data[1]?.attributes?.funding_amount,
      fundingAmount1: pdFormData.fundings.data[2]?.attributes?.funding_amount,
      fundingAmount2: pdFormData.fundings.data[3]?.attributes?.funding_amount,
      fundingAmount3: pdFormData.fundings.data[4]?.attributes?.funding_amount,

      fundingRoundSeries: pdFormData.fundings.data[0]?.attributes?.funding_round,
      fundingRoundSeries0: pdFormData.fundings.data[1]?.attributes?.funding_round,
      fundingRoundSeries1: pdFormData.fundings.data[2]?.attributes?.funding_round,
      fundingRoundSeries2: pdFormData.fundings.data[3]?.attributes?.funding_round,
      fundingRoundSeries3: pdFormData.fundings.data[4]?.attributes?.funding_round,

      fundingInvestor: pdFormData.fundings.data[0]?.attributes?.investor,
      fundingInvestor0: pdFormData.fundings.data[1]?.attributes?.investor,
      fundingInvestor1: pdFormData.fundings.data[2]?.attributes?.investor,
      fundingInvestor2: pdFormData.fundings.data[3]?.attributes?.investor,
      fundingInvestor3: pdFormData.fundings.data[4]?.attributes?.investor,

      fundingRoundMonth: pdFormData.fundings.data[0]?.attributes?.funding_date,
      fundingRoundMonth0: pdFormData.fundings.data[1]?.attributes?.funding_date,
      fundingRoundMonth1: pdFormData.fundings.data[2]?.attributes?.funding_date,
      fundingRoundMonth2: pdFormData.fundings.data[3]?.attributes?.funding_date,
      fundingRoundMonth3: pdFormData.fundings.data[4]?.attributes?.funding_date,
      currency_id: pdFormData.fundings.data[0]?.attributes?.currency_id,
      currency_id0: pdFormData.fundings.data[1]?.attributes?.currency_id,
      currency_id1: pdFormData.fundings.data[2]?.attributes?.currency_id,
      currency_id2: pdFormData.fundings.data[3]?.attributes?.currency_id,
      currency_id3: pdFormData.fundings.data[4]?.attributes?.currency_id,

      fundingRoundSeriesCount: pdFormData.fundings.data[4]?.attributes?.funding_amount ? 3 : pdFormData.fundings.data[3]?.attributes?.funding_amount ? 2 : pdFormData.fundings.data[2]?.attributes?.funding_amount ? 1 : pdFormData.fundings.data[1]?.attributes?.funding_amount ? 0 : -1,

      fundingEditID0: pdFormData.fundings.data[0]?.id,
      fundingEditID1: pdFormData.fundings.data[1]?.id,
      fundingEditID2: pdFormData.fundings.data[2]?.id,
      fundingEditID3: pdFormData.fundings.data[3]?.id,
      fundingEditID4: pdFormData.fundings.data[4]?.id,



      // founder
      founderBackgroundColor: pdFormData.founder_color,
      foundersHeading: pdFormData.founders.data[0]?.attributes?.heading_name,
      foundersHeadingColor: pdFormData.founders.data[0]?.attributes?.heading_color,
      foundersDesignation: pdFormData.founders.data[0]?.attributes?.designation,
      foundersDesignation0: pdFormData.founders.data[1]?.attributes?.designation,
      foundersDesignation1: pdFormData.founders.data[2]?.attributes?.designation,
      foundersDesignation2: pdFormData.founders.data[3]?.attributes?.designation,
      foundersDesignation3: pdFormData.founders.data[4]?.attributes?.designation,
      founderPeopleImage: pdFormData.founders.data[0]?.attributes?.founder_image || "",
      founderPeopleImage0: pdFormData.founders.data[1]?.attributes?.founder_image || "",
      founderPeopleImage1: pdFormData.founders.data[2]?.attributes?.founder_image || "",
      founderPeopleImage2: pdFormData.founders.data[3]?.attributes?.founder_image || "",
      founderPeopleImage3: pdFormData.founders.data[4]?.attributes?.founder_image || "",
      foundersbriefBackground: pdFormData.founders.data[0]?.attributes?.background || "",
      briefBackground0: pdFormData.founders.data[1]?.attributes?.background || "",
      briefBackground1: pdFormData.founders.data[2]?.attributes?.background || "",
      briefBackground2: pdFormData.founders.data[3]?.attributes?.background || "",
      briefBackground3: pdFormData.founders.data[4]?.attributes?.background || "",
      founderslinkdinProfileLink: pdFormData.founders.data[0]?.attributes?.linkedin_profile_link || "",
      linkdinProfileLink0: pdFormData.founders.data[1]?.attributes?.linkedin_profile_link || "",
      linkdinProfileLink1: pdFormData.founders.data[2]?.attributes?.linkedin_profile_link || "",
      linkdinProfileLink2: pdFormData.founders.data[3]?.attributes?.linkedin_profile_link || "",
      linkdinProfileLink3: pdFormData.founders.data[4]?.attributes?.linkedin_profile_link || "",
      foundersName: pdFormData.founders.data[0]?.attributes?.name || "",
      foundersName0: pdFormData.founders.data[1]?.attributes?.name || "",
      foundersName1: pdFormData.founders.data[2]?.attributes?.name || "",
      foundersName2: pdFormData.founders.data[3]?.attributes?.name || "",
      foundersName3: pdFormData.founders.data[4]?.attributes?.name || "",

      countAddMoreFoundersRound: pdFormData.founders.data[4]?.attributes?.name ? 3 : pdFormData.founders.data[3]?.attributes?.name ? 2 : pdFormData.founders.data[2]?.attributes?.name ? 1 : pdFormData.founders.data[1]?.attributes?.name ? 0 : -1,

      foundingEditID0: pdFormData.founders.data[0]?.id,
      foundingEditID1: pdFormData.founders.data[1]?.id,
      foundingEditID2: pdFormData.founders.data[2]?.id,
      foundingEditID3: pdFormData.founders.data[3]?.id,
      foundingEditID4: pdFormData.founders.data[4]?.id,

      //Hierarchy level
      hierarchyLevelName: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[0]?.attributes?.name,
      hierarchyLevelName0: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[1]?.attributes?.name,
      hierarchyLevelName1: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[2]?.attributes?.name,
      hierarchyLevelName2: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[3]?.attributes?.name,
      hierarchyLevelName3: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[4]?.attributes?.name,
      hierarchyLevelPosition: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[0]?.attributes?.position,
      hierarchyLevelPosition0: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[1]?.attributes?.position,
      hierarchyLevelPosition1: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[2]?.attributes?.position,
      hierarchyLevelPosition2: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[3]?.attributes?.position,
      hierarchyLevelPosition3: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[4]?.attributes?.position,

      HierarchyLevel1Count: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[4]?.attributes?.name ? 3 : pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[3]?.attributes?.name ? 2 : pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[2]?.attributes?.name ? 1 : pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[1]?.attributes?.name ? 0 : -1,

      hierarchyLevel2Name: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[0]?.attributes?.name,
      hierarchyLevel2Name0: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[1]?.attributes?.name,
      hierarchyLevel2Name1: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[2]?.attributes?.name,
      hierarchyLevel2Name2: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[3]?.attributes?.name,
      hierarchyLevel2Name3: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[4]?.attributes?.name,
      hierarchyLevel2Position: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[0]?.attributes?.position,
      hierarchyLevel2Position0: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[1]?.attributes?.position,
      hierarchyLevel2Position1: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[2]?.attributes?.position,
      hierarchyLevel2Position2: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[3]?.attributes?.position,
      hierarchyLevel2Position3: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[4]?.attributes?.position,

      HierarchyLevel2Count: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[4]?.attributes?.name ? 3 : pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[3]?.attributes?.name ? 2 : pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[2]?.attributes?.name ? 1 : pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[1]?.attributes?.name ? 0 : -1,

      hierarchyLevel3Name: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[0]?.attributes?.name,
      hierarchyLevel3Name0: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[1]?.attributes?.name,
      hierarchyLevel3Name1: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[2]?.attributes?.name,
      hierarchyLevel3Name2: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[3]?.attributes?.name,
      hierarchyLevel3Name3: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[4]?.attributes?.name,
      hierarchyLevel3Position: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[0]?.attributes?.position,
      hierarchyLevel3Position0: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[1]?.attributes?.position,
      hierarchyLevel3Position1: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[2]?.attributes?.position,
      hierarchyLevel3Position2: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[3]?.attributes?.position,
      hierarchyLevel3Position3: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[4]?.attributes?.position,

      HierarchyLevel3Count: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[4]?.attributes?.name ? 3 : pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[3]?.attributes?.name ? 2 : pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[2]?.attributes?.name ? 1 : pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[1]?.attributes?.name ? 0 : -1,

      hierarchyLevel4Name: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[0]?.attributes?.name,
      hierarchyLevel4Name0: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[1]?.attributes?.name,
      hierarchyLevel4Name1: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[2]?.attributes?.name,
      hierarchyLevel4Name2: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[3]?.attributes?.name,
      hierarchyLevel4Name3: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[4]?.attributes?.name,
      hierarchyLevel4Position: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[0]?.attributes?.position,
      hierarchyLevel4Position0: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[1]?.attributes?.position,
      hierarchyLevel4Position1: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[2]?.attributes?.position,
      hierarchyLevel4Position2: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[3]?.attributes?.position,
      hierarchyLevel4Position3: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[4]?.attributes?.position,

      HierarchyLevel4Count: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[4]?.attributes?.name ? 3 : pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[3]?.attributes?.name ? 2 : pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[2]?.attributes?.name ? 1 : pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[1]?.attributes?.name ? 0 : -1,

      hierarcyLevel1EditID0: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[0]?.id,
      hierarcyLevel1EditID1: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[1]?.id,
      hierarcyLevel1EditID2: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[2]?.id,
      hierarcyLevel1EditID3: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[3]?.id,
      hierarcyLevel1EditID4: pdFormData.hierarchies.data[0]?.attributes?.hierarchy_members.data[4]?.id,

      hierarcyLevelsID1: pdFormData.hierarchies.data[0]?.id,
      hierarcyLevelsID2: pdFormData.hierarchies.data[1]?.id,
      hierarcyLevelsID3: pdFormData.hierarchies.data[2]?.id,
      hierarcyLevelsID4: pdFormData.hierarchies.data[3]?.id,
      hierarcyLevelsID5: pdFormData.hierarchies.data[4]?.id,

      hierarcyLevel2EditID0: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[0]?.id,
      hierarcyLevel2EditID1: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[1]?.id,
      hierarcyLevel2EditID2: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[2]?.id,
      hierarcyLevel2EditID3: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[3]?.id,
      hierarcyLevel2EditID4: pdFormData.hierarchies.data[1]?.attributes?.hierarchy_members.data[4]?.id,

      hierarcyLevel3EditID0: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[0]?.id,
      hierarcyLevel3EditID1: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[1]?.id,
      hierarcyLevel3EditID2: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[2]?.id,
      hierarcyLevel3EditID3: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[3]?.id,
      hierarcyLevel3EditID4: pdFormData.hierarchies.data[2]?.attributes?.hierarchy_members.data[4]?.id,

      hierarcyLevel4EditID0: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[0]?.id,
      hierarcyLevel4EditID1: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[1]?.id,
      hierarcyLevel4EditID2: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[2]?.id,
      hierarcyLevel4EditID3: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[3]?.id,
      hierarcyLevel4EditID4: pdFormData.hierarchies.data[3]?.attributes?.hierarchy_members.data[4]?.id,

      //CME
      currentMandateHeading: pdFormData.current_mandate_expectations.data[0]?.attributes?.heading_name,
      currentMandateHeadingColor: pdFormData.current_mandate_expectations.data[0]?.attributes?.heading_color,
      CMEText: RichTextEditor.createValueFromString(pdFormData.current_mandate_expectations.data[0]?.attributes?.expectation || "", 'html') || "",
      // designationText0: RichTextEditor.createValueFromString(pdFormData.current_mandate_expectations.data[1]?.attributes?.expectation, 'html') || "",
      // designationText1: RichTextEditor.createValueFromString(pdFormData.current_mandate_expectations.data[2]?.attributes?.expectation || "", 'html') || "",
      // designationText2: RichTextEditor.createValueFromString(pdFormData.current_mandate_expectations.data[3]?.attributes?.expectation|| "", 'html') || "",
      // designationText3: RichTextEditor.createValueFromString(pdFormData.current_mandate_expectations.data[4]?.attributes?.expectation || "", 'html') || "",
      currentDesignation: pdFormData.current_mandate_expectations.data[0]?.attributes?.designation,
      // currentDesignation0: pdFormData.current_mandate_expectations.data[1]?.attributes?.designation,
      // currentDesignation1: pdFormData.current_mandate_expectations.data[2]?.attributes?.designation,
      // currentDesignation2: pdFormData.current_mandate_expectations.data[3]?.attributes?.designation,
      // currentDesignation3: pdFormData.current_mandate_expectations.data[4]?.attributes?.designation,
      currentReportingTo: pdFormData.current_mandate_expectations.data[0]?.attributes?.reporting_to,
      // currentReportingTo0: pdFormData.current_mandate_expectations.data[1]?.attributes?.reporting_to,
      // currentReportingTo1: pdFormData.current_mandate_expectations.data[2]?.attributes?.reporting_to,
      // currentReportingTo2: pdFormData.current_mandate_expectations.data[3]?.attributes?.reporting_to,
      // currentReportingTo3: pdFormData.current_mandate_expectations.data[4]?.attributes?.reporting_to,
      currentLocation: pdFormData.current_mandate_expectations.data[0]?.attributes?.location,
      // currentLocation0: pdFormData.current_mandate_expectations.data[1]?.attributes?.location,
      // currentLocation1: pdFormData.current_mandate_expectations.data[2]?.attributes?.location,
      // currentLocation2: pdFormData.current_mandate_expectations.data[3]?.attributes?.location,
      // currentLocation3: pdFormData.current_mandate_expectations.data[4]?.attributes?.location,
      CMEBackgroundColor: pdFormData.current_mandate_expectations.data[0]?.attributes?.color,
      CME_ID: pdFormData.current_mandate_expectations.data[0]?.id,
      // cmeCountPanel: pdFormData.current_mandate_expectations.data[4]?.attributes?.expectation ? 3 : pdFormData.current_mandate_expectations.data[3]?.attributes?.expectation ? 2 : pdFormData.current_mandate_expectations.data[2]?.attributes?.expectation ?  1 : pdFormData.current_mandate_expectations.data[1]?.attributes?.expectation ? 0 : -1,

      //Vector
      vectorElement: pdFormData.vector_element,
      vectorBackgroundColor: pdFormData.confidentiality_vector_color,
      //interview panel image
      interviewBackgroundColor: pdFormData.interview_panel_color,
      interviewPanelHeading: pdFormData.interview_panels.data[0]?.attributes?.heading_name,
      interviewPanelHeadingColor: pdFormData.interview_panels.data[0]?.attributes?.heading_color,
      interviewPanelImage: pdFormData.interview_panels.data[0]?.attributes?.interview_panel_image,
      interviewPanelImage0: pdFormData.interview_panels.data[1]?.attributes?.interview_panel_image,
      interviewPanelImage1: pdFormData.interview_panels.data[2]?.attributes?.interview_panel_image,
      interviewPanelImage2: pdFormData.interview_panels.data[3]?.attributes?.interview_panel_image,
      interviewPanelImage3: pdFormData.interview_panels.data[4]?.attributes?.interview_panel_image,

      interViewRoundName: pdFormData.interview_panels.data[0]?.attributes?.round_name,
      interViewRoundName0: pdFormData.interview_panels.data[1]?.attributes?.round_name,
      interViewRoundName1: pdFormData.interview_panels.data[2]?.attributes?.round_name,
      interViewRoundName2: pdFormData.interview_panels.data[3]?.attributes?.round_name,
      interViewRoundName3: pdFormData.interview_panels.data[4]?.attributes?.round_name,

      interViewPanelName: pdFormData.interview_panels.data[0]?.attributes?.name,
      interViewPanelName0: pdFormData.interview_panels.data[1]?.attributes?.name,
      interViewPanelName1: pdFormData.interview_panels.data[2]?.attributes?.name,
      interViewPanelName2: pdFormData.interview_panels.data[3]?.attributes?.name,
      interViewPanelName3: pdFormData.interview_panels.data[4]?.attributes?.name,

      interViewlinkdinProfileLink0: pdFormData.interview_panels.data[1]?.attributes?.linkedin_profile_link,
      interViewlinkdinProfileLink1: pdFormData.interview_panels.data[2]?.attributes?.linkedin_profile_link,
      interViewlinkdinProfileLink2: pdFormData.interview_panels.data[3]?.attributes?.linkedin_profile_link,
      interViewlinkdinProfileLink3: pdFormData.interview_panels.data[4]?.attributes?.linkedin_profile_link,
      interViewlinkdinProfileLink: pdFormData.interview_panels.data[0]?.attributes?.linkedin_profile_link,

      countInterViewPanel: pdFormData.interview_panels.data[4]?.attributes?.name ? 3 : pdFormData.interview_panels.data[3]?.attributes?.name ? 2 : pdFormData.interview_panels.data[2]?.attributes?.name ? 1 : pdFormData.interview_panels.data[1]?.attributes?.name ? 0 : -1,

      interviewPanelEditID0: pdFormData.interview_panels.data[0]?.id,
      interviewPanelEditID1: pdFormData.interview_panels.data[1]?.id,
      interviewPanelEditID2: pdFormData.interview_panels.data[2]?.id,
      interviewPanelEditID3: pdFormData.interview_panels.data[3]?.id,
      interviewPanelEditID4: pdFormData.interview_panels.data[4]?.id,
    })
    this.getCurrency()
  }

  openDropdownModal = (profileId: number) => {
    this.setState({
      isDropDownOpenId: this.state?.isDropDownOpenId === profileId ? null : profileId
    })
  }

  openDropDownModalUI = () => {
    return <ul className="ellipsis-dropdown-ul" id="drop-downDashboard">
      <li className="ellipsis-dropdown-li" style={{ borderBottom: '1px solid lightgray' }} onClick={() => this.setState({ isPreviewCompanyDetails: true })}>Preview/Download</li>
      {/* @ts-ignore */}
      {!!this.props.companySection ? " " : <li className="ellipsis-dropdown-li" style={{ borderBottom: '1px solid lightgray' }} onClick={() => this.handleEditPQForm(this.state?.isDropDownOpenId)}>Edit</li>}
      {/* <li className="ellipsis-dropdown-li">Download</li> */}
      {/* <li className="ellipsis-dropdown-li">Upload</li> */}
      {/* this.deletePQCapsule(this.state?.isDropDownOpenId) */}
      <li className="ellipsis-dropdown-li" style={{ borderBottom: '1px solid lightgray' }} onClick={() => this.deletePQCapsule(this.state?.isDropDownOpenId)}>Delete</li>

      <li className="ellipsis-dropdown-li" style={{ borderBottom: '1px solid lightgray' }} onClick={() => this.ApprovePQCapsule(this.state?.isDropDownOpenId)}>Mark As Approve</li>
    </ul>
  }

  confirmModal = () => {
    return <Modal
      onRequestClose={() => this.setState({ confirmModal: false })}
      isOpen={this.state.confirmModal}
      contentLabel=""
      className="logout-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row ">
            <div className="mt-1 mb-3" style={{ textAlign: 'center' }}>
              <label className="task-label">Are you sure want to Delete?</label>
            </div>
            <div className="col-6" style={{ textAlign: 'right' }}>
              <button type="button" onClick={() => {
                this.deletePQCapsule(this.state.deleteId)
                this.setState({ confirmModal: false })
              }} style={{ width: '-webkit-fill-available' }} className="btn auth-button">Yes</button>
            </div>
            <div className="col-6">
              <button type="button" className="btn auth-button" style={{ width: '-webkit-fill-available' }} onClick={() => { this.setState({ confirmModal: false }) }}>No</button>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  }


  render() {
    const { navigation } = this.props;
    const assets = require("./assets");
    //@ts-ignore
    const { thisYear, selectedYear } = this.state;
    const options = [];
    //@ts-ignore
    for (let i = 0; i <= 60; i++) {
      const year = thisYear - i;
      options.push({ value: year, label: year })
      // options.push( {value={year}{year}});
    }
    const interviewRounds = [{ value: 'Exploratory Chat', label: 'Exploratory Chat' },
    { value: 'Technical Discussion', label: 'Technical Discussion' },
    { value: 'Leadership Discussion', label: 'Leadership Discussion' },
    { value: 'Board Meeting', label: 'Board Meeting' }];

    //Customizable Area Start
    return (
      <div className="wrapper">
        {/* @ts-ignore */}
        {!!this.props.companySection ? "" : <SideNav data={this.state?.currentUser} />}
        <div className="kanban-content companies-content" >
          {/* @ts-ignore */}
          {!!this.props.companySection ? "" : <TopNav data={this.state?.currentUser} />}
          {this.showSaveModal()}
          {this.genrateCompanyPdf()}
          {this.confirmModal()}
          <div className="mb-4 mt-4" style={{ overflow: 'scroll', height: '700px' }} id="pq-capsule-top">
            {/* @ts-ignore */}
            {!!this.props.companySection ? "" : <form onSubmit={(e) => { e.preventDefault(); this.setState({ isSavePQCapsule: true }) }} >
              <div className="view-rectangle-team-builder">
                <div className="view-rectangle-team">
                  <p className="heading-head">Add New Client</p>
                  <br />
                  <div className="row col-12">
                    <label className={false ? "col-4 main-menu-label-active" : "col-4 main-menu-label"} ><Link to={'/newclient?id=1'}>Agreement</Link></label>
                    <label className={false ? "col-4 main-menu-label-active" : "col-4 main-menu-label"} style={{ textAlign: 'center' }}><Link to={'/newclient?id=2'}>Invoicing</Link></label>
                    <label className={true ? "col-4 main-menu-label-active" : "col-4 main-menu-label"} onClick={() => { }} style={{ textAlign: 'right' }}><Link to={'/pqCapsule'}>Purple Quarter Capsule</Link></label>
                  </div>
                </div>

                <br />
                <div className="pq-capsule-section-page">
                  {/* Cover page */}
                  <div >
                    <hr className="pq-capsule-hr" />
                    <h1 className="compress-section" id="coverPage" onClick={() => {
                      this.changeSymbol('coverPage', 'coverPageSectionForm')
                      this.handleSectionHide('coverPageSectionForm')
                    }}>—</h1>
                    <p className="heading-subhead">COVER PAGE</p>
                    <div className="cover-page" id="coverPageSectionForm">
                      <div className="mt-1 mb-3">
                        <p className="relocation-status">Company Name & Logo</p>
                        <label className="my-label">Select Name</label>
                        {this.state.editPQCapsuleForm
                          && <TextField
                            id="outlined-secondary"
                            // label="Enter Name"
                            variant="outlined"
                            color="primary"
                            // onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="companyName" type="text"
                            value={this.state?.companyName}
                            required
                          />}
                        {!this.state.editPQCapsuleForm && <Select styles={customStyles}
                          options={this.state.companiesData}
                          placeholder={this.state?.companyName.length > 0 ? this.state?.companyName : ""}
                          onChange={(selectedOption) => this.handleSelect(selectedOption, 'companyName')}
                        />}
                        <div className="row" style={{ marginTop: '20px' }}>
                          <p className="relocation-status">Logo</p>
                          <div className="col-3">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="companyLogoImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />
                                Upload Image
                              </button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label" style={{ marginLeft: '0%' }}><i> {this.state?.companyLogoImage?.fileName !== undefined ? this.state?.companyLogoImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>

                        {/* <p className="relocation-status" style={{ marginTop: '20px' }}>Tagline</p> */}
                        <label className="my-label">Tagline</label>
                        <TextField
                          id="outlined-secondary"
                          // label="Enter Tagline"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => this.handleChange(e)}
                          className="form-control input-field text-field-input"
                          name="companyTagline"
                          type="text"
                          value={this.state?.companyTagline}
                          required
                        />
                      </div>
                      <div className="row">

                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="companyBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label" style={{ marginLeft: '0%' }}><i> {this.state?.companyBackgroundImage?.fileName !== undefined ? this.state?.companyBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>

                        <div className="col-4 row">
                          <div>
                            <label htmlFor="companyBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="companyBackgroundColor" type="color" className="color-pallete col-6" value={this.state.companyBackgroundColor ? this.state.companyBackgroundColor : ""} name="companyBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Snapshot Form */}
                  <div>
                    <h1 className="compress-section" id="snapShotPage" onClick={() => {
                      this.changeSymbol('snapShotPage', 'snapshotSectionForm')
                      this.handleSectionHide('snapshotSectionForm')
                    }}>—</h1>
                    <p className="heading-subhead">SNAPSHOT</p>
                    <div className="row" id="snapshotSectionForm">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Snapshot Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="snapShotHeading"
                            type="text"
                            value={this.state?.snapShotHeading}
                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="snapShotHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="snapShotHeadingColor" type="color" className="color-pallete col-6" name="snapShotHeadingColor" value={this.state?.snapShotHeadingColor ? this.state?.snapShotHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="relocation-status">Founded In</p>
                          <label className="my-label">Select Year</label>
                          <Select
                            options={options}
                            className=""
                            placeholder={this.state?.CompanyFoundedIn || ''}
                            onChange={(e) => this.handleChange(e, 'CompanyFoundedIn')}
                            name="CompanyFoundedIn"
                            value={this.state?.CompanyFoundedIn}
                            styles={customStyles}
                          />

                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <p className="relocation-status">Headquarters</p>
                          <label className="my-label">Enter City Name</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="CompanyHeaderquarter"
                            type="text"
                            value={this.state?.CompanyHeaderquarter}
                            required
                          />
                        </div>
                      </div>

                      <div>
                        <div className="col-12" style={{ marginTop: '20px' }}>
                          <p className="relocation-status">Founding Members</p>
                          <label className="my-label">Enter Names</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter Names"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="CompanyFoundingMember"
                            value={this.state?.CompanyFoundingMember}
                            type="text"
                            required
                          />
                        </div>

                        <div className="row" style={{ marginTop: '20px' }}>
                          <div className="col-4">
                            <p className="relocation-status">Total Funding </p>
                            <label className="my-label">$</label>
                            <TextField
                              id="outlined-secondary"
                              // label="$"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="totalFunding"
                              value={this.state?.totalFunding}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-6">
                            <p className="relocation-status">Last Funding Round</p>
                            <div className="row">
                              <div className="col-4">
                                <label className="my-label">$</label>
                                <TextField
                                  id="outlined-secondary"
                                  // label="$"
                                  variant="outlined"
                                  color="primary"
                                  onChange={(e) => this.handleChange(e)}
                                  className="form-control input-field text-field-input"
                                  name="lastFundingRoundMoney"
                                  value={this.state?.lastFundingRoundMoney}
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="col-8">
                                <label className="my-label">Last Funding Round Date</label>
                                <TextField
                                  type="text"
                                  style={{ fontSize: '14px', height: '44px' }}
                                  onChange={(e => this.handleChange(e))}
                                  InputProps={{ inputProps: { max: '9999-12-31' } }}
                                  //@ts-ignore
                                  onFocus={(e) => e.target.type = 'date'}
                                  //@ts-ignore
                                  onBlur={(e) => e.target.type = 'text'}
                                  className="form-control input-field text-field-input"
                                  value={this.state?.lastFundingRoundMonth ? this.state?.lastFundingRoundMonth : ""}
                                  name="lastFundingRoundMonth"
                                  id="outlined-secondary"
                                  // label="Last Funding Round Date"
                                  variant="outlined"
                                  color="primary"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: '20px' }}>
                          <div className="col-4">
                            <p className="relocation-status">Company Stage</p>
                            <label className="my-label">Select Stage</label>
                            <Select
                              options={[{ value: 'Series A', label: 'Series A' }, { value: 'Series B', label: 'Series B' }, { value: 'Series C', label: 'Series C' },
                              { value: 'Series D', label: 'Series D' },
                              { value: 'Series IPO', label: 'Series IPO' },

                              ]}
                              className=""
                              placeholder={this.state.companyStage || ''}
                              onChange={(e) => this.handleChange(e, 'companyStage')}
                              name="companyStage"
                              styles={customStyles}
                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <p className="relocation-status">Valuation</p>
                            <label className="my-label">$</label>
                            <TextField
                              id="outlined-secondary"
                              // label="$"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state?.companyValuation}
                              className="form-control input-field text-field-input"
                              name="companyValuation"
                              type="text"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12" style={{ marginTop: '20px' }}>
                          <p className="relocation-status">Number of Employees</p>
                          <label className="my-label">Enter Number</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter Number"
                            variant="outlined"
                            color="primary"
                            value={this.state?.numberOfEmployees}
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input mb-3"
                            name="numberOfEmployees"
                            type="number"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <div>
                            <label htmlFor="snapshotBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="snapshotBackgroundColor" type="color" className="color-pallete col-6" name="snapshotBackgroundColor" value={this.state?.snapshotBackgroundColor ? this.state?.snapshotBackgroundColor : ""} onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/*Funding Round  */}
                  <div>
                    <h1 className="compress-section" id="fundingPage" onClick={() => {
                      this.changeSymbol('fundingPage', 'fundingRoundSectionForm')
                      this.handleSectionHide('fundingRoundSectionForm')
                    }
                    }>—</h1>
                    <p className="heading-subhead">FUNDING</p>
                    <div >

                      <div className="" style={{ marginTop: '20px' }} id="fundingRoundSectionForm">
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="my-label">Rename Funding Heading</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter City Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="fundingHeading"
                              type="text"
                              value={this.state?.fundingHeading}
                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <div style={{ marginTop: '40px' }}>
                              <label htmlFor="fundingHeadingColor" className="color-pallete-label">Add Heading Color</label>
                              <input id="fundingHeadingColor" type="color" className="color-pallete col-6" name="fundingHeadingColor" value={this.state?.fundingHeadingColor ? this.state?.fundingHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4" >
                            {/* <p className="relocation-status">Funding Date</p> */}
                            <label className="my-label">Funding Date</label>
                            <input type="text"
                              style={{ fontSize: '14px', height: '44px', margin: '4px' }}
                              onChange={(e => this.handleChange(e))}
                              onFocus={(e) => e.target.type = 'date'}
                              max="9999-12-31"
                              className="form-control input-field"
                              value={this.state?.fundingRoundMonth ? this.state?.fundingRoundMonth : ""}
                              // placeholder="Funding Round Date"
                              name="fundingRoundMonth"
                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-6">
                            {/* <p className="relocation-status">Funding Amount</p> */}
                            <label className="my-label">Funding Amount</label>
                            <div className="row">
                              <div className="col-9">
                                <TextField
                                  id="outlined-secondary"
                                  // label="Funding Amount"
                                  variant="outlined"
                                  color="primary"
                                  onChange={(e) => this.handleChange(e)}
                                  className="form-control input-field text-field-input"
                                  name="fundingAmount"
                                  value={this.state?.fundingAmount}
                                  type="text"
                                  required
                                  style={{ width: '136%' }}
                                />
                              </div>
                              <div className="col-4 funding-currency-pq">
                                <Select
                                  options={this.state.currencyOptions}
                                  placeholder={this.state.currencyOptions?.filter((ele: any) => ele.value === this.state.currency_id)[0]?.label || "$"}
                                  onChange={(e) => this.handleChange(e, 'currency_id')}
                                  name="currency_id"
                                  styles={customStyle1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                          <div className="col-4">
                            <p className="relocation-status">Funding Round</p>
                            <label className="my-label">Select Series</label>
                            <Select
                              options={[{ value: 'Seed Funding', label: 'Seed Funding' }, { value: 'Series A', label: 'Series A' }, { value: 'Series B', label: 'Series B' }, { value: 'Series C', label: 'Series C' }]}
                              className=""
                              placeholder={this.state?.fundingRoundSeries || ''}
                              onChange={(e) => this.handleChange(e, 'fundingRoundSeries')}
                              name="fundingRoundSeries"
                              styles={customStyles}

                            />
                          </div>
                          <div className="col-2" />

                          <div className="col-6">
                            <p className="relocation-status">Investors</p>
                            <label className="my-label">Enter Names separated by comma</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Names separated by comma"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input mb-3"
                              name="fundingInvestor"
                              value={this.state?.fundingInvestor}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {this.state?.fundingRoundSeriesCount >= 0 && this.addFundingRounds(0)}
                        {this.state?.fundingRoundSeriesCount >= 1 && this.addFundingRounds(1)}
                        {this.state?.fundingRoundSeriesCount >= 2 && this.addFundingRounds(2)}
                        {this.state?.fundingRoundSeriesCount >= 3 && this.addFundingRounds(3)}

                        <div className="row">
                          <div className="col-6" style={{ marginTop: '5%' }}>
                            <p
                              style={{ color: 'black', fontWeight: 'bold', cursor: 'pointer' }}
                              onClick={() => this.state?.fundingRoundSeriesCount < 4 && this.setState({ fundingRoundSeriesCount: this.state?.fundingRoundSeriesCount + 1 })}>
                              + Add More Funding Rounds
                            </p>
                          </div>

                          <div className="col-6" style={{ marginTop: '5%' }}>
                            <div>
                              <label htmlFor="fundingBackgroundColor" className="color-pallete-label">Color Picker</label>
                              <input id="fundingBackgroundColor" type="color" className="color-pallete col-6" name="fundingBackgroundColor" value={this.state?.fundingBackgroundColor ? this.state.fundingBackgroundColor : ""} onChange={(e) => this.handleChange(e)} required /><br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Founders Round  */}
                  <div>
                    <h1 className="compress-section" id="founderspage" onClick={() => {
                      this.changeSymbol('founderspage', 'founderRoundSectionForm')
                      this.handleSectionHide('founderRoundSectionForm')
                    }}>—</h1>

                    <p className="heading-subhead">FOUNDERS</p>
                    <div >
                      <div className="" style={{ marginTop: '20px' }} id="founderRoundSectionForm">
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="my-label">Rename Founders Heading</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter City Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="foundersHeading"
                              type="text"
                              value={this.state?.foundersHeading}

                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <div style={{ marginTop: '40px' }}>
                              <label htmlFor="foundersHeadingColor" className="color-pallete-label">Add Heading Color</label>
                              <input id="foundersHeadingColor" type="color" className="color-pallete col-6" name="foundersHeadingColor" value={this.state?.foundersHeadingColor ? this.state?.foundersHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2 mb-3">
                          {/* <p className="relocation-status">Name</p> */}
                          <div className="col-6">
                            <label className="my-label">Enter Name</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="foundersName"
                              value={this.state?.foundersName}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-6">
                            <label className="my-label">Enter Designation</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Designation"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="foundersDesignation"
                              value={this.state?.foundersDesignation}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mt-2 mb-3">
                          <div className="col-8 row">
                            <div className="col-5">
                              <label className="btn-upload">
                                <input type='file' accept="image/*" name="founderPeopleImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                                <button type="button" className="btn">
                                  <img src={uploadIcon} className="upload-icon" />
                                  Upload Image</button>
                              </label>
                              <br />
                              <span className="file-type">File Type: jpg/jpeg/png</span>
                            </div>
                            <div className="col-5 file-label"><i> {this.state?.founderPeopleImage?.fileName !== undefined ? this.state?.founderPeopleImage?.fileName : "No Image Uploaded"}</i></div>

                          </div>
                          <div className="col-4">
                            <div>
                              <label htmlFor="founderBackgroundColor" className="color-pallete-label">Color Picker</label>
                              <input id="founderBackgroundColor" value={this.state?.founderBackgroundColor ? this.state?.founderBackgroundColor : ""} type="color" className="color-pallete col-6" name="founderBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 mb-3">
                          <label className="my-label">Brief Background</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Brief Background"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="foundersbriefBackground"
                            value={this.state?.foundersbriefBackground}
                            type="text"
                            required
                          />
                        </div>
                        <div className="mt-2 mb-3">
                          <label className="my-label">Linkedin Profile Link</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Linkedin Profile Link"
                            variant="outlined"
                            style={{ marginLeft: '20px', width: '96%' }}
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input linked-inputfield"
                            name="founderslinkdinProfileLink"
                            value={this.state?.founderslinkdinProfileLink}
                            type="text"
                            required
                          />
                          <img src={linkdinIcon} className="linkdin-icon" />

                        </div>

                        {/* Add more founders Round */}
                        {this.state?.countAddMoreFoundersRound >= 0 && this.addMoreFounders(0)}
                        {this.state?.countAddMoreFoundersRound >= 1 && this.addMoreFounders(1)}
                        {this.state?.countAddMoreFoundersRound >= 2 && this.addMoreFounders(2)}
                        {this.state?.countAddMoreFoundersRound >= 3 && this.addMoreFounders(3)}

                        <button type="button"
                          className="btn"
                          onClick={() => this.setState({ countAddMoreFoundersRound: this.state?.countAddMoreFoundersRound + 1 })}
                          style={{ fontWeight: 500 }}>
                          + Add More Founders
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Vision */}
                  <div>
                    <h1 className="compress-section" id="visionpage" onClick={() => {
                      this.changeSymbol('visionpage', 'visionSection')
                      this.handleSectionHide('visionSection')
                    }}>—</h1>
                    <p className="heading-subhead">VISION</p>
                    <div className="row mb-3">
                      <div className="col-4">
                        <label className="my-label">Rename Vision Heading</label>
                        <TextField
                          id="outlined-secondary"
                          // label="Enter City Name"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => this.handleChange(e)}
                          className="form-control input-field text-field-input"
                          name="visionHeading"
                          type="text"
                          value={this.state?.visionHeading}
                        />
                      </div>
                      <div className="col-2" />
                      <div className="col-4">
                        <div style={{ marginTop: '40px' }}>
                          <label htmlFor="visionHeadingColor" className="color-pallete-label">Add Heading Color</label>
                          <input id="visionHeadingColor" type="color" className="color-pallete col-6" name="visionHeadingColor" value={this.state?.visionHeadingColor ? this.state?.visionHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                        </div>
                      </div>
                    </div>
                    <div id="visionSection">
                      <RichTextEditor
                        value={this.state?.companyVisionText}
                        onChange={(value) => this.setState({ companyVisionText: value })}
                        className="mt-2 mb-3 text-editor"
                        placeholder="Enter Text(limit : 150)"
                      />

                      {(this.state.companyVisionText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.companyVisionText?.toString('markdown').replace('\n', '#').length - 1)}/150</div>}

                      <div className="col-12 row">
                        <div className="col-3">
                          <label className="btn-upload">
                            <input type='file' accept="image/*" name="visionImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                            <button type="button" className="btn">
                              <img src={uploadIcon} className="upload-icon" />
                              Upload Image</button>
                          </label>
                          <br />
                          <span className="file-type">File Type: jpg/jpeg/png</span>
                        </div>
                        <div className="col-5 file-label"><i> {this.state?.visionImage?.fileName !== undefined ? this.state?.visionImage?.fileName : "No Image Uploaded"}</i></div>
                      </div>
                    </div>
                  </div>

                  {/* Mission */}
                  <div>
                    <h1 className="compress-section" id="missionPage" onClick={() => {
                      this.changeSymbol('missionPage', 'missionSection')
                      this.handleSectionHide('missionSection')
                    }}>—</h1>
                    <p className="heading-subhead">MISSION</p>
                    <div className="row mb-3">
                      <div className="col-4">
                        <label className="my-label">Rename Mission Heading</label>
                        <TextField
                          id="outlined-secondary"
                          // label="Enter City Name"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => this.handleChange(e)}
                          className="form-control input-field text-field-input"
                          name="missionHeading"
                          type="text"
                          value={this.state?.missionHeading}

                        />
                      </div>
                      <div className="col-2" />
                      <div className="col-4">
                        <div style={{ marginTop: '40px' }}>
                          <label htmlFor="missionHeadingColor" className="color-pallete-label">Add Heading Color</label>
                          <input id="missionHeadingColor" type="color" className="color-pallete col-6" name="missionHeadingColor" value={this.state?.missionHeadingColor ? this.state?.missionHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                        </div>
                      </div>
                    </div>
                    <div id="missionSection">
                      <RichTextEditor
                        value={this.state?.companyMissionText}
                        onChange={(value) => this.setState({ companyMissionText: value })}
                        className="mt-2 mb-3 text-editor"
                        placeholder="Enter Text  (Limit : 150)"
                      />
                      {(this.state.companyMissionText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.companyMissionText?.toString('markdown').replace('\n', '#').length - 1)}/150</div>}

                      <div className="col-12 row">
                        <div className="col-3">
                          <label className="btn-upload">
                            <input type='file' accept="image/*" name="missionImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                            <button type="button" className="btn">
                              <img src={uploadIcon} className="upload-icon" />

                              Upload Image</button>
                          </label>
                          <br />
                          <span className="file-type">File Type: jpg/jpeg/png</span>
                        </div>
                        <div className="col-5 file-label"><i> {this.state?.missionImage?.fileName !== undefined ? this.state?.missionImage?.fileName : "No Image Uploaded"}</i></div>
                      </div>
                    </div>
                  </div>

                  {/* Problem statement */}
                  <div>
                    <h1 className="compress-section" id="problemstamentpage" onClick={() => {
                      this.changeSymbol('problemstamentpage', 'problemStatementSection')
                      this.handleSectionHide('problemStatementSection')
                    }}>—</h1>
                    <p className="heading-subhead">PROBLEM STATEMENT</p>
                    <div id="problemStatementSection">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Problem Statement Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="problemStatementHeading"
                            type="text"
                            value={this.state?.problemStatementHeading}
                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="problemStatementHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="problemStatementHeadingColor" type="color" className="color-pallete col-6" name="problemStatementHeadingColor" value={this.state?.problemStatementHeadingColor ? this.state?.problemStatementHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.problemStatementText}
                        onChange={(value) => this.setState({ problemStatementText: value })}
                        className="mt-2 mb-3 text-editor"
                        placeholder="Enter Text  (Limit : 150)"
                      />
                      {(this.state.problemStatementText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.problemStatementText?.toString('markdown').replace('\n', '#').length - 1)}/150</div>}

                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="problemStatmentImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />
                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.problemStatmentImage?.fileName !== undefined ? this.state?.problemStatmentImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="problemStatementColor" className="color-pallete-label">Color Picker</label>
                            <input id="problemStatementColor" type="color" className="color-pallete col-6" name="problemStatementColor" value={this.state?.problemStatementColor ? this.state?.problemStatementColor : ""} onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* OverView */}
                  <div>
                    <h1 className="compress-section" id="overViewPage" onClick={() => {
                      this.changeSymbol('overViewPage', 'overViewSection')
                      this.handleSectionHide('overViewSection')
                    }
                    }>—</h1>
                    <p className="heading-subhead">OVERVIEW</p>
                    <div id="overViewSection">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Overview Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="overviewHeading"
                            type="text"
                            value={this.state?.overviewHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="overviewHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="overviewHeadingColor" type="color" className="color-pallete col-6" name="overviewHeadingColor" value={this.state?.overviewHeadingColor ? this.state?.overviewHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.overViewText}
                        onChange={(value) => this.setState({ overViewText: value })}
                        className="mt-2 mb-3 text-editor"
                        placeholder="Enter Text  (Limit : 1000)"
                      />

                      {(this.state.overViewText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.overViewText?.toString('markdown').replace('\n', '#').length - 1)}/1000</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="overViewImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />
                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.overViewImage?.fileName !== undefined ? this.state?.overViewImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="overViewColor" className="color-pallete-label">Color Picker</label>
                            <input id="overViewColor" value={this.state?.overViewColor ? this.state?.overViewColor : ""} type="color" className="color-pallete col-6" name="overViewColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Products */}
                  <div>
                    <h1 className="compress-section" id="productPage" onClick={() => {
                      this.changeSymbol('productPage', 'productSection')
                      this.handleSectionHide('productSection')
                    }
                    }>—</h1>
                    <p className="heading-subhead">PRODUCTS</p>
                    <div >
                      <div id="productSection">
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="my-label">Rename Products Heading</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter City Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="productsHeading"
                              type="text"
                              value={this.state?.productsHeading}
                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <div style={{ marginTop: '40px' }}>
                              <label htmlFor="productsHeadingColor" className="color-pallete-label">Add Heading Color</label>
                              <input id="productsHeadingColor" type="color" className="color-pallete col-6" name="productsHeadingColor" value={this.state?.productsHeadingColor ? this.state?.productsHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-2 mb-3">
                          <label className="my-label">Product Name</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Product Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="productName"
                            value={this.state?.productName}
                            type="text"
                            required
                          />
                        </div>
                        <RichTextEditor
                          value={this.state?.productText}
                          onChange={(value) => this.setState({ productText: value })}
                          placeholder="Enter Product Description (Limit : 250)"
                          className="mt-2 mb-3 text-editor"
                        />
                        {(this.state.productText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.productText?.toString('markdown').replace('\n', '#').length - 1)}/250</div>}

                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="productBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.productBackgroundImage?.fileName !== undefined ? this.state?.productBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        {/* <div className="text-editor-image" /> */}

                        {this.state?.countAddMoreProduct >= 0 && this.addMoreProducts(0)}
                        {this.state?.countAddMoreProduct >= 1 && this.addMoreProducts(1)}
                        {this.state?.countAddMoreProduct >= 2 && this.addMoreProducts(2)}
                        {this.state?.countAddMoreProduct >= 3 && this.addMoreProducts(3)}
                        <button type="button" className="btn" onClick={() => this.state?.countAddMoreProduct < 4 && this.setState({ countAddMoreProduct: this.state?.countAddMoreProduct + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More Products</button>
                        <div className="row mt-2 mb-3">
                          <div className="col-2">
                            <div>
                              <label htmlFor="productBackgroundColor" className="color-pallete-label">Color Picker</label>
                              <input id="productBackgroundColor" value={this.state?.productBackgroundColor ? this.state?.productBackgroundColor : ''} type="color" className="color-pallete col-6" name="productBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Key Metrics */}
                  <div>
                    <h1 className="compress-section" id="keyMericsPage" onClick={() => {
                      this.changeSymbol('keyMericsPage', 'keyMetrics')
                      this.handleSectionHide('keyMetrics')
                    }}>—</h1>
                    <p className="heading-subhead">KEY METRICS</p>
                    <div >

                      <div id="keyMetrics">
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="my-label">Rename Key Metrics Heading</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter City Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="keyMetricsHeading"
                              type="text"
                              value={this.state?.keyMetricsHeading}
                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <div style={{ marginTop: '40px' }}>
                              <label htmlFor="keyMetricsHeadingColor" className="color-pallete-label">Add Heading Color</label>
                              <input id="keyMetricsHeadingColor" type="color" className="color-pallete col-6" name="keyMetricsHeadingColor" value={this.state?.keyMetricsHeadingColor ? this.state?.keyMetricsHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                            </div>
                          </div>
                        </div>
                        <RichTextEditor
                          value={this.state?.keyMetricsText}
                          onChange={(value) => this.setState({ keyMetricsText: value })}
                          placeholder="Enter Text (Text limit : 50 words)"
                          className="mt-2 mb-3 text-editor"
                        />
                        {(this.state.keyMetricsText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.keyMetricsText?.toString('markdown').replace('\n', '#').length - 1)}/50</div>}

                        {/* <div className="text-editor-image" /> */}
                        <div className="row mt-2 mb-3">
                          <div className="col-2">
                            <div>
                              <label htmlFor="keyMetricsBackgroundColor" className="color-pallete-label">Color Picker</label>
                              <input id="keyMetricsBackgroundColor" value={this.state?.keyMetricsBackgroundColor ? this.state?.keyMetricsBackgroundColor : ""} type="color" className="color-pallete col-6" name="keyMetricsBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                            </div>
                          </div>
                        </div>

                        {this.state?.countKeyMatrics >= 0 && this.addMorKeyMetrics(0)}
                        {this.state?.countKeyMatrics >= 1 && this.addMorKeyMetrics(1)}
                        {this.state?.countKeyMatrics >= 2 && this.addMorKeyMetrics(2)}
                        {this.state?.countKeyMatrics >= 3 && this.addMorKeyMetrics(3)}
                        {this.state?.countKeyMatrics >= 4 && this.addMorKeyMetrics(4)}
                        {this.state?.countKeyMatrics >= 5 && this.addMorKeyMetrics(5)}
                        {this.state?.countKeyMatrics >= 6 && this.addMorKeyMetrics(6)}
                        {this.state?.countKeyMatrics >= 7 && this.addMorKeyMetrics(7)}
                        {this.state?.countKeyMatrics >= 8 && this.addMorKeyMetrics(8)}
                        {this.state?.countKeyMatrics >= 9 && this.addMorKeyMetrics(9)}
                        {this.state?.countKeyMatrics >= 10 && this.addMorKeyMetrics(10)}
                        {/* {this.state?.countKeyMatrics >= 11 && this.addMorKeyMetrics(11)} */}

                        <br />
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="keyMetricsBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.keyMetricsBackgroundImage?.fileName !== undefined ? this.state?.keyMetricsBackgroundImage?.fileName : "No Image Uploaded"}</i></div>

                        </div>

                        <button type="button" className="btn" onClick={() => this.state?.countKeyMatrics < 12 && this.setState({ countKeyMatrics: this.state?.countKeyMatrics + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More Metrics</button>
                      </div>
                    </div>
                  </div>

                  {/* Business Road Map */}
                  <div>
                    <h1 className="compress-section" id="businessRoadMapPage" onClick={() => {
                      this.changeSymbol('businessRoadMapPage', 'roadMap')
                      this.handleSectionHide('roadMap')

                    }

                    }>—</h1>
                    <p className="heading-subhead">BUSINESS ROADMAP</p>
                    <div id="roadMap">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Business Roadmap Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="businessRoadMapHeading"
                            type="text"
                            value={this.state?.businessRoadMapHeading}
                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="businessRoadMapHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="businessRoadMapHeadingColor" type="color" className="color-pallete col-6" name="businessRoadMapHeadingColor" value={this.state?.businessRoadMapHeadingColor ? this.state?.businessRoadMapHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.businessRoadMapText}
                        onChange={(value) => this.setState({ businessRoadMapText: value })}
                        placeholder="Enter Text (Limit : 1500)"
                        className="mt-2 mb-3 text-editor"
                      />
                      {(this.state.businessRoadMapText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.businessRoadMapText?.toString('markdown').replace('\n', '#').length - 1)}/1500</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="bussinessroadBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.bussinessroadBackgroundImage?.fileName !== undefined ? this.state?.bussinessroadBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="bussinessroadBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="bussinessroadBackgroundColor" value={this.state?.bussinessroadBackgroundColor ? this.state?.bussinessroadBackgroundColor : ""} type="color" className="color-pallete col-6" name="bussinessroadBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Tech Stack */}
                  <div>
                    <h1 className="compress-section" id="techStackPage" onClick={() => {
                      this.changeSymbol('techStackPage', 'techStack')
                      this.handleSectionHide('techStack')
                    }}>—</h1>
                    <p className="heading-subhead">TECH STACK</p>
                    <div id="techStack">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Tech Stack Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="techStackHeading"
                            type="text"
                            value={this.state?.techStackHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="techStackHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="techStackHeadingColor" type="color" className="color-pallete col-6" name="techStackHeadingColor" value={this.state?.techStackHeadingColor ? this.state?.techStackHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="techBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.techBackgroundImage?.fileName !== undefined ? this.state?.techBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="techBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="techBackgroundColor" value={this.state?.techBackgroundColor ? this.state?.techBackgroundColor : ""} type="color" className="color-pallete col-6" name="techBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TECH LAND SCAPE */}
                  <div>
                    <h1 className="compress-section" id="landScapePage" onClick={() => {
                      this.changeSymbol('landScapePage', 'techLandScape')
                      this.handleSectionHide('techLandScape')
                    }

                    }>—</h1>
                    <p className="heading-subhead">TECH LANDSCAPE</p>
                    <div id="techLandScape">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Tech Landscape Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="techLandScapeHeading"
                            type="text"
                            value={this.state?.techLandScapeHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="techLandScapeHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="techLandScapeHeadingColor" type="color" className="color-pallete col-6" name="techLandScapeHeadingColor" value={this.state?.techLandScapeHeadingColor ? this.state?.techLandScapeHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.techLandScapeText}
                        onChange={(value) => this.setState({ techLandScapeText: value })}
                        placeholder="Enter Text (Limit : 200)"
                        className="mt-2 mb-3 text-editor"
                      />

                      {(this.state.techLandScapeText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.techLandScapeText?.toString('markdown').replace('\n', '#').length - 1)}/200</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="techLandBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.techLandBackgroundImage?.fileName !== undefined ? this.state?.techLandBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="techLandBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="techLandBackgroundColor" value={this.state?.techLandBackgroundColor ? this.state?.techLandBackgroundColor : ""} type="color" className="color-pallete col-6" name="techLandBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TECH CHALLENGES */}
                  <div>
                    <h1 className="compress-section" id="techChallangesPage" onClick={() => {
                      this.changeSymbol('techChallangesPage', 'techChallenges')
                      this.handleSectionHide('techChallenges')
                    }
                    }>—</h1>
                    <p className="heading-subhead">TECH CHALLENGES</p>
                    <div id="techChallenges">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Tech Challenges Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="techChallengesHeading"
                            type="text"
                            value={this.state?.techChallengesHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="techChallengesHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="techChallengesHeadingColor" type="color" className="color-pallete col-6" name="techChallengesHeadingColor" value={this.state?.techChallengesHeadingColor ? this.state?.techChallengesHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.techChallangesText}
                        onChange={(value) => this.setState({ techChallangesText: value })}
                        placeholder="Enter Text (Limit : 200)"
                        className="mt-2 mb-3 text-editor"
                      />
                      {(this.state.techChallangesText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.techChallangesText?.toString('markdown').replace('\n', '#').length - 1)}/200</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="techChallengeBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.techChallengeBackgroundImage?.fileName !== undefined ? this.state?.techChallengeBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="techChallengeBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="techChallengeBackgroundColor" value={this.state?.techChallengeBackgroundColor ? this.state?.techChallengeBackgroundColor : ""} type="color" className="color-pallete col-6" name="techChallengeBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TECH ROADMAP */}
                  <div>
                    <h1 className="compress-section" id="techRoadMapPage" onClick={() => {
                      this.changeSymbol('techRoadMapPage', 'techRoadMap')
                      this.handleSectionHide('techRoadMap')
                    }}>—</h1>
                    <p className="heading-subhead">TECH ROADMAP</p>
                    <div id="techRoadMap">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Tech Roadmap Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="roadMapHeading"
                            type="text"
                            value={this.state?.roadMapHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="roadMapHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="roadMapHeadingColor" type="color" className="color-pallete col-6" name="roadMapHeadingColor" value={this.state?.roadMapHeadingColor ? this.state?.roadMapHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.techRoadMapText}
                        onChange={(value) => this.setState({ techRoadMapText: value })}
                        placeholder="Enter Text (Limit : 500)"
                        className="mt-2 mb-3 text-editor"
                      />
                      {(this.state.techRoadMapText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.techRoadMapText?.toString('markdown').replace('\n', '#').length - 1)}/500</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="techRoadMapBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                            <br />
                            <span className="file-type">File Type: jpg/jpeg/png</span>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.techRoadMapBackgroundImage?.fileName !== undefined ? this.state?.techRoadMapBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="techRoadMapBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="techRoadMapBackgroundColor" value={this.state?.techRoadMapBackgroundColor ? this.state?.techRoadMapBackgroundColor : ""} type="color" className="color-pallete col-6" name="techRoadMapBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TECH ORG */}
                  <div>
                    <h1 className="compress-section" id="techOrgPage" onClick={() => {
                      this.changeSymbol('techOrgPage', 'techOrg')
                      this.handleSectionHide('techOrg')
                    }

                    }>—</h1>
                    <p className="heading-subhead">TECH ORG</p>
                    <div id="techOrg">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Tech Org Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="techOrgHeading"
                            type="text"
                            value={this.state?.techOrgHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="techOrgHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="techOrgHeadingColor" type="color" className="color-pallete col-6" name="techOrgHeadingColor" value={this.state?.techOrgHeadingColor ? this.state?.techOrgHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.techOrgText}
                        onChange={(value) => this.setState({ techOrgText: value })}
                        placeholder="Enter Text (Limit : 500)"
                        className="mt-2 mb-3 text-editor"
                      />
                      {(this.state.techOrgText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.techOrgText?.toString('markdown').replace('\n', '#').length - 1)}/500</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-6">
                          <div>
                            <label htmlFor="techOrgBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="techOrgBackgroundColor" value={this.state?.techOrgBackgroundColor ? this.state?.techOrgBackgroundColor : ""} type="color" className="color-pallete col-6" name="techOrgBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* HIERARCHY LEVEL1 */}
                  <div>
                    <h1 className="compress-section" id="h1Page" onClick={() => {
                      this.changeSymbol('h1Page', 'hierarchyLevel1')
                      this.handleSectionHide('hierarchyLevel1')
                    }}>—</h1>
                    <p className="heading-subhead">Hierarchy Level 1</p>
                    <div >
                      <div id="hierarchyLevel1">
                        <div className="row mt-2 mb-3">
                          <div className="col-6">
                            <label className="my-label">Enter Name</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevelName"
                              value={this.state?.hierarchyLevelName}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-6">
                            <label className="my-label">Enter Position</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Position"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevelPosition"
                              value={this.state?.hierarchyLevelPosition}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {/* Add more of h1 level */}
                        {this.state?.HierarchyLevel1Count >= 0 && this.hrLevel1(0)}
                        {this.state?.HierarchyLevel1Count >= 1 && this.hrLevel1(1)}
                        {this.state?.HierarchyLevel1Count >= 2 && this.hrLevel1(2)}
                        {this.state?.HierarchyLevel1Count >= 3 && this.hrLevel1(3)}

                        <button type="button" className="btn" onClick={() => this.state?.HierarchyLevel1Count < 4 && this.setState({ HierarchyLevel1Count: this.state?.HierarchyLevel1Count + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More People</button>

                      </div>

                    </div>
                  </div>

                  {/* HIERARCHY LEVEL2 */}
                  <div>
                    <h1 className="compress-section" id="h2Page" onClick={() => {
                      this.changeSymbol('h2Page', 'hierarchyLevel2')
                      this.handleSectionHide('hierarchyLevel2')
                    }

                    }>—</h1>
                    <p className="heading-subhead">Hierarchy Level 2</p>
                    <div >
                      <div id="hierarchyLevel2">
                        <div className="row mt-2 mb-3">
                          <div className="col-6">
                            <label className="my-label">Enter Name</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevel2Name"
                              value={this.state?.hierarchyLevel2Name}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-6">
                            <label className="my-label">Enter Position</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Position"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevel2Position"
                              value={this.state?.hierarchyLevel2Position}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {/* Add more of h2 level */}
                        {this.state?.HierarchyLevel2Count >= 0 && this.hrLevel2(0)}
                        {this.state?.HierarchyLevel2Count >= 1 && this.hrLevel2(1)}
                        {this.state?.HierarchyLevel2Count >= 2 && this.hrLevel2(2)}
                        {this.state?.HierarchyLevel2Count >= 3 && this.hrLevel2(3)}

                        <button type="button" className="btn" onClick={() => this.state?.HierarchyLevel2Count < 4 && this.setState({ HierarchyLevel2Count: this.state?.HierarchyLevel2Count + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More People</button>

                      </div>

                    </div>
                  </div>

                  {/* HIERARCHY LEVEL3 */}
                  <div>
                    <h1 className="compress-section" id="h3Page" onClick={() => {
                      this.changeSymbol('h3Page', 'hierarchyLevel3')

                      this.handleSectionHide('hierarchyLevel3')
                    }
                    }>—</h1>
                    <p className="heading-subhead">Hierarchy Level 3</p>
                    <div >
                      <div id="hierarchyLevel3">
                        <div className="row mt-2 mb-3">
                          <div className="col-6">
                            <label className="my-label">Enter Name</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevel3Name"
                              value={this.state?.hierarchyLevel3Name}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-6">
                            <label className="my-label">Enter Position</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Position"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevel3Position"
                              value={this.state?.hierarchyLevel3Position}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {/* Add more of h3 level */}
                        {this.state?.HierarchyLevel3Count >= 0 && this.hrLevel3(0)}
                        {this.state?.HierarchyLevel3Count >= 1 && this.hrLevel3(1)}
                        {this.state?.HierarchyLevel3Count >= 2 && this.hrLevel3(2)}
                        {this.state?.HierarchyLevel3Count >= 3 && this.hrLevel3(3)}

                        <button type="button" className="btn" onClick={() => this.state?.HierarchyLevel3Count < 4 && this.setState({ HierarchyLevel3Count: this.state?.HierarchyLevel3Count + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More People</button>

                      </div>

                    </div>
                  </div>

                  {/* HIERARCHY LEVEL4 */}
                  <div>
                    <h1 className="compress-section" id="h4Page" onClick={() => {
                      this.changeSymbol('h4Page', 'hierarchyLevel4')
                      this.handleSectionHide('hierarchyLevel4')

                    }
                    }>—</h1>
                    <p className="heading-subhead">Hierarchy Level 4</p>
                    <div >
                      <div id="hierarchyLevel4">
                        <div className="row mt-2 mb-3">
                          <div className="col-6">
                            <label className="my-label">Enter Name</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevel4Name"
                              value={this.state?.hierarchyLevel4Name}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-6">
                            <label className="my-label">Enter Position</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Position"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="hierarchyLevel4Position"
                              value={this.state?.hierarchyLevel4Position}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {/* Add more of h3 level */}
                        {this.state?.HierarchyLevel4Count >= 0 && this.hrLevel4(0)}
                        {this.state?.HierarchyLevel4Count >= 1 && this.hrLevel4(1)}
                        {this.state?.HierarchyLevel4Count >= 2 && this.hrLevel4(2)}
                        {this.state?.HierarchyLevel4Count >= 3 && this.hrLevel4(3)}

                        <button type="button" className="btn" onClick={() => this.state?.HierarchyLevel4Count < 4 && this.setState({ HierarchyLevel4Count: this.state?.HierarchyLevel4Count + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More People</button>

                      </div>

                    </div>
                  </div>

                  {/* CURRENT MANDATE & EXPECTATIONS */}
                  <div>
                    <h1 className="compress-section" id="cmdePage" onClick={() => {
                      this.changeSymbol('cmdePage', 'currenntMandateExpectations')
                      this.handleSectionHide('currenntMandateExpectations')
                    }}>—</h1>
                    <p className="heading-subhead">CURRENT MANDATE & EXPECTATIONS</p>
                    <div >
                      <div id="currenntMandateExpectations">
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="my-label">Rename Current Mandate & Expectations Heading</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter City Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="currentMandateHeading"
                              type="text"
                              value={this.state?.currentMandateHeading}

                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <div style={{ marginTop: '40px' }}>
                              <label htmlFor="currentMandateHeadingColor" className="color-pallete-label">Add Heading Color</label>
                              <input id="currentMandateHeadingColor" type="color" className="color-pallete col-6" name="currentMandateHeadingColor" value={this.state?.currentMandateHeadingColor ? this.state?.currentMandateHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-2 mb-3">
                          <label className="my-label">Enter Designation</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter Designation"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="currentDesignation"
                            value={this.state?.currentDesignation}
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-12 mt-2 mb-3">
                          <label className="my-label">Directly Reporting to</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Directly Reporting to"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="currentReportingTo"
                            value={this.state?.currentReportingTo}
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-12 mt-2 mb-3">
                          <label className="my-label">Enter Location</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter Location"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="currentLocation"
                            value={this.state?.currentLocation}
                            type="text"
                            required
                          />
                        </div>

                        <RichTextEditor
                          value={this.state?.CMEText}
                          onChange={(value) => this.setState({ CMEText: value })}
                          placeholder="Enter Expectations (Text Limit : 1300)"
                          className="mt-2 mb-3 text-editor"
                        />

                        {(this.state.CMEText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.CMEText?.toString('markdown').replace('\n', '#').length - 1)}/1300</div>}

                        <div className="col-6">
                          <div>
                            <label htmlFor="CMEBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="CMEBackgroundColor" type="color" className="color-pallete col-6" value={this.state?.CMEBackgroundColor ? this.state?.CMEBackgroundColor : ""} name="CMEBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>

                        {/* <div className="text-editor-image" /> */}

                        {this.state?.cmeCountPanel >= 0 && this.addMoreDesignations(0)}
                        {this.state?.cmeCountPanel >= 1 && this.addMoreDesignations(1)}
                        {this.state?.cmeCountPanel >= 2 && this.addMoreDesignations(2)}
                        {this.state?.cmeCountPanel >= 3 && this.addMoreDesignations(3)}

                        <div className="row mt-2 mb-3">
                          {!this.state.editPQCapsuleForm && <div className="col-6">
                            <button type="button" className="btn" onClick={() => this.state?.cmeCountPanel < 4 && this.setState({ cmeCountPanel: this.state?.cmeCountPanel + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More Designations</button>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*INTERVIEW PANEL */}
                  <div>
                    <h1 className="compress-section" id="interviewpage" onClick={() => {
                      this.changeSymbol('interviewpage', 'interviewPanel')
                      this.handleSectionHide('interviewPanel')
                    }
                    }>—</h1>
                    <p className="heading-subhead">INTERVIEW PANEL</p>
                    <div>
                      <div className="" style={{ marginTop: '20px' }} id="interviewPanel">
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="my-label">Rename Interview Panel</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter City Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="interviewPanelHeading"
                              type="text"
                              value={this.state?.interviewPanelHeading}

                            />
                          </div>
                          <div className="col-2" />
                          <div className="col-4">
                            <div style={{ marginTop: '40px' }}>
                              <label htmlFor="interviewPanelHeadingColor" className="color-pallete-label">Add Heading Color</label>
                              <input id="interviewPanelHeadingColor" type="color" className="color-pallete col-6" name="interviewPanelHeadingColor" value={this.state?.interviewPanelHeadingColor ? this.state?.interviewPanelHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2 mb-3">
                          <div className="col-6">
                            <label className="my-label">Enter Name</label>
                            <TextField
                              id="outlined-secondary"
                              // label="Enter Name"
                              variant="outlined"
                              color="primary"
                              onChange={(e) => this.handleChange(e)}
                              className="form-control input-field text-field-input"
                              name="interViewPanelName"
                              value={this.state?.interViewPanelName}
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-6 row">
                            <div className="col-6">
                              <label className="btn-upload">
                                <input type='file' accept="image/*" name="interviewPanelImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                                <button type="button" style={{ fontSize: '13px' }} className="btn">
                                  <img src={uploadIcon} className="upload-icon" />

                                  Upload Image</button>
                              </label>
                              <br />
                              <span className="file-type">File Type: jpg/jpeg/png</span>
                            </div>
                            <div className="col-6 file-label"><i> {this.state?.interviewPanelImage?.fileName !== undefined ? this.state?.interviewPanelImage?.fileName : "No Image Uploaded"}</i></div>
                          </div>
                        </div>
                        <div className="mt-2 mb-3">
                          <label className="my-label">Linkedin Profile Link</label>
                          <TextField
                            id="outlined-secondary"
                            // label={`Linkedin Profile Link`}
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="interViewlinkdinProfileLink"
                            value={this.state?.interViewlinkdinProfileLink}
                            type="text"
                            style={{ marginLeft: '20px', width: '96%' }}
                            required
                          />
                          <span className="mdc-notched-outline__leading"> <img src={linkdinIcon} className="linkdin-icon" /></span>

                        </div>
                        <div className="mt-2 mb-3">
                          <div className="col-4">
                            {/* <p className="relocation-status">Select Invterview Round</p> */}
                            <label className="my-label">Select Interview Round</label>
                            <Select
                              // @ts-ignore
                              options={interviewRounds}
                              className=""
                              placeholder={this.state?.interViewRoundName || ''}
                              onChange={(e) => this.handleChange(e, 'interViewRoundName')}
                              name="interViewRoundName"
                              value={this.state?.interViewRoundName}
                              styles={customStyles}
                            />
                          </div>
                        </div>

                        {/* add interview panel section */}
                        {this.state?.countInterViewPanel >= 0 && this.addInterviewPanelMore(0)}
                        {this.state?.countInterViewPanel >= 1 && this.addInterviewPanelMore(1)}
                        {this.state?.countInterViewPanel >= 2 && this.addInterviewPanelMore(2)}
                        {this.state?.countInterViewPanel >= 3 && this.addInterviewPanelMore(3)}

                        <div className="row mt-2 mb-3">
                          <div className="col-6">
                            <button type="button" className="btn" onClick={() => this.state?.countInterViewPanel < 4 && this.setState({ countInterViewPanel: this.state?.countInterViewPanel + 1 })} style={{ fontWeight: 500, marginLeft: '20px' }}>+ Add More Names</button>
                          </div>
                          <div className="col-6">
                            <div>
                              <label htmlFor="interviewBackgroundColor" className="color-pallete-label">Color Picker</label>
                              <input id="interviewBackgroundColor" value={this.state?.interviewBackgroundColor ? this.state?.interviewBackgroundColor : ""} type="color" className="color-pallete col-6" name="interviewBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* CULTURE & DNA */}
                  <br />
                  <br />
                  <div>
                    <h1 className="compress-section" id="cdnaPage" onClick={() => {
                      this.changeSymbol('cdnaPage', 'cultureDna')
                      this.handleSectionHide('cultureDna')
                    }}>—</h1>
                    <p className="heading-subhead">CULTURE & DNA</p>
                    <div id="cultureDna">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Culture & Dna Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="cultureDnaHeading"
                            type="text"
                            value={this.state?.cultureDnaHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="cultureDnaHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="cultureDnaHeadingColor" type="color" className="color-pallete col-6" name="cultureDnaHeadingColor" value={this.state?.cultureDnaHeadingColor ? this.state?.cultureDnaHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <RichTextEditor
                        value={this.state?.cultureDnaText}
                        onChange={(value) => this.setState({ cultureDnaText: value })}
                        placeholder="Enter Text(Limit : 500)"
                        className="mt-2 mb-3 text-editor"
                      />
                      {(this.state.cultureDnaText?.toString('markdown').replace('\n', '#').length - 1) > 2 && <div className="textCount">{(this.state.cultureDnaText?.toString('markdown').replace('\n', '#').length - 1)}/500</div>}
                      {/* <div className="text-editor-image" /> */}

                      <div className="row mt-2 mb-3">
                        <div className="col-8 row">
                          <div className="col-5">
                            <label className="btn-upload">
                              <input type='file' accept="image/*" name="cultureBackgroundImage" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                              <button type="button" className="btn">
                                <img src={uploadIcon} className="upload-icon" />

                                Upload Image</button>
                            </label>
                          </div>
                          <div className="col-5 file-label"><i> {this.state?.cultureBackgroundImage?.fileName !== undefined ? this.state?.cultureBackgroundImage?.fileName : "No Image Uploaded"}</i></div>
                        </div>
                        <div className="col-2">
                          <div>
                            <label htmlFor="cultureBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="cultureBackgroundColor" value={this.state?.cultureBackgroundColor ? this.state?.cultureBackgroundColor : ""} type="color" className="color-pallete col-6" name="cultureBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                        <span className="file-type">Upload imagepng/any other file</span>
                      </div>
                    </div>
                  </div>

                  {/* CONFINDENTIALITY */}
                  <div>
                    <h1 className="compress-section" id="cfdPage" onClick={() => {
                      this.changeSymbol('cfdPage', 'confidentiality')
                      this.handleSectionHide('confidentiality')
                    }

                    }>—</h1>
                    <p className="heading-subhead">CONFIDENTIALITY</p>
                    <div id="confidentiality">
                      <div className="row mb-3">
                        <div className="col-4">
                          <label className="my-label">Rename Confidentiality Heading</label>
                          <TextField
                            id="outlined-secondary"
                            // label="Enter City Name"
                            variant="outlined"
                            color="primary"
                            onChange={(e) => this.handleChange(e)}
                            className="form-control input-field text-field-input"
                            name="confidentialityHeading"
                            type="text"
                            value={this.state?.confidentialityHeading}

                          />
                        </div>
                        <div className="col-2" />
                        <div className="col-4">
                          <div style={{ marginTop: '40px' }}>
                            <label htmlFor="confidentialityHeadingColor" className="color-pallete-label">Add Heading Color</label>
                            <input id="confidentialityHeadingColor" type="color" className="color-pallete col-6" name="confidentialityHeadingColor" value={this.state?.confidentialityHeadingColor ? this.state?.confidentialityHeadingColor : ""} onChange={(e) => this.handleChange(e)} /><br />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 mb-3">
                        <textarea style={{ width: '95%', marginLeft: '20px' }} className="form-control text-editor" name="confindentialText" id="text-area-confidential" value={this.state.confindentialText} onChange={(e) => { this.handleChange(e); this.handleTextFieldArea(); }} required />
                      </div>

                      {(this.state.confindentialText.length) > 2 && <div className="textCount">{(this.state.confindentialText.length)}/250</div>}

                      <div className="row mt-2 mb-3">
                        <div className="col-6">
                          <div>
                            <label htmlFor="confindentialBackgroundColor" className="color-pallete-label">Color Picker</label>
                            <input id="confindentialBackgroundColor" value={this.state?.confindentialBackgroundColor ? this.state?.confindentialBackgroundColor : ""} type="color" className="color-pallete col-6" name="confindentialBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-2 mb-3">
                      <div className="col-6">
                        <Select
                          options={[{ value: 'Stage 1', label: 'Stage 1' }, { value: 'Stage 2', label: 'Stage 2' }]}

                          placeholder={this.state.vectorElement || 'Choose Vector Element'}
                          onChange={(e) => this.handleChange(e, 'vectorElement')}
                          name="vectorElement"
                          styles={customStyles}
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="vectorBackgroundColor" className="color-pallete-label">Color Picker</label>
                        <input id="vectorBackgroundColor" value={this.state?.vectorBackgroundColor ? this.state?.vectorBackgroundColor : ""} type="color" className="color-pallete col-6" name="vectorBackgroundColor" onChange={(e) => this.handleChange(e)} required /><br />
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* Genrate Button */}
                <div className="" style={{ textAlign: 'center' }}>
                  <button type="submit" className="btn btn-sm genrate-button" >
                    {this.state.editPQCapsuleForm ? 'Update Purple Quarter Capsule' : 'Generate Purple Quarter Capsule'}
                  </button>

                  {/* <button type="button" className="btn btn-sm genrate-button" onClick={() => this.setState({ isPreviewCompanyDetails: true })}>Preview</button> */}
                </div>

              </div>
            </form>}
            {/* Saved PQ-capsule forms */}
            <div id="agreement-company-page" className="view-rectangle capsule-form " style={{ marginBottom: '20px', marginTop: '20px', height: '600px' }}>
              {/* @ts-ignore */}
              <div className={`${this.props.companySection ? "pq-capsule-companypage row" : "row"}`} style={{ backgroundColor: '#f9f9f9', padding: '10px' }}>
                <div className="capsuleForm-display col-3"><b>TITLE</b></div>
                <div className="capsuleForm-display col-3"><b>DATE</b></div>
                <div className="capsuleForm-display col-3"><b>CREATED BY</b></div>
                <div className="capsuleForm-display col-3"><b></b></div>
              </div>

              {/* @ts-ignore */}
              {!!this.props.companySection && this?.state?.pqCapsuleFormList?.length  ? (this.state.pqCapsuleFormList.filter((item: any) => item.attributes.name === this.props.presentcompanyName).length == 0 ? <div className="loader mt-2">
                    No Data Found
                  </div> : "" ) : "" }

              <div className="agreement-listing">
                {/* @ts-ignore */}
                {this?.state?.pqCapsuleFormList?.length > 0 ? (!!this.props.companySection ? (this.state.pqCapsuleFormList.filter((item: any) => item.attributes.name === this.props.presentcompanyName)) : this.state?.pqCapsuleFormList).map((ele: any) => <div id={ele?.id} className="row" style={{ padding: '10px' }}>
                  <div className="col-3">{ele.attributes.name}</div>
                  <div className="col-3 ">{ele.attributes.date}</div>
                  <div className="col-3">{ele.attributes.pq_employee.data.attributes.first_name + ' ' + ele.attributes.pq_employee.data.attributes.last_name}</div>
                  <div className="col-3">
                    <div className="col-2 ellipsis-item"
                      onClick={() => {
                        this.openDropdownModal(this.state?.isDropDownOpenId === ele?.id ? null : ele?.id)
                        this.setState(
                          {
                            // isDropDownOpenId: this.state?.isDropDownOpenId === ele?.id ? null : ele?.id,
                            previewPQForm: ele.attributes
                          }
                        )
                      }}
                    >
                      <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                      <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                      <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                      {this.state?.isDropDownOpenId === ele?.id && this.openDropDownModalUI()}
                    </div>
                  </div>

                
                </div>) :
                  <CircularProgress className="agreement-loader" />}
              </div>
            </div>

          </div>

        </div >
      </div >
    );
    //Customizable Area End
  }
}

// Customizable Area Start
//Preview the PDF section 
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    height: '44px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
const customStyle1 = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    zIndex: 90,
    borderRadius: '10px',
    height: '40px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
//Customizable Area End
