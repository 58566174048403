import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ArcElement
  } from 'chart.js';
  import { Bar, Chart, Pie } from 'react-chartjs-2'

export default function BarChart() {
        const chartData2 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            type: 'line' ,
            label: 'Dataset 1',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            fill: false,
            data: [10, 20, 50, 40, 80, 60, 10],
    
          },
          {
            type: 'bar' ,
            label: 'Dataset 2',
            backgroundColor: 'rgb(75, 192, 192)',
            data: [10, 20, 50, 40, 80, 60, 10],
    
            borderColor: 'white',
            borderWidth: 2,
          },
          {
            type: 'bar' ,
            label: 'Dataset 3',
            backgroundColor: 'rgb(53, 162, 235)',
            data: [10, 20, 50, 40, 80, 60, 10],
    
          },
        ],
      }

    return (
        <div>
            <Chart type='bar' data={chartData2} />
        </div>
    )
}
