import React from "react";

// Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity
} from "react-native";
import { Formik } from "formik";

import * as yup from "yup";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  passwordSchema = () => {
    return yup.object().shape({
      password: yup
        .string()
        .required("Password is required."),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Password is required."),
    });
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    const assets = require("./assets");

    // Customizable Area Start
    return (
      <div className="main">
        <div className="center-item">
          <img src={assets.pqLogo} className="pq-logo" alt="pq-logo" />
          <div className="view-base2">
            <div>
              <p className="textLabel_Heading">New Password</p>
              <p className="textLabel_sub_Heading">Enter
                New password </p>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: '',
                  passwordToken: new URLSearchParams(window.location.search).get('token')
                }}
                validationSchema={this.passwordSchema}
                onSubmit={values => {
                  // same shape as initial values
                  this.resetPassword(values);
                }}
              >
                {({ errors, touched, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mt-1 mb-3">
                      <input onChange={handleChange} className="form-control input-field" name="password" type={!this.state.passwordView ? "password" : "text"} placeholder="Password" />
                      <div className="password-view2">
                        {!this.state.passwordView ? <img src={assets.imgPasswordVisible} alt="view" onClick={() => this.handlePasswordView()} /> : <img src={assets.imgPasswordInVisible} onClick={() => this.handlePasswordView()} alt="view" />}
                      </div>
                      {errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
                    </div>
                    <div className="mt-1 mb-3">
                      <input onChange={handleChange} className="form-control input-field" name="confirmPassword" type={!this.state.confirmPasswordView ? "password" : "text"} placeholder="Repeat Password" />
                      <div className="password-view2">
                        {!this.state.confirmPasswordView ? <img src={assets.imgPasswordVisible} alt="view" onClick={() => this.handleConfirmPasswordView()} /> : <img src={assets.imgPasswordInVisible} onClick={() => this.handleConfirmPasswordView()} alt="view" />}
                      </div>
                      {errors.confirmPassword && touched.confirmPassword ? <div className="error">{errors.confirmPassword}</div> : null}
                    </div>
                    <button className="btn signin-button mt-2" type="submit">Save</button>
                    {this.state.successMessage !== null ? <div className="success errror-msg mt-2">{this.state.successMessage}</div> : ""}
                    {this.state.failureMessage !== null ? <div className="error error-msg mt-2">{this.state.failureMessage[0].otp}</div> : ""}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: "center"
  },

  passwordShowHide: {
    alignSelf: "center"
  },

  passwordRulesStyle: {
    padding: 15
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5
  },

  passwordContainerWeb: {
    flexDirection: "row",
    borderBottomWidth: 1,
    backgroundColor: "#00000000",
    borderColor: "#767676",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordInputWeb: {
    flex: 1,
    fontSize: 18,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderColor: "#767676",
    borderRadius: 2
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
