// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Share from "../../blocks/Share/src/Share";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import AdvancedSearch2 from "../../blocks/AdvancedSearch2/src/AdvancedSearch2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CustomForm from "../../blocks/CustomForm/src/CustomForm";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CallRecording from "../../blocks/CallRecording/src/CallRecording";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import LandingPage from "../../blocks/LandingPage/src/LandingPage";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import Download from "../../blocks/Download/src/Download";
import GraphicalCharts from "../../blocks/GraphicalCharts/src/GraphicalCharts";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import 'bootstrap/dist/css/bootstrap.min.css';

import Candidates from '../../blocks/dashboard/src/Candidates.web'
import BasicDetails from '../../blocks/dashboard/src/BasicDetails.web'
import DomainKnowledge from '../../blocks/dashboard/src/DomainKnowledge.web'
import FamilyDetails from '../../blocks/dashboard/src/FamilyDetails.web'
import Compensation from '../../blocks/dashboard/src/Compensation.web'
import ExpectedCompensation from '../../blocks/dashboard/src/ExpectedCompensation.web'
import Competencies from '../../blocks/dashboard/src/Competencies.web'
import CandidateProfile from '../../blocks/dashboard/src/CandidateProfileInfo.web'
import './app.css'

// web routing
import RouteConfig from "./route.config";



const routeMap = {
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  AdvancedSearch2: {
    component: AdvancedSearch2,
    path: "/AdvancedSearch2"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  CustomForm: {
    component: CustomForm,
    path: "/CustomForm"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  CallRecording: {
    component: CallRecording,
    path: "/CallRecording"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: "/AssessmentTest"
  },
  TimeTracker: {
    component: TimeTracker,
    path: "/TimeTracker"
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: "/ReviewAndApproval"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: "/PrivacySettings"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  GraphicalCharts: {
    component: GraphicalCharts,
    path: "/GraphicalCharts"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  Candidates: {
    component: Candidates,
    path: '/Candidates'
  },
  BasicDetails: {
    component: BasicDetails,
    path: '/BasicDetails'
  },
  DomainKnowledge: {
    component: DomainKnowledge,
    path: "/DomainKnowledge",
  },
  FamilyDetails: {
    component: FamilyDetails,
    path: "/FamilyDetails",
  },
  Compensation: {
    component: Compensation,
    path: "/Compensation",
  },
  ExpectedCompensation: {
    component: ExpectedCompensation,
    path: "/ExpectedCompensation",
  },
  Competencies: {
    component: Competencies,
    path: "/Competencies",
  },
  CandidateProfile: {
    component: CandidateProfile,
    path: "/CandidateProfile"
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      //   <View style={{ height: '100vh' }}>
      //     {/* <TopNav /> */}
      //     {/* {WebRoutesGenerator({ routeMap })}
      //     <ModalContainer /> */}
      //   </View>
      <RouteConfig></RouteConfig>
    );
  }
}

export default App;