import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
// Customizable Area Start
import { Formik } from 'formik'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import * as yup from "yup";
import { TextField } from '@material-ui/core';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

export default class Compensataion extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    compensationSchema = () => {
        return yup.object().shape({
            base: yup
                .number()
                .required("Base is required."),
            variable: yup
                .number()
                .required("Variable is required."),
            bonus: yup
                .number()
                .required("Bonus is required."),
            totalCashCompensation: yup
                .number()
                .required("Total Cash Compensation is required."),
            selectedStockValue: yup
                .number()
                .required("Stock Value is required."),
            vested: yup
                .number()
                .required("Vested is required."),
            unvested: yup
                .number()
                .required("Unvested is required."),
            exercisePrice: yup
                .number()
                .required("Exercise Price is required.")
        });
    }

    handleTextFieldArea = () => {
        let textarea: any = document.getElementById('text-area-compentencies');
        let limit = 250
        textarea.oninput = function () {
            textarea.style.height = "";
            textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
        };
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    base: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.base ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.base : "",
                                    variable: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.variable ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.variable : "",
                                    bonus: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.bonus ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.bonus : "",
                                    totalCashCompensation: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.total_cash_compensation ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.total_cash_compensation : "",
                                    selectedStockValue: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.stocks_value ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.stocks_value : "",
                                    vested: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.vested ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.vested : "",
                                    unvested: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.unvested ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.unvested : "",
                                    exercisePrice: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.exercise_price ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.exercise_price : "",
                                    additionalComments: this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.additional_comments ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.additional_comments : "",
                                }}
                                validationSchema={this.compensationSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    this.handleCompansation(values);
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <p className="heading-head">{window.location.href.includes('Edit-user') ? 'Edit Candidate' : 'Add New Candidate'}</p>
                                            <p className="heading"><b>Compensation</b></p>

                                            <div>
                                                <div className="row mt-1 mb-3">
                                                    <label className="my-label">Base</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            //label='Base'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.base}
                                                            name="base"
                                                            type="text"
                                                        />
                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')} styles={customStyles} options={this.state.currencyOptions} className="cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'baseCurrency')} />
                                                                {this.state.baseCurrencyError !== null && <div className="error">{this.state.baseCurrencyError}</div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ label: this.state.baseCurrencyValue.label, value: this.state.baseCurrencyValue.value }} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'baseCurrency')} />
                                                                {this.state.baseCurrencyError !== null && <div className="error">{this.state.baseCurrencyError}</div>
                                                                }
                                                            </>
                                                    }

                                                </div>
                                                <div className="row mt-1 mb-3">
                                                    <label className="my-label">Variable</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            // label='Variable'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.variable}
                                                            name="variable"
                                                            type="text"
                                                        />
                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'vairableCurrency')} />
                                                                {this.state.vairableCurrencyError !== null && <div className="error">{this.state.vairableCurrencyError}</div>}
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ label: this.state.variableCurrencyValue.label, value: this.state.variableCurrencyValue.value }} styles={customStyles} options={this.state.currencyOptions} className="cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'vairableCurrency')} />
                                                                {this.state.vairableCurrencyError !== null && <div className="error">{this.state.vairableCurrencyError}</div>}
                                                            </>
                                                    }

                                                </div>
                                                <div className="row  mt-1 mb-3">
                                                    <label className="my-label">Bonus</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            //  label='Bonus'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.bonus}
                                                            name="bonus"
                                                            type="text"
                                                        />

                                                        {errors.bonus && touched.bonus ? <div className="error">{errors.bonus}</div> : null}

                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')}
                                                                    styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'bonusCurrency')} />
                                                                {this.state.bonusCurrencyError !== null && <div className="error">{this.state.bonusCurrencyError}</div>}
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ label: this.state.baseCurrencyValue.label, value: this.state.baseCurrencyValue.value }}
                                                                    styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'bonusCurrency')} />
                                                                {this.state.bonusCurrencyError !== null && <div className="error">{this.state.bonusCurrencyError}</div>}
                                                            </>
                                                    }


                                                </div>
                                                <div className="row  mt-1 mb-3">
                                                    <label className="my-label">Total Cash Compensation</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            //  label='Total Cash Compensation'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.totalCashCompensation}
                                                            name="totalCashCompensation"
                                                            type="text"
                                                        />

                                                        {errors.totalCashCompensation && touched.totalCashCompensation ? <div className="error">{errors.totalCashCompensation}</div> : null}

                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'cashCurrency')} />
                                                                {this.state.cashCurrencyError !== null && <div className="error">{this.state.cashCurrencyError}</div>}
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ value: this.state.cashCurrencyValue.value, label: this.state.cashCurrencyValue.label }} styles={customStyles} options={this.state.currencyOptions} className="col-2" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'cashCurrency')} />
                                                                {this.state.cashCurrencyError !== null && <div className="error">{this.state.cashCurrencyError}</div>}
                                                            </>
                                                    }

                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Stock</label>
                                                    <Select styles={customStyles} options={this.state.stockOptions} placeholder={this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.stocks ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.stocks : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'stocks')} />
                                                    {this.state.stocksError !== null && <div className="error">{this.state.stocksError}</div>}

                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">{`Enter ${this.state.stocksValue?.value !== undefined ? this.state.stocksValue?.value : ""}`}</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label={}
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.selectedStockValue}
                                                        name="selectedStockValue"
                                                        type="text"
                                                    />

                                                    {errors.selectedStockValue && touched.selectedStockValue ? <div className="error">{errors.selectedStockValue}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Vested</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label='Vested'
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.vested}
                                                        name="vested"
                                                        type="text"
                                                    />

                                                    {errors.vested && touched.vested ? <div className="error">{errors.vested}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Unvested</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label='Unvested'
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.unvested}
                                                        name="unvested"
                                                        type="text"
                                                    />

                                                    {errors.unvested && touched.unvested ? <div className="error">{errors.unvested}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Vesting Schedule</label>
                                                    {/* <p></p> */}
                                                    <Select styles={customStyles} options={this.state.vestingOptions} placeholder={this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.vesting_schedule ? this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.vesting_schedule : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'vestingOptions')} />
                                                    {this.state.vestingOptionsError !== null && <div className="error">{this.state.vestingOptionsError}</div>}
                                                </div>

                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Exercise Price</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label='Exercise Price'
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.exercisePrice}
                                                        name="exercisePrice"
                                                        type="text"
                                                    />

                                                    {errors.exercisePrice && touched.exercisePrice ? <div className="error">{errors.exercisePrice}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Liquidation event at current org</label>
                                                    {/* <p>Liquidation event at current org</p> */}
                                                    <Select styles={customStyles} options={this.state.liqOptions} placeholder={this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.liquidation_event_at_current_org !== undefined ? (this.state.currentCandidateData?.attributes?.compensation?.data?.attributes?.liquidation_event_at_current_org === true ? "Yes" : "No") : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'liqEvent')} />
                                                    {this.state.liqEventError !== null && <div className="error">{this.state.liqEventError}</div>}
                                                </div>

                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Additional Comments</label>
                                                    <TextField
                                                        multiline
                                                        rows={5}
                                                        id="outlined-secondary"
                                                        // label="Additional Comments"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control input-field"
                                                        name="additionalComments"
                                                        value={values.additionalComments}
                                                    />
                                                    {/* <textarea style={{ height: '108px' }} value={values.additionalComments} onChange={(e) => { handleChange(e); this.handleTextFieldArea() }} className="form-control input-field" id="text-area-compentencies" name="additionalComments" placeholder="Additional Comments" /> */}
                                                    {errors.additionalComments && touched.additionalComments ? <div className="error">{errors.additionalComments}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-step row">
                                            <div className="row col-9">
                                                <Link to={window.location.href.includes('Edit-user') ? "/BasicDetails/Edit-user" : "/BasicDetails"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/DomainKnowledge/Edit-user" : "/DomainKnowledge"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/FamilyDetails/Edit-user" : "/FamilyDetails"} className="step-bar col-2"></Link>

                                            </div>
                                            <div className="row col-3">
                                                <button className="btn candidate-button" type="submit">Save And Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
