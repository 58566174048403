import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  currentUser: any;
  auditLogs: any
  viewLogModal: any
  selectedLog: any
  entityType: any
  empArray: any
  teamVisible: any
  changedBy: any
  description: any
  changeType: any
  date: any,
  filterAuditLogs: any
  teamVisibilityData: any
  viewCandidateModal: any
  selectedCandidate: any
  editCandidateRole: any
  editCandidateContact: any
  editCandidateReportingTo: any
  editCandidateEmail: any
  editCandidateTeam: any
  editCandidateDesignation: any
  editCandidateName: any
  downloadPreviewModal: any
  editCandidateProfilePicture: any
  isFilterStarted: any
  filterTeamVisibility: any
  loader: any
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AuditTrailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCurrentUserApiCallId: string = "";
  GetAuditApiCallId: string = "";
  GetTeamVisibilityApiCallId: string = "";
  GetPqEmployeeCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentUser: "",
      auditLogs: [],
      viewLogModal: false,
      selectedLog: "",
      entityType: { label: 'All', value: 'All' },
      empArray: [],
      changedBy: { label: 'All', value: 'All' },
      description: "",
      changeType: { label: 'All', value: 'All' },
      date: "",
      teamVisible: [],
      filterAuditLogs: [],
      teamVisibilityData: "",
      viewCandidateModal: false,
      selectedCandidate: "",
      editCandidateRole: "",
      editCandidateContact: "",
      editCandidateReportingTo: "",
      editCandidateEmail: "",
      editCandidateTeam: "",
      editCandidateDesignation: "",
      editCandidateName: "",
      editCandidateProfilePicture: "",
      downloadPreviewModal: false,
      isFilterStarted: false,
      filterTeamVisibility: [],
      loader: false
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            currentUser: responseJson?.data?.attributes,
          })
        }
        //get auditLogs
        if (apiRequestCallId === this.GetAuditApiCallId) {
          this.setState({
            auditLogs: responseJson?.data,
            filterAuditLogs: responseJson?.data,
            loader: false
          })
        }

        // get Team Data
        if (apiRequestCallId === this.GetTeamVisibilityApiCallId) {
          this.setState({
            teamVisible: responseJson,
            filterTeamVisibility: responseJson,
            loader: false
          })
        }

        //get auditLogs
        if (apiRequestCallId === this.GetPqEmployeeCallId) {
          this.setState({
            empArray: responseJson?.pq_employee?.map((x: any) => {
              return {
                label: `${x.first_name} ${x.last_name}`, value: `${x.first_name} ${x.last_name}`
              }
            })
          })
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getPqEmployee();
    await this.getCurrentUser();
    await this.getAuditLogs();
    await this.getTeamVisibility()

  }
  //getCurrentUser
  getCurrentUser = (): boolean => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCurrentUser
  getPqEmployee = (): boolean => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetPqEmployeeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidatesGetUrl}/get_pq_employee`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getAuditLogs
  getAuditLogs = (): boolean => {

    this.setState({
      loader: true
    })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAuditApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_log/logs"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getTeamVisibility = (): boolean => {

    this.setState({
      loader: true
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetTeamVisibilityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teamDetailsAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End

}
