Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.examplePUTMethod = "PUT";
exports.exampleAPiDeleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProposalGeneration2";
exports.labelBodyText = "ProposalGeneration2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user"
exports.currencyApiEndPoint = "bx_block_candidate/candidates/get_currency"
exports.createInvoice = "bx_block_invoice/invoice";
exports.postDocusign = 'bx_block_docusignintegration/share_agreement_to_docusign';
exports.putRequest = "PUT"
// Customizable Area End