import React from "react";
import ReactToPrint from "react-to-print";
import { linkdinIcon } from './assets';
import 'react-orgchart/index.css';
const confidentialityImage = require('../../../components/src/confidential-image.png');
const pq_logo = require('../../../components/src/purpleQuarter.svg');

export function PreviewCompanyPdf(closeModal, values, currencyOptions) {
  const options = {
    orientation: 'landscape',
    unit: 'in',
  };

  var convertType = function (value) {
    try {
      return (new Function("return " + value + ";"))();
    } catch (e) {
      return value;
    }
  }

  const interviewRounds = ['Exploratory Chat', 'Technical Discussion', 'Leadership Discussion',
    'Board Meeting'];

  return (
    <div style={{ margin: '0px' }}>
      <div onClick={closeModal()} className="btn btn-sm close-button" > Close</div>
      <div className="">
        <ReactToPrint
          trigger={() => <div className="btn btn-sm download-button" type="submit">Download
          </div>}
          // pageStyle='@page { margin: 9mm !important;} @media print { body { -webkit-print-color-adjust: exact; } }'
          content={() => {
            const tableStat = document.getElementById('preview-company-ref');
            const PrintElem = document.createElement('div');
            const footer =
              `<div class="page-footer"><img src="${pq_logo}" alt="purple quarter" class="pq-capsule-footer-pq"/></div>`;
            PrintElem.innerHTML = footer;
            PrintElem.appendChild(tableStat);
            return PrintElem;
          }}
        />
      </div>
      <div id="preview-company-ref" options={options} style={{ lineBreak: "auto" }}>
        {/*Cover Page */}
        <div className="page full-page" id="coverPageForm" style={{ backgroundColor: values.color }}>
          <div className="row" >
            <div className="row" style={{ marginTop: '80px' }}>
              <div className="col-4" style={{ textAlign: 'right' }}>
                <img src={values.logo} className="company-logo col-4" />
              </div>
              <div className="col-8" style={{ textAlign: 'left' }}>
                <h1 style={{ color: 'white', lineBreak: 'auto', marginTop: '35px' }}>{values.name}</h1>
              </div>
            </div>
            <div className="col-12">
              <h3 style={{ color: 'black', lineBreak: 'auto', textAlign: 'center' }}>{values.tagline}</h3>
            </div>
            <img className="row company-image" style={{ height: '400px' }} src={values.tagline_image} />
          </div>
        </div>

        {/* Snapshot */}
        <div className="page">
          <div className="col-12 half-page" style={{ backgroundColor: values.snapshot?.data?.attributes?.color }}>
            <h1 className="heading-previewCompany" style={{ color: convertType(values.snapshot?.data?.attributes?.heading_color) !== null && values.snapshot?.data?.attributes?.heading_color }}>{convertType(values.snapshot?.data?.attributes?.heading_name) !== null && values.snapshot?.data?.attributes?.heading_name !== "" ? values.snapshot?.data?.attributes?.heading_name : "Snapshot"}</h1>
            <div className="snapshot-table div-table" id="snapshotForm" >
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Founded in</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.founded_in}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Founders</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.founding_members.slice(0, 54)}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Headquarters</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.headquarters.slice(0, 54)}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Total Funding</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.total_funding}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Last Funding Round</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.last_funding_round}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Company Stage</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.company_stage}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>Valuation</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.valuation}</div>
              </div>
              <div className="div-table-row">
                <div className="div-table-col" style={{ width: '30%' }}><b>No Of Employees</b></div>
                <div className="div-table-col value-col" style={{ width: '70%' }}>{values.snapshot?.data?.attributes?.number_of_employees}</div>
              </div>
            </div>

          </div>
          {/* Funding  Round */}
          <div className="col-12 half-page" style={{ backgroundColor: values?.funding_color }}>
            <h1 className="heading-previewCompany" style={{ color: convertType(values.fundings?.data[0]?.attributes?.heading_color) !== null && values.fundings?.data[0]?.attributes?.heading_color }}>{convertType(values.fundings?.data[0]?.attributes?.heading_name) !== null && values.fundings?.data[0]?.attributes?.heading_name !== "" ? values.fundings?.data[0]?.attributes?.heading_name : "Funding Rounds"}</h1>
            <div className="snapshot-table div-table" >
              <div className="div-table-row" style={{ backgroundColor: 'navy', color: 'white' }}>
                <div className="div-table-col-funding" style={{ width: '15%' }}><b>Founding Date</b></div>
                <div className="div-table-col-funding" style={{ width: '20%' }}><b>Founding Amount</b></div>
                <div className="div-table-col-funding" style={{ width: '18%' }}><b>Founding Round</b></div>
                <div className="div-table-col-funding" style={{ width: '47%' }}><b>Investors</b></div>
              </div>
              {/* // funding_rounds */}
              {values?.fundings?.data.slice(0, 5).map((ele) => ele.attributes.funding_amount && <div className="div-table-row-funding">
                <div className="div-table-col-funding" style={{ width: '15%' }}>{ele.attributes.funding_date ? ele.attributes.funding_date : "-"}</div>
                <div className="div-table-col-funding value-col" style={{ width: '20%' }}>{ele.attributes.funding_amount + " " + (ele.attributes?.currency_id && currencyOptions?.filter((el) => el.value === ele.attributes?.currency_id)[0]?.label) || "$"}</div>
                <div className="div-table-col-funding" style={{ width: '18%' }}>{ele.attributes.funding_round ? ele.attributes.funding_round : "-"}</div>
                <div className="div-table-col-funding" style={{ width: '47%' }}>{ele.attributes.investor ? ele.attributes.investor.slice(0, 70) : "-"}</div>
                <br /></div>)}
            </div>
          </div>
        </div>

        {/* Founders*/}
        <div className="page full-page" style={{ backgroundColor: values?.founder_color, alignItems: 'center' }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values.founders?.data[0]?.attributes?.heading_color) !== null && values.founders?.data[0]?.attributes?.heading_color }}>{convertType(values.founders?.data[0]?.attributes?.heading_name) !== null && values.founders?.data[0]?.attributes?.heading_name !== "" ? values.founders?.data[0]?.attributes?.heading_name : "Founders"}</h1>
          {values.founders?.data?.slice(0, 5).map((ele) =>
            ele.attributes?.name && <div key={ele.id} className="row founder-card">
              <div className="col-4">

                <img src={ele.attributes?.founder_image || require('../../../components/src/img_avatar.png')} className="linkedIn-image" />
                <br />
                <div style={{ backgroundColor: 'gainsboro', marginLeft: '-17px', width: '175px', padding: '1px', marginTop: "3px" }}>
                  <a target="_blank" style={{ margin: '25%' }} href={ele.attributes?.linkedin_profile_link}>
                    <img style={{ marginLeft: '25px' }} src={linkdinIcon} /></a>
                </div>
              </div>
              <div className="col-8">
                <div style={{
                  marginLeft: '-14%',
                  marginTop: '10px'
                }}>
                  <div><h4 style={{ display: 'contents' }}>{ele.attributes?.name}</h4> - {ele.attributes?.designation} </div>
                  <p>{ele.attributes?.background.length > 300 ? `${ele.attributes?.background.slice(0, 300)}..` : ele.attributes?.background}</p>
                </div>
              </div>
            </div>)}
        </div>

        {/* Vision */}
        <div className="page">
          <div className="oneThird-page">
            <h1 className="heading-previewCompany" style={{ color: convertType(values?.vision_heading_color) !== null && values?.vision_heading_color }}>{convertType(values?.vision_heading_name) !== null && values?.vision_heading_name !== "" ? values?.vision_heading_name : "Vision"}</h1>
            <div className="founder-card row">
              <div className="col-8">
                <div style={{ height: '170px', overflow: 'hidden', padding: '30px' }} dangerouslySetInnerHTML={{ __html: values?.vision }} />
              </div>
              <div className="col-4">
                {/* <img src={values.visionImage}/> */}
                <img src={values.vision_image} className="vision-page-images" />
              </div>
            </div>
          </div>

          {/* Mission */}
          <div className="oneThird-page">
            <h1 className="heading-previewCompany" style={{ color: values?.mission_heading_color !== null && values?.mission_heading_color }}>{values?.mission_heading_name !== null && values?.mission_heading_name !== "" ? values?.mission_heading_name : "Mission"}</h1>
            <div className="row founder-card">
              <div className="col-4">
                {/* <img src={values?.companyVisionText?.toString("html")}/> */}
                <img src={values.mission_image} className="vision-page-images" />
              </div>
              <div className="col-8" >
                <div style={{ height: '170px', overflow: 'hidden', padding: '30px' }} dangerouslySetInnerHTML={{ __html: values.mission }} />
              </div>
            </div>
          </div>

          {/*Problem statement  */}
          <div className="oneThird-page" style={{ backgroundColor: values.problem_statement_color, overflow: 'hidden' }}>
            <h1 className="heading-previewCompany" style={{ color: values?.problem_statement_color_heading_color !== null && values?.problem_statement_color_heading_color }}>{values?.problem_statement_color_heading_name !== null && values?.problem_statement_color_heading_name !== "" ? values?.problem_statement_color_heading_name : "Problem Statement"}</h1>
            <div className="col-12">
              <div style={{ padding: '12px' }} dangerouslySetInnerHTML={{ __html: values?.problem_statement }} />
            </div>
            <div className="col-12" >
              {/* <img src={values.problemStatmentImage} /> */}
              <img style={{ height: '150px' }} src={values.problem_statement_image} className="vision-page-images" />
            </div>

          </div>
        </div>

        {/*Over view*/}
        <div className="page full-page" style={{ backgroundColor: values.overview_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.overview_color_heading_color) !== null && values?.overview_color_heading_color }}>{convertType(values?.overview_color_heading_name) !== null && values?.overview_color_heading_name !== "" ? values?.overview_color_heading_name : "Overview"}</h1>
          <div style={{ padding: '20px' }} dangerouslySetInnerHTML={{ __html: values?.overview?.toString("html").slice(0, 500) }} />
          {/* overViewImage */}
          <img style={{ height: '290px', overflow: 'hidden' }} src={values.overview_image} className="vision-page-images" />
          <p style={{ height: '300px', overflow: 'hidden', fontSize: "inherit" }} dangerouslySetInnerHTML={{ __html: values?.overview?.slice(501, 1200) }} />
        </div>

        {/* Products*/}
        <div className="page full-page" style={{ backgroundColor: values.pq_capsule_product_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.pq_capsule_products?.data[0]?.attributes.heading_color) !== null && values?.pq_capsule_products?.data[0]?.attributes.heading_color }}>{convertType(values?.pq_capsule_products?.data[0]?.attributes.heading_name) !== null && values?.pq_capsule_products?.data[0]?.attributes.heading_name !== "" ? values?.pq_capsule_products?.data[0]?.attributes.heading_name : "Products"}</h1>
          <div className="row">
            {values.pq_capsule_products?.data?.slice(0, 5).map((ele) => ele.attributes?.name &&
              <div className="product-cards col-4">
                {/* productBackgroundImage */}
                <div style={{
                  backgroundColor: '#f1f1f1',
                }}>
                  <img src={ele.attributes?.pq_capsule_product_image} className="product-images" />
                </div>
                <div style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px' }}>{ele.attributes?.name}</div>
                <div dangerouslySetInnerHTML={{ __html: ele.attributes?.product_description }} />
              </div>
            )}
          </div>
        </div>

        {/* key Metrics*/}
        <div className="page full-page" style={{ backgroundColor: values.key_metric_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.key_metrics?.data[0]?.attributes.heading_color) !== null && values?.key_metrics?.data[0]?.attributes.heading_color }}>{convertType(values?.key_metrics?.data[0]?.attributes.heading_name) !== null && values?.key_metrics?.data[0]?.attributes.heading_name !== "" ? values?.key_metrics?.data[0]?.attributes.heading_name : "Key Metrics"}</h1>
          <div className="row">
            {values.key_metrics?.data?.slice(0, 12).map((ele) => (ele.attributes?.key_metrics.length > 1) && <div key={ele} className="col-4">
              <div className style={{
                backgroundColor: ele.attributes?.back_color,
                padding: '10px',
                boxShadow: "0px 2px 2px rgb(0 0 0 / 10%) ",
                margin: '3px'
              }}>
                <div className="key-matrix-card" dangerouslySetInnerHTML={{ __html: ele.attributes?.key_metrics }} />
              </div>
            </div>)}
            <br />
            <br />
            <img style={{ height: '550px', overflow: 'hidden', marginTop: '30px' }} src={values.key_metric_image} className="vision-page-images" />
          </div>
        </div>

        {/* Business Roadmap*/}
        <div className="page full-page" style={{ backgroundColor: values.business_roadmap_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.business_roadmap_color_heading_color) !== null && values?.business_roadmap_color_heading_color }}>{convertType(values?.business_roadmap_color_heading_name) !== null && values?.business_roadmap_color_heading_name !== "" ? values?.business_roadmap_color_heading_name : "Business Roadmap"}</h1>

          <div className="col-12">
            {/* bussinessroadBackgroundImage */}
            <div style={{ height: '600px', overflow: 'hidden', padding: '20px' }} dangerouslySetInnerHTML={{ __html: values?.business_roadmap }} />
          </div>
          <img style={{ height: "300px", padding: '20px', overflow: 'hidden', width: '100%' }} src={values.business_roadmap_image} className="" />
        </div>

        {/* page - 9 TEch Stack/landscape*/}
        <div className="page">
          {/* Tech stack */}
          <div className="oneThird-page" style={{ backgroundColor: values.tech_stack_color }}>
            <h1 className="heading-previewCompany" style={{ color: convertType(values?.tech_stack_color_heading_color) !== null && values?.tech_stack_color_heading_color }}>{convertType(values?.tech_stack_color_heading_name) !== null && values?.tech_stack_color_heading_name !== "" ? values?.tech_stack_color_heading_name : "Techstack"}</h1>
            <img style={{ height: '250px', padding: '20px' }} src={values.tech_stack_image} className="vision-page-images" />
          </div>

          {/* Tech Landscape */}
          <div className="oneThird-page" style={{ backgroundColor: values.tech_landscape_color }}>
            <h1 className="heading-previewCompany" style={{ color: values?.tech_landscape_color_heading_color !== null && values?.tech_landscape_color_heading_color }}>{values?.tech_landscape_color_heading_name !== null && values?.tech_landscape_color_heading_name !== "" ? values?.tech_landscape_color_heading_name : "Tech Landscape"}</h1>
            <div className="row" style={{ padding: '20px' }}>
              <div className="col-4">
                <img src={values.tech_landscape_image} className="vision-page-images" />
              </div>
              <div className="col-8">
                <div style={{ height: '170px', overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: values?.tech_landscape }} />
              </div>
            </div>
          </div>

          {/* Tech Challenges */}
          <div className="oneThird-page" style={{ backgroundColor: values.tech_challenges_color }}>
            <h1 className="heading-previewCompany" style={{ color: values?.tech_challenges_color_heading_color !== null && values?.tech_challenges_color_heading_color }}>{values?.tech_challenges_color_heading_name !== null && values?.tech_challenges_color_heading_name !== "" ? values?.tech_challenges_color_heading_name : "Tech Challenges"}</h1>
            <div className="row" style={{ padding: '20px' }}>
              <div className="col-8">
                <div style={{ height: '170px', overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: values?.tech_challenges }} />
              </div>
              <div className="col-4">
                <img style={{ widht: '200px' }} src={values?.tech_challenge_image} className="vision-page-images" />
              </div>
            </div>
          </div>

        </div>

        {/* page -10, org/roadmap */}
        <div className="page">
          {/* Tech RoadMap */}
          <div className="full-page" style={{ backgroundColor: values.tech_roadmap_color }}>
            <h1 className="heading-previewCompany" style={{ color: convertType(values?.tech_roadmap_heading_color) !== null && values?.tech_roadmap_heading_color }}>{convertType(values?.tech_roadmap_heading_name) !== null && values?.tech_roadmap_heading_name !== "" ? values?.tech_roadmap_heading_name : "Tech Roadmap"}</h1>
            <div className="col-12">
              <div style={{ height: '500px', overflow: 'hidden', padding: '20px' }} dangerouslySetInnerHTML={{ __html: values?.tech_roadmap }} />
            </div>
            <div className="col-12" >
              <img style={{ height: '200px', overflow: "hidden" }} src={values.tech_roadmap_image} className="roadmap-page-images" />
            </div>
          </div>
        </div>
        {/* Tech Org */}

        <div className="page full-page" style={{ backgroundColor: values.tech_org_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.tech_org_heading_color) !== null && values?.tech_org_heading_color }}>{convertType(values?.tech_org_heading_name) !== null && values?.tech_org_heading_name !== "" ? values?.tech_org_heading_name : "Tech Org"}</h1>
          <div className="col-12">
            <div style={{ height: '325px', overflow: 'hidden', padding: '20px' }} dangerouslySetInnerHTML={{ __html: values?.tech_org }} />
          </div>

          {/* Hierarcy */}
          <br />
          <div className=" heading-hierarcy">Roles Hierarchy</div>
          <div className="row" style={{ textAlign: 'center' }}>
            {/* <span className="col-2 heading-hierarcy">Level 1</span> */}
            <div className="col-12 hiearcy-balance" >
              {values?.hierarchies?.data[0]?.attributes?.hierarchy_members?.data?.map(ele => (<span>
                <span className="heirarcy-list">
                  <b>{ele.attributes.name.length < 10 ? ele.attributes.name : `${ele.attributes.name.slice(0, 9)}..`}</b>
                  <br />
                  {ele.attributes.position.length < 10 ? ele.attributes.position : `${ele.attributes.position.slice(0, 9)}..`}
                </span>
                <span className='heirarch-bottom'>|</span></span>))}
              <br />
            </div>

            <hr className="heirarcy-line" />

            {/* <span className="col-2 heading-hierarcy">Level 2</span> */}
            <div className="col-12 hiearcy-balance">
              {values?.hierarchies?.data[1]?.attributes?.hierarchy_members?.data?.map(ele => (
                <span>
                  {/* <span className='heirarch-top-list'>------------------</span> */}
                  <span className='heirarch-top'>|</span><span className="heirarcy-list"> <b className="user-name-hierarcy">{ele.attributes.name.length < 10 ? ele.attributes.name : `${ele.attributes.name.slice(0, 9)}..`}</b><br /> {ele.attributes.position.length < 10 ? ele.attributes.position : `${ele.attributes.position.slice(0, 9)}..`}</span><span className='heirarch-bottom'>|</span></span>))}
              <br />
            </div>

            <hr className="heirarcy-line" />

            {/* <span className="col-2 heading-hierarcy">Level 3</span> */}
            <div className="col-12 hiearcy-balance">
              {values?.hierarchies?.data[2]?.attributes?.hierarchy_members?.data?.map(ele => (<span>
                {/* <span className='heirarch-top-list'>------------------</span> */}
                <span className='heirarch-top'>|</span><span className="heirarcy-list"><b>{ele.attributes.name.length < 10 ? ele.attributes.name : `${ele.attributes.name.slice(0, 9)}..`}</b><br /> {ele.attributes.position.length < 10 ? ele.attributes.position : `${ele.attributes.position.slice(0, 9)}..`}</span><span className='heirarch-bottom'>|</span></span>))}
              <br />
            </div>

            <hr className="heirarcy-line" />

            {/* <span className="col-2 heading-hierarcy">Level 4</span> */}
            <div className="col-12 hiearcy-balance">
              {values?.hierarchies?.data[3]?.attributes?.hierarchy_members?.data?.map(ele => (<span>
                {/* <span className='heirarch-top-list'>------------------</span> */}
                <span className='heirarch-top'>|</span><span className="heirarcy-list"><b>{ele.attributes.name.length < 10 ? ele.attributes.name : `${ele.attributes.name.slice(0, 9)}..`}</b><br /> {ele.attributes.position.length < 10 ? ele.attributes.position : `${ele.attributes.position.slice(0, 9)}..`}</span></span>))}
              <br />
            </div>
          </div>

        </div>

        {/* Current Mandate & Expectation , page - 10*/}
        {values.current_mandate_expectations?.data?.slice(0, 5).map((ele) =>
          <div className="page full-page" style={{ backgroundColor: ele.attributes?.color }}>
            <h1 className="heading-previewCompany" style={{ color: convertType(ele.attributes?.heading_color) !== null && ele.attributes?.heading_color }}>{convertType(ele.attributes?.heading_name) !== null && ele.attributes?.heading_name !== "" ? ele.attributes?.heading_name : "Current Mandate & Expectations"}</h1>
            <div className="col-12" style={{ padding: '20px' }}>
              <div>
                <b>Designation - </b>{ele.attributes?.designation}<br />
                <b>Reporting to - </b>{ele.attributes?.reporting_to}<br />
                <b>Location - </b>{ele.attributes?.location}
              </div>
              <div style={{ height: '850px', overflow: 'hidden', marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: ele.attributes?.expectation }} />
            </div>
          </div>)}

        {/* Inteview panel  page -11*/}
        <div className="page full-page" style={{ backgroundColor: values.interview_panel_color }}>
          <h1 className="heading-previewCompany" style={{ padding: '0px !important', color: convertType(values.interview_panels?.data[0]?.attributes?.heading_color) !== null && values.interview_panels?.data[0]?.attributes?.heading_color }}>{convertType(values.interview_panels?.data[0]?.attributes?.heading_name) !== null && values.interview_panels?.data[0]?.attributes?.heading_name !== "" ? values.interview_panels?.data[0]?.attributes?.heading_name : "Interview Panel"}</h1>
          {/* here the rounds will come: values.interViewRoundName */}
          <div className="row">
            {/* interviewRounds */}
            {interviewRounds.map((round, index) => <div>
              <h5 className="interview-round-index">{index + 1}</h5>
              {values?.interview_panels?.data?.slice(0, 5).map((ele) => ele.attributes?.round_name === round && <div key={ele.id} className="interview-panel-cards">
                <div style={{ textAlign: 'center' }}>
                  <img src={ele.attributes?.interview_panel_image || require('../../../components/src/img_avatar.png')} className="interview-image" />
                </div>
                <p style={{ textAlign: 'center', marginBottom: '0', marginTop: '-5px' }}><b>{ele.attributes?.name}</b></p>


                {/* <p style={{ textAlign: 'center' }}>{ele.attributes?.round_name}</p> */}
                <div style={{ textAlign: 'center' }}>
                  <a href={ele?.attributes.linkedin_profile_link} target="_blank" ><img src={linkdinIcon} /></a>
                </div>
              </div>)} </div>

            )}

          </div>
        </div>

        {/* Culture and DNA  page -12*/}
        <div className="page full-page" style={{ backgroundColor: values.culture_dna_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.culture_dna_color_heading_color) !== null && values?.culture_dna_color_heading_color }}>{convertType(values?.culture_dna_color_heading_name) !== null && values?.culture_dna_color_heading_name !== "" ? values?.culture_dna_color_heading_name : "Culture & DNA"}</h1>
          <div className="col-12" >
            <div style={{ height: '590px', overflow: 'hidden', padding: '20px' }} dangerouslySetInnerHTML={{ __html: values?.culture_dna }} />
          </div>
          <div>
            {/* <img src={cultureBackgroundImage}/> */}
            <img style={{ height: '400px', overflow: 'hidden' }} src={values.culture_dna_image} className="vision-page-images" />
          </div>
        </div>

        {/* Confidentiality page -13*/}
        <div className="page full-page" style={{ backgroundColor: values.confidentiality_color }}>
          <h1 className="heading-previewCompany" style={{ color: convertType(values?.confidentiality_color_heading_color) !== null && values?.confidentiality_color_heading_color }}>{convertType(values?.confidentiality_color_heading_name) !== null && values?.confidentiality_color_heading_name !== "" ? values?.confidentiality_color_heading_name : "Confidentiality"}</h1>
          <div className="col-12" style={{ height: '1000px', padding: '50px' }}>
            {values.confidentiality}
            {/* <div dangerouslySetInnerHTML={{ __html: values?.confindentialText?.toString("html")?.slice(0,50)}}/> */}
            <img style={{ height: '600px', overflow: 'hidden', marginTop: '300px', width: '100%' }} src={confidentialityImage} />

          </div>
        </div>
      </div>

    </div >
  );
}

