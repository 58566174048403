import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import CustomFormController, {
  Props,
  configJSON
} from "./CustomFormController";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import './styles.css'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import KanbanBoard from "../../KanbanBoard/src/KanbanBoard.web";
import AgreementSection from "../../ProposalGeneration2/src/ProposalGeneration2.web";
import PqcapsuleSection from "../../TeamBuilder/src/TeamBuilder.web";
export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  openDropdownModal = (id: number) => {
    this.setState({
      ismodalOpenId: this.state.ismodalOpenId === id ? null : id
    })
  }

  openDropDownModalUI = (id: any) => {
    return <ul className="ellipsis-dropdown-ul">
      <li className="ellipsis-dropdown-li" onClick={() => {
        this.setState({
          viewCompany: true,
          viewCompanyId: id
        })
        this.getSingleCompanyData(id)
      }}>View Profile</li>
      {/* <li className="ellipsis-dropdown-li">Edit Profile</li> */}
    </ul>
  }

  getFoundersName = (ele: any, type?: any) => {
    let str = "";
    ele ? ele?.map((ele: any) => str = str + ele + ', ') : "";
    str = str?.slice(0, str.length - 2);
    return str.length > (type === 'roles' ? 15 : 45) ? `${str.slice(0, (type === 'roles' ? 15 : 45))}...` : str;
  }

  handleSelect = (selectedOption: any, type: any) => {
    // @ts-ignore
    this.setState({ [type]: selectedOption.value })
  }

  filterSelectedCompany = () => {
    this.setState({ isfilterApplied: true });
    let filteredCompanyData = [];
    filteredCompanyData = this.state.allCompanies.filter((ele: any) => (ele.attributes.domain === this.state.addCompanyDomain) || (ele.attributes.role.includes(this.state.addCompanyRole)) || (ele.attributes.status === this.state.addCompanyStatus));
    this.setState({ filteredCompanyData: filteredCompanyData });
  }

  viewCompanies = (listOfDomain: any, listOfRoles: any) => {

    return <>
      <nav className="navbar3 navbar-expand-lg">
        <div className="row" style={{ lineBreak: "auto" }}>
          <div className="col-2">
            <label className="text-label-navbar">Domain</label>
            <Select
              options={listOfDomain}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                this.handleSelect(selectedOption, 'addCompanyDomain')
              }}
              value={this.state?.addCompanyDomain?.value}
              name="addCompanyDomain"
              className="listOfDomain"
              styles={customStyles}
            />
          </div>
          <div className="col-2">
            <label className="text-label-navbar">Status</label>
            <Select
              options={[
                { label: "Inactive", value: "Inactive" },
                { label: "Active", value: "Active" }
              ]}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                this.handleSelect(selectedOption, 'addCompanyStatus')
              }}
              name="addCompanyStatus"
              value={this.state?.addCompanyStatus?.value}

              styles={customStyles}
            />
          </div>
          <div className="col-2">
            <label className="text-label-navbar">Role</label>
            <Select
              options={listOfRoles}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                this.handleSelect(selectedOption, 'addCompanyRole')
              }}
              name="addCompanyRole"
              styles={customStyles}
              value={this.state?.addCompanyRole?.value}

            />
          </div>
          <div className="col-1">
            <button type="button" style={{ marginTop: '33px' }} onClick={() => this.filterSelectedCompany()} className="candidate-button btn">
              Apply
            </button>
          </div>
          {this.state.isfilterApplied && <div className="col-1">
            <button
              type="button"
              style={{ marginLeft: '-15px', marginTop: '33px' }}
              onClick={() =>
                this.setState({ isfilterApplied: false, addCompanyDomain: { value: '', label: '' }, addCompanyRole: { value: '', label: '' }, addCompanyStatus: { value: '', label: '' } })}

              className="candidate-button btn"
            >
              X
            </button>
          </div>}


          {/* <div className="col-2">
            <button type="button" className="btn" style={{ color: '#b006ae', marginTop: '29px', fontWeight: 500 }}
              onClick={() => this.setState({
                searchFilter: true
              })}
            >
              + More Filters
            </button>
          </div> */}
        </div>
      </nav>

      {/* All companies */}
      <div className="mb-4">
        <div className="p-3">
          <div style={{ height: '578px', overflowX: 'hidden', overflowY: 'auto' }}>
            <div className="row dashboard-candidate" style={{ fontSize: '12px', padding: '5px' }} >
              {
                this.state.allCompanies.length === 0 ?

                  <div className="loader">
                    {
                      this.state.loader ?
                        <CircularProgress />
                        :
                        "No Data Found"

                    }
                  </div>
                  :

                  (this.state.isfilterApplied ? this.state.filteredCompanyData : this.state.allCompanies && this.state.allCompanies)?.map((x: any) =>
                    <>
                      <div className="view-mask-companiescard2 m-2" style={{ cursor: 'pointer' }} >
                        <div className="card_background" onClick={() => {
                          this.setState({
                            viewCompany: true,
                            viewCompanyId: x.id
                          })
                          this.getSingleCompanyData(x.id)
                        }} />
                        <div className="row" style={{ 'marginTop': '12px' }} onClick={() => {
                          this.setState({
                            viewCompany: true,
                            viewCompanyId: x.id
                          })
                          this.getSingleCompanyData(x.id)
                        }} >
                          <div className="col-10" onClick={() => {
                            this.setState({
                              viewCompany: true,
                              viewCompanyId: x.id
                            })
                            this.getSingleCompanyData(x.id)
                          }}  >
                            <h6>{x?.attributes?.company_name}</h6>
                          </div>
                        </div>
                        <div>
                          <div style={{ height: '138px' }} onClick={() => {
                            this.setState({
                              viewCompany: true,
                              viewCompanyId: x.id
                            })
                            this.getSingleCompanyData(x.id)
                          }} >
                            <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Founder - &nbsp;</label>{this.getFoundersName(x?.attributes?.founder)}</p>
                            <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Deck Approval Date - &nbsp;</label>{x?.attributes?.deck_approval_date}</p>
                            <div className="row">
                              <p title={x?.attributes?.domain} className="col-6 mt-2 mb-1"><label style={{ color: 'grey' }}>Domain - &nbsp;</label>{x?.attributes?.domain.length > 12 ? `${x?.attributes?.domain.slice(0, 10)}...` : x?.attributes?.domain}</p>
                              <p className="col-6 mt-2 mb-1"><label style={{ color: 'grey' }}>Agreement - &nbsp;</label>{x?.attributes?.status === 'Active' ? 'Signed' : 'Not Signed'}</p>
                            </div>
                            <div className="row">
                              <p className="col-6 mt-2 mb-1" title={this.getFoundersName(x.attributes.role, 'roles')}><label style={{ color: 'grey' }}>Role -  &nbsp;</label>{this.getFoundersName(x.attributes.role, 'roles')}</p>
                              <p className="col-6 mt-2 mb-1"><label style={{ color: 'grey' }}>Open Positions - &nbsp;</label>{x?.attributes?.open_position}</p>
                            </div>
                          </div>
                          <div className="row" style={{ marginLeft: '-10px', marginTop: '22px' }}>
                            <button title={x?.attributes?.status} className={x?.attributes?.status !== "Inactive" ? "col-3 btn btn-profile-active" : "col-3 btn btn-profile-inactive"}>{x?.attributes?.status}</button>

                            <button
                              className="col-3 btn btn-profile-invoice"
                              // onClick={() => {
                              //   this.setState({
                              //     viewCompany: true,
                              //     viewCompanyId: x.id,
                              //     companyMenuType: 'inovices'
                              //   })
                              //   this.getSingleCompanyData(x.id)

                              // }}

                              onClick={() => window.location.replace(`/newclient?companyId=${x.id}`)}
                            >
                              {x?.attributes?.raise_invoice || 'No Invoice'}
                            </button>


                            {
                              x?.attributes?.pq_capsule === "true" && (
                                <button title={"Purple Quarter Capsule"} className="col-3 btn btn-profile-capsule" onClick={() => {
                                  this.setState({
                                    viewCompany: true,
                                    viewCompanyId: x.id,
                                    companyMenuType: 'pqCapsule'
                                  })
                                  this.getSingleCompanyData(x.id)

                                }}>Purple Quarter Capsule</button>
                              )
                            }
                          </div>
                        </div>
                      </div>

                    </>
                  )
              }
            </div>

          </div>
        </div>
      </div>
    </>
  }

  viewCompanyProfile = () => {
    return <>
      <div className="mt-4 mb-5 row">
        <div className="canidate-profile-view-page">
          <div className="col-9" style={{ width: '90%', marginLeft: '-7%' }}>
            <div className="company-profile-view-rectangle">
              <u><h5 style={{ textAlign: 'center' }}>{this.state.singleCompanyData?.attributes?.company_name}</h5></u>
              <div style={{}}>
                <div className="row mt-1 mb-3">
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Founder : &nbsp;</label> {this.getFoundersName(this.state?.singleCompanyData?.attributes?.founder)}</p>
                  </div>
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Founded In : &nbsp;</label> {this.state.singleCompanyData?.attributes?.founded_in}</p>
                  </div>
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Revenue : &nbsp;</label> {this.state.singleCompanyData?.attributes?.funding}</p>
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Domain : &nbsp;</label> {this.state.singleCompanyData?.attributes?.domain}</p>
                  </div>
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Location : &nbsp;</label> {this.state.singleCompanyData?.attributes?.location[0]?.location}</p>
                  </div>
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Employees : &nbsp;</label> {this.state.singleCompanyData?.attributes?.employees}</p>
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-8">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Address : &nbsp;</label>{this.state.singleCompanyData?.attributes?.address}</p>
                  </div>
                  <div className="col-4">
                    <p className="mt-2 mb-1"><label style={{ color: 'grey' }}>Official Website : &nbsp;</label>{this.state.singleCompanyData?.attributes?.official_website}</p>
                  </div>
                </div>
              </div>
              <hr style={{ 'opacity': '0.1', backgroundColor: '#8f92a1' }} />
              <div className="company-sub-menu">
                <div className="row col-12" style={{ textAlign: 'center' }}>
                  <div className={this.state.companyMenuType === "agreements" ? "col-3 company-menu-active" : 'col-3'} onClick={() => this.handleCompanyMenu('agreements')}>
                    Agreements
                  </div>
                  <div className={this.state.companyMenuType === "inovices" ? "col-3 company-menu-active" : 'col-3'} onClick={() => this.handleCompanyMenu('inovices')}>
                    Invoicing
                  </div>
                  <div className={this.state.companyMenuType === "pqCapsule" ? "col-3 company-menu-active" : 'col-3'} onClick={() => this.handleCompanyMenu('pqCapsule')}>
                    Purple Quarter Capsule
                  </div>
                  <div className={this.state.companyMenuType === "jobs" ? "col-3 company-menu-active" : 'col-3'} onClick={() => this.handleCompanyMenu('jobs')}>
                    Jobs
                  </div>
                </div>
              </div>
            </div>
            <div className="company-page-sections" style={{ width: this.state.companyMenuType === "pqCapsule" ? '126%' : '' }}>
              {
                // @ts-ignore
                this.state.companyMenuType === "agreements" && <AgreementSection presentcompanyName={this.state.singleCompanyData?.attributes?.company_name} companyAgreementSection={true} />}
              {/* @ts-ignore */}
              {this.state.companyMenuType === "jobs" && <KanbanBoard companySection={true} presentcompanyName={this.state.singleCompanyData?.attributes?.company_name} />
              }
              {/* @ts-ignore */}
              {this.state.companyMenuType === "inovices" && <AgreementSection presentcompanyName={this.state.singleCompanyData?.attributes?.company_name} menuType={this.state.companyMenuType} companyAgreementSection={true} />
              }
              {
                // @ts-ignore
                this.state.companyMenuType === "pqCapsule" && <PqcapsuleSection companySection={true} presentcompanyName={this.state.singleCompanyData?.attributes?.company_name} />
              }
            </div>

          </div>
        </div>
      </div>
    </>
  }

  addCompanyfilter = (filters: any) => {
    this.setState({
      filterData: filters
    });
  }

  handleSearch = (text: any) => {

    this.setState({
      isfilterApplied: true,
      filteredCompanyData: this.state.allCompanies?.filter((ele: any) => {
        return ele.attributes?.domain?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.company_name?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.status?.toLowerCase().includes(text?.toLowerCase()) || ele.attributes?.raise_invoice?.toLowerCase().includes(text?.toLowerCase())
      })
    })

  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div className="wrapper">
        <SideNav data={this.state.currentUser} listOfRoles={this.state.listOfRoles.map((item: any) => item.label)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)} listOfDomain={this.state.listOfDomain} searchFilter={this.state.searchFilter} handleFilter={() => this.setState({
            searchFilter: false
          })}
          filterData={(value: any) => this.addCompanyfilter(value)} />
        <div className="kanban-content companies-content" >
          <TopNav data={this.state.currentUser}
          // handleSearchText={(text: any) => this.handleSearch(text)}
          />

          {/* Static Graph part */}
          {
            !this.state.viewCompany ?
              this.viewCompanies(this.state.listOfDomain, this.state.listOfRoles)
              :
              this.viewCompanyProfile()
          }
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    height: '44px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
// Customizable Area End
