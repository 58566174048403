import React from "react";
// Customizable Area Start
import moment from 'moment'
import Modal from "react-modal";
const { PreviewResume } = require("./previewResume");
import { Formik } from 'formik'
import Select from 'react-select'
import { TextField } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from 'react-router-dom'
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";

export default class ProfileInfo extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.getTimeLine()
        this.getCandidateDocument()
        // Customizable Area End
    }
    // Customizable Area Start
    userId = new URLSearchParams(window.location.search).get('id');
    getCurrencyType = (currency_id: number) => {
        return this.state.currencyOptions[currency_id - 1]?.label;
    }

    updateResumePreview = () => {
        this.setState({ isPreviewResume: !this.state?.isPreviewResume })
    }

    generateResumePdf = () => {
        return <Modal
            isOpen={this.state?.isPreviewResume}
            contentLabel=""
            className=""
            style={{
                overlay: {
                    backgroundColor: 'rgb(49 42 42 / 90%)'
                },
                content: {
                    color: 'Black'
                }
            }}
        >
            {PreviewResume(() => this.updateResumePreview, this.state?.resumeDetails)}
        </Modal >
    }

    handleTextFieldArea = (selectedComponent: any) => {
        let textarea: any = document.getElementById(selectedComponent);
        let limit = 150
        textarea.oninput = function () {
            textarea.style.height = "";
            textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
        };
    }

    openDropDownModalUI = () => {
        return (
            <ul className="ellipsis-dropdown-ul-view" id="view-modal-dropdown">
                <li className="ellipsis-dropdown-li-view" onClick={() => this.setState({ domainModal: true })}>View</li>
            </ul>
        )
    }
    viewFile = (data: any) => {
        const link = document.createElement('a');
        link.href = data.file !== null && data.file || data.image !== null && data.image
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    // Customizable Area End

    render() {
        const assets = require("./assets");
        var menuType = "basicDetails"

        return (
            // Customizable Area Start
            <div className="wrapper">
                <SideNav data={this.state.currentUser} />
                <div className="content">
                    <TopNav data={this.state.currentUser} />
                    {this.generateResumePdf()}
                    <div className="mt-4 mb-5 row">

                        {
                            this.state.loaderStatus || this.state.candidatesData?.filter((y: any) => { return y.id === this.userId }).length === 0 ?
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                                :
                                this.state.candidatesData?.filter((y: any) => { return y.id === this.userId }).map((x: any) => {
                                    return (
                                        <div className="canidate-profile-view-page">
                                            <div className="col-9" style={{ width: '90%', marginLeft: '-7%' }}>
                                                <img src={assets.imageCover} className="view-image-reactangle" />
                                                <div className="view-rectangle2">
                                                    <div className="profile-image-backgorund">
                                                        <img src={x?.attributes.profile_picture || require('../../../components/src/img_avatar.png')} className="profile-image" />
                                                    </div>
                                                    <div className="mt-3" style={{ textAlign: 'center' }}>
                                                        <label className="profile-heading">{x.attributes?.full_name}</label>
                                                        <p className="profile-sub-heading">{x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.designation} at {x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.company_name} </p>
                                                        <p className="profile-heading-categeory">{x.attributes?.domain_knowledge?.data?.attributes?.candidate_type}</p>
                                                    </div>
                                                    <hr style={{ 'opacity': '0.1', backgroundColor: '#8f92a1' }} />
                                                    <div className="sub-menu">
                                                        <div className="row col-12">
                                                            <div className={this.state.profileMenuType === "basicDetails" ? "col-2 profile-menu-active" : 'col-2 profile-menu'} onClick={() => this.handleProfileMenu('basicDetails')}>
                                                                Basic Details
                                                            </div>
                                                            <div className={this.state.profileMenuType === "competencies" ? "col-2 profile-menu-active" : 'col-2 profile-menu'} onClick={() => this.handleProfileMenu('competencies')}>
                                                                Competencies
                                                            </div>
                                                            <div className={this.state.profileMenuType === "domainKnowledge" ? "col-2 profile-menu-active" : 'col-2 profile-menu'} onClick={() => this.handleProfileMenu('domainKnowledge')}>
                                                                Domain Knowledge
                                                            </div>

                                                            <div className={this.state.profileMenuType === "compensation" ? "col-2 profile-menu-active" : 'col-2 profile-menu'} onClick={() => this.handleProfileMenu('compensation')}>
                                                                Compensation
                                                            </div>
                                                            <div className={this.state.profileMenuType === "expectedCompensation" ? "col-2 profile-menu-active" : 'col-2 profile-menu'} onClick={() => this.handleProfileMenu('expectedCompensation')}>
                                                                Expected Compensation
                                                            </div>
                                                            <div
                                                                className={this.state.profileMenuType === "familyDetails" ? "col-2 profile-menu-active" : 'col-2 profile-menu'} onClick={() => this.handleProfileMenu('familyDetails')}
                                                            >
                                                                Family Details
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                float: 'right', marginTop: '-24px',
                                                                marginRight: '23px',
                                                                fontWeight: 500
                                                            }} onClick={() => this.setState({ viewEdit: !this.state.viewEdit })}>
                                                            . . .
                                                        </div>
                                                        {
                                                            this.state.viewEdit &&
                                                            <div style={{ float: 'right', marginRight: '23px', }}
                                                            >
                                                                <Link to={`BasicDetails/Edit-user`}>Edit</Link>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="view-rectangle3 mt-3 mb-4">
                                                    <div className="col-12 row">
                                                        <div className="col-4">
                                                            <div className="profile-view-a">
                                                                <label className="profile-view-a-intro p-2">Intro</label>
                                                                <hr style={{ 'opacity': '0.1', backgroundColor: '#8f92a1' }} />
                                                                <div className="p-3">
                                                                    <div>
                                                                        {
                                                                            x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.designation &&
                                                                            <>
                                                                                <img src={assets.imageCase} className="m-2" />
                                                                                {x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.designation} at <b>{x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data[0]?.attributes?.company_name} </b>
                                                                            </>

                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <img src={assets.locaiton} className="m-2" /> Lives in <b>{x?.attributes?.current_location
                                                                        }</b>
                                                                    </div>
                                                                    <div>
                                                                        <img src={assets.currentLocation} className="m-2" /> From <b>{x?.attributes?.current_location}</b>
                                                                    </div>
                                                                    <div>
                                                                        <img src={assets.status} className="m-2" /><b>{
                                                                            x?.attributes?.family_detail?.data?.attributes?.marital_status === 'married' || x?.attributes?.family_detail?.data?.attributes?.marital_status === 'Married' ? "Married" : x?.attributes?.family_detail?.data?.attributes?.marital_status !== 'married' || x?.attributes?.family_detail?.data?.attributes?.marital_status !== 'Married' ? "Single" : ""
                                                                        }</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="profile-view-b mt-2" style={{ fontSize: 'small' }}>
                                                                <label className="profile-view-a-intro p-2">Timeline</label>
                                                                <hr />
                                                                <div style={{ height: '161px', overflow: 'scroll', padding: '1px' }}>
                                                                    {
                                                                        this.state?.timeLineData && this.state?.timeLineData?.map((x: any, index: any) => {
                                                                            return (
                                                                                <div style={{ marginLeft: '5%' }}>
                                                                                    {/* {x?.attributes?.candidate_job_status?.name} */}
                                                                                    <label style={x?.attributes?.candidate_job_status?.name === "placed" ? { color: 'green' } : x?.attributes?.candidate_job_status?.name === "rejected" ? { color: 'red' } : x?.attributes?.candidate_job_status?.name === "send to client" ? { color: 'blue' } : { color: index % 2 === 0 ? 'blue' : index % 3 ? 'magenta' : 'chocolate' }}><b>{x?.attributes?.candidate_job_status?.name.charAt(0).toUpperCase() + x?.attributes?.candidate_job_status?.name.slice(1)}</b></label>
                                                                                    <br />
                                                                                    <label><b>Assigned {x?.attributes?.company}</b> <label> ({x?.attributes.created_at})</label></label>
                                                                                    <br />
                                                                                    <label style={{ fontSize: '12px' }}><b>{x?.attributes?.pq_employee}</b></label>
                                                                                    <br />
                                                                                    <br />
                                                                                </div>

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="profile-view-b mt-2" style={{ fontSize: 'small', textAlign: 'justify', lineBreak: 'auto' }}>
                                                                <label className="profile-view-a-intro p-2">Documents</label>
                                                                <hr />
                                                                <>
                                                                    <div style={{ cursor: 'pointer' }} className="candidate-resume-profile" onClick={this.getResumeDocument}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="purple" className="bi bi-file-earmark-code-fill" viewBox="0 0 16 16">
                                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708z" />
                                                                    </svg> Resume</div>
                                                                    {

                                                                        this.state?.candidateDocumentArray?.length > 0 && this.state?.candidateDocumentArray?.map((ele: any) => {
                                                                            return (
                                                                                <div style={{ cursor: 'pointer', padding: '1px' }} className="candidate-resume-profile" onClick={() => this.viewFile(ele.attributes)}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="purple" className="bi bi-file-earmark-code-fill" viewBox="0 0 16 16">
                                                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708z" />
                                                                                </svg><span style={{ marginLeft: '4px', marginTop: '1px' }}>{ele.attributes.name}</span></div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 mb-4">
                                                            <div className="profile-menu-main">
                                                                {this.state.profileMenuType === 'basicDetails' && (
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <b>Name</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.full_name} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Mobile Number</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.full_phone_number} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Alternate Mobile Number</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.alternate_phone_number} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Email Id</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.email} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Highest Education</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.highest_education} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Current Location</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.current_location} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Relocation Status</b>
                                                                            {
                                                                                x?.attributes?.relocation_status?.length > 0 ?
                                                                                    <input className="form-control input-field mt-1 mb-3" value={`${x?.attributes?.relocation_status[0] && x?.attributes?.relocation_status[0]}${x?.attributes?.relocation_status[0] && x?.attributes?.relocation_status[0].length > 0 ? "," : ""} ${x?.attributes?.relocation_status[1] && x?.attributes?.relocation_status[1]}${x?.attributes?.relocation_status[1] && x?.attributes?.relocation_status[1].length > 0 ? "," : ""} ${x?.attributes?.relocation_status[2] && x?.attributes?.relocation_status[2]}`} readOnly />
                                                                                    :
                                                                                    <input className="form-control input-field mt-1 mb-3" readOnly />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {this.state.profileMenuType === 'competencies' && (
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <b>Core Competencies</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.competency?.data?.attributes?.core_competency.map((x: any) => {
                                                                                return ` ${x.name}`
                                                                            })} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Coding Proficiency</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.competency?.data?.attributes?.coding_proficiency} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Type of  Technologies</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.competency?.data?.attributes?.type_of_technologist} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Purple Quarter Observations</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.competency?.data?.attributes?.pq_observations} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Exclusive Summary</b>
                                                                            <textarea className="form-control input-field mt-1 mb-3" style={{ 'height': '120px' }} value={x?.attributes?.competency?.data?.attributes?.executive_summary} readOnly />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {this.state.profileMenuType === 'compensation' && (
                                                                    <div>
                                                                        <b>Base</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.base} readOnly />
                                                                        <div className="currency_type">| {this.getCurrencyType(x?.attributes?.compensation?.data?.attributes.currency_id)}</div>
                                                                        <b>Variable</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.variable} readOnly />
                                                                        <div className="currency_type">| {this.getCurrencyType(x?.attributes?.compensation?.data?.attributes.currency_id)}</div>
                                                                        <b>Bonus</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.bonus} readOnly />
                                                                        <div className="currency_type">| {this.getCurrencyType(x?.attributes?.compensation?.data?.attributes.currency_id)}</div>
                                                                        <b>Total Cash Compensation </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.total_cash_compensation} readOnly />
                                                                        <div className="currency_type">| {this.getCurrencyType(x?.attributes?.compensation?.data?.attributes.currency_id)}</div>
                                                                        <b>Stocks </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.stocks} readOnly />
                                                                        <b>Stock Value</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.stocks_value} readOnly />
                                                                        <b>Vested</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.vested} readOnly />
                                                                        <b>Unvested</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.unvested} readOnly />
                                                                        <b>Vesting Schedule</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.vesting_schedule} readOnly />
                                                                        <b>Exercise Price </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.exercise_price} readOnly />
                                                                        <b>Liquidation event at current org</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.compensation?.data?.attributes?.liquidation_event_at_current_org === true ? "Yes" : "No"} readOnly />
                                                                        <b>Additional Comments</b>
                                                                        <textarea className="form-control input-field mt-1 mb-3" style={{ 'height': '120px' }} name="additonalComments" value={x?.attributes?.compensation?.data?.attributes?.additional_comments} readOnly />
                                                                    </div>
                                                                )
                                                                }
                                                                {this.state.profileMenuType === 'domainKnowledge' && (
                                                                    <div>
                                                                        <b>Domain Exposure</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.domain_exposures?.map((x: any) => {
                                                                            return ` ${x.name}`
                                                                        })} readOnly />
                                                                        <b>Geographies Exposure </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.geographies_exposure?.map((x: any) => {
                                                                            return ` ${x}`
                                                                        })} readOnly />
                                                                        <b>Scale of Growth  </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.scale_of_growth} readOnly />
                                                                        <b>Tech built to scale  </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.tech_built_to_scale} readOnly />
                                                                        <b>Motivation </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.motivation && x?.attributes?.domain_knowledge?.data?.attributes?.motivation.map((x: any) => {
                                                                            return x
                                                                        }).join(', ')} readOnly />
                                                                        <b>Learnings from previous stints  </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.learnings_from_previous_stints} readOnly />
                                                                        <b>Candidate Type</b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.candidate_type} readOnly />
                                                                        <b>Highest span of people managed  </b>
                                                                        <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.domain_knowledge?.data?.attributes?.highest_span_of_people_managed} readOnly />

                                                                        <div className="row col-12" >

                                                                            {
                                                                                x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials && x?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data.map((x: any) => {
                                                                                    return (
                                                                                        <div className="col-6">
                                                                                            <div className="view-mask-view m-2">
                                                                                                <div className="row">
                                                                                                    <div className="col-2">
                                                                                                        <img src={x?.attributes?.logo || require('../../../components/src/img_avatar.png')} alt="" className="view-avatar" />
                                                                                                    </div>
                                                                                                    <div className="col-8">
                                                                                                        <div className="cred-main-text">
                                                                                                            <p className="card-name">{x.attributes.company_name}</p>
                                                                                                            <p className="card-company">{x.attributes.location}</p>
                                                                                                            <p className="card-company">{new Date(x.attributes.worked_from).getFullYear()}-{new Date(x.attributes.worked_till).getFullYear()}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2">
                                                                                                        <div className="col-2 ellipsis-item"
                                                                                                            onClick={() => {
                                                                                                                this.setState(
                                                                                                                    {
                                                                                                                        isDropDownOpenId: this.state?.isDropDownOpenId === x?.id ? null : x?.id,
                                                                                                                        selectedCredentials: x,
                                                                                                                        compensationCurrency: { "value": x?.attributes?.currency_id }


                                                                                                                    }
                                                                                                                )
                                                                                                            }}
                                                                                                        >
                                                                                                            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                                                                            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                                                                            <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                                                                                                            {this.state?.isDropDownOpenId === x?.id && this.openDropDownModalUI()}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p style={{ color: '#171717' }}>{x.attributes.designation}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <table className="card-info2">
                                                                                                        <tr>
                                                                                                            <td>Technical</td><td>{x.attributes.technologies_worked_upon}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Team Managed</td><td>{x.attributes.no_of_people_managed}</td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                )
                                                                }
                                                                {this.state.profileMenuType === 'expectedCompensation' && (
                                                                    <div>
                                                                        <b>Hike Expected </b>
                                                                        <input className="form-control input-field mt-1 mb-1" value={x?.attributes?.expected_compensation?.data?.attributes?.hike_expected} readOnly />
                                                                        <b>Base </b>
                                                                        <input className="form-control input-field mt-1 mb-4" value={x?.attributes?.expected_compensation?.data?.attributes?.base} readOnly />
                                                                        <div className="currency_type1">| {this.getCurrencyType(x?.attributes?.expected_compensation?.data?.attributes?.currency_id)}</div>
                                                                        <b>Variable </b>
                                                                        <input className="form-control input-field mt-1 mb-4" value={x?.attributes?.expected_compensation?.data?.attributes?.variable} readOnly />
                                                                        <div className="currency_type1">| {this.getCurrencyType(x?.attributes?.expected_compensation?.data?.attributes?.currency_id)}</div>
                                                                        <b>Bonus </b>
                                                                        <input className="form-control input-field mt-1 mb-4" value={x?.attributes?.expected_compensation?.data?.attributes?.bonus} readOnly />
                                                                        <div className="currency_type1">| {this.getCurrencyType(x?.attributes?.expected_compensation?.data?.attributes?.currency_id)}</div>
                                                                        <b>Total Cash Compensation </b>
                                                                        <input className="form-control input-field mt-1 mb-4" value={x?.attributes?.expected_compensation?.data?.attributes?.total_compensation} readOnly />
                                                                        <div className="currency_type1">| {this.getCurrencyType(x?.attributes?.expected_compensation?.data?.attributes?.currency_id)}</div>
                                                                        <b>Stocks </b>
                                                                        <input className="form-control input-field mt-1 mb-1" value={x?.attributes?.expected_compensation?.data?.attributes?.stocks} readOnly />
                                                                        <b>Stock Value </b>
                                                                        <input className="form-control input-field mt-1 mb-4" value={x?.attributes?.expected_compensation?.data?.attributes?.stocks_value} readOnly />
                                                                        {/* Remove */}
                                                                        {/* <b>Equity </b>
                                                                <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.expected_compensation?.data?.attributes?.equity} readOnly /> */}
                                                                    </div>
                                                                )
                                                                }
                                                                {this.state.profileMenuType === 'familyDetails' && (
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <b>Total Number of Family Memebers</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.family_detail?.data?.attributes?.total_member} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Martial Status</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.family_detail?.data?.attributes?.marital_status.charAt(0).toUpperCase() + x?.attributes?.family_detail?.data?.attributes?.marital_status.slice(1)} readOnly />
                                                                        </div>
                                                                        {
                                                                            x?.attributes?.family_detail?.data?.attributes?.marital_status?.toLowerCase(x) !== "single" && x?.attributes?.family_detail?.data?.attributes?.marital_status !== null && (
                                                                                <div className="form-group">
                                                                                    <b>Spouse Name</b>
                                                                                    <input className="form-control input-field mt-1 mb-3" value={x?.attributes?.family_detail?.data?.attributes?.spouse_name} readOnly />
                                                                                </div>
                                                                            )

                                                                        }

                                                                        <div className="form-group">
                                                                            <b>Date of Birth</b>
                                                                            <input className="form-control input-field mt-1 mb-3" value={moment(x?.attributes?.family_detail?.data?.attributes?.date_of_birth).format('DD/MM/YYYY')} readOnly />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <b>Other Details</b>
                                                                            <textarea style={{ 'height': '120px' }} className="form-control input-field mt-1 mb-3" value={x?.attributes?.family_detail?.data?.attributes?.other_details} readOnly />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div >

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="candidate-comment-section col-3">
                                                <h5>Add Comments</h5>

                                                <div className="mt-2 mb-2">
                                                    <TextField
                                                        multiline
                                                        rows={5}
                                                        id="add-profile-comment"
                                                        label="Add your Comments here ..."
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={e => { this.setState({ enteredCommentDescription: e?.target?.value }); this.handleTextFieldArea('add-profile-comment') }}
                                                        className="add-profile-comment"
                                                        name="enteredCommentDescription"
                                                        value={this.state.enteredCommentDescription}
                                                    />
                                                    <div onClick={() => this.saveProfileComment()} className="btn add-profile-comment-btn">Save</div>
                                                    <br />
                                                    <br />
                                                    <hr />
                                                    <div className="comment-listing">
                                                        {this.state.candidateProfileComments.length > 0 && this.state?.candidateProfileComments?.map((ele: any) => <div className="comments-list">
                                                            <h6>{ele.attributes.comment_by}</h6>
                                                            <p>{ele.attributes.comment}</p>

                                                            <div style={{ display: 'flex' }}>
                                                                <b style={{ width: '60%' }}>{ele.attributes.comment_to}</b>
                                                                <span>{ele.attributes.date}</span>
                                                            </div>
                                                            <hr className="comment-line" />
                                                            <br />
                                                        </div>)}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>

                </div>
                <Modal
                    isOpen={this.state.domainModal}
                    onRequestClose={this.closeDomainModal}
                    style={modalStyles}
                    contentLabel=""

                >
                    <div className="">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                companyName: this.state.selectedCredentials?.attributes?.company_name ? this.state.selectedCredentials?.attributes?.company_name : "",
                                companyLocation: this.state.selectedCredentials?.attributes?.location ? this.state.selectedCredentials?.attributes?.location : "",
                                designation: this.state.selectedCredentials?.attributes?.designation ? this.state.selectedCredentials?.attributes?.designation : "",
                                fromDate: this.state.selectedCredentials?.attributes?.worked_from ? this.state.selectedCredentials?.attributes?.worked_from : "",
                                tillDate: this.state.selectedCredentials?.attributes?.worked_till ? this.state.selectedCredentials?.attributes?.worked_till : "",
                                workedOnTechnologies: this.state.selectedCredentials?.attributes?.technologies_worked_upon ? this.state.selectedCredentials?.attributes?.technologies_worked_upon : "",
                                peopleManaged: this.state.selectedCredentials?.attributes?.no_of_people_managed ? this.state.selectedCredentials?.attributes?.no_of_people_managed : "",
                                compensation: this.state.selectedCredentials?.attributes?.compensation ? this.state.selectedCredentials?.attributes?.compensation : "",
                                reportingManager: this.state.selectedCredentials?.attributes?.reporting_manager ? this.state.selectedCredentials?.attributes?.reporting_manager : "",
                                reasonMovingOut: this.state.selectedCredentials?.attributes?.reason_of_moving_out ? this.state.selectedCredentials?.attributes?.reason_of_moving_out : "",
                                comment: this.state.selectedCredentials?.attributes?.comment ? this.state.selectedCredentials?.attributes?.comment : "",
                                aboutCompany: this.state.selectedCredentials?.attributes?.company_description ? this.state.selectedCredentials?.attributes?.company_description : "",
                                keyAchivements: this.state.selectedCredentials?.attributes?.key_achievement ? this.state.selectedCredentials?.attributes?.key_achievement : ""
                            }}
                            // validationSchema={this.addSchema}
                            onSubmit={values => {
                                console.log(values)
                            }}
                        >
                            {({ errors, touched, handleChange, handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="view-rectangle">
                                        <div className="mt-1 mb-3" style={{ color: 'black' }}>
                                            <label className="my-label">Name of the Company</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Name of the Company"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="companyName"
                                                type="text"
                                                value={values.companyName}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">About Company</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="About Company"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="aboutCompany"
                                                type="text"
                                                value={values.aboutCompany}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </div>

                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Location</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Location"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="companyLocation"
                                                type="text"
                                                value={values.companyLocation}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Designation</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Designation"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="designation"
                                                type="text"
                                                value={values.designation}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </div>
                                        <div className="row mt-1 mb-3">
                                            <p className="worked-heading" style={{ marginLeft: '10px' }}>Worked</p>
                                            <div className="col-6">
                                                <label className="my-label">From Date</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="From Date"
                                                    variant="outlined"
                                                    value={values.fromDate}
                                                    onChange={handleChange}
                                                    type="text"
                                                    color="primary"
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="fromDate"
                                                    inputProps={
                                                        { readOnly: true, }
                                                    } />
                                                {errors.fromDate && touched.fromDate ? <div className="error">{errors.fromDate}</div> : null}
                                            </div>
                                            <div className="col-6">
                                                <label className="my-label">Till Date</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="Till Date"
                                                    variant="outlined"
                                                    value={values.tillDate}
                                                    onChange={handleChange}
                                                    className="form-control input-field mt-1 mb-3"
                                                    name="tillDate"
                                                    type="text"
                                                    color="primary"
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                />
                                                {errors.tillDate && touched.tillDate ? <div className="error">{errors.tillDate}</div> : null}
                                            </div>
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Technologies worked Upon</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Technologies worked Upon"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="workedOnTechnologies"
                                                type="text"
                                                value={values.workedOnTechnologies}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Number of the people manged</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Number of the people managed"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="peopleManaged"
                                                type="text"
                                                value={values.peopleManaged}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </div>
                                        <div className="row mt-1 mb-3">
                                            <div className="col-12">
                                                <label className="my-label">Compensation</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label='Compensation'
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input"
                                                    name="compensation"
                                                    type="text"
                                                    value={values.compensation}
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                />
                                            </div>
                                            {
                                                this.state.selectedCredentials?.attributes?.currency_id !== undefined ?
                                                    <>
                                                        <Select isDisabled styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency2" placeholder={this.getCuurencyValue('compensationCurrency')?.label !== undefined ? this.getCuurencyValue('compensationCurrency')?.label : '$'} onChange={(selectedOption) => this.handleSelect(selectedOption, 'compensationCurrency')} />
                                                    </>
                                                    :
                                                    <>

                                                        <Select isDisabled styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency2" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'compensationCurrency')} />
                                                    </>
                                            }
                                            {/* <Select styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'compensationCurrency')} /> */}
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Reporting Manager</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Reporting Manager"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="reportingManager"
                                                type="text"
                                                value={values.reportingManager}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                            {errors.reportingManager && touched.reportingManager ? <div className="error">{errors.reportingManager}</div> : null}
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Reason of Moving out</label>
                                            <TextField
                                                id="outlined-secondary"
                                                // label="Reason of Moving Out"
                                                variant="outlined"
                                                color="primary"
                                                onChange={handleChange}
                                                className="form-control input-field text-field-input text-field-addCompany"
                                                name="reasonMovingOut"
                                                type="text"
                                                value={values.reasonMovingOut}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                            {errors.reasonMovingOut && touched.reasonMovingOut ? <div className="error">{errors.reasonMovingOut}</div> : null}
                                        </div>
                                        <div className="mt-1 mb-3">
                                            <label className="my-label">Key Achievements</label>
                                            <TextField
                                                multiline
                                                rows={5}
                                                id="outlined-secondary"
                                                // label="Key Achievements"
                                                variant="outlined"
                                                color="primary"
                                                onChange={(e) => handleChange(e)}
                                                className="form-control input-field"
                                                name="keyAchivements"
                                                value={values.keyAchivements}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                            {errors.keyAchivements && touched.keyAchivements ? <div className="error">{errors.keyAchivements}</div> : null}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row" style={{ textAlign: 'center' }}>
                                        <div className="col-6">
                                            <button className="btn candidate-button" type="button" onClick={() => {
                                                this.closeDomainModal()
                                                this.setState({
                                                    selectedCredentials: ''
                                                })
                                            }
                                            }>Cancel</button>
                                        </div>

                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Modal>
            </div >
            // Customizable Area End
            );
    }
}

// Customizable Area Start
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '80%',
        // color: 'lightsteelblue'
    },
    overlay: {
        backgroundColor: 'rgb(49 42 42 / 90%)'
    }
};
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
