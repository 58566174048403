import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";

// Customizable Area Start
import DashboardController, { Props } from "./DashboardController.web";
import ProfileInfo from "./CandidateProfileInfo.web";
import AllCandidate from "./AllCandidate.web";
import './dashboard.css'
// Customizable Area End


export default class Candidates extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    return (
      // Customizable Area Start
      <div>
        <div className="wrapper">
          <SideNav data={this.state.currentUser} />
          <div className="content">
            <TopNav data={this.state.currentUser} />
            <AllCandidate {...this.props} />
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area End
