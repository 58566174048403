import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import RichTextEditor from 'react-rte';
//@ts-ignore
import moment from 'moment'

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  currentUser: any
  menuType: number
  serviceAgreementData: any
  serviceAgreementDate: any
  serviceAgreementLegalEntity: any
  serviceAgreementOfficeAddress: any
  serviceAgreementBusinessName: any
  serviceAgreementCompanyAct: any
  scopeOfServicesExecutivePeriod: any
  scopeOfServicesData: any
  termsEngagementData: any
  commercialsData: any
  independentContractorData: any
  warrantiesData: any
  indemnityData: any
  limitationOfLiabilityData: any
  confidentialityData: any
  terminationData: any
  consequencesOfTerminationData: any
  settlementOfDisputeAndJurisdictionData: any
  settlementCountry: any
  settlementCity: any
  noticesAddress: any
  noticesPinCode: any
  noticesState: any
  noticesTelephone: any
  noticesData: any
  noticesEmail: any
  noticesCity: any
  noticesCountry: any
  miscellaneousData: any
  agreementOrganizationName: any
  agreementSignatoryName: any
  agreementDesignation: any
  agreementData: any
  scheduleAData: any
  scheduleFeeType: any
  scheduleFixedAmount: any
  retainerAdvance: any
  businessDaysAdvancePayment: any
  secondInvoice: any
  businessDaysSecondPayment: any
  finalPayment: any
  businessDaysFinalPayment: any
  feesBeyondPercentage: any
  feesBeyondDays: any
  scheduleError: any
  companyActError: any
  confirmModal: any
  agreementGenerateDate: any
  agreementUserName: any
  agreementFileName: any
  previewAgreementModal: any
  previewAgreement: any
  isShippingAddressSame: any
  corporateAddress1: any
  corporateAddress2: any
  corporateCity: any
  corporateState: any
  corporateCountry: any
  corporatePincode: any
  billingAddress1: any
  billingAddress2: any
  billingCity: any
  billingState: any
  billingCountry: any
  billingPincode: any
  invoiceGSTNumber: any
  gstPanCertificateImage: any
  currencyOptions: any
  budgetRoleAmount: any
  budgetRoleAmountCurrencyId: any
  feesAgreed: any
  numberOfInvoices: any
  actualCompensation: any
  actualCompensationCurrencyId: any
  retainerAdvanceAmount: any
  retainerAdvancePaidDate: any
  secondInvoiceAmount: any
  thirdInvoiceAmount: any
  fixedPercentage: any
  allAggrementsList: any
  isDropDownOpenId: any
  previewAgreementData: any
  disclosureAgreementCompanyData: any
  disclosureAgreementCompanyName: any
  returnOfConfidentialData: any
  obligationData: any
  nonPromotionData: any
  disputeResolutionData: any
  injunctiveReliefData: any
  billingOrganizationinvoiceGSTNumber: any
  billingOrganizationIsShippingAddressSame: boolean
  billingOrganizationPincode: any
  billingOrganizationCountry: any
  billingOrganizationState: any
  billingOrganizationCity: any
  billingOrganizationAddress2: any
  billingOrganizationAddress1: any
  billingOrganizationShippingPincode: any
  billingOrganizationShippingCountry: any
  billingOrganizationShippingState: any
  billingOrganizationShippingCity: any
  billingOrganizationShippingAddress2: any
  billingOrganizationShippingAddress1: any
  payblePhoneNumber: any
  paybleEmail: any
  paybleName: any
  paybleTitle: any
  docuSignModal: boolean
  docusignUserName: any
  docusignDate: any
  Recipient3Email: any
  Recipient3FirstName: any
  Recipient3LastName: any
  Recipient2Email: any
  Recipient2FirstName: any
  Recipient2LastName: any
  Recipient1Email: any
  Recipient1FirstName: any
  Recipient1LastName: any
  docusignDocumentPDF: any
  selectedAgreementID: any
  billingOrganizationName: any
  editAgreementState: boolean
  editAgreementId: any
  docuSignAgreementName: any
  isTriggeredCalled: boolean
  genrateInvoiceData: any
  fourthInvoiceAmount: any
  nonDisabledButton: any
  fourth_invoice_value: any,
  first_invoice_value: any,
  third_invoice_value: any,
  second_invoice_value: any,
  InvoiceCompanyName: any,
  isLoadingEvent: boolean,
  uploadModal: any
  uploadedDocument: any
  agreementList: any
  selectedAgreement: any
  selectedAgreementStatus: any
  isInvoiceComplete: Boolean
  confirmDeleteModal: any
  deleteId: any
  loader: any
  noticesPinCodeError: any
  billingOrganizationPincodeError: any
  billingOrganizationShippingPincodeError: any
  formError: any
  billingOrganizationinvoiceGSTNumberError: any
  noticesTelephoneError: any
  payblePhoneNumberError: any
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProposalGeneration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCurrentUserApiCallId: string = "";
  currencyApiCallId: string = "";
  createInvoiceApiCallID: string = "";
  POSTAPICallSaveAggrement: string = "";
  GetAllAggrementsAPICall: string = "";
  deleteApiCallId: string = "";
  saveDocuSignPOSTAPICall: string = "";
  uploadDocSignPUTAPICall: string = "";
  GetCurrentAgreementsAPICall: string = "";
  triggerStatusOfAgreements: string = "";
  GetGenrateInvoice: string = "";
  GetinvoiceCreateAPICall: string = "";
  CreateSignedTemplateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      loader: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentUser: "",
      menuType: 1,
      serviceAgreementDate: "",
      serviceAgreementLegalEntity: "",
      serviceAgreementOfficeAddress: "",
      serviceAgreementBusinessName: "",
      serviceAgreementCompanyAct: "",
      serviceAgreementData: RichTextEditor.createValueFromString(`<p>This Service Agreement (hereinafter referred to as <strong>“Agreement”</strong>) dated <strong>xxxxxxxxxx</strong>, is made by and between:</p>
      <p><strong>PurpleLabs Search Private Limited</strong>, a company incorporated and registered under the Companies Act, 2013 having its registered office at “#257, 2nd Floor, 16th Cross, 5th Main, 6th Sector, HSR Layout, Bangalore 560102” (hereinafter referred to as “Service Provider" or “Purple Quarter”, which expression shall unless repugnant to the context and meaning thereof be deemed to mean and include its successors and assigns) of the <strong>One Part</strong>;&nbsp;</p>
      <p><strong>AND:</strong>&nbsp;</p>
      <p><strong>xxxxxxxxxx</strong>, a company incorporated and registered under the <strong>xxxxxxxxxx</strong> having its registered office at <strong>xxxxxxxxxx</strong> (hereinafter referred to as “the Company", which expression shall unless repugnant to the context and meaning thereof be deemed to mean and include its successors and assigns) of the <strong>Other Part;</strong></p>
      <p>Service Provider and Company are singly referred to as <strong>“Party”</strong> and jointly referred to as <strong>“Parties”</strong> herein below.&nbsp;</p>
      <p><strong>WHEREAS:</strong>&nbsp;</p>
      <p>A. The Service Provider is engaged in the business of providing Retained Leadership level hiring to the IT Industry by sourcing candidates to their Clientele.&nbsp;<br>
      B. The Company is, inter alia, engaged in the business of <strong>xxxxxxxxxx</strong>&nbsp;<br>
      C. Based on the representations made by the Service Provider, the Company is desirous of engaging the Service Provider to perform the Services (as set out in Clause 1 below) and the Service Provider has agreed to do so in accordance with the terms and conditions specified in this Agreement.<br>
      D. The Parties are now desirous of recording their respective rights and obligations in relation to the Services in this Service Agreement.<br>
      E. “Business Day” shall mean a day other than Saturday, Sunday and any public or bank holiday in India.;&nbsp;<br>
      F. The Parties are further entering into a non-disclosure agreement dated as of the date hereof recording their mutual rights and obligations with respect to protecting the confidentiality of certain information of each party. (“Non-Disclosure Agreement”).</p>`, 'html'),
      scopeOfServicesExecutivePeriod: "",
      scopeOfServicesData: RichTextEditor.createValueFromString(`<p>1.1. The Service Provider shall provide CXO level hiring to the Company and, in this regard, source the candidates having the requisite qualifications to match the job description as provided by the Company during the Term.&nbsp;<br>
      1.2. Agreement applies complete exclusivity for a period of <strong>xxxxxxxxxx</strong> business days to Purple Quarter and any reference(s) deemed internally by the Company during this period will be considered as Purple Quarter’s profile. The Service Provider's fee structure applies even if an internal candidate emerges as the Company's final choice, during the Exclusivity Period.&nbsp;<br>
      1.3. Exclusivity will commence on approval of the Purple Quarter Capsule (Talent Information deck), which will be after the date of execution of agreement.&nbsp;<br>
      1.4. Both parties will mutually agree on any changes to the services, including modifications of the job specification or candidate profile, and any pricing associated with such changes.&nbsp;<br>
      1.5. In case of a significant change in the requirement, the exclusivity period will commence from the date of change in the requirement and not from the approval of talent information deck as mentioned above.</p>`, 'html'),
      termsEngagementData: RichTextEditor.createValueFromString(`<p>This Agreement shall commence from the date of signing of the agreement and remain in effect for a period of 12 months <b><b>(&lsquo;Initial Term&rsquo;)</b></b>. The Company shall be entitled to renew this Agreement for a further period of 1 (one) year each upon identical terms and conditions as those contained in this Agreement.</p>`, 'html'),
      commercialsData: RichTextEditor.createValueFromString(`<p>The Company will pay the Service Provider as per the rates mentioned in <b><b>Schedule A</b></b>.</p>`, 'html'),
      independentContractorData: RichTextEditor.createValueFromString(`<p>The relationship between the parties is of independent contractors on a Principal to Principal basis it being clearly understood that this is a &ldquo;contract for services&rdquo; and not a &ldquo;contract of services&rdquo;. The Service Provider shall maintain complete control over its employees, personnel, representative or agent&rsquo;s representatives. Service Provider alone will have the ultimate discretion to direct its Personnel in matters relating to the provision of the Services without any required intervention from or by the Company and the employees of the Service Provider shall in no manner be deemed to be the employees of Company. Nothing contained in this Agreement shall be construed to create a relationship of Principal - Agent, Employer - Employee, Partners or Joint Venture/s between the parties. Neither party will have the right to bind the other through any of its actions.</p>`, 'html'),
      warrantiesData: RichTextEditor.createValueFromString(`<p>5.1 The Service Provider warrants as follows:&nbsp;&nbsp;</p>
      <p>a. that it has the right to enter into this Agreement and perform its obligations under this Agreement without violating the terms or provisions of any other agreement or contract to which it is a party;&nbsp;<br>
      b. it shall, at all times, act in the best interests of the Company and shall follow instructions/ directions which the Company may stipulate from time to time;&nbsp;<br>
      c. that it will provide the Services in a timely, competent and professional manner by appropriately skilled, experienced and qualified personnel having at all times due regard to the Company's business operations and shall replace such personnel immediately at the Company's request, if the Company finds any personnel unacceptable for any reason whatsoever;&nbsp;<br>
      d. It shall replace the Selected Candidate, if such candidate leaves within the 365 calendar days of joining the company at no further costs.&nbsp;<br>
      e. that it shall provide the Services with regard to Prospective Candidates by utilizing the best of its professional capabilities and by exercising all due skill, care and diligence to ensure that the Services are in compliance with best industry practices and standards;&nbsp;<br>
      f. That it shall comply at all times with all applicable laws and regulations of any jurisdiction in which Service Provider acts and provides Services in accordance with all applicable Laws. However, the Company shall provide documents and/or such details, as may be required, to the Service Provider in order to enable the Service Provider to comply with such applicable Laws. In the event of any default by Company in this regard, the Service Provide shall under no circumstances be solely held responsible and/or liable thereunder.&nbsp;<br>
      g. that it has all necessary rights, authorizations and licenses to provide the Services and areas related to such services <br>
      h. that the person executing this Agreement has the authority to duly execute this Agreement on behalf of the Service Provider.&nbsp;&nbsp;</p>
      <p>5.2 The Company warrants as follows:&nbsp;&nbsp;</p>
      <p>a. that it has the right to enter into this Agreement and perform its obligations under this Agreement and avail of the Services without violating any applicable Laws or the terms or provisions of any other agreement or contract to which it is a party; and&nbsp;<br>
      b. that the person executing this Agreement has the authority to duly execute this Agreement on behalf of the Company.&nbsp;<br>
      c. that it will provide the Service Provider with comprehensive details of the job description for the talent that is to be acquired;&nbsp;<br>
      d. that it will provide Service Providers with the appropriate access to key leadership and the final hiring authority.&nbsp;<br>
      e. that it will provide timely feedback to the Service Provider on all aspects of the assignment.&nbsp;<br>
      f. that it will Schedule interviews promptly with candidates and report their findings as soon as possible.&nbsp;<br>
      g. that it will provide Service Provider with information on candidates identified from other sources or from within the organization, so that they may be evaluated as part of the search process.&nbsp;<br>
      h. that inputs concerning the technical aspects and the overall personality of the Prospective Candidate desired by the Company shall be provided to the Service Provider;&nbsp;<br>
      i. that the candidate profile sourced by the Service Provider to the Company shall be valid for a period of 12 (Twelve) months from the date of sharing it with the Company. In case the Company hires the same candidate through any other means/internally, the Service Provider shall claim fees on joining of the Candidate to Company as per the commercials agreed via this agreement.&nbsp;<br>
      j. If a Candidate sourced by the Service Provider is hired as a part-time consultant or an independent contractor (or by any other name), an invoice amounting to INR 10 Lakh shall be raised immediately, payable within 15 days.&nbsp;<br>
      k. In case of any Public Relations effort with regards to the position, the company agrees to a Press Release.&nbsp;<br>
      l. The Company agrees to provide a Video Testimonial, within 3 months of the first Press Release.</p>`, 'html'),
      indemnityData: RichTextEditor.createValueFromString(`<p>6.1 In the event of any breach by the Service Provider of any representation, warranty, covenant or agreement made or given by it in this Agreement, the Service Provider undertakes to indemnify and hold harmless the Company, its Affiliates, directors, officers, employees, agents and representatives to the extent of (a) any and all Damages suffered or incurred by them in relation to such breach of representation or warranty, covenant or agreement and (b) any tax, penalty or interest that may be levied or assessed as a result of the delay or failure of Service Provider or any its subcontractors or suppliers to pay GST or any other tax on the Services provided pursuant to this Agreement or to file any return or information required under any applicable Laws.&nbsp;</p>
      <p>6.2 In the event of any breach by the Company of any representation, warranty, covenant or agreement made or given by it in this Agreement, the Company undertakes to indemnify and hold harmless the Service Provider, its Affiliates, directors, officers, employees, agents and representatives to the extent of adjudicated Damages suffered or incurred by them in relation to such breach of representation or warranty, covenant or agreement.&nbsp;</p>
      <p>6.3 Without limiting the generality of the foregoing, the Service Provider shall:&nbsp;</p>
      <p>&emsp;&emsp;a. be liable for all damage to or loss of property (including loss by theft) and injury to or death of any third party, including the Service Provider Personnel, the Service Provider’s agents and representatives, arising out of or in consequence of the performance of the Services or the acts or omissions of the Service Provider’s agents and representatives, and shall indemnify and keep indemnified the Company, its Affiliates, directors, officers, employees, agents and representatives, against all Damages that may be suffered by them in this regard;&nbsp;</p>
      <p>&emsp;&emsp;b. be liable for all Damages claimed by or found owing to any third parties or customers in connection with any act or omission of the Service Provider, the Service Provider Personnel, the Service Provider's officers, agents, representatives or sub-contractors arising from the performance, non-performance or breach of the Service Provider's obligations, undertakings or warranties under this Agreement or on account of failure by the Service Provider, its officers, employees, agents, representatives or sub-contractors to comply with any applicable Laws;&nbsp;</p>
      <p>&emsp;&emsp;c. indemnify and hold harmless the Company, its Affiliates, directors, officers, employees, agents and representatives against any Damages arising from any failure by the Service Provider or its subcontractors in making payments of the compensation and all statutory benefits of the Service Provider Personnel or the subcontractor's employees (as the case may be) and other personnel engaged by them.</p>`, 'html'),
      limitationOfLiabilityData: RichTextEditor.createValueFromString(`<p>Notwithstanding anything contained in this Agreement, in no event shall either Party and/ or its respective affiliates be liable to the other Party (including its respective affiliates) , its personnel or representatives, or any other Person acting on their behalf, whether in contract, tort, under any warranty or any other theory of liability, for any increased costs or wasted expenditure (save and except as provided for in this Agreement) loss of profits, business, contracts, or revenues, loss of operation time, loss of goodwill or reputation of the other Party (including its Affiliates), indirect, special, incidental, consequential, punitive or exemplary damages of any nature whatsoever or howsoever arising out of this Agreement.&nbsp;</p>
      <p>Notwithstanding the foregoing, no limitation or exclusion of liability shall apply with respect to any claims based on either Parties gross negligence or willful misconduct or with respect to any claims for personal injury or property damage or the Service Provider’s indemnification obligations under Clause 6 or based on Clause 8.&nbsp;</p>
      <p>Service Provider’s aggregate liability for any damages, losses, costs and expenses arising out of this Agreement will not exceed in the aggregate the total amount of the professional fees paid to the Service Provider, pursuant to this Agreement.</p>`, 'html'),
      confidentialityData: RichTextEditor.createValueFromString(`<p>8.1 The Parties acknowledge that that during the course of this Agreement, either party may acquire information regarding the other party in oral and/or written form relating to the past, present or future business activities of the Company or the methods employed by the Service Provider for the purpose of recruiting prospective candidates sourced through them, its subsidiaries or affiliates, or their respective employees, customers or third party suppliers or contractors, including without limitation its client’s lists, information relating to plans, pricing, methods, methodologies, processes, financial data, lists, intellectual property, customer information, equipment, statistics, programs, research, development, information technology and any other proprietary, confidential or non-public information of each Party or any of its affiliates or subsidiaries or clients or third parties to whom either Party or its affiliates owe a duty of confidentiality and any other information which the undersigned knows or reasonably ought to know to be proprietary or confidential (“Confidential Information”).&nbsp;</p>
      <p>8.2 Each Party agrees that:&nbsp;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. it shall hold all Confidential Information in trust and confidence and shall use the same degree of care in safeguarding the Confidential Information as it uses for its own Confidential Information of like importance, but no less than reasonable care,<br>
       &nbsp;&nbsp;&nbsp;&nbsp;b. it shall not disclose, publish or communicate to any person, firm or enterprise, or use for benefit, any Confidential Information save and except as set forth in this Service Agreement or as otherwise may be authorized in writing,<br>
       &nbsp;&nbsp;&nbsp;&nbsp;c. Service Provider may use and include information obtained in the engagement in their databases and use data for research and to improve their offerings. They will continue to own their pre-existing intellectual property, including databases, assessments, tools, and methodology, and grant the Company a limited license to use the reports provided solely for their internal use.&nbsp;</p>
      <p>8.3 The restrictions on use or disclosure of the Confidential Information will not apply to: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. any information which is in or enters the public domain (provided that this has not happened because of a breach of this Agreement or of any other duty of confidentiality);<br>
       &nbsp;&nbsp;&nbsp;&nbsp;b. any information which the Receiving Party already possessed prior to disclosure by the Disclosing Party and where the Receiving Party was at the time of such disclosure free to disclose that information to others;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;c. any information independently originated by the Receiving Party or acquired by the Receiving Party from a third party in circumstances in which such Party is free to disclose it to others;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;d. any information which is disclosed to a third party by the Disclosing Party without similar restrictions on that third party’s right of disclosure;&nbsp;</p>
      <p>8.4 It is understood and agreed that in the event of a breach of the provisions of this Agreement, Parties shall be entitled to apply for appropriate injunctive relief to restrain any such breach, threatened or actual.</p>`, 'html'),
      terminationData: RichTextEditor.createValueFromString(`<p>9.1 Without prejudice to any other rights or remedies which it may have, Either Party shall be entitled to terminate this Agreement forthwith by giving 30 days’ advance written notice of termination to other if:&nbsp;</p>
      <p>  a) Either party believes that the other Party is in a Material Breach of this Agreement and defaulting Party does not remedy the breach within 15 days of the date of notice from the other party specifying the breach and requiring it to be remedied or/and if such Material Breach is incapable of remedy or as may be mutually decided between the Parties, after a notice specifying the breach and requiring the same to be remedied has been given to the defaulting Party; or&nbsp;<br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) if (save in relation to a reorganization, reconstruction or amalgamation not affecting the credit-worthiness of the other party):&nbsp;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. the Party becomes insolvent, an application for insolvency procedure or similar has been submitted by or against the Company,&nbsp;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. an order is made or resolution is passed for the winding-up of the other Party or if a provisional &nbsp;liquidator is appointed in respect of the other Party; or&nbsp;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. a change in applicable laws or regulations that has a material effect on, or causes a material change to the obligations of Service Provider under this Agreement&nbsp;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. a receiver (which expression shall include administrative receiver) is appointed in respect of the other Party or all or any of its assets</p>`, 'html'),
      consequencesOfTerminationData: RichTextEditor.createValueFromString(`<p>a) Following the service of a written notice terminating this Agreement, each Party shall continue to abide by the terms and conditions of this Agreement in effect at such time and comply fully with its obligations hereunder and it shall not in any way hinder or interrupt the performance of this Agreement during any period between the date of service of a termination notice and the date of actual termination. In addition, each Party shall immediately return all Confidential Information.&nbsp;<br>
      b) On expiry, cancellation or termination of this Agreement for whatever reason:&nbsp;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. the Service Provider shall provide an invoice in respect of any Services performed since the date of the last invoice;&nbsp;<br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. the Company shall pay the undisputed amounts of such invoice in accordance with the provisions of this Agreement;&nbsp;<br>
      c) Termination shall be without prejudice to any rights or remedies either Party may have against the other in respect of any antecedent breach of the terms of this Agreement. Further, any provision of the Agreement that contemplates performance or observance subsequent to termination or expiration of the Agreement will survive termination or expiration of the Agreement and continue in full force and effect</p>`, 'html'),
      settlementCountry: "",
      settlementCity: "",
      settlementOfDisputeAndJurisdictionData: RichTextEditor.createValueFromString(`<p>a) Any claim, dispute, or controversy arising out of, or in relation to, this Agreement, the interpretation thereof, the activities performed hereunder, or the breach thereof, which cannot be satisfactorily resolved by mutual understanding between the parties, shall be finally settled through arbitration in accordance with the provisions of the Indian Arbitration and Conciliation Act, 1996.&nbsp;<br>
      b) The venue of arbitration shall be exclusively at <strong>xxxxxx, xxxxxx</strong> and any award whether interim or final, shall be made, and shall be deemed for all purposes between the parties to be made, in <strong>xxxxxx, xxxxxx</strong>. The arbitral proceedings shall be conducted in English.&nbsp;<br>
      c) The award of the arbitrator shall be final and conclusive and binding upon the parties and shall be subject to execution in the Courts of <strong>xxxxxx, xxxxxx</strong>.&nbsp;<br>
      d) This Agreement will be construed in accordance with and governed by the Laws of India and each Party agrees to submit to the exclusive jurisdiction of the courts in the city of <strong>xxxxxx, xxxxxx</strong>.</p>`, 'html'),
      noticesAddress: "",
      noticesPinCode: "",
      noticesState: "",
      noticesTelephone: "",
      noticesEmail: "",
      noticesCity: "",
      noticesCountry: "",
      noticesData: RichTextEditor.createValueFromString(`<p>Any notice and other communications provided for in this Agreement shall be in writing and shall be first transmitted by facsimile/electronic transmission, and then confirmed by postage, prepaid registered airmail or by internationally recognized courier service, in the manner as elected by each of the Parties giving such notice to the following addresses:&nbsp;</p>
      <p>a. In the case of notices to the Company:&nbsp;</p>
      <p><i><b>Address</b></i> : <b>xxxxxxxxxx</b>&nbsp;</p>
      <p><i><b>Telephone</b></i> : <b>xxxxxxxxxx</b>&nbsp;</p>
      <p><b>Email</b> : <b>xxxxxxxxxx</b>&nbsp;</p>
      <p>b. In the case of notices to the Service Provider:&nbsp;</p>
      <p><i><b>Address</i></b> : #257, 2 nd Floor, 16 th Cross, 5 th Main, 6th Sector, HSR Layout, Bangalore 560 102&nbsp;</p>
      <p><i><b>Telephone</b></i> : +91 9555980278&nbsp;</p>
      <p><b>Email</b> : Sourabh.joshi@purplequarter.com</p>
      `, 'html'),
      miscellaneousData: RichTextEditor.createValueFromString(`<p><strong>Reservation of rights</strong>&nbsp;</p>
      <p>No forbearance, indulgence or relaxation or inaction by either Party at any time to require performance of any of the provisions of this Agreement shall in any way affect, diminish or prejudice the right of either Party to require performance of that provision. Any waiver or acquiescence by either Party of any breach of any of the provisions of this Agreement shall not be construed as a waiver or acquiescence of any right under or arising out of this Agreement or of the subsequent breach, or acquiescence to or recognition of rights other than as expressly stipulated in this Agreement. The remedies provided in this Agreement are cumulative and not exclusive of any remedies provided by law.&nbsp;</p>
      <p><strong><b>Severability</b></strong>&nbsp;</p>
      <p>If any provision of this Agreement is held for any reason to be invalid or unenforceable the remaining provisions of this Agreement will be unimpaired and, unless a modification or replacement of the invalid or unenforceable provision is further held to deprive a Party of a material benefit, in which case the Agreement shall immediately terminate, the invalid or unenforceable provision will be replaced with a provision that is valid and enforceable and that comes closest to the parties’ intention underlying the invalid or unenforceable provision.&nbsp;</p>
      <p><strong><b>Force Majeure</b></strong>&nbsp;</p>
      <p>Neither Party shall be liable to other in case of delayed responses, performance, act or omission as contemplated in this Agreement by reason of any act of nature, war, terrorism, and change in law affecting this agreement which could not have been prevented by the Non-Performing Parties by way of reasonable precautions or commercially accepted processes. Each such incident along with such others shall be considered for the purposes of this agreement as Force Majeure.&nbsp;</p>
      <p>If the act of force majeure delays the performance of the non-performing Party for a period of 30 calendar days, under such circumstances the Party unaffected by the said incident may at its sole option terminate the agreement with immediate effect by written notice to the other party. Notwithstanding any other provision of this Agreement, a Force Majeure event shall not relieve the Party of its liabilities and obligations if such non-performance of its obligations occurred prior to the force majeure incident.</p>
      <p><strong><b>Assignment and subcontracting</b></strong>&nbsp;</p>
      <p>The Service Provider shall not, without the prior written consent of the Company, which may be withheld in its sole discretion, assign or sub-contract its rights and obligations hereunder. It is clarified that consent by the Company to the Service Provider to sub-contract/assign its obligations shall not in any manner absolve the Service Provider to perform its obligations under this Agreement and the Service Provider shall continue to be responsible for the performance of its obligations under this Agreement.&nbsp;</p>
      <p><strong><b>Amendments</b></strong>&nbsp;</p>
      <p>No modification, variation or amendment to this Agreement and no waiver of any of the terms hereto shall be valid or binding unless made in writing and duly executed by all the Parties.&nbsp;</p>
      <p><strong><b>Entirety</b></strong>&nbsp;</p>
      <p>This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof to the exclusion of all other understandings and assurances, either written or oral.&nbsp;</p>
      <p><strong><b>Binding nature</b></strong>&nbsp;</p>
      <p>This agreement will ensure to the benefit of and be binding on the Parties and their respective heirs, executors, administrators and permitted successors and assigns.&nbsp;</p>
      <p><strong><b>Counterparts</b></strong>&nbsp;</p>
      <p>This Agreement has been executed and registered in duplicate, each of which shall be deemed to be original and shall become effective when the Parties have signed both sets.&nbsp;</p>
      <p><strong><b>Costs</b></strong>&nbsp;</p>
      <p>Save as otherwise provided in this Agreement, each of the Parties shall bear their respective costs (including Taxes) and expenses incurred in connection with the execution of this Agreement and all transactions herein envisaged.&nbsp;</p>
      <p><strong><b>Non-exclusivity</b></strong>&nbsp;</p>
      <p>Nothing in this Agreement shall be construed as creating any obligations of exclusivity on the Company except as agreed in clause 1.3. The Company shall, at all times, be entitled to retain other third-party vendors for the purpose of procuring services similar or identical to the Services.&nbsp;</p>
      <p><strong><b>Disclaimer of the Service Provider</b></strong>&nbsp;</p>
      <p>The Service Provider does not and cannot control the flow of data between its employees’ devices and the internet. Such flow would, in large part, depend on the performance of the internet and the services provided or controlled by third parties. At times, actions or inactions of such third parties can impair or disrupt the Company’s connections to the internet (or portions thereof). Subject to its obligations, the Service Provider hereby disclaims any and all liability resulting from or related to such events.&nbsp;</p>
      <p><strong><b>IN WITNESS WHEREOF</b>,</strong> the Parties have executed this Agreement as of the date first written above</p>`, 'html'),
      agreementOrganizationName: "",
      agreementSignatoryName: "",
      agreementDesignation: "",
      agreementData: RichTextEditor.createValueFromString(`<p>Executed by the within named Undersigned by "PurpleLabs Search Private Limited" through authorized signatory&nbsp;</p>
      <p>Name: Sourabh Joshi&nbsp;</p>
      <p>Designation: VP Finance&nbsp;</p>
      <p>Date:&nbsp;</p>
      <p>Agreed and accepted by the within named “<b>xxxxxxxxx</b>” through its authorized signatory&nbsp;</p>
      <p>Name: <b>xxxxxxxxx</b></p>
      <p>Designation: <b>xxxxxxxxx</b></p>
      <p>Date:&nbsp;</p>`, 'html'),
      scheduleFeeType: "",
      scheduleFixedAmount: 0,
      retainerAdvance: 0,
      businessDaysAdvancePayment: 0,
      secondInvoice: 0,
      businessDaysSecondPayment: 0,
      finalPayment: 0,
      businessDaysFinalPayment: 0,
      feesBeyondPercentage: 0,
      feesBeyondDays: 0,
      scheduleAData: RichTextEditor.createValueFromString(`<p><strong>Service Description</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Fee Per Position</strong>&nbsp;</p>
      <p><strong>Tech Head</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>xxxxxxx of Total CTC of first year (all cash components apart from stocks)</strong>&nbsp;</p>
      <p>a) Retainer advance amounting to <strong>xxxxxxx</strong> of fees per position payable within <strong>xxxxxxx</strong> calendar days of the signing of this agreement <strong>(non-refundable)</strong>. The invoice for the same shall be raised immediately after the signing of this agreement.&nbsp;</p>
      <p>b) Second payment of <strong>xxxxxxx</strong> of fees per position payable within <strong>xxxxxxx</strong> calendar days of the candidate giving a written consent on the offer acceptance (email or in written). The invoice for the same shall be raised immediately after the candidate gives his/her written consent on the offer acceptance (email or in written).&nbsp;</p>
      <p>c) If the candidate search extends beyond <strong>xxxxxxx</strong> calendar days from the date when exclusivity commences, then an invoice amounting to <strong>xxxxxxx</strong> of the total fees shall be raised.&nbsp;</p>
      <p>d) Final payment of <strong>xxxxxxx</strong> of fees per position is payable within <strong>xxxxxxx</strong> calendar days of the candidate joining the Company. The invoice for the same shall be raised immediately upon joining of the candidate at the Company.&nbsp;</p>
      <p>** All above percentages will be charged on the budget fixed for the role, <strong>any changes in the compensation offered will be adjusted in the second and final invoice.</strong>&nbsp;</p>
      <p><strong>Note(s):</strong>&nbsp;</p>
      <p>● The above fees are exclusive of Goods &amp; Service tax (GST), the liability of which shall be borne by the Company.&nbsp;<br>
      ● The Company agrees to reimburse such out-of-pocket expenses and expenses incurred by the Service Provider in the performance of services incurred with the approval of the Company in writing.&nbsp;<br>
      ● If the candidate does not join the Company on the joining date, and if thereafter a replacement for such candidate, which shall be duly approved by the Company, is not identified by the Service Provider within 90 (ninety) business days from the date of joining of the initial candidate, then the Service Provider shall refund the Second payment to the Company within 30 (thirty) calendar days. The replacement clause remains standard.&nbsp;<br>
      ● The Company must notify the Service Provider in writing of any dispute regarding the amount of an invoice within 5 days from the date of receipt of the invoice, otherwise, it shall be deemed that the invoice is correct and has been accepted by the Company for making payment.&nbsp;<br>
      ● If payment is not made in a timely manner, Purple Quarter reserves the right to suspend services until all invoices are paid in full.</p>`, 'html'),
      scheduleError: "",
      companyActError: "",
      confirmModal: false,
      agreementGenerateDate: moment(new Date()).format("DD-MM-YYYY"),
      agreementUserName: "",
      agreementFileName: "",
      previewAgreementModal: "",
      previewAgreement: "",
      isShippingAddressSame: true,
      corporateAddress1: "",
      corporateAddress2: "",
      corporateCity: "",
      corporateState: "",
      corporateCountry: "",
      corporatePincode: "",
      billingAddress1: "",
      billingAddress2: "",
      billingCity: "",
      billingState: "",
      billingCountry: "",
      billingPincode: "",
      invoiceGSTNumber: "",
      gstPanCertificateImage: "",
      currencyOptions: [],
      budgetRoleAmount: 0,
      budgetRoleAmountCurrencyId: 3,
      feesAgreed: "",
      numberOfInvoices: 3,
      actualCompensation: 0,
      actualCompensationCurrencyId: "",
      retainerAdvanceAmount: 0,
      retainerAdvancePaidDate: "",
      secondInvoiceAmount: 0,
      thirdInvoiceAmount: 0,
      fixedPercentage: 0,
      allAggrementsList: [],
      isDropDownOpenId: '',
      previewAgreementData: {},
      disclosureAgreementCompanyData: RichTextEditor.createValueFromString(`<p>1. I, <em>Sourabh Joshi</em>, the undersigned individual (the <b><b>“Undersigned”</b></b>), am aware that "<b><b>xxxxxxx</b></b>" (hereinafter referred to as <b><b>“the Company") and "Purple Labs Search Private Limited"</b></b>, (hereinafter referred to as the <b><b>“Service Provider”</b></b>) have entered into a Service Agreement, pursuant to which I, the Undersigned, as personnel employed/engaged by the Service Provider and the Company may have access to Confidential Information pertaining to each other (as defined below). The Undersigned understands that as part of its obligations under the Agreement, the requisite personnel from respective Parties to this Agreement are required to enter into this Non-Disclosure Agreement to ensure understanding and compliance with the confidentiality and data protection obligations to the Agreement. The Undersigned is executing this agreement in consideration of retaining services with the Company.</p>
      <p><b><b>&nbsp;a. Confidential information</b></b> means any information obtained in oral and/or written form relating to the past, present or future business activities of the Company or the methods employed by the Service Provider for the purpose of recruiting prospective candidates sourced through them, its subsidiaries or affiliates, or their respective employees, customers or third party suppliers or contractors, including without limitation the terms and conditions of this Non-Disclosure Agreement, any information relating to plans, pricing, methods, methodologies, processes, financial data, lists, intellectual property rights, customer information, equipment, statistics, programs, research, development, information technology and any other proprietary, confidential or non-public information of each Party or any of its affiliates or subsidiaries or clients or third parties to whom either Party or its affiliates owe a duty of confidentiality and any other information which the undersigned knows or reasonably ought to know to be proprietary or confidential.</p>
      <p>&nbsp;b. <strong>Confidential Information </strong> does not include any particular information that can be demonstrated by the Undersigned as <b><b><strong>(i)</strong></b></b> currently in the public domain and/or previously known to the undersigned, and in either case free from any obligation to keep it confidential, <b><b><strong>(ii)</strong></b></b> publicly disclosed by either Party or any of its affiliates or subsidiaries either prior to or subsequent to receipt by the undersigned of such information, <b><b><strong>(iii)</strong></b></b> independently developed by the undersigned without any access to or use of the Confidential Information, or <b><b><strong>(iv)</strong></b></b> rightfully obtained undersigned from a third party lawfully in possession of the Confidential Information not bound by confidentiality obligations to either Party, or its affiliates or subsidiaries.&nbsp;</p>
      <p>c. The exceptions described in Clause 1 (b) above shall not apply to any information that would otherwise be considered Confidential Information and that is or relates to identifiable personal or financial information provided by individual employees, consumers or customers of either Party and/or its Affiliates and any list, description or other grouping of employees, consumers or customers that is derived using any such information and such information shall remain confidential in all circumstances.&nbsp;</p>
      <p>d. The Undersigned agrees to hold all Confidential Information in trust and confidence. Save as set forth in this Non-Disclosure Agreement or as otherwise may be authorized in writing, the Undersigned will not disclose to any person, firm or enterprise, or use for benefit, any Confidential Information. The Undersigned shall be entitled to disclose Confidential Information to other employees and contractors of the Service Provider who agree to abide by the confidentiality obligations substantially equivalent to those set forth in this Non-Disclosure Agreement and only on a “need to know basis”. The Undersigned shall be entitled to disclose Confidential Information if required to do so under applicable law, rule or order provided that the Undersigned, subject to it being, reasonably practicable and to the extent legally permissible, shall provide with prior written notice of the required disclosure so that the aggrieved party may seek a protective order or other appropriate remedy, and provided further that the Undersigned discloses no more Confidential Information than is reasonably necessary in order to respond to the required disclosure.</p>`, 'html'),
      disclosureAgreementCompanyName: "",
      returnOfConfidentialData: RichTextEditor.createValueFromString(`<p>At any time and in the event of termination or expiration of any service order executed under the Agreement and/or the Agreement or termination of the services with the Service Provider, the undersigned agrees to promptly: <b><b>(a)</b></b> return to the respective Party, the Confidential Information in their possession or <b><b>(b)</b></b> destroy or permanently erase (on all forms of recordable devices) the Confidential Information in his/her possession, to the satisfaction of the Party, and if requested by either Party,, acknowledge in writing that all such Confidential Information as applicable, has been destroyed or permanently erased.</p>`, 'html'),
      obligationData: RichTextEditor.createValueFromString(`<p>&emsp;&emsp;a. In consideration of his/her future or continued assignment and responsibilities in connection with Service Provider’s performance under the Agreement, the Undersigned hereby acknowledges, represents and confirms to both Parties as follows: <b><b>(i)</b></b> he/she has read the provisions of this Non-Disclosure Agreement and know of no agreements, obligations or restrictions which prevent or prohibit me from complying with them; <b><b>(ii)</b></b> he/she will receive and keep confidential the Confidential Information and, in all respects, conduct himself/herself in a manner consistent with these obligations; and <b><b>(iii)</b></b> he/she agrees not to, directly or indirectly, engage in or assist others to engage in, any activity or conduct which violates the provisions of this Non-Disclosure Agreement.&nbsp;</p>
      <p>&emsp;&emsp;b. The Undersigned affirms that he/she is not an employee of the Company for any purpose and that he/she is not entitled to exercise any rights, or seek any benefit, accruing to the regular employees of the Company by virtue of the Services performed by him/her. The Undersigned agrees to provide any assistance necessary to the respective Party in investigating any illegal or fraudulent activities, security breaches or similar situations.&nbsp;</p>
      <p>&emsp;&emsp;c. The Undersigned acknowledges that the Company has restrictions and procedures regarding the conduct of employees, consultants, contractors and agrees to comply with all such restrictions and procedures applicable to me.&nbsp;</p>
      <p>&emsp;&emsp;d. The Undersigned agrees to comply with the health and safety policy, fire precautions and all applicable security regulations (including those relating to systems security), together with other applicable policies and procedures,&nbsp;</p>
      <p>&emsp;&emsp;e. The Undersigned agrees that in connection with performing the Services, he/she may be subject to a background check, including employers, education, credit or other checking or testing permissible by law, and the Undersigned consents to the foregoing. The Undersigned consents to provide, within a reasonable period, such information and assistance as the Party may reasonably request in connection with such checking and testing.&nbsp;</p>
      <p>&emsp;&emsp;f. The Undersigned understands that if he/she threatens to or actually commits a breach of or fails to observe any of the obligations set forth in this Non-Disclosure Agreement, either Party may have no adequate remedy in money or damages and, accordingly, may seek an injunction against such breach; provided, however, that no specification herein of any particular legal or equitable remedy will be construed as a waiver, prohibition or limitation of any other legal or equitable remedies available.&nbsp;</p>
      <p>&emsp;&emsp;g. The Undersigned further understands that this Non-Disclosure Agreement is executed pursuant to the Agreement and shall be enforceable.</p>`, 'html'),
      nonPromotionData: RichTextEditor.createValueFromString(`<p>The Undersigned agrees that he/she will not, without the prior written consent of the Company in each instance: <b><b>(a)</b></b> use in advertising, publicity or otherwise the name of rather Party nor, any trade name, trade mark, trade device, service mark, symbol or any abbreviation, contraction or simulation thereof owned by either Party; or <b><b>(b)</b></b> represent, directly or indirectly, that any product or any service provided by the undersigned has been approved or endorsed by the Company hereinabove mentioned.</p>`, 'html'),
      disputeResolutionData: RichTextEditor.createValueFromString(`<p>a. All disputes pertaining to this Non-Disclosure Agreement shall be finally and exclusively settled under the agreement before the courts of Bangalore.&nbsp;<br>
      b. The undersigned further agrees that this Non-Disclosure Agreement will be governed by the laws of India, and subject to Clause (a) above, he/she irrevocably agrees to submit to the jurisdiction of the appropriate court located in Bangalore.</p>`, 'html'),
      injunctiveReliefData: RichTextEditor.createValueFromString(`<p>a. It is understood and agreed that in the event of a breach of the provisions of this Non-Disclosure Agreement, damages may not be an adequate remedy and the Party shall be entitled to apply for appropriate injunctive relief to restrain any such breach, threatened or actual from the Other Party.&nbsp;<br>
      b. The obligations created by this Agreement shall survive the termination of the services of the undersigned</p>`, 'html'),
      billingOrganizationinvoiceGSTNumber: "",
      billingOrganizationIsShippingAddressSame: false,
      billingOrganizationPincode: "",
      billingOrganizationCountry: "",
      billingOrganizationState: "",
      billingOrganizationCity: "",
      billingOrganizationAddress2: "",
      billingOrganizationAddress1: "",
      billingOrganizationShippingPincode: "",
      billingOrganizationShippingCountry: "",
      billingOrganizationShippingState: "",
      billingOrganizationShippingCity: "",
      billingOrganizationShippingAddress2: "",
      billingOrganizationShippingAddress1: "",
      payblePhoneNumber: "",
      paybleEmail: "",
      paybleName: "",
      paybleTitle: "",
      docuSignModal: false,
      docusignUserName: "",
      docusignDate: "",
      Recipient3Email: "",
      Recipient3FirstName: "",
      Recipient3LastName: "",
      Recipient2Email: "",
      Recipient2FirstName: "",
      Recipient2LastName: "",
      Recipient1Email: "",
      Recipient1FirstName: "",
      Recipient1LastName: "",
      docusignDocumentPDF: "",
      selectedAgreementID: "",
      billingOrganizationName: "",
      editAgreementState: false,
      editAgreementId: "",
      docuSignAgreementName: "",
      isTriggeredCalled: false,
      genrateInvoiceData: [],
      fourthInvoiceAmount: 0,
      nonDisabledButton: "",
      fourth_invoice_value: "",
      first_invoice_value: "",
      third_invoice_value: "",
      second_invoice_value: "",
      InvoiceCompanyName: "",
      isLoadingEvent: false,
      uploadModal: false,
      uploadedDocument: '',
      agreementList: [],
      selectedAgreement: "",
      selectedAgreementStatus: "",
      isInvoiceComplete: false,
      confirmDeleteModal: false,
      deleteId: "",
      noticesPinCodeError: null,
      billingOrganizationPincodeError: null,
      billingOrganizationShippingPincodeError: null,
      formError: null,
      billingOrganizationinvoiceGSTNumberError: null,
      noticesTelephoneError: null,
      payblePhoneNumberError: null
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            currentUser: responseJson?.data?.attributes,
            agreementUserName: responseJson?.data?.attributes?.first_name
          })
        }
        // get currencyData 
        if (apiRequestCallId === this.currencyApiCallId) {
          this.setState({
            currencyOptions: responseJson?.currency.map((x: any) => {
              return {
                label: x.currency_symbol, value: x.id
              }

            })
          })
        }
        // create Invoice 
        if (apiRequestCallId === this.createInvoiceApiCallID) {
          console.log("Create Invoice API :", responseJson)
        }
        if (apiRequestCallId === this.POSTAPICallSaveAggrement) {
          if (responseJson?.data) {
            alert('Saved Successfully');
            window.location.reload();
          } else {
            alert('something went Wrong');
          }
        }
        if (apiRequestCallId === this.GetAllAggrementsAPICall) {
          if (responseJson?.data) {
            this.setState({ allAggrementsList: responseJson.data, loader: false });
            this.setState({
              agreementList: responseJson.data.map((ele: any) => {
                return {
                  label: ele.attributes?.dynamic_data?.agreementName !== undefined ? ele.attributes?.dynamic_data?.agreementName : "Agreement", value: ele.id
                }
              })
            })
            // !this.state.isTriggeredCalled && this.triggerAllUpdateStatusofAgreements();
          }
        }
        if (apiRequestCallId === this.GetCurrentAgreementsAPICall) {
          this.setState({
            //service agreement
            serviceAgreementDate: responseJson?.data?.attributes?.dynamic_data?.service_agreement?.date,
            serviceAgreementLegalEntity: responseJson?.data?.attributes?.dynamic_data?.service_agreement?.legal_entity,
            serviceAgreementOfficeAddress: responseJson?.data?.attributes?.dynamic_data?.service_agreement?.office_address,
            serviceAgreementBusinessName: responseJson?.data?.attributes?.dynamic_data?.service_agreement?.business_name,
            serviceAgreementCompanyAct: responseJson?.data?.attributes?.dynamic_data?.service_agreement?.serviceAgreementCompanyAct,

            serviceAgreementData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.service_agreement, 'html'),

            //exclusitivity period
            scopeOfServicesExecutivePeriod: responseJson?.data?.attributes?.dynamic_data?.scope_of_services?.business_days,
            scopeOfServicesData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.scope_of_services, 'html'),

            // termsEngagementData
            termsEngagementData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.term_and_engagement, 'html'),
            commercialsData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.commercials, 'html'),
            independentContractorData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.independentContractorData, 'html'),
            warrantiesData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.warrantiesData, 'html'),
            indemnityData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.indemnityData, 'html'),
            limitationOfLiabilityData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.limitationOfLiabilityData, 'html'),
            confidentialityData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.confidentialityData, 'html'),
            terminationData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.terminationData, 'html'),
            consequencesOfTerminationData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.consequencesOfTerminationData, 'html'),
            // settlementOfDisputeAndJurisdictionData
            settlementCountry: responseJson?.data?.attributes?.dynamic_data?.settlementCountry,
            settlementCity: responseJson?.data?.attributes?.dynamic_data?.settlementCity,
            settlementOfDisputeAndJurisdictionData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.settlementOfDisputeAndJurisdictionData, 'html'),
            //notices
            noticesAddress: responseJson?.data?.attributes?.dynamic_data?.noticesAddress,
            noticesPinCode: responseJson?.data?.attributes?.dynamic_data?.noticesPinCode,
            noticesCity: responseJson?.data?.attributes?.dynamic_data?.noticesCity,
            noticesState: responseJson?.data?.attributes?.dynamic_data?.noticesState,
            noticesCountry: responseJson?.data?.attributes?.dynamic_data?.noticesCountry,
            noticesTelephone: responseJson?.data?.attributes?.dynamic_data?.noticesTelephone,
            noticesEmail: responseJson?.data?.attributes?.dynamic_data?.noticesEmail,
            noticesData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.noticesData, 'html'),
            // miscellaneousData
            miscellaneousData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.miscellaneousData, 'html'),
            //billing
            billingOrganizationName: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationName,
            billingOrganizationAddress1: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationAddress1,
            billingOrganizationAddress2: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationAddress2,
            billingOrganizationCity: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationCity,
            billingOrganizationState: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationState,
            billingOrganizationCountry: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationCountry,
            billingOrganizationPincode: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationPincode,
            billingOrganizationIsShippingAddressSame: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationIsShippingAddressSame === "true" ? true : false,
            billingOrganizationShippingAddress1: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationShippingAddress1,
            billingOrganizationShippingAddress2: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationShippingAddress2,
            billingOrganizationShippingCity: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationShippingCity,
            billingOrganizationShippingState: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationShippingState,
            billingOrganizationShippingCountry: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationShippingCountry,
            billingOrganizationShippingPincode: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationShippingPincode,
            billingOrganizationinvoiceGSTNumber: responseJson?.data?.attributes?.dynamic_data?.billingOrganizationinvoiceGSTNumber,
            paybleTitle: responseJson?.data?.attributes?.dynamic_data?.paybleTitle,
            paybleName: responseJson?.data?.attributes?.dynamic_data?.paybleName,
            paybleEmail: responseJson?.data?.attributes?.dynamic_data?.paybleEmail,
            payblePhoneNumber: responseJson?.data?.attributes?.dynamic_data?.payblePhoneNumber,
            //non disclousere agreement
            disclosureAgreementCompanyName: responseJson?.data?.attributes?.dynamic_data?.disclosureAgreementCompanyData?.company_name,
            disclosureAgreementCompanyData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.disclosureAgreementCompanyData, 'html'),
            returnOfConfidentialData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.returnOfConfidentialData, 'html'),
            obligationData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.obligationData, 'html'),
            nonPromotionData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.nonPromotionData, 'html'),
            disputeResolutionData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.disputeResolutionData, 'html'),
            injunctiveReliefData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.injunctiveReliefData, 'html'),
            //agreement
            agreementOrganizationName: responseJson?.data?.attributes?.dynamic_data?.agreementOrganizationName,
            agreementSignatoryName: responseJson?.data?.attributes?.dynamic_data?.agreementSignatoryName,
            agreementDesignation: responseJson?.data?.attributes?.dynamic_data?.agreementDesignation,
            agreementData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.agreementData, 'html'),
            //schduleA data
            scheduleFeeType: responseJson?.data?.attributes?.dynamic_data?.scheduleFeeType,
            scheduleFixedAmount: responseJson?.data?.attributes?.dynamic_data?.scheduleFixedAmount,
            retainerAdvance: responseJson?.data?.attributes?.dynamic_data?.retainerAdvance,
            businessDaysAdvancePayment: responseJson?.data?.attributes?.dynamic_data?.businessDaysAdvancePayment,
            secondInvoice: responseJson?.data?.attributes?.dynamic_data?.secondInvoice,
            businessDaysSecondPayment: responseJson?.data?.attributes?.dynamic_data?.businessDaysSecondPayment,
            finalPayment: responseJson?.data?.attributes?.dynamic_data?.finalPayment,
            businessDaysFinalPayment: responseJson?.data?.attributes?.dynamic_data?.businessDaysFinalPayment,
            feesBeyondDays: responseJson?.data?.attributes?.dynamic_data?.feesBeyondDays,
            feesBeyondPercentage: responseJson?.data?.attributes?.dynamic_data?.feesBeyondPercentage,
            scheduleAData: RichTextEditor.createValueFromString(responseJson?.data?.attributes?.static_data?.scheduleAData, 'html'),
            budgetRoleAmountCurrencyId: responseJson?.data?.attributes?.dynamic_data.currency_id
          })
        }

        if (apiRequestCallId === this.deleteApiCallId) {
          if (responseJson.message) {
            alert('Delete Successfully');
            this.getAllAggrements();
          }
        }

        if (apiRequestCallId === this.uploadDocSignPUTAPICall) {
          if (responseJson?.data) {
            this.callSaveDocuSign(this.state.isDropDownOpenId);
            alert('Document Uploaded successfully ..!')
          } else {
            alert("Try Again, Error in uploading file");
            console.log('Error occur', responseJson);
          }
          this.setState({ confirmModal: false, docuSignModal: false });
        }

        if (apiRequestCallId === this.saveDocuSignPOSTAPICall) {
          if (!responseJson?.error) {
            alert("Sent for DocuSign Successfully");
            await this.getAllAggrements();
          } else {
            alert("something Went Wrong for docusign Process, Make sure you have upload right PDF");
            console.log('Error docusign', responseJson);
          }
          this.setState({ docuSignModal: false })
        }

        if (apiRequestCallId === this.triggerStatusOfAgreements) {
          console.log(responseJson);
          await this.getAllAggrements();
          this.setState({ isTriggeredCalled: true })
        }

        // if (apiRequestCallId === this.GetGenrateInvoice) {
        //   if (responseJson?.data) {
        //     this.setState({
        //       menuType: 2,
        //       corporateAddress1: "1" || responseJson?.data?.attributes?.addresses[0].shipping_address?.address_one,
        //       corporateAddress2: "1" || responseJson?.data?.attributes?.addresses[0].shipping_address?.address_two,
        //       corporateCity: "1" || responseJson?.data?.attributes?.addresses[0].shipping_address?.city,
        //       corporateState: "1" || responseJson?.data?.attributes?.addresses[0].shipping_address?.state,
        //       corporateCountry: "1" || responseJson?.data?.attributes?.addresses[0].shipping_address?.country,
        //       corporatePincode: "1" || responseJson?.data?.attributes?.addresses[0].shipping_address?.pin_code,

        //       billingAddress1: "1" || responseJson?.data?.attributes?.addresses[1].billing_address?.address_one,
        //       billingAddress2: "1" || responseJson?.data?.attributes?.addresses[1].billing_address?.address_two,
        //       billingCity: "1" || responseJson?.data?.attributes?.addresses[1].billing_address?.city,
        //       billingState: "1" || responseJson?.data?.attributes?.addresses[1].billing_address?.state,
        //       billingCountry: "1" || responseJson?.data?.attributes?.addresses[1].billing_address?.country,
        //       billingPincode: "1" || responseJson?.data?.attributes?.addresses[1].billing_address?.pin_code,
        //       retainerAdvancePaidDate: responseJson?.data?.attributes?.paid_date,

        //       invoiceGSTNumber: responseJson?.data?.attributes?.gst_number,

        //       feesAgreed: responseJson?.data.attributes.payment.fee_type,
        //       numberOfInvoices: responseJson?.data.attributes.number_of_invoices,
        //       budgetRoleAmountCurrencyId: responseJson?.data?.attributes.currency_id === 0 ? 0 : responseJson?.data?.attributes.currency_id || 2,
        //       fixedPercentage: responseJson?.data?.attributes?.payment?.total_amount,
        //       retainerAdvanceAmount: responseJson?.data?.attributes?.payment?.first_invoice_amount,
        //       secondInvoiceAmount: responseJson?.data?.attributes?.payment?.second_invoice_amount,
        //       thirdInvoiceAmount: responseJson?.data?.attributes?.payment?.third_invoice_amount,
        //       fourthInvoiceAmount: responseJson?.data?.attributes?.payment?.fourth_invoice || responseJson?.data?.attributes?.payment?.fourth_invoice_value,
        //       nonDisabledButton: responseJson.data.attributes.invoice_type,
        //       second_invoice_value: responseJson.data.attributes.payment.second_invoice_value,
        //       third_invoice_value: responseJson.data.attributes.payment.third_invoice_value,
        //       first_invoice_value: responseJson.data.attributes.payment.first_invoice_value,
        //       fourth_invoice_value: responseJson.data.attributes.payment.fourth_invoice_value,
        //       actualCompensationCurrencyId: responseJson.data.attributes.currency_id,
        //       isShippingAddressSame: responseJson.data.attributes.billing_and_shipping_same,
        //       InvoiceCompanyName: responseJson.data.attributes.company_name,
        //     });

        //   }
        // }

        //Create Invoice
        if (apiRequestCallId === this.GetinvoiceCreateAPICall) {
          if (responseJson.invoice) {
            alert("Invoice Raised Successfully.");
          } else {
            alert("Some Error Occured")
          }
          this.setState({ menuType: 2, isLoadingEvent: false });
          window.location.reload();
        }
        if (apiRequestCallId === this.CreateSignedTemplateApiCallId) {
          if (responseJson.data) {
            alert("Document Uploaded Successfully");
            window.location.reload();
            this.setState({
              uploadModal: false
            })
          } else {
            alert("Some Error Occured");
          }

        }
      }
    }
    // Customizable Area End

  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    await this.getCurrentUser();
    await this.getCurrency();
    await this.getAllAggrements();

    let presentPath: any = window.location.href.split('=')[1];
    presentPath = presentPath === '2' ? 2 : 1;
    this.setState({ menuType: presentPath })
    if (window.localStorage.getItem('scroll') === "down") {
      this.scrollBottom()
    }

    document.addEventListener("mousedown", this.handleClickOutside)

    let url = new URLSearchParams(window.location.search)
    if (url.get('companyId') !== null) {
      this.setState({
        menuType: 2
      })
    }


  }

  // @ts-ignore
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e?: any) => {
    // @ts-ignore
    if (document.getElementById("view-modal-dropdown") && !document.getElementById("view-modal-dropdown").contains(e.target)) {
      this.setState({ isDropDownOpenId: null });
    }
  };

  handleUploadAgreement = () => {

    const header = {
      'token': window.localStorage.getItem('authToken'),
      // "Content-Type": configJSON.validationApiContentType,
    };

    var formData = new FormData();
    formData.append('template_file', this.state.uploadedDocument);
    formData.append('status', this.state.selectedAgreementStatus);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CreateSignedTemplateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_template/create_signed_template/${this.state.selectedAgreement}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


    return true
  }

  scrollBottom() {
    //@ts-ignore
    document.getElementById("agreement-company-page").scrollIntoView()
    window.localStorage.removeItem('scroll')
  }

  //sameAddress
  isSameAddress = () => {

    if (this.state.billingOrganizationIsShippingAddressSame === false) {
      this.setState({
        billingOrganizationShippingPincode: this.state.billingOrganizationPincode,
        billingOrganizationShippingCountry: this.state.billingOrganizationCountry,
        billingOrganizationShippingState: this.state.billingOrganizationState,
        billingOrganizationShippingCity: this.state.billingOrganizationCity,
        billingOrganizationShippingAddress2: this.state.billingOrganizationAddress2,
        billingOrganizationShippingAddress1: this.state.billingOrganizationAddress1
      })
    } else {
      this.setState({
        billingOrganizationShippingPincode: "",
        billingOrganizationShippingCountry: "",
        billingOrganizationShippingState: "",
        billingOrganizationShippingCity: "",
        billingOrganizationShippingAddress2: "",
        billingOrganizationShippingAddress1: ""
      })
    }
  }

  //getCurrency
  getCurrency = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currencyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currencyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //handleContent
  handleContent = (type: any) => {
    if (type === 'serviceAgreementData') {
      this.setState({
        serviceAgreementData: RichTextEditor.createValueFromString(`<p>This Service Agreement (hereinafter referred to as <b><b>“Agreement”</b></b>) dated <b><b>${this.state.serviceAgreementDate.length > 0 ? moment(this.state.serviceAgreementDate).format('Do MMMM YYYY') : "xxxxxxxxxx"
          }</b></b>, is made by and between:</p>
        <p><b><b>PurpleLabs Search Private Limited</b></b>, a company incorporated and registered under the Companies Act, 2013 having its registered office at “#257, 2nd Floor, 16th Cross, 5th Main, 6th Sector, HSR Layout, Bangalore 560102” (hereinafter referred to as “Service Provider" or “Purple Quarter”, which expression shall unless repugnant to the context and meaning thereof be deemed to mean and include its successors and assigns) of the <b><b>One Part;</b></b>&nbsp;</p>
        <p><b><b>AND:</b></b>&nbsp;</p>
        <p><b><b>${this.state.serviceAgreementLegalEntity.length > 0 ? this.state.serviceAgreementLegalEntity : "xxxxxxxxxx"
          }</b></b>, a company incorporated and registered under the <b><b>${this.state.serviceAgreementCompanyAct.length > 0 ? this.state.serviceAgreementCompanyAct : "xxxxxxxxxx"
          }</b></b> having its registered office at <b><b>${this.state.serviceAgreementOfficeAddress.length > 0 ? this.state.serviceAgreementOfficeAddress : "xxxxxxxxxx"
          }</b></b> (hereinafter referred to as “the Company", which expression shall unless repugnant to the context and meaning thereof be deemed to mean and include its successors and assigns) of the <b><b>Other Part;</b></b></p>
          <p>Service Provider and Company are singly referred to as <b><b>“Party”</b></b> and jointly referred to as <b><b>“Parties”</b></b> herein below.&nbsp;</p>
        <p><b><b>WHEREAS:</b></b>&nbsp;</p>
        <p>A. The Service Provider is engaged in the business of providing Retained Leadership level hiring to the IT Industry by sourcing candidates to their Clientele.&nbsp;</br>
        B. The Company is, inter alia, engaged in the business of <b><b>${this.state.serviceAgreementBusinessName?.length > 0 ? this.state.serviceAgreementBusinessName : "xxxxxxxxxx"}</b></b>&nbsp;</br>
        C. Based on the representations made by the Service Provider, the Company is desirous of engaging the Service Provider to perform the Services (as set out in Clause 1 below) and the Service Provider has agreed to do so in accordance with the terms and conditions specified in this Agreement.&nbsp;</br>
        D. The Parties are now desirous of recording their respective rights and obligations in relation to the Services in this Service Agreement.&nbsp;</br>
        E. “Business Day” shall mean a day other than Saturday, Sunday and any public or bank holiday in India.;&nbsp;</br>
        F. The Parties are further entering into a non-disclosure agreement dated as of the date hereof recording their mutual rights and obligations with respect to protecting the confidentiality of certain information of each party. (“Non-Disclosure Agreement”).</p>`, 'html')
      })
    }
    if (type === 'scopeOfServicesData') {
      this.setState({
        scopeOfServicesData: RichTextEditor.createValueFromString(`<p>1.1. The Service Provider shall provide CXO level hiring to the Company and, in this regard, source the candidates having the requisite qualifications to match the job description as provided by the Company during the Term.&nbsp;</br>
        1.2. Agreement applies complete exclusivity for a period of <b><b>${this.state.scopeOfServicesExecutivePeriod.length > 0 ? this.state.scopeOfServicesExecutivePeriod : "xxxxxxxxxx"
          }</b></b> business days to Purple Quarter and any reference(s) deemed internally by the Company during this period will be considered as Purple Quarter’s profile. The Service Provider's fee structure applies even if an internal candidate emerges as the Company's final choice, during the Exclusivity Period.&nbsp;</br>
        1.3. Exclusivity will commence on approval of the Purple Quarter Capsule (Talent Information deck), which will be after the date of execution of agreement.&nbsp;</br>
        1.4. Both parties will mutually agree on any changes to the services, including modifications of the job specification or candidate profile, and any pricing associated with such changes.&nbsp;</br>
        1.5. In case of a significant change in the requirement, the exclusivity period will commence from the date of change in the requirement and not from the approval of talent information deck as mentioned above.</p>`, 'html')
      })
    }
    if (type === 'settlementOfDisputeAndJurisdictionData') {
      this.setState({
        settlementOfDisputeAndJurisdictionData: RichTextEditor.createValueFromString(`<p>a) Any claim, dispute, or controversy arising out of, or in relation to, this Agreement, the interpretation thereof, the activities performed hereunder, or the breach thereof, which cannot be satisfactorily resolved by mutual understanding between the parties, shall be finally settled through arbitration in accordance with the provisions of the Indian Arbitration and Conciliation Act, 1996.&nbsp;</br>
  b) The venue of arbitration shall be exclusively at <b><b>${this.state.settlementCity.length > 0 ? this.state.settlementCity : "xxxxxx"
          }</b></b>, <b><b>${this.state.settlementCountry.length > 0 ? this.state.settlementCountry : "xxxxxx"
          }</b></b> and any award whether interim or final, shall be made, and shall be deemed for all purposes between the parties to be made, in <b><b>${this.state.settlementCity.length > 0 ? this.state.settlementCity : "xxxxxx"
          }</b></b>, <b><b>${this.state.settlementCountry.length > 0 ? this.state.settlementCountry : "xxxxxx"
          }</b></b>. The arbitral proceedings shall be conducted in English.&nbsp;</br>
  c) The award of the arbitrator shall be final and conclusive and binding upon the parties and shall be subject to execution in the Courts of <b><b>${this.state.settlementCity.length > 0 ? this.state.settlementCity : "xxxxxx"
          }</b></b>, <b><b>${this.state.settlementCountry.length > 0 ? this.state.settlementCountry : "xxxxxx"
          }</b></b>.&nbsp;</br>
  d) This Agreement will be construed in accordance with and governed by the Laws of India and each Party agrees to submit to the exclusive jurisdiction of the courts in the city of <b><b>${this.state.settlementCity.length > 0 ? this.state.settlementCity : "xxxxxx"
          }</b></b>, <b><b>${this.state.settlementCountry.length > 0 ? this.state.settlementCountry : "xxxxxx"
          }</b></b>.</p>`, 'html')
      })
    }
    if (type === 'noticesData') {
      this.setState({
        noticesData: RichTextEditor.createValueFromString(`<p>Any notice and other communications provided for in this Agreement shall be in writing and shall be first transmitted by facsimile/electronic transmission, and then confirmed by postage, prepaid registered airmail or by internationally recognized courier service, in the manner as elected by each of the Parties giving such notice to the following addresses:&nbsp;</p>
        <p>a. In the case of notices to the Company:&nbsp;</p>
        <p><i><b>Address</b></i> : ${this.state.noticesAddress.length > 0 ? this.state.noticesAddress : "xxxxxxxxxx"
          }, ${this.state.noticesCity}, ${this.state.noticesState}, ${this.state.noticesCountry}, ${this.state.noticesPinCode} &nbsp;</p>
        <p><i><b>Telephone</b></i> : ${this.state.noticesTelephone.length > 0 ? this.state.noticesTelephone : "xxxxxxxxxx"
          }&nbsp;</p>
        <p><b>Email</b> : ${this.state.noticesEmail.length > 0 ? this.state.noticesEmail : "xxxxxxxxxx"
          }&nbsp;</p>
        <p>b. In the case of notices to the Service Provider:&nbsp;</p>
        <p><i><b>Address</b></i> : #257, 2 nd Floor, 16 th Cross, 5 th Main, 6th Sector, HSR Layout, Bangalore 560 102&nbsp;</p>
        <p><i><b>Telephone</b></i> : +91 9555980278&nbsp;</p>
        <p><b>Email</b> : Sourabh.joshi@purplequarter.com</p>`, 'html')
      })
    }
    if (type === 'agreementData') {
      this.setState({
        agreementData: RichTextEditor.createValueFromString(`<p>Executed by the within named Undersigned by "PurpleLabs Search Private Limited" through authorized signatory&nbsp;</p>
        <p>Name: Sourabh Joshi&nbsp;</p>
        <p>Designation: VP Finance&nbsp;</p>
        <p>Date:&nbsp; <b><b>${this.state.agreementGenerateDate}</b></b></p>
        <p>Agreed and accepted by the within named <b><b>“${this.state.agreementOrganizationName.length > 0 ? this.state.agreementOrganizationName : "xxxxxxxxxx"
          }"</b></b> through its authorized signatory&nbsp;</p>
        <p>Name: <b><b>${this.state.agreementSignatoryName.length > 0 ? this.state.agreementSignatoryName : "xxxxxxxxxx"
          }</b></b></p>
        <p>Designation: <b><b>${this.state.agreementDesignation.length > 0 ? this.state.agreementDesignation : "xxxxxxxxxx"
          }</b></b></p>
        <p>Date:&nbsp; <b><b>${this.state.agreementGenerateDate}</b></b></p>`, 'html')
      })
    }
    if (type === 'scheduleAData') {
      this.setState({
        scheduleAData: RichTextEditor.createValueFromString(`<p><strong>Service Description</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Fee Per Position</strong>&nbsp;</p>
        <p><strong>Tech Head</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong><b><b>${this.state.scheduleFixedAmount.length > 0 ? this.state.scheduleFixedAmount : "xxxxxxx"}</b></b> ${this.state.scheduleFeeType === 'Fixed Fees' ? "Amount" : "%"} (${this.state?.currencyOptions[this.state?.budgetRoleAmountCurrencyId - 1]?.label}) of Total CTC of first year (all cash components apart from stocks)</strong>&nbsp;</p>
        <p>a) Retainer advance amounting to <b><b>${this.state.retainerAdvance.length > 0 ? this.state.retainerAdvance : "xxxxxxx"}</b></b>${this.state.scheduleFeeType === 'Fixed Fees' ? '' : "%"} of fees per position payable within <b><b>${this.state.businessDaysAdvancePayment.length > 0 ? this.state.businessDaysAdvancePayment : "xxxxxxx"}</b></b> calendar days of the signing of this agreement <b><b>(non-refundable)</b></b>. The invoice for the same shall be raised immediately after the signing of this agreement.&nbsp;</p>
        <p>b) Second payment of <b><b>${this.state.secondInvoice.length > 0 ? this.state.secondInvoice : "xxxxxxx"}</b></b>${this.state.scheduleFeeType === 'Fixed Fees' ? '' : "%"} of fees per position payable within <b><b>${this.state.businessDaysSecondPayment.length > 0 ? this.state.businessDaysSecondPayment : "xxxxxxx"}</b></b> calendar days of the candidate giving a written consent on the offer acceptance (email or in written). The invoice for the same shall be raised immediately after the candidate gives his/her written consent on the offer acceptance (email or in written).&nbsp;</p>
        <p>c) If the candidate search extends beyond <b><b>${this.state.feesBeyondDays.length > 0 ? this.state.feesBeyondDays : "xxxxxxx"}</b></b> calendar days from the date when exclusivity commences, then an invoice amounting to <b><b>${this.state.feesBeyondPercentage.length > 0 ? this.state.feesBeyondPercentage : "xxxxxxx"}</b></b>${this.state.scheduleFeeType === 'Fixed Fees' ? '' : "%"} of the total fees shall be raised.&nbsp;</p>
        <p>d) Final payment of <b><b>${this.state.finalPayment.length > 0 ? this.state.finalPayment : "xxxxxxx"}</b></b>${this.state.scheduleFeeType === 'Fixed Fees' ? '' : "%"} fees per position is payable within <b><b>${this.state.businessDaysFinalPayment.length > 0 ? this.state.businessDaysFinalPayment : "xxxxxxx"}</b></b> calendar days of the candidate joining the Company. The invoice for the same shall be raised immediately upon joining of the candidate at the Company.&nbsp;</p>
        <p>** All above percentages will be charged on the budget fixed for the role, <b><b>any changes in the compensation offered will be adjusted in the second and final invoice.</b></b>&nbsp;</p>
        <p>Note(s):&nbsp;</p>
        <p>● The above fees are exclusive of Goods &amp; Service tax (GST), the liability of which shall be borne by the Company.&nbsp;</br>
        ● The Company agrees to reimburse such out-of-pocket expenses and expenses incurred by the Service Provider in the performance of services incurred with the approval of the Company in writing.&nbsp;</br>
        ● If the candidate does not join the Company on the joining date, and if thereafter a replacement for such candidate, which shall be duly approved by the Company, is not identified by the Service Provider within 90 (ninety) business days from the date of joining of the initial candidate, then the Service Provider shall refund the Second payment to the Company within 30 (thirty) calendar days. The replacement clause remains standard.&nbsp;</br>
        ● The Company must notify the Service Provider in writing of any dispute regarding the amount of an invoice within 5 days from the date of receipt of the invoice, otherwise, it shall be deemed that the invoice is correct and has been accepted by the Company for making payment.&nbsp;</br>
        ● If payment is not made in a timely manner, Purple Quarter reserves the right to suspend services until all invoices are paid in full.</p>`, 'html')
      })
    }
    if (type === 'disclosureAgreementCompanyData') {
      this.setState({
        disclosureAgreementCompanyData: RichTextEditor.createValueFromString(`<p>1. I, Sourabh Joshi, the undersigned individual (the “Undersigned”), am aware that <b><b>${this.state.disclosureAgreementCompanyName.length > 0 ? this.state.disclosureAgreementCompanyName : "xxxxxxx"}</b></b> (hereinafter referred to as “the Company") and "Purple Labs Search Private Limited", (hereinafter referred to as the “Service Provider”) have entered into a Service Agreement, pursuant to which I, the Undersigned, as personnel employed/engaged by the Service Provider and the Company may have access to Confidential Information pertaining to each other (as defined below). The Undersigned understands that as part of its obligations under the Agreement, the requisite personnel from respective Parties to this Agreement are required to enter into this Non-Disclosure Agreement to ensure understanding and compliance with the confidentiality and data protection obligations to the Agreement. The Undersigned is executing this agreement in consideration of retaining services with the Company.</p>
        <p>&nbsp;a. Confidential information means any information obtained in oral and/or written form relating to the past, present or future business activities of the Company or the methods employed by the Service Provider for the purpose of recruiting prospective candidates sourced through them, its subsidiaries or affiliates, or their respective employees, customers or third party suppliers or contractors, including without limitation the terms and conditions of this Non-Disclosure Agreement, any information relating to plans, pricing, methods, methodologies, processes, financial data, lists, intellectual property rights, customer information, equipment, statistics, programs, research, development, information technology and any other proprietary, confidential or non-public information of each Party or any of its affiliates or subsidiaries or clients or third parties to whom either Party or its affiliates owe a duty of confidentiality and any other information which the undersigned knows or reasonably ought to know to be proprietary or confidential.</p>
        <p>&nbsp;b. Confidential Information does not include any particular information that can be demonstrated by the Undersigned as (i) currently in the public domain and/or previously known to the undersigned, and in either case free from any obligation to keep it confidential, (ii) publicly disclosed by either Party or any of its affiliates or subsidiaries either prior to or subsequent to receipt by the undersigned of such information, (iii) independently developed by the undersigned without any access to or use of the Confidential Information, or (iv) rightfully obtained undersigned from a third party lawfully in possession of the Confidential Information not bound by confidentiality obligations to either Party, or its affiliates or subsidiaries.&nbsp;</p>
        <p>c. The exceptions described in Clause 1 (b) above shall not apply to any information that would otherwise be considered Confidential Information and that is or relates to identifiable personal or financial information provided by individual employees, consumers or customers of either Party and/or its Affiliates and any list, description or other grouping of employees, consumers or customers that is derived using any such information and such information shall remain confidential in all circumstances.&nbsp;</p>
        <p>d. The Undersigned agrees to hold all Confidential Information in trust and confidence. Save as set forth in this Non-Disclosure Agreement or as otherwise may be authorized in writing, the Undersigned will not disclose to any person, firm or enterprise, or use for benefit, any Confidential Information. The Undersigned shall be entitled to disclose Confidential Information to other employees and contractors of the Service Provider who agree to abide by the confidentiality obligations substantially equivalent to those set forth in this Non-Disclosure Agreement and only on a “need to know basis”. The Undersigned shall be entitled to disclose Confidential Information if required to do so under applicable law, rule or order provided that the Undersigned, subject to it being, reasonably practicable and to the extent legally permissible, shall provide with prior written notice of the required disclosure so that the aggrieved party may seek a protective order or other appropriate remedy, and provided further that the Undersigned discloses no more Confidential Information than is reasonably necessary in order to respond to the required disclosure.</p>`, 'html')
      })
    }
  }

  //handleContent
  handleUpdateContent = (value: any, type: any) => {
    console.log(value?.toString('html'))
    if (type === 'serviceAgreementData') {
      this.setState({
        serviceAgreementData: value
      })
    }
    if (type === 'scopeOfServicesData') {
      this.setState({
        scopeOfServicesData: value
      })
    }
    if (type === 'termsEngagementData') {
      this.setState({
        termsEngagementData: value
      })
    }
    if (type === 'commercialsData') {
      this.setState({
        commercialsData: value
      })
    }
    if (type === 'independentContractorData') {
      this.setState({
        independentContractorData: value
      })
    }
    if (type === 'warrantiesData') {
      this.setState({
        warrantiesData: value
      })
    }
    if (type === 'indemnityData') {
      this.setState({
        indemnityData: value
      })
    }
    if (type === 'limitationOfLiabilityData') {
      this.setState({
        limitationOfLiabilityData: value
      })
    }
    if (type === 'confidentialityData') {
      this.setState({
        confidentialityData: value
      })
    }
    if (type === 'terminationData') {
      this.setState({
        terminationData: value
      })
    }
    if (type === 'consequencesOfTerminationData') {
      this.setState({
        consequencesOfTerminationData: value
      })
    }
    if (type === 'settlementOfDisputeAndJurisdictionData') {
      this.setState({
        settlementOfDisputeAndJurisdictionData: value
      })
    }
    if (type === 'noticesData') {
      this.setState({
        noticesData: value
      })
    }
    if (type === 'miscellaneousData') {
      this.setState({
        miscellaneousData: value
      })
    }
    if (type === 'agreementData') {
      this.setState({
        agreementData: value
      })
    }
    if (type === 'scheduleAData') {
      this.setState({
        scheduleAData: value
      })
    }
    if (type === 'disclosureAgreementCompanyData') {
      this.setState({
        disclosureAgreementCompanyData: value
      })
    }
    if (type === 'returnOfConfidentialData') {
      this.setState({
        returnOfConfidentialData: value
      })
    }
    if (type === 'obligationData') {
      this.setState({
        obligationData: value
      })
    }
    if (type === 'nonPromotionData') {
      this.setState({
        nonPromotionData: value
      })
    }
    if (type === 'disputeResolutionData') {
      this.setState({
        disputeResolutionData: value
      })
    }
    if (type === 'injunctiveReliefData') {
      this.setState({
        injunctiveReliefData: value
      })
    }
  }

  //getCurrentUser
  getCurrentUser = (): boolean => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //generateAgreement
  generateAgreement = (e: any) => {
    e.preventDefault();
    this.setState({
      companyActError: "",
      scheduleError: "",
      confirmModal: true,
      agreementFileName: `${this.state.agreementOrganizationName}-Service Agreement-Purple Quarter-${this.state.agreementGenerateDate}`
    })

    // Email validation if required
    // // @ts-ignore
    // let emailEle = document.querySelectorAll('input[type=email]');

    // for(let i=0;i<2;i++){
    //   // @ts-ignore
    //   if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailEle[i]?.value))){
    //     alert("You have entered an invalid email address!")
    //     return ;       
    //   }
    // }
  }

  saveAgreement = () => {
    let retainerAdvanceCalculatedAmount, thirdInvoiceCalculatedAmount, fourthInvoiceCalculatedAmount;
    let secondInvoiceCalculatedAmount;

    if (this.state.scheduleFeeType === 'Fixed Fees') {
      retainerAdvanceCalculatedAmount = this.state.retainerAdvance
      secondInvoiceCalculatedAmount = this.state.secondInvoice
      thirdInvoiceCalculatedAmount = this.state.finalPayment;
      fourthInvoiceCalculatedAmount = this.state.feesBeyondPercentage;
    } else {
      retainerAdvanceCalculatedAmount = this.state.scheduleFixedAmount * (this.state.retainerAdvance / 100);
      let amount = this.state.scheduleFixedAmount - retainerAdvanceCalculatedAmount;
      secondInvoiceCalculatedAmount = (this.state.secondInvoice * amount / 100);
      amount = amount - secondInvoiceCalculatedAmount;
      thirdInvoiceCalculatedAmount = amount - (this.state.finalPayment * amount / 100);
      amount = amount - thirdInvoiceCalculatedAmount;
      fourthInvoiceCalculatedAmount = (this.state.feesBeyondPercentage * amount / 100);
    }

    // let fixedAmountLeft = (parseInt(this.state.finalPayment) + parseInt(this.state.secondInvoice) + parseInt(this.state.retainerAdvance));


    let data = {
      "static_data": {
        "service_agreement": this.state.serviceAgreementData?.toString('html'),
        "scope_of_services": this.state.scopeOfServicesData?.toString('html'),
        "term_and_engagement": this.state.termsEngagementData?.toString('html'),
        "commercials": this.state.commercialsData?.toString('html'),
        "independentContractorData": this.state.independentContractorData?.toString('html'),
        "warrantiesData": this.state.warrantiesData?.toString('html'),
        "indemnityData": this.state.indemnityData?.toString('html'),
        "limitationOfLiabilityData": this.state.limitationOfLiabilityData?.toString('html'),
        "confidentialityData": this.state.confidentialityData?.toString('html'),
        "consequencesOfTerminationData": this.state.consequencesOfTerminationData?.toString('html'),
        "terminationData": this.state.terminationData?.toString('html'),
        "scheduleAData": this.state.scheduleAData?.toString('html'),
        "noticesData": this.state.noticesData?.toString('html'),
        "miscellaneousData": this.state.miscellaneousData?.toString('html'),
        "settlementOfDisputeAndJurisdictionData": this.state.settlementOfDisputeAndJurisdictionData?.toString('html'),
        "injunctiveReliefData": this.state.injunctiveReliefData?.toString('html'),
        "disputeResolutionData": this.state.disputeResolutionData?.toString('html'),
        "nonPromotionData": this.state.nonPromotionData?.toString('html'),
        "obligationData": this.state.obligationData?.toString('html'),
        "returnOfConfidentialData": this.state.returnOfConfidentialData?.toString('html'),
        "disclosureAgreementCompanyData": this.state.disclosureAgreementCompanyData?.toString('html'),
        "agreementData": this.state.agreementData?.toString('html')
      },
      "dynamic_data": {
        "service_agreement": {
          "date": this.state.serviceAgreementDate,
          "legal_entity": this.state.serviceAgreementLegalEntity,
          "office_address": this.state.serviceAgreementOfficeAddress,
          "business_name": this.state.serviceAgreementBusinessName,
          "serviceAgreementCompanyAct": this.state.serviceAgreementCompanyAct,
        },
        "scope_of_services": { "business_days": this.state.scopeOfServicesExecutivePeriod },
        "settlementCountry": this.state.settlementCountry,
        "settlementCity": this.state.settlementCity,
        "noticesAddress": this.state.noticesAddress,
        "noticesPinCode": this.state.noticesPinCode,
        "noticesCity": this.state.noticesCity,
        "noticesState": this.state.noticesState,

        "noticesCountry": this.state.noticesCountry,
        "noticesTelephone": this.state.noticesTelephone,
        "noticesEmail": this.state.noticesEmail,

        "agreementOrganizationName": this.state.agreementOrganizationName,
        "agreementSignatoryName": this.state.agreementSignatoryName,
        "agreementDesignation": this.state.agreementDesignation,
        "scheduleFeeType": this.state.scheduleFeeType,
        "scheduleFixedAmount": this.state.scheduleFixedAmount,
        "retainerAdvance": this.state.retainerAdvance,
        "businessDaysAdvancePayment": this.state.businessDaysAdvancePayment,
        "secondInvoice": this.state.secondInvoice,
        "businessDaysSecondPayment": this.state.businessDaysSecondPayment,
        "finalPayment": this.state.finalPayment,
        "businessDaysFinalPayment": this.state.businessDaysFinalPayment,
        "feesBeyondDays": this.state.feesBeyondDays,
        "feesBeyondPercentage": this.state.feesBeyondPercentage,
        "disclosureAgreementCompanyData": { "company_name": this.state.disclosureAgreementCompanyName },
        "agreementName": this.state.agreementFileName,
        "billingOrganizationinvoiceGSTNumber": this.state.billingOrganizationinvoiceGSTNumber,
        "billingOrganizationIsShippingAddressSame": this.state.billingOrganizationIsShippingAddressSame,
        "billingOrganizationPincode": this.state.billingOrganizationPincode,
        "billingOrganizationCountry": this.state.billingOrganizationCountry,
        "billingOrganizationState": this.state.billingOrganizationState,
        "billingOrganizationCity": this.state.billingOrganizationCity,
        "billingOrganizationAddress2": this.state.billingOrganizationAddress2,
        "billingOrganizationAddress1": this.state.billingOrganizationAddress1,
        "billingOrganizationShippingPincode": this.state.billingOrganizationShippingPincode,
        "billingOrganizationShippingCountry": this.state.billingOrganizationShippingCountry,
        "billingOrganizationShippingState": this.state.billingOrganizationShippingState,
        "billingOrganizationShippingCity": this.state.billingOrganizationShippingCity,
        "billingOrganizationShippingAddress2": this.state.billingOrganizationShippingAddress2,
        "billingOrganizationShippingAddress1": this.state.billingOrganizationShippingAddress1,
        "billingOrganizationName": this.state.billingOrganizationName,
        "payblePhoneNumber": this.state.payblePhoneNumber,
        "paybleEmail": this.state.paybleEmail,
        "paybleName": this.state.paybleName,
        "paybleTitle": this.state.paybleTitle,
        // Calculation]
        "retainerAdvanceCalculatedAmount": retainerAdvanceCalculatedAmount,
        "secondInvoiceCalculatedAmount": secondInvoiceCalculatedAmount,
        "thirdInvoiceCalculatedAmount": thirdInvoiceCalculatedAmount,
        "fourthInvoiceCalculatedAmount": fourthInvoiceCalculatedAmount,
        "numberOfInvoices": fourthInvoiceCalculatedAmount > 0 ? 4 : 3,
        "currency_id": this.state.budgetRoleAmountCurrencyId,
      }
    };

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.POSTAPICallSaveAggrement = requestMessage.messageId;

    this.state.editAgreementState ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_template/templates/${this.state.editAgreementId}`
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_template/templates"
      )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));

    this.state.editAgreementState ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.examplePUTMethod
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      )

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  createInvoice = (e: any) => {
    e.preventDefault()

    var formData = new FormData();
    formData.append('certificate', this.state.gstPanCertificateImage?.file);
    formData.append('gst_number', this.state.invoiceGSTNumber);
    formData.append('commercial_amount', this.state.budgetRoleAmount);
    formData.append('fees_type', this.state.feesAgreed);
    formData.append('currency_id', this.state.budgetRoleAmountCurrencyId);
    formData.append('fees_value', this.state.fixedPercentage);
    formData.append('number_of_invoices', this.state.numberOfInvoices);
    formData.append('retainer_agreement', this.state.retainerAdvanceAmount);
    formData.append('currency_amount', this.state.actualCompensation);
    formData.append('paid_date', this.state.retainerAdvancePaidDate);
    //@ts-ignore
    formData.append('template_id', 1);
    // shipping Address
    formData.append('addresses_attributes[0][address_one]', this.state.corporateAddress1);
    formData.append('addresses_attributes[0][address_two]', this.state.corporateAddress2);
    formData.append('addresses_attributes[0][city]', this.state.corporateCity);
    formData.append('addresses_attributes[0][state]', this.state.corporateState);
    formData.append('addresses_attributes[0][country]', this.state.corporateCountry);
    formData.append('addresses_attributes[0][pin_code]', this.state.corporatePincode);
    formData.append('addresses_attributes[0][address_type]', 'Corporate');
    // Billing Address
    formData.append('addresses_attributes[0][address_one]', this.state.billingAddress1);
    formData.append('addresses_attributes[0][address_two]', this.state.billingAddress2);
    formData.append('addresses_attributes[0][city]', this.state.billingCity);
    formData.append('addresses_attributes[0][state]', this.state.billingState);
    formData.append('addresses_attributes[0][country]', this.state.billingCountry);
    formData.append('addresses_attributes[0][pin_code]', this.state.billingPincode);
    formData.append('addresses_attributes[0][address_type]', 'Billing');

    const header = {
      'token': window.localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createInvoiceApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createInvoice
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getAllAggrements = () => {
    this.setState({
      loader: true
    })
    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllAggrementsAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_template/templates"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true

  }

  //delete aggrement
  deleteAggrement = (id: any) => {
    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_template/templates/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  callSaveDocuSign = (selectedId: any) => {

    let emailAdress = [{ "name": this.state.Recipient1FirstName, "email": this.state.Recipient1Email }, { "name": this.state.Recipient2FirstName, "email": this.state.Recipient2Email }, { "name": this.state.Recipient3FirstName, "email": this.state.Recipient3Email }];
    // @ts-ignore
    let templateData = {
      "agreement_id": this.state.selectedAgreementID,
      "email_subject": "Sign the Document With DocuSign",
      "email_body": "Kindly find Attached Document for your signature",
      "document_anchor_string": "(Sign here)",
      "anchor_x_offset": "-30",
      "anchor_y_offset": "35",
      "signers": emailAdress
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveDocuSignPOSTAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDocusign
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(templateData));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  uploadDocuSign = (e: any) => {
    e.preventDefault();

    var formData = new FormData();
    this.setState({ confirmModal: false })
    formData.append('template_file', this.state.docusignDocumentPDF.file);

    const header = {
      'token': window.localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadDocSignPUTAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_template/save_pdf/' + this.state.selectedAgreementID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  editAgreement = (id: any) => {
    this.setState({
      editAgreementState: true,
      editAgreementId: id
    })
    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentAgreementsAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_template/templates/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


    return true
  }

  // generateInvoice = (docuID: any) => {
  //   const header = {
  //     'token': window.localStorage.getItem('authToken'),
  //     "Content-Type": configJSON.validationApiContentType,
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.GetGenrateInvoice = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     "/bx_block_invoice/invoices/" + docuID
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.validationApiMethodType
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   return true
  // }

  raiseAgreementInvoice = (invoiceType: any) => {
    this.setState({ isLoadingEvent: true })
    // if (this.state.gstPanCertificateImage === '' && this.state.nonDisabledButton === 'first_invoice') {
    //   alert('Please Upload GST/PAN Certificate')
    //   this.setState({ isLoadingEvent: false })
    //   return;
    // }

    // Calculation for amount.
    let calculatedAmount = 0
    if (invoiceType === 'first_invoice') {
      calculatedAmount = this.state.feesAgreed === "Fixed Fees" ? this.state.retainerAdvanceAmount : (this.state.budgetRoleAmount * this.state.fixedPercentage / 100 * this.state.retainerAdvanceAmount / 100)
    } else if (invoiceType === 'second_invoice') {
      calculatedAmount = this.state.feesAgreed === "Fixed Fees" ? this.state.secondInvoiceAmount : (
        this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.secondInvoiceAmount / 100 +

        ((this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.retainerAdvanceAmount / 100) - (this.state.budgetRoleAmount * this.state.secondInvoiceAmount / 100 * this.state.retainerAdvanceAmount / 100)))
    } else if (invoiceType === 'third_invoice') {
      calculatedAmount = this.state.feesAgreed === "Fixed Fees" ? this.state.thirdInvoiceAmount : this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.thirdInvoiceAmount / 100
    } else if (invoiceType === 'fourth_invoice') {
      calculatedAmount = this.state.feesAgreed === "Fixed Fees" ? this.state.fourthInvoiceAmount : this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.fourthInvoiceAmount / 100
    }


    var formData = new FormData();
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let fullDate = `${day}/${month}/${year}`;


    formData.append('template_id', this.state.selectedAgreement);
    // formData.append('gst_number', this.state.invoiceGSTNumber);
    formData.append('budget_role_amount', this.state.budgetRoleAmount);
    formData.append('fees_type', this.state.feesAgreed);
    formData.append('number_of_invoices', this.state.numberOfInvoices || 3);
    formData.append('fee_agreed_amount', this.state.fixedPercentage);
    formData.append('currency_id', this.state.budgetRoleAmountCurrencyId || 3);
    formData.append('retainer_amount', invoiceType === 'first_invoice' ? this.state.retainerAdvanceAmount : invoiceType === 'second_invoice' ? this.state.secondInvoiceAmount : invoiceType === 'third_invoice' ? this.state.thirdInvoiceAmount : invoiceType === 'fourth_invoice' ? this.state.fourthInvoiceAmount : null);
    formData.append('paid_date', fullDate);
    // @ts-ignore
    formData.append('invoice_amount', calculatedAmount);

    if (invoiceType === 'second_invoice') {
      formData.append('actual_compensation', this.state.actualCompensation);
    }

    formData.append('invoice_type', invoiceType); //first, second, third, fourth

    const header = {
      'token': window.localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetinvoiceCreateAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_invoice/invoices'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  // Customizable Area End

}
