import React, { PureComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import ProtectedRoute from './ProtectedRoute.web';
import Candidates from '../../blocks/dashboard/src/Candidates.web';
import BasicDetails from '../../blocks/dashboard/src/BasicDetails.web';
import DomainKnowledge from '../../blocks/dashboard/src/DomainKnowledge.web'
import FamilyDetails from '../../blocks/dashboard/src/FamilyDetails.web'
import Compensation from '../../blocks/dashboard/src/Compensation.web'
import ExpectedCompensation from '../../blocks/dashboard/src/ExpectedCompensation.web'
import Competencies from '../../blocks/dashboard/src/Competencies.web'
import CandidateProfile from '../../blocks/dashboard/src/CandidateProfileInfo.web'
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import KanbanBoard from '../../blocks/KanbanBoard/src/KanbanBoard.web';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator.web';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder.web';
import CandidateDocuments from '../../blocks/dashboard/src/CandidateDocuments.web';
import CandidateCreateProfile from '../../blocks/dashboard/src/CandidateCreateProfile.web';
import ProposalGeneration2 from '../../blocks/ProposalGeneration2/src/ProposalGeneration2.web'
import VisualAnalytics from '../../blocks/VisualAnalytics/src/VisualAnalytics.web'
import CustomForm from '../../blocks/CustomForm/src/CustomForm.web';
import AuditTrail from '../../blocks/AuditTrail/src/AuditTrail.web'
import GraphicalCharts from '../../blocks/GraphicalCharts/src/GraphicalCharts.web'
class RouteConfig extends PureComponent {
  render() {
    const { history } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact>
            <EmailAccountLoginBlock />
          </Route>
          <Route path="/login" exact>
            <EmailAccountLoginBlock />
          </Route>
          <Route path="/forgotPassword" exact>
            <ForgotPassword />
          </Route>
          <Route path="/NewPassword" exact>
            <NewPassword />
          </Route>
          <ProtectedRoute path="/dashboard" exact>
            <KanbanBoard />
          </ProtectedRoute>
          <ProtectedRoute path="/reminder" exact>
            <TaskAllocator />
          </ProtectedRoute>
          <ProtectedRoute path="/user-tasks" exact>
            <TaskAllocator />
          </ProtectedRoute>
          <ProtectedRoute path="/Candidates" exact>
            <Candidates />
          </ProtectedRoute>
          <ProtectedRoute path="/BasicDetails" exact>
            <BasicDetails />
          </ProtectedRoute>
          <ProtectedRoute path="/BasicDetails/Edit-user" exact>
            <BasicDetails />
          </ProtectedRoute>
          <ProtectedRoute path="/DomainKnowledge" exact>
            <DomainKnowledge />
          </ProtectedRoute>
          <ProtectedRoute path="/DomainKnowledge/Edit-user" exact>
            <DomainKnowledge />
          </ProtectedRoute>
          <ProtectedRoute path="/FamilyDetails/Edit-user" exact>
            <FamilyDetails />
          </ProtectedRoute>
          <ProtectedRoute path="/FamilyDetails" exact>
            <FamilyDetails />
          </ProtectedRoute>
          <ProtectedRoute path="/Compensation" exact>
            <Compensation />
          </ProtectedRoute>
          <ProtectedRoute path="/Compensation/Edit-user" exact>
            <Compensation />
          </ProtectedRoute>
          <ProtectedRoute path="/ExpectedCompensation" exact>
            <ExpectedCompensation />
          </ProtectedRoute>
          <ProtectedRoute path="/ExpectedCompensation/Edit-user" exact>
            <ExpectedCompensation />
          </ProtectedRoute>
          <ProtectedRoute path="/Competencies" exact>
            <Competencies />
          </ProtectedRoute>
          <ProtectedRoute path="/Competencies/Edit-user" exact>
            <Competencies />
          </ProtectedRoute>
          <ProtectedRoute path="/CandidateProfile" exact>
            <CandidateProfile />
          </ProtectedRoute>
          <ProtectedRoute path="/pqCapsule" exact>
            <TeamBuilder />
          </ProtectedRoute>
          <ProtectedRoute path="/newclient" exact>
            <ProposalGeneration2 />
          </ProtectedRoute>
          <ProtectedRoute path="/candidateDocuments" exact>
            <CandidateDocuments />
          </ProtectedRoute>
          <ProtectedRoute path="/candidateCreateProfile" exact>
            <CandidateCreateProfile />
          </ProtectedRoute>
          <ProtectedRoute path="/reports" exact>
            <VisualAnalytics />
          </ProtectedRoute>
          <ProtectedRoute path="/companies" exact>
            <CustomForm />
          </ProtectedRoute>
          <ProtectedRoute path="/auditLogs" exact>
            <AuditTrail page='auditLogs' />
          </ProtectedRoute>
          <ProtectedRoute path="/teamVisibility" exact>
            <AuditTrail page='teamVisibility' />
          </ProtectedRoute>
          <ProtectedRoute path="/graphicalCharts" exact>
            <GraphicalCharts />
          </ProtectedRoute>
        </Switch>
      </Router>
    );
  }
}
export default RouteConfig;
