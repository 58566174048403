export const imageCover = require('../assets/image-cover.png')
export const status = require('../assets/image-status.png')
export const currentLocation = require('../assets/image-curren-location.png')
export const imageCase = require('../assets/image-case.png')
export const locaiton = require('../assets/image-location.png')
export const uploadIcon = require('../assets/upload-icon.png')
export const cities = require('../assets/cities.json')
export const documentIcon = require('../assets/document-icon.png')
export const uploadIcon2 = require("../assets/upload-icon2.png");

