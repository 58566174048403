Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.putApiMethod = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "KanbanBoard";
exports.labelBodyText = "KanbanBoard Body";
exports.candidatesGetUrl = "bx_block_candidate/candidates";
exports.jobGetUrl = "bx_block_job/jobs";
exports.dashboarApiMethodType = "GET";
exports.addTaskCall = 'bx_block_taskallocator/task_allocation';
exports.updateJobStatus = 'bx_block_job/jobs';
exports.getRemindersUrl = 'bx_block_notifications/reminders';
exports.allocateCandiate = 'bx_block_job/jobs/';

exports.btnExampleTitle = "CLICK ME";
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user"
exports.getAllTasks = "bx_block_taskallocator/task_allocation"
exports.addReminder = "bx_block_notifications/reminders";
exports.addNewJobAPI = "bx_block_job/jobs";
exports.getAllJobStatus ="bx_block_job/jobs/get_all_job_statuses";
exports.getAllCandidateStatus = "bx_block_job/jobs/get_candidate_job_statuses";
exports.updateCandidateDragStatus = "bx_block_job/jobs/";
exports.markTaskAsComplete = "bx_block_taskallocator/update_status/";
exports.getCandidateJobStatus = "bx_block_job/jobs/get_candidate_job_statuses"
exports.updateCandidateJobStatus = "/bx_block_job/jobs"
exports.getCompanies = "bx_block_company/companies/get_companies"



// Customizable Area End