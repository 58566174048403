import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import ProposalGeneration2Controller, {
  Props,
  configJSON
} from "./ProposalGeneration2Controller";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
import './styles.css'
import { TextField } from '@material-ui/core';
import Select from 'react-select'
import RichTextEditor from 'react-rte';
import { uploadIcon } from "./assets";
import Modal from "react-modal";
const { PreviewAgreementPdf } = require("./previewAgreementPdf");
import { Link, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Country, State, City }  from 'country-state-city';

//@ts-ignore
import Dropzone from 'react-dropzone'
import { isThisSecond } from "date-fns";
export default class ProposalGeneration2 extends ProposalGeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    let countryList = Country.getAllCountries().map(ele => ({'value': ele.isoCode,'label': ele.name }));
    // Customizable Area End
  }

  // Customizable Area Start
  


  handleFileChange = (event: any) => {
    let name: any = '';
    let target: any = '';
    let value: any = '';
    event && event.preventDefault();
    target = event.target;
    value = {
      file: target.files[0],
      fileName: target.files[0].name
    }
    name = target.name;
    //@ts-ignore
    this.setState({ [name]: value });
  }

  handleChange = (event: any, isSelectFields: any = false) => {
    let name: any = '';
    let target: any = '';
    let value: any = '';

    if (isSelectFields) {
      name = isSelectFields;
      value = event.value;
    } else {
      event && event.preventDefault();
      target = event.target;
      value = target.value;
      name = target.name;
    }

    //@ts-ignore
    this.setState({ ...this.state, [name]: value });

    if (isSelectFields === 'selectedAgreement') {
      this.setState({
        budgetRoleAmount: 0,
        feesAgreed: "",
        numberOfInvoices: 3,
        fixedPercentage: 0,
        budgetRoleAmountCurrencyId: 3,
        retainerAdvanceAmount: 0,
        retainerAdvancePaidDate: "",
        secondInvoiceAmount: 0,
        thirdInvoiceAmount: 0,
        fourthInvoiceAmount: 0,
        isInvoiceComplete: false,
      });
    }
    isSelectFields === 'selectedAgreement' && setTimeout(() => this.handleSelectedAgreementInvoice(), 200);
  }

  handleSectionHide = (hideSection: any) => {
    let displaySection = document.getElementById(hideSection);

    if (displaySection) {
      //@ts-ignore
      document.getElementById(hideSection).style.display = displaySection?.style?.display === 'none' ? '' : 'none'
    }
  }

  changeSymbol = (hideSection: any, hide: any) => {
    let displaySection = document.getElementById(hide);

    var add = `<h1 className="compress-section-aggrement">+</h1>`
    var remove = `<h1 className="compress-section-aggrement">—</h1>`
    //@ts-ignore
    document.getElementById(hide).style.display = displaySection?.style?.display === 'none' ? document.getElementById(hideSection).innerHTML = remove : document.getElementById(hideSection).innerHTML = add

  }

  confirmModal = () => {
    return <Modal
      isOpen={this.state.confirmModal}
      contentLabel=""
      className="confirm-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          zIndex: 9999999999
        },
      }}
    >
      <div >
        <h1>Save Agreement</h1>
        <div style={{ float: 'right', cursor: 'pointer', marginTop: '-10%', fontWeight: 'bold' }} onClick={() => {
          this.setState({ confirmModal: false })
        }}>X</div>
        <label className="my-label">File Name</label>
        <TextField
          id="outlined-secondary"
          required
          variant="outlined"
          color="primary"
          onChange={(e) => {
            this.handleChange(e)
          }}
          className="form-control input-field text-field-input mt-2 mb-3"
          name="agreementFileName"
          type="text"
          value={this.state.agreementFileName}
          inputProps={
            { readOnly: true, }
          }
        />
        <label className="my-label">Created On</label>
        <TextField
          id="outlined-secondary"
          label=""
          variant="outlined"
          color="primary"
          onChange={(e) => {
            this.handleChange(e)
          }}
          className="form-control input-field text-field-input mt-2 mb-3"
          name="agreementGenerateDate"
          type="text"
          aria-readonly
          value={this.state.agreementGenerateDate}
          inputProps={
            { readOnly: true, }
          }
        />
        <label className="my-label">Created By</label>
        <TextField
          id="outlined-secondary"
          label=""
          variant="outlined"
          color="primary"
          onChange={(e) => {
            this.handleChange(e)
          }}
          className="form-control input-field text-field-input mt-2 mb-3"
          name="agreementUserName"
          type="text"
          value={this.state.agreementUserName}
          inputProps={
            { readOnly: true, }
          }
        />
        <div style={{ textAlign: 'center' }}>
          <button type="submit" onClick={() => {
            this.saveAgreement();
            this.setState({ confirmModal: false })
          }} className="btn btn-sm agreement-button" >
            Save
          </button>
        </div>
      </div>
    </Modal >
  }

  updateCompanyModal = () => {
    this.setState({ previewAgreementModal: !this.state?.previewAgreementModal })
  }

  generateCompanyPdf = () => {
    return <Modal
      isOpen={this.state?.previewAgreementModal}
      contentLabel=""
      className=""
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          zIndex: 9999999999
        },
        content: {
          color: 'black',
        }
      }}
    >
      {PreviewAgreementPdf(() => this.updateCompanyModal, this.state?.previewAgreementData)}
    </Modal >
  }

  openDropDownModalUI = (ele: any) => {
    return <ul className="ellipsis-dropdown-ul" style={{

    }} id="view-modal-dropdown">
      <li className="ellipsis-dropdown-li" style={{ borderBottom: '1px solid lightgray' }} onClick={() => { this.setState({ previewAgreementModal: true }) }}>Preview/Download</li>
      {/* @ts-ignore */}
      {!this.props.companyAgreementSection && <li style={{ borderBottom: '1px solid lightgray' }} className="ellipsis-dropdown-li" onClick={() => {
        this.scrolldiv(); this.editAgreement(ele.id)
      }}>Edit</li>}
      {/* <li className="ellipsis-dropdown-li">Download</li> */}
      {/* <li className="ellipsis-dropdown-li">Upload</li> */}
      <li style={{ borderBottom: '1px solid lightgray' }} className="ellipsis-dropdown-li" onClick={() => {
        this.setState({
          deleteId: ele.id,
          confirmDeleteModal: true
        })
      }}>Delete</li>

      {/* {(ele.attributes.signed_status === "Not Shared") &&
        <li
          className="ellipsis-dropdown-li"
          onClick={() => this.setState({ docuSignModal: true, selectedAgreementID: ele.id })}>DocuSign</li>} */}
      {/* @ts-ignore */}
      {/* {(ele.attributes.signed_status === true) && (!ele.attributes.invoices_completed) && (this.props.companyAgreementSection && this.props.menuType === 'inovices') &&
        <li
          className="ellipsis-dropdown-li"

          onClick={() => {
            this.generateInvoice(ele.attributes?.invoices?.data[ele.attributes?.invoices?.data.length - 1].id);
            this.setState({ selectedAgreementID: ele.id })

          }}>

          {
            // @ts-ignore
            this.props.companyAgreementSection ? <a href={"/newclient?id=2"}>Generate Invoice</a> : 'Generate Invoice'}
        </li>} */}

    </ul >
  }

  confirmDeleteModal = () => {
    return <Modal
      onRequestClose={() => this.setState({ confirmDeleteModal: false })}
      isOpen={this.state.confirmDeleteModal}
      contentLabel=""
      className="logout-modal"

      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)',
          //@ts-ignore
          zIndex: '9999999999999999999999'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row ">
            <div className="mt-1 mb-3" style={{ textAlign: 'center' }}>
              {/* <label className="task-label" >  Are you sure? <br /><br /></label> */}
              <label className="task-label" style={{ textAlign: 'justify' }}>
                Are you sure you want to delete this agreement ?
                {/* All PQ Capsules, Invoices and  Jobs created for this agreement will be deleted. */}
              </label>
            </div>
            <div className="col-6" style={{ textAlign: 'right' }}>
              <button type="button" onClick={() => {
                this.deleteAggrement(this.state.deleteId)
                this.setState({ confirmDeleteModal: false })
              }} style={{ width: '-webkit-fill-available' }} className="btn auth-button">Yes</button>
            </div>
            <div className="col-6">
              <button type="button" className="btn auth-button" style={{ width: '-webkit-fill-available' }} onClick={() => { this.setState({ confirmDeleteModal: false }) }}>No</button>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  }

  docusignModal = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let fullDate = `${day}/${month}/${year}`;
    return <Modal isOpen={this.state.docuSignModal}
      style={{
        overlay: {
          zIndex: 99999999999999999,
          backgroundColor: 'rgb(49 42 42 / 90%)',
        },
        content: {
          color: 'black',
        }
      }}
      className="docusign-modal"
    >
      <br />

      <div className="cross-docusign" onClick={() => {
        this.setState({ docuSignModal: false, selectedAgreementID: null, docusignDocumentPDF: "", confirmModal: false })
      }}>X</div>
      <h3 style={{ textAlign: 'center', width: '90%', lineBreak: 'auto' }} className="">{this.state.docuSignAgreementName}</h3>


      <form className="" onSubmit={(e) => { this.uploadDocuSign(e) }}>
        {/* <div style={{margin: '130px', marginTop: '0px'}}> */}
        <div className="row mt-2 mb-3">
          <div className='col-6'>
            <label className="my-label">Recipient 1 - First Name</label>
            <TextField
              id="outlined-secondary"
              // label="Recipient 1 - First Name"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleChange(e)}
              className="form-control input-field text-field-input"
              name="Recipient1FirstName"
              type="text"
              required
            />
          </div>

          <div className='col-6'>
            <label className="my-label">Last Name</label>
            <TextField
              id="outlined-secondary"
              // label="Last Name "
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleChange(e)}
              className="form-control input-field text-field-input"
              name="Recipient1LastName"
              type="text"
              required
            />
          </div>
        </div>


        <div className="mt-2 mb-3">
          <label className="my-label">Recipient 1 - Email</label>
          <TextField
            id="outlined-secondary"
            // label="Recipient 1 - Email"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name="Recipient1Email"
            type="email"
            required
          />
        </div>

        <div className="row mt-2 mb-3">
          <div className='col-6'>
            <label className="my-label">Recipient 2 - First Name</label>
            <TextField
              id="outlined-secondary"
              // label="Recipient 2 - First Name"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleChange(e)}
              className="form-control input-field text-field-input"
              name="Recipient2FirstName"
              type="text"
              required
            />
          </div>

          <div className='col-6'>
            <label className="my-label">Last Name</label>
            <TextField
              id="outlined-secondary"
              // label="Last Name"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleChange(e)}
              className="form-control input-field text-field-input"
              name="Recipient2LastName"
              type="text"
              required
            />
          </div>
        </div>

        <div className="mt-2 mb-3">
          <label className="my-label">Recipient 2 - Email</label>
          <TextField
            id="outlined-secondary"
            // label="Recipient 2 - Email"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name="Recipient2Email"
            type="email"
            required
          />
        </div>

        <div className="row mt-2 mb-3">
          <div className='col-6'>
            <label className="my-label">Recipient 3 - First Name</label>
            <TextField
              id="outlined-secondary"
              // label="Recipient 3 - First Name"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleChange(e)}
              className="form-control input-field text-field-input"
              name="Recipient3FirstName"
              type="text"
              required
            />
          </div>

          <div className='col-6'>
            <label className="my-label">Last Name</label>
            <TextField
              id="outlined-secondary"
              // label="Last Name"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleChange(e)}
              className="form-control input-field text-field-input"
              name="Recipient3LastName"
              type="text"
              required
            />
          </div>
        </div>

        <div className="mt-2 mb-3">
          <label className="my-label">Recipient 3 - Email</label>
          <TextField
            id="outlined-secondary"
            // label="Recipient 3 - Email"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name="Recipient3Email"
            type="email"
            required
          />
        </div>
        {/* <span style={{ margin: '10px' }}>Date:</span> */}
        <div className="mt-2 mb-3">
          <label className="my-label">Date</label>
          <TextField
            id="outlined-secondary"
            // label="Date"
            variant="outlined"
            color="primary"
            // onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name="docusignDate"
            type="text"
            value={fullDate}
          // required
          />
        </div>

        <div className="mt-2 mb-3">
          <label className="my-label">User Name</label>
          <TextField
            id="outlined-secondary"
            // label="User Name"
            variant="outlined"
            color="primary"
            onChange={(e) => this.handleChange(e)}
            className="form-control input-field text-field-input"
            name="docusignUserName"
            value={this.state?.currentUser?.first_name}
            type="text"
            required
          />
        </div>

        <span style={{ margin: '10px' }}>Upload Pdf File for Docusign</span>
        <div className="col-6">
          <label className="btn-upload-agreement" style={{ marginTop: '10px' }}>
            <input type='file' accept="pdf" name="docusignDocumentPDF" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
            <button type="button" className="btn">
              <img src={uploadIcon} className="upload-icon" />
              Upload PDF
            </button>
          </label>
          <br />
          <span className="file-type">File Type: pdf</span>
        </div>

        <div className="col-5 file-label"><i> {this.state.docusignDocumentPDF?.fileName !== undefined ? this.state.docusignDocumentPDF?.fileName : "No Image Uploaded"}</i></div>
        <br />
        <div className="center-item-button">
          <button className="btn save-button2" type="submit">Save</button>
        </div>
        <br />
      </form>
    </Modal>

  }

  uploadModal = () => {
    return <Modal isOpen={this.state.uploadModal}
      style={{
        overlay: {
          zIndex: 99999999999999999,
          backgroundColor: 'rgb(49 42 42 / 90%)',
        },
        content: {
          color: 'black',
        }
      }}
      className="docusign-modal"
    >
      <br />

      <div className="cross-docusign" onClick={() => {
        this.setState({ uploadModal: false })
      }}>X</div>
      <div className="mt-2 mb-3">
        <label className="my-label">Select Agreement</label>
        <Select styles={customStyles} options={this.state.agreementList} placeholder={""} onChange={(selectedOption) => this.handleChange(selectedOption, 'selectedAgreement')} />
      </div>
      <div className="upload-agreement">
        <Dropzone onDrop={(acceptedFiles: any) => this.setState({
          uploadedDocument: acceptedFiles[0]
        })}>
          {({ getRootProps, getInputProps }: any) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center', marginTop: '33px' }}>
                  <p style={{ fontSize: '64px' }}>+</p>
                  <p style={{ marginTop: '10px', color: '#b006ae' }}>
                    {
                      this.state.uploadedDocument !== "" ?
                        this.state.uploadedDocument.name
                        :
                        ""
                    }
                  </p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div className="mt-2 mb-3">
        <label className="my-label">Agreement Status</label>
        <Select styles={customStyles} options={[{ value: 'Signed', label: 'Signed' }, { value: 'UnSigned', label: 'UnSigned' }]} placeholder={""} onChange={(selectedOption) => this.handleChange(selectedOption, 'selectedAgreementStatus')} />
      </div>
      <div className="mt-2 mb-3">
        <button className="btn doc-save-btn" onClick={() => this.handleUploadAgreement()}>Save</button>
      </div>
    </Modal>

  }

  scrolldiv() {
    //@ts-ignore
    document.getElementById("serviceAgreement").scrollIntoView()
  }

  Agreement = () => {
    let firstPercentAmount = this.state.scheduleFixedAmount - this.state.scheduleFixedAmount * (this.state.retainerAdvance / 100);
    let secondPercentAmount = firstPercentAmount - (this.state.secondInvoice * firstPercentAmount / 100);
    let finalPercentAmount = secondPercentAmount - (this.state.finalPayment * secondPercentAmount / 100);
    let fixedAmountLeft = (parseInt(this.state.finalPayment) + parseInt(this.state.secondInvoice) + parseInt(this.state.retainerAdvance));

    return (
      <div>
        {this.confirmModal()}
        {this.generateCompanyPdf()}
        {this.docusignModal()}
        {this.uploadModal()}
        {this.confirmDeleteModal()}

        {/* @ts-ignore */}
        {!!this.props.companyAgreementSection ? "" : <form onSubmit={(e) => {
          if (this.state.noticesPinCodeError === null &&
            this.state.billingOrganizationPincodeError === null &&
            this.state.billingOrganizationShippingPincodeError === null && this.state.billingOrganizationinvoiceGSTNumberError === null && this.state.noticesTelephoneError === null && this.state.payblePhoneNumberError === null) {
            this.generateAgreement(e)
            this.setState({ formError: null })
          } else {
            this.setState({ formError: "Please Enter Valid Data " })
          }
        }}>
          {/* Service Agreement */}

          <h1 className="compress-section-aggrement" id="serviceAgreement" onClick={() => {
            this.changeSymbol('serviceAgreement', 'serviceAgreementSection')
            this.handleSectionHide('serviceAgreementSection')
          }}>—</h1>
          <p className="heading-subhead">A. SERVICE AGREEMENT</p>
          <div className="cover-page" id="serviceAgreementSection">

            <div>
              <div className="row mt-1 mb-3">
                <div className="col-6">
                  <label className="my-label">Date</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Date"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('serviceAgreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="serviceAgreementDate"
                    type="text"
                    InputProps={{ inputProps: { max: '9999-12-31' } }}
                    value={this.state.serviceAgreementDate}
                    //@ts-ignore
                    onFocus={(e) => e.target.type = 'date'}
                    //@ts-ignore
                    onBlur={(e) => e.target.type = 'text'}

                  />
                </div>
                <div className="col-6">
                  <label className="my-label">Legal Entity</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Legal Entity"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('serviceAgreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="serviceAgreementLegalEntity"
                    type="text"
                    value={this.state.serviceAgreementLegalEntity}

                  />
                </div>
              </div>
              <div className="row mt-1 mb-3">
                <div className="col-6">
                  <label className="my-label">Office Address</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Office Address"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('serviceAgreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="serviceAgreementOfficeAddress"
                    type="text"
                    value={this.state.serviceAgreementOfficeAddress}
                  />
                </div>
                <div className="col-6">
                  <label className="my-label">Company Scope of Work</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Business Name"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('serviceAgreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="serviceAgreementBusinessName"
                    type="text"
                    value={this.state.serviceAgreementBusinessName}

                  />
                </div>
              </div>
              <div className="row mt-1 mb-3">
                <div className="col-12">
                  <label className="my-label">Select Company Act</label>
                  <Select
                    options={[{ value: 'Companies Act, 2013', label: 'Companies Act, 2013' }, { value: 'Companies Act, 1956', label: 'Companies Act, 1956' }]}
                    // @ts-ignore
                    placeholder={this.state.serviceAgreementCompanyAct?.length > 0 ? this.state.serviceAgreementCompanyAct : ''}
                    onChange={(e) => {
                      this.handleChange(e, 'serviceAgreementCompanyAct')
                      setTimeout(() => {
                        this.handleContent('serviceAgreementData')
                      }, 100)
                    }}
                    name={`serviceAgreementCompanyAct`}
                    className="company-act-select"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div>
                <RichTextEditor
                  //@ts-ignore
                  value={this.state.serviceAgreementData}
                  //@ts-ignore
                  onChange={(value) => this.handleUpdateContent(value, 'serviceAgreementData')}
                  className="mt-2 mb-3 text-editor"
                />
              </div>
            </div>

            {/* Scope Of Services */}
            <div>
              <h1 className="compress-section-aggrement" id="scopeOfService" onClick={() => {
                this.changeSymbol('scopeOfService', 'scopeOfServiceSection')
                this.handleSectionHide('scopeOfServiceSection')
              }}>—</h1>
              <p className="heading-subhead-sub">1. Scope Of Services</p>
              <div className="cover-page" id="scopeOfServiceSection">
                <div className="row mt-1 mb-3">
                  <div className="col-12">
                    <label className="my-label">Exclusivity Period in days</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Exclusivity Period in days"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scopeOfServicesData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="scopeOfServicesExecutivePeriod"
                      type="number"
                      value={this.state.scopeOfServicesExecutivePeriod}
                    />
                  </div>
                </div>
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.scopeOfServicesData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'scopeOfServicesData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* Terms & Engagement */}
            <div>
              <h1 className="compress-section-aggrement" id="termsEngagement" onClick={() => {
                this.changeSymbol('termsEngagement', 'termsEngagementSection')
                this.handleSectionHide('termsEngagementSection')
              }}>—</h1>
              <p className="heading-subhead-sub">2. Terms & Engagement</p>
              <div className="cover-page" id="termsEngagementSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.termsEngagementData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'termsEngagementData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* Commercials */}
            <div>
              <h1 className="compress-section-aggrement" id="commercials" onClick={() => {
                this.changeSymbol('commercials', 'commercialsSection')
                this.handleSectionHide('commercialsSection')
              }}>—</h1>
              <p className="heading-subhead-sub">3. Commercials</p>
              <div className="cover-page" id="commercialsSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.commercialsData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'commercialsData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* Independent Contractor*/}
            <div>
              <h1 className="compress-section-aggrement" id="independentContractor" onClick={() => {
                this.changeSymbol('independentContractor', 'independentContractorSection')
                this.handleSectionHide('independentContractorSection')
              }}>—</h1>
              <p className="heading-subhead-sub">4. Independent Contractor</p>
              <div className="cover-page" id="independentContractorSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.independentContractorData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'independentContractorData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* Warranties*/}
            <div>
              <h1 className="compress-section-aggrement" id="warranties" onClick={() => {
                this.changeSymbol('warranties', 'warrantiesSection')
                this.handleSectionHide('warrantiesSection')
              }}>—</h1>
              <p className="heading-subhead-sub">5. Warranties</p>
              <div className="cover-page" id="warrantiesSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.warrantiesData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'warrantiesData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/*Indemnity*/}
            <div>
              <h1 className="compress-section-aggrement" id="indemnity" onClick={() => {
                this.changeSymbol('indemnity', 'indemnitySection')
                this.handleSectionHide('indemnitySection')
              }}>—</h1>
              <p className="heading-subhead-sub">6. Indemnity</p>
              <div className="cover-page" id="indemnitySection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.indemnityData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'indemnityData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* 7. Limitation Of Liability*/}
            <div>
              <h1 className="compress-section-aggrement" id="limitationOfLiability" onClick={() => {
                this.changeSymbol('limitationOfLiability', 'limitationOfLiabilitySection')
                this.handleSectionHide('limitationOfLiabilitySection')
              }}>—</h1>
              <p className="heading-subhead-sub">7. Limitation Of Liability</p>
              <div className="cover-page" id="limitationOfLiabilitySection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.limitationOfLiabilityData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'limitationOfLiabilityData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* 8. Confidentiality*/}
            <div>
              <h1 className="compress-section-aggrement" id="confidentiality" onClick={() => {
                this.changeSymbol('confidentiality', 'confidentialitySection')
                this.handleSectionHide('confidentialitySection')
              }}>—</h1>
              <p className="heading-subhead-sub">8. Confidentiality</p>
              <div className="cover-page" id="confidentialitySection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.confidentialityData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'confidentialityData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* 9. Termination*/}
            <div>
              <h1 className="compress-section-aggrement" id="termination" onClick={() => {
                this.changeSymbol('termination', 'terminationSection')
                this.handleSectionHide('terminationSection')
              }}>—</h1>
              <p className="heading-subhead-sub">9. Termination</p>
              <div className="cover-page" id="terminationSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.terminationData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'terminationData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* 10. Consequences Of Termination*/}
            <div>
              <h1 className="compress-section-aggrement" id="consequencesOfTermination" onClick={() => {
                this.changeSymbol('consequencesOfTermination', 'consequencesOfTerminationSection')
                this.handleSectionHide('consequencesOfTerminationSection')
              }}>—</h1>
              <p className="heading-subhead-sub">10. Consequences Of Termination</p>
              <div className="cover-page" id="consequencesOfTerminationSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.consequencesOfTerminationData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'consequencesOfTerminationData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/* 11. Settlement Of Dispute & Jurisdiction*/}
            <div>
              <h1 className="compress-section-aggrement" id="settlementOfDisputeandJurisdiction" onClick={() => {
                this.changeSymbol('settlementOfDisputeandJurisdiction', 'settlementOfDisputeandJurisdictionSection')
                this.handleSectionHide('settlementOfDisputeandJurisdictionSection')
              }}>—</h1>
              <p className="heading-subhead-sub">11. Settlement Of Dispute & Jurisdiction</p>
              <div className="cover-page" id="settlementOfDisputeandJurisdictionSection">
                <div className="row mt-1 mb-3">
                  <div className="col-12">
                    <label className="my-label">Country</label>
                    <TextField
                      id="outlined-secondary"
                      required

                      // label="Country"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        const re = /^[a-zA-Z\s]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.handleChange(e)
                          setTimeout(() => {
                            this.handleContent('settlementOfDisputeAndJurisdictionData')
                          }, 100)
                        }
                      }
                      }
                      className="form-control input-field text-field-input"
                      name="settlementCountry"
                      type="text"
                      value={this.state.settlementCountry}
                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-12">
                    <label className="my-label">State/City</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="State/City"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        const re = /^[a-zA-Z\s]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.handleChange(e)
                          setTimeout(() => {
                            this.handleContent('settlementOfDisputeAndJurisdictionData')
                          }, 100)
                        }
                      }}
                      className="form-control input-field text-field-input"
                      name="settlementCity"
                      type="text"
                      value={this.state.settlementCity}
                    />
                  </div>
                </div>
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.settlementOfDisputeAndJurisdictionData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'settlementOfDisputeAndJurisdictionData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/*12. Notices*/}
            <div>
              <h1 className="compress-section-aggrement" id="notices" onClick={() => {
                this.changeSymbol('notices', 'noticesSection')
                this.handleSectionHide('noticesSection')
              }}>—</h1>
              <p className="heading-subhead-sub">12. Notices</p>
              <div className="cover-page" id="noticesSection">
                <div className="row mt-1 mb-3">
                  <div className="col-12">
                    <label className="my-label">Address</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Address"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('noticesData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesAddress"
                      type="text"
                      value={this.state.noticesAddress}
                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">Pin Code</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Pin Code"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('noticesData')
                        }, 100)
                        var regExp = /^[1-9][0-9]{5}$/;
                        if (e.target.value === "" || regExp.test(e.target.value) === true) {
                          this.setState({ noticesPinCodeError: null })
                        } else {
                          this.setState({ noticesPinCodeError: "Invalid Pin Code" })
                        }
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesPinCode"
                      type="text"
                      value={this.state.noticesPinCode}

                    />
                    <label style={{ color: 'red', fontSize: '12px' }}>{this.state.noticesPinCodeError !== null ? this.state.noticesPinCodeError : ""}</label>
                  </div>
                  <div className="col-6">
                    <label className="my-label">City</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="City"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        const re = /^[a-zA-Z\s]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.handleChange(e)
                          setTimeout(() => {
                            this.handleContent('noticesData')
                          }, 100)
                        }
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesCity"
                      type="text"
                      value={this.state.noticesCity}

                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">State</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="State"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        const re = /^[a-zA-Z\s]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.handleChange(e)
                          setTimeout(() => {
                            this.handleContent('noticesData')
                          }, 100)
                        }
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesState"
                      type="text"
                      value={this.state.noticesState}

                    />
                  </div>
                  <div className="col-6">
                    <label className="my-label">Country</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Country"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        const re = /^[a-zA-Z\s]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          this.handleChange(e)
                          setTimeout(() => {
                            this.handleContent('noticesData')
                          }, 100)
                        }
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesCountry"
                      type="text"
                      value={this.state.noticesCountry}

                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">Telephone</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Telephone"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('noticesData')
                        }, 100)
                        var regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/;
                        if (e.target.value === "" || regExp.test(e.target.value) === true) {
                          this.setState({ noticesTelephoneError: null })
                        } else {
                          this.setState({ noticesTelephoneError: "Invalid Telephone Number" })
                        }
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesTelephone"
                      type="number"
                      value={this.state.noticesTelephone}

                    />
                    <label style={{ color: 'red', fontSize: '12px' }}>{this.state.noticesTelephoneError !== null ? this.state.noticesTelephoneError : ""}</label>

                  </div>
                  <div className="col-6">
                    <label className="my-label">Email</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Email"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('noticesData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="noticesEmail"
                      type="email"
                      value={this.state.noticesEmail}
                    />
                  </div>
                </div>
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.noticesData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'noticesData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
            {/*13. Miscellaneous*/}
            <div>
              <h1 className="compress-section-aggrement" id="miscellaneous" onClick={() => {
                this.changeSymbol('miscellaneous', 'miscellaneousSection')
                this.handleSectionHide('miscellaneousSection')
              }}>—</h1>
              <p className="heading-subhead-sub">13. Miscellaneous</p>
              <div className="cover-page" id="miscellaneousSection">
                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.miscellaneousData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'miscellaneousData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>

            {/* Billing */}
            <div>
              <br />
              <h3 className="heading-subhead-sub">Billing Information - To Be Completed By Client</h3>
              <div className="cover-page">
                <p className="heading-subhead-sub">Company</p>
                <div className="row mt-1 mb-3">
                  <label className="my-label">Organization Name</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Organization Name"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                    }}
                    className="form-control input-field text-field-input"
                    name="billingOrganizationName"
                    type="text"
                    value={this.state.billingOrganizationName}

                  />
                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Billing Address</p>
                    <div className="col-6">
                      <label className="my-label">Address 1</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Address 1"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationAddress1"
                        type="text"
                        value={this.state.billingOrganizationAddress1}

                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Address 2</label>
                      <TextField
                        id="outlined-secondary"
                        // label="Address 2"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationAddress2"
                        type="text"
                        value={this.state.billingOrganizationAddress2}

                      />
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">City</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="City"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          const re = /^[a-zA-Z\s]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            this.handleChange(e)
                          }
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationCity"
                        type="text"
                        value={this.state.billingOrganizationCity}

                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">State</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="State"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          const re = /^[a-zA-Z\s]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            this.handleChange(e)
                          }
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationState"
                        type="text"
                        value={this.state.billingOrganizationState}

                      />
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">Country</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Country"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          const re = /^[a-zA-Z\s]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            this.handleChange(e)
                          }
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationCountry"
                        type="text"
                        value={this.state.billingOrganizationCountry}
                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Pin Code</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Pin Code"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                          //@ts-ignore
                          var regExp = /^[1-9][0-9]{5}$/;
                          if (e.target.value === "" || regExp.test(e.target.value) === true) {
                            this.setState({ billingOrganizationPincodeError: null })
                          } else {
                            this.setState({ billingOrganizationPincodeError: "Invalid Pin Code" })
                          }
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationPincode"
                        type="number"
                        value={this.state.billingOrganizationPincode}
                      />
                      <label style={{ color: 'red', fontSize: '12px' }}>{this.state.billingOrganizationPincodeError !== null ? this.state.billingOrganizationPincodeError : ""}</label>

                    </div>
                  </div>
                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Shipping Address</p>
                    <div className="col-6">
                      <input type="checkbox" name="billingOrganizationIsShippingAddressSame"
                        onChange={(e) => {
                          this.setState({
                            billingOrganizationIsShippingAddressSame: e.target.checked
                          })
                          this.isSameAddress()
                        }}
                        className="p-1" checked={this.state.billingOrganizationIsShippingAddressSame} />
                      <label className="heading-subhead-sub p-1">
                        Shipping Address Same As Billing Address
                      </label>
                    </div>
                    <div className="row mt-1 mb-3">
                      <div className="col-6">
                        <label className="my-label">Address 1</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          // label="Address 1"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => {
                            this.handleChange(e)
                          }}
                          className="form-control input-field text-field-input"
                          name="billingOrganizationShippingAddress1"
                          type="text"
                          value={this.state.billingOrganizationShippingAddress1}
                        />
                      </div>
                      <div className="col-6">
                        <label className="my-label">Address 2</label>
                        <TextField
                          id="outlined-secondary"
                          // label="Address 2"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => {
                            this.handleChange(e)
                          }}
                          className="form-control input-field text-field-input"
                          name="billingOrganizationShippingAddress2"
                          type="text"
                          value={this.state.billingOrganizationShippingAddress2}

                        />
                      </div>
                    </div>
                    <div className="row mt-1 mb-3">
                      <div className="col-6">
                        <label className="my-label">City</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          // label="City"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => {
                            const re = /^[a-zA-Z\s]+$/;
                            if (e.target.value === "" || re.test(e.target.value)) {
                              this.handleChange(e)
                            }
                          }}
                          className="form-control input-field text-field-input"
                          name="billingOrganizationShippingCity"
                          type="text"
                          value={this.state.billingOrganizationShippingCity}

                        />
                      </div>
                      <div className="col-6">
                        <label className="my-label">State</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          // label="State"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => {
                            const re = /^[a-zA-Z\s]+$/;
                            if (e.target.value === "" || re.test(e.target.value)) {
                              this.handleChange(e)
                            }
                          }}
                          className="form-control input-field text-field-input"
                          name="billingOrganizationShippingState"
                          type="text"
                          value={this.state.billingOrganizationShippingState}

                        />
                      </div>
                    </div>
                    <div className="row mt-1 mb-3">
                      <div className="col-6">
                        <label className="my-label">Country</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          // label="Country"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => {
                            const re = /^[a-zA-Z\s]+$/;
                            if (e.target.value === "" || re.test(e.target.value)) {
                              this.handleChange(e)
                            }
                          }}
                          className="form-control input-field text-field-input"
                          name="billingOrganizationShippingCountry"
                          type="text"
                          value={this.state.billingOrganizationShippingCountry}

                        />
                      </div>
                      <div className="col-6">
                        <label className="my-label">Pin Code</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          // label="Pin Code"
                          variant="outlined"
                          color="primary"
                          onChange={(e) => {
                            this.handleChange(e)
                            //@ts-ignore
                            var regExp = /^[1-9][0-9]{5}$/;
                            if (e.target.value === "" || regExp.test(e.target.value) === true) {
                              this.setState({ billingOrganizationShippingPincodeError: null })
                            } else {
                              this.setState({ billingOrganizationShippingPincodeError: "Invalid Pin Code" })
                            }
                          }}
                          className="form-control input-field text-field-input"
                          name="billingOrganizationShippingPincode"
                          type="number"
                          value={this.state.billingOrganizationShippingPincode}

                        />
                        <label style={{ color: 'red', fontSize: '12px' }}>{this.state.billingOrganizationShippingPincodeError !== null ? this.state.billingOrganizationShippingPincodeError : ""}</label>

                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    {/* <p className="heading-subhead-sub">GST No</p> */}
                    <div className="col-6">
                      <label className="my-label">GST NO</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="GST No"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                          var reg = /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/;
                          if (e.target.value === "" || reg.test(e.target.value)) {
                            this.setState({
                              billingOrganizationinvoiceGSTNumberError: null
                            })
                          }
                          else {
                            this.setState({
                              billingOrganizationinvoiceGSTNumberError: "Invalid GST"
                            })

                          }
                        }}
                        className="form-control input-field text-field-input"
                        name="billingOrganizationinvoiceGSTNumber"
                        type="text"
                        value={this.state.billingOrganizationinvoiceGSTNumber}

                      />
                      <label style={{ color: 'red', fontSize: '12px' }}>{this.state.billingOrganizationinvoiceGSTNumberError !== null ? this.state.billingOrganizationinvoiceGSTNumberError : ""}</label>

                    </div>
                  </div>
                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Accounts Payble Contact</p>
                    <div className="col-6">
                      <label className="my-label">Title</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Title"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                        }}
                        className="form-control input-field text-field-input"
                        name="paybleTitle"
                        type="text"
                        value={this.state.paybleTitle}

                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Name</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Name"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                        }}
                        className="form-control input-field text-field-input"
                        name="paybleName"
                        type="text"
                        value={this.state.paybleName}

                      />
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">Email ID</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Email ID"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                        }}
                        className="form-control input-field text-field-input"
                        name="paybleEmail"
                        type="email"
                        value={this.state.paybleEmail}

                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Phone Number</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Phone Number"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                          var regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/;
                          if (e.target.value === "" || regExp.test(e.target.value) === true) {
                            this.setState({ payblePhoneNumberError: null })
                          } else {
                            this.setState({ payblePhoneNumberError: "Invalid Phone Number" })
                          }
                        }}
                        className="form-control input-field text-field-input"
                        name="payblePhoneNumber"
                        type="number"
                        value={this.state.payblePhoneNumber}
                      />
                      <label style={{ color: 'red', fontSize: '12px' }}>{this.state.payblePhoneNumberError !== null ? this.state.payblePhoneNumberError : ""}</label>

                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div>
            <h1 className="compress-section-aggrement" id="disclosureAgreement" onClick={() => {
              this.changeSymbol('disclosureAgreement', 'desclosureAgreementSection')
              this.handleSectionHide('desclosureAgreementSection')
            }}>—</h1>
            <p className="heading-subhead">B. NON-DICLOSURE AGREEMENT</p>
            <div className="cover-page" id="desclosureAgreementSection">
              {/* return of confidential information */}
              <div>
                <div>
                  <div className="row mt-1 mb-3">
                    <div className="col-12">
                      <label className="my-label">Company Name</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Company Name"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e)
                          setTimeout(() => {
                            this.handleContent('disclosureAgreementCompanyData')
                          }, 100)
                        }}
                        className="form-control input-field text-field-input"
                        name="disclosureAgreementCompanyName"
                        type="text"
                        value={this.state.disclosureAgreementCompanyName}
                      />
                    </div>

                  </div>
                  <div>
                    <RichTextEditor
                      //@ts-ignore
                      value={this.state.disclosureAgreementCompanyData}
                      //@ts-ignore
                      onChange={(value) => this.handleUpdateContent(value, 'disclosureAgreementCompanyData')}
                      className="mt-2 mb-3 text-editor"
                    />
                  </div>
                </div>
              </div>


              {/* return of confedential information */}
              <div>
                <h1 className="compress-section-aggrement" id="returnOfConfidentail" onClick={() => {
                  this.changeSymbol('returnOfConfidentail', 'returnOfConfidentailSection')
                  this.handleSectionHide('returnOfConfidentailSection')
                }}>—</h1>
                <p className="heading-subhead-sub">2. Return Of Confidential Information</p>
                <div className="cover-page" id="returnOfConfidentailSection">
                  <div>
                    <RichTextEditor
                      //@ts-ignore
                      value={this.state.returnOfConfidentialData}
                      //@ts-ignore
                      onChange={(value) => this.handleUpdateContent(value, 'returnOfConfidentialData')}
                      className="mt-2 mb-3 text-editor"
                    />
                  </div>
                </div>
              </div>
              {/* obligation Data */}
              <div>
                <h1 className="compress-section-aggrement" id="obligations" onClick={() => {
                  this.changeSymbol('obligations', 'obligationsSection')
                  this.handleSectionHide('obligationsSection')
                }}>—</h1>
                <p className="heading-subhead-sub">3. Obligations</p>
                <div className="cover-page" id="obligationsSection">
                  <div>
                    <RichTextEditor
                      //@ts-ignore
                      value={this.state.obligationData}
                      //@ts-ignore
                      onChange={(value) => this.handleUpdateContent(value, 'obligationData')}
                      className="mt-2 mb-3 text-editor"
                    />
                  </div>
                </div>
              </div>
              {/* non-promotion Data */}
              <div>
                <h1 className="compress-section-aggrement" id="nonPromotion" onClick={() => {
                  this.changeSymbol('nonPromotion', 'nonPromotionSection')
                  this.handleSectionHide('nonPromotionSection')
                }}>—</h1>
                <p className="heading-subhead-sub">4. Non-Promotion</p>
                <div className="cover-page" id="nonPromotionSection">
                  <div>
                    <RichTextEditor
                      //@ts-ignore
                      value={this.state.nonPromotionData}
                      //@ts-ignore
                      onChange={(value) => this.handleUpdateContent(value, 'nonPromotionData')}
                      className="mt-2 mb-3 text-editor"
                    />
                  </div>
                </div>
              </div>
              {/* dispute resolution & governing law */}
              <div>
                <h1 className="compress-section-aggrement" id="disputeResolution" onClick={() => {
                  this.changeSymbol('disputeResolution', 'disputeResolutionSection')
                  this.handleSectionHide('disputeResolutionSection')
                }}>—</h1>
                <p className="heading-subhead-sub">5. Dispute Resolution & Governing Law</p>
                <div className="cover-page" id="disputeResolutionSection">
                  <div>
                    <RichTextEditor
                      //@ts-ignore
                      value={this.state.disputeResolutionData}
                      //@ts-ignore
                      onChange={(value) => this.handleUpdateContent(value, 'disputeResolutionData')}
                      className="mt-2 mb-3 text-editor"
                    />
                  </div>
                </div>
              </div>
              {/* Injunctive Relief */}
              <div>
                <h1 className="compress-section-aggrement" id="injunctiveRelief" onClick={() => {
                  this.changeSymbol('injunctiveRelief', 'injunctiveReliefSection')
                  this.handleSectionHide('injunctiveReliefSection')
                }}>—</h1>
                <p className="heading-subhead-sub">6. Injunctive Relief</p>
                <div className="cover-page" id="injunctiveReliefSection">
                  <div>
                    <RichTextEditor
                      //@ts-ignore
                      value={this.state.injunctiveReliefData}
                      //@ts-ignore
                      onChange={(value) => this.handleUpdateContent(value, 'injunctiveReliefData')}
                      className="mt-2 mb-3 text-editor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="row mt-1 mb-3">
                <div className="col-12">
                  <label className="my-label">Organization Name</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Organization Name"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('agreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="agreementOrganizationName"
                    type="text"
                    value={this.state.agreementOrganizationName}
                  />
                </div>
              </div>
              <div className="row mt-1 mb-3">
                <div className="col-6">
                  <label className="my-label">Authorized Signatory Name</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Authorized Signatory Name"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('agreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="agreementSignatoryName"
                    type="text"
                    value={this.state.agreementSignatoryName}

                  />
                </div>
                <div className="col-6">
                  <label className="my-label">Designation</label>
                  <TextField
                    id="outlined-secondary"
                    required
                    // label="Designation"
                    variant="outlined"
                    color="primary"
                    onChange={(e) => {
                      this.handleChange(e)
                      setTimeout(() => {
                        this.handleContent('agreementData')
                      }, 100)
                    }}
                    className="form-control input-field text-field-input"
                    name="agreementDesignation"
                    type="text"
                    value={this.state.agreementDesignation}

                  />
                </div>
              </div>
              <div>
                <RichTextEditor
                  //@ts-ignore
                  value={this.state.agreementData}
                  //@ts-ignore
                  onChange={(value) => this.handleUpdateContent(value, 'agreementData')}
                  className="mt-2 mb-3 text-editor"
                />
              </div>
            </div>
            <div>
              <p className="heading-subhead-sub">Schedule A</p>
              <div className="cover-page">
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">Select Fee Type</label>
                    <Select
                      options={[{ value: 'Fixed %', label: 'Fixed %' }, { value: 'Fixed Fees', label: 'Fixed Fees' }]}
                      // @ts-ignore
                      placeholder={this.state.scheduleFeeType?.length > 0 ? this.state.scheduleFeeType : ''}
                      onChange={(e) => {
                        this.handleChange(e, 'scheduleFeeType')
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      name={`scheduleFeeType`}
                      styles={customStyles}
                    />
                  </div>
                  <div className="col-6">
                    <label className="my-label"> {this.state.scheduleFeeType === 'Fixed Fees' ? "Enter Fixed Amount" : "Enter Fixed %"}</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Enter Fixed Amount"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="scheduleFixedAmount"
                      type="number"
                      value={this.state.scheduleFixedAmount || ""}
                    />
                  </div>
                  <div className="col-4 budget-role enter-fix-amount">
                    <Select
                      placeholder="$"
                      options={this.state.currencyOptions}
                      onChange={(e) => this.handleChange(e, 'budgetRoleAmountCurrencyId')}
                      name="budgetRoleAmountCurrencyId"
                      styles={customStyles}
                      value={this.state?.currencyOptions[this.state?.budgetRoleAmountCurrencyId - 1]}
                    />
                  </div>
                  {/* {this.state.scheduleFixedAmount} */}
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">{this.state.scheduleFeeType === 'Fixed Fees' ? "Retainer Advance - Enter Amount" : "Retainer Advance - Enter %"}</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="retainerAdvance"
                      type="number"
                      value={this.state.retainerAdvance || ""}
                    />
                    {/* {this.state.retainerAdvance && (this.state.scheduleFeeType === 'Fixed Fees' ? (this.state.scheduleFixedAmount - this.state.retainerAdvance): firstPercentAmount)} */}
                  </div>
                  <div className="col-6">
                    <label className="my-label">Business Days for Retainer Advance Payment</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Business Days for Retainer Advance Payment"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="businessDaysAdvancePayment"
                      type="number"
                      value={this.state.businessDaysAdvancePayment || ""}

                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">{this.state.scheduleFeeType === 'Fixed Fees' ? "Second Invoice - Enter Amount" : "Second Invoice - Enter %"}</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="secondInvoice"
                      type="number"
                      value={this.state.secondInvoice || ""}

                    />
                  </div>
                  {/* {this.state.secondInvoice && this.state.retainerAdvance && (this.state.scheduleFeeType === 'Fixed Fees' ? 
                  (this.state.scheduleFixedAmount - this.state.secondInvoice - this.state.retainerAdvance)
                  : secondPercentAmount)} */}

                  <div className="col-6">
                    <label className="my-label">Business Days for Second Payment</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Business Days for Second Payment"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="businessDaysSecondPayment"
                      type="number"
                      value={this.state.businessDaysSecondPayment || ""}

                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-6">
                    <label className="my-label">{this.state.scheduleFeeType === 'Fixed Fees' ? "Final Invoice - Enter Amount" : "Final Invoice - Enter %"}</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="finalPayment"
                      type="number"
                      value={this.state.finalPayment || ""}
                    />
                  </div>
                  {/* {this.state.finalPayment && this.state.secondInvoice && this.state.retainerAdvance && (this.state.scheduleFeeType === 'Fixed Fees' ? 
                  (this.state.scheduleFixedAmount - this.state.secondInvoice - this.state.retainerAdvance - this.state.finalPayment) : finalPercentAmount) }  */}

                  <div className="col-6">
                    <label className="my-label">Business Days for Final Payment</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Business Days for Final Payment"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="businessDaysFinalPayment"
                      type="number"
                      value={this.state.businessDaysFinalPayment || ""}

                    />
                  </div>
                </div>

                <div className="row mt-1 mb-3">
                  <p className="heading-subhead-sub">Fees beyond X number of days</p>

                  <div className="col-6">
                    <label className="my-label">{this.state.scheduleFeeType === 'Fixed Fees' ? "Enter Amount" : "Enter %"}</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="feesBeyondPercentage"
                      type="number"
                      value={this.state.feesBeyondPercentage || ""}

                    />
                  </div>

                  <div className="col-6">
                    <label className="my-label">Enter Calendar days</label>
                    <TextField
                      id="outlined-secondary"
                      required
                      // label="Enter Calendar days"
                      variant="outlined"
                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e)
                        setTimeout(() => {
                          this.handleContent('scheduleAData')
                        }, 100)
                      }}
                      className="form-control input-field text-field-input"
                      name="feesBeyondDays"
                      type="number"
                      value={this.state.feesBeyondDays || ""}

                    />
                  </div>
                </div>

                <div>
                  <RichTextEditor
                    //@ts-ignore
                    value={this.state.scheduleAData}
                    //@ts-ignore
                    onChange={(value) => this.handleUpdateContent(value, 'scheduleAData')}
                    className="mt-2 mb-3 text-editor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              {
                this.state.companyActError && (
                  <>
                    <label style={{ color: 'red' }}>{this.state.companyActError}</label>
                    <br />
                  </>
                )
              }{
                this.state.scheduleError && (
                  <label style={{ color: 'red' }}>{this.state.scheduleError}</label>
                )
              }
            </div>
            <div className="" style={{ textAlign: 'center' }}>
              {
                this.state.editAgreementState ?
                  <>
                    <button type="submit" className="btn btn-sm agreement-button" >
                      Update Agreement
                    </button>
                    <button type="button"
                      onClick={() => window.location.reload()}
                      className="btn btn-sm agreement-button" >
                      Cancel
                    </button>

                  </>
                  :
                  <>
                    <div style={{ fontSize: '12px', color: 'red' }}>{this.state.formError}</div>
                    <button type="submit" className="btn btn-sm agreement-button" >
                      Generate Agreement
                    </button>
                  </>
              }
            </div>
          </div>
        </form>}

        {/* Display Data */}
        {/* @ts-ignore */}
        <div style={{ lineBreak: 'auto' }} className={this.props.companyAgreementSection ? "agreement-company-page" : ""}>
          <br />
          <br />
          {/* Table Heading */}
          <div className="row" style={{ textAlign: 'left', backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '15px' }}>
            <div className="capsuleForm-display col-2"><b>TITLE</b></div>
            <div className="capsuleForm-display col-2"><b>DATE</b></div>
            <div className="capsuleForm-display col-2"><b>CREATED BY</b></div>
            <div className="capsuleForm-display col-2"><b>STATUS</b></div>
            <div className="capsuleForm-display col-2"><b>SIGNED DATE</b></div>
            <div className="capsuleForm-display col-2" style={{ cursor: 'pointer' }}>
              <div onClick={() => this.setState({ uploadModal: true })} className="Docusign-button">
                <img src={uploadIcon} style={{ width: '12%' }} />
                <label style={{ marginLeft: '6%' }}>Upload</label>
              </div>
            </div>

          </div>
          {/* Table Data */}
          <div className="agreement-listing">
            {this?.state?.allAggrementsList?.length === 0 ?
              <>{
                this.state.loader ?
                  <CircularProgress className="agreement-loader" />
                  :
                  <div className="loader mt-2">
                    No Data Found
                  </div>
              }


              </>
              :
              <div>
                {/* @ts-ignore */}
                {((!!this.props.companyAgreementSection ? (this.state.allAggrementsList.filter((item: any) => item.attributes?.dynamic_data?.agreementName.split("-")[0].toLowerCase() === this.props.presentcompanyName.toLowerCase())) : this.state?.allAggrementsList)).map((ele: any) =>

                  <div id={ele?.id} key={ele?.id} className="row" style={{ padding: '10px' }}>
                    <div title={ele.attributes?.dynamic_data?.agreementName !== undefined ? ele.attributes?.dynamic_data?.agreementName : "Agreement"} className="col-2" >{ele.attributes?.dynamic_data?.agreementName !== undefined ? `${ele.attributes?.dynamic_data?.agreementName?.slice(0, 25)}...` : "Agreement"}</div>
                    <div className="col-2">{ele.attributes?.created_at}</div>
                    <div className="col-2">{ele.attributes?.created_by}</div>
                    <div className="col-2">{ele.attributes?.status || '-'}</div>
                    <div className="col-2">{ele.attributes?.signed_date || '-'}</div>
                    <div className="col-1">
                      <div className="col-1 ellipsis-item"
                        onClick={() => {
                          this.setState(
                            {
                              isDropDownOpenId: this.state?.isDropDownOpenId === ele?.id ? null : ele?.id,
                              previewAgreementData: { ...ele.attributes.dynamic_data, ...ele.attributes.static_data, ...{ "currencyOptions": this.state.currencyOptions } },
                              docuSignAgreementName: ele.attributes?.dynamic_data?.agreementName
                            }
                          )
                        }}
                      >
                        <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                        <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                        <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                        {this.state?.isDropDownOpenId === ele?.id && this.openDropDownModalUI(ele)}
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {/* @ts-ignore */}
                  {!!this.props.companyAgreementSection ? (this.state.allAggrementsList.filter((item: any) => item.attributes?.dynamic_data?.agreementName.split("-")[0].toLowerCase() === this.props.presentcompanyName.toLowerCase()).length == 0 ? <div className="loader mt-2">
                    No Data Found
                  </div>
                    : "") : ""}

                </div>

              </div>

            }
          </div>
          <div id="agreement-company-page">

          </div>
        </div>
        {/* end of div */}
      </div >
    )
  }

  handleSelectedAgreementInvoice = () => {
    // @ts-ignore
    let selectedAgreement = this.state.allAggrementsList.filter((ele: any) => ele.attributes.id == this.state.selectedAgreement);

    let checkInvoiceComplete = selectedAgreement[0]?.attributes?.invoices?.data[2]?.attributes?.number_of_invoices == 3 && selectedAgreement[0]?.attributes?.last_invoice_created == 'third_invoice' || selectedAgreement[0]?.attributes?.invoices?.data[3]?.attributes?.number_of_invoices == 4 && selectedAgreement[0]?.attributes?.last_invoice_created == 'fourth_invoice'

    this.setState({
      budgetRoleAmount: selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.budget_role_amount,
      feesAgreed: selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.fees_type,
      numberOfInvoices: (selectedAgreement[0]?.attributes?.invoices?.data[3]?.attributes?.number_of_invoices || selectedAgreement[0]?.attributes?.invoices?.data[2]?.attributes?.number_of_invoices || selectedAgreement[0]?.attributes?.invoices?.data[1]?.attributes?.number_of_invoices || selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.number_of_invoices),
      fixedPercentage: selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.fee_agreed_amount,
      budgetRoleAmountCurrencyId: selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.currency_id,
      retainerAdvanceAmount: selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.retainer_amount,
      isInvoiceComplete: checkInvoiceComplete,
      retainerAdvancePaidDate: selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.paid_date,
    });


    selectedAgreement[0]?.attributes?.last_invoice_created !== 'first_invoice' && this.setState({ secondInvoiceAmount: selectedAgreement[0]?.attributes?.invoices?.data[1]?.attributes?.retainer_amount || this.state.secondInvoiceAmount, actualCompensation: selectedAgreement[0]?.attributes?.invoices?.data[1]?.attributes?.actual_compensation });

    selectedAgreement[0]?.attributes?.last_invoice_created !== 'second_invoice' && this.setState({ thirdInvoiceAmount: selectedAgreement[0]?.attributes?.invoices?.data[2]?.attributes?.retainer_amount || this.state.thirdInvoiceAmount, })

    selectedAgreement[0]?.attributes?.last_invoice_created !== 'third_invoice' && this.setState({ fourthInvoiceAmount: selectedAgreement[0]?.attributes?.invoices?.data[2]?.attributes?.retainer_amount || this.state.fourthInvoiceAmount, });

  }


  Invoice = () => {
    let selectedAgreement = this.state.allAggrementsList.filter((ele: any) => ele.attributes.id == this.state.selectedAgreement);
    let signedInvoices = this.state.allAggrementsList.filter((ele: any) => ele.attributes.status === 'Signed');
    signedInvoices = signedInvoices.map((ele: any) => {
      return {
        label: ele.attributes?.dynamic_data?.agreementName !== undefined ? ele.attributes?.dynamic_data?.agreementName : "Agreement", value: ele.id
      }
    })

    return (
      <div>
        <div>
          <form onSubmit={(e) => this.createInvoice(e)}>
            {/* Company Basic Details */}
            <div>
              <label className="my-label">Select Agreement</label>
              <Select styles={customStyles} options={signedInvoices} placeholder={""} onChange={(selectedOption) => { this.handleChange(selectedOption, 'selectedAgreement') }} />

              {this.state.isInvoiceComplete && <h4 className="is-invoice-complete">Invoices are Completed</h4>}
              <h1 className="compress-section-aggrement" id="companyBasicDetails" onClick={() => {
                this.changeSymbol('companyBasicDetails', 'companyBasicDetailsSection')
                this.handleSectionHide('companyBasicDetailsSection')
              }}>—</h1>
              <p className="heading-subhead">COMPANY BASIC DETAILS</p>

              <div className="cover-page" id="companyBasicDetailsSection">
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Corporate Address</p>
                    <div className="col-6">
                      <label className="my-label">Address 1</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Address 1"
                        variant="outlined"
                        color="primary"
                        className="form-control input-field text-field-input"
                        name="corporateAddress1"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationShippingAddress1}
                        type="text"
                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Address 2</label>
                      <TextField
                        id="outlined-secondary"

                        // label="Address 2"
                        variant="outlined"
                        color="primary"
                        className="form-control input-field text-field-input"
                        name="corporateAddress2"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationShippingAddress2}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">City</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="City"
                        variant="outlined"
                        color="primary"
                        className="form-control input-field text-field-input"
                        name="corporateCity"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationShippingCity}
                        type="text"
                      />

                    </div>

                    <div className="col-6">
                      <label className="my-label">State</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="State"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="corporateState"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationShippingState}
                        type="text"
                      />

                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">Country</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Country"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="corporateCountry"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationShippingCountry}
                        type="text"
                      />

                    </div>
                    <div className="col-6">
                      <label className="my-label">Pin Code</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Pin Code"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="corporatePincode"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationShippingPincode}
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Billing Address</p>
                    <div className="col-6">
                      <label className="my-label">Address 1</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Address 1"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="billingAddress1"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationAddress1}
                        type="text"
                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Address 2</label>
                      <TextField
                        id="outlined-secondary"
                        // label="Address 2"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="billingAddress2"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationAddress2}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">City</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="City"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="billingCity"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationCity}
                        type="text"
                      />

                    </div>
                    <div className="col-6">
                      <label className="my-label">State</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="State"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="billingState"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationState}
                        type="text"
                      />

                    </div>
                  </div>
                  <div className="row mt-1 mb-3">
                    <div className="col-6">
                      <label className="my-label">Country</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Country"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="billingCountry"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationCountry}
                        type="text"
                      />
                    </div>
                    <div className="col-6">
                      <label className="my-label">Pin Code</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        // label="Pin Code"
                        variant="outlined"
                        color="primary"
                        // onChange={(e) => {
                        //   // this.handleChange(e)
                        // }}
                        className="form-control input-field text-field-input"
                        name="billingPincode"
                        value={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationPincode}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Shipping Address</p>
                    <div className="col-6">
                      <input type="checkbox" name="isShippingAddressSame"

                        className="p-1" checked={selectedAgreement[0]?.attributes?.dynamic_data?.billingOrganizationIsShippingAddressSame === "true"} />
                      <label className="heading-subhead-sub p-1">
                        Shipping Address Same As Billing Address
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>

            {/* COMMERCIALS*/}
            <div>
              <h1 className="compress-section-aggrement" id="commercials" onClick={() => {
                this.changeSymbol('commercials', 'commercialSection')
                this.handleSectionHide('commercialSection')
              }}>—</h1>
              <p className="heading-subhead">COMMERCIALS</p>
              <div className="cover-page" id="commercialSection">
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Budget For Role</p>
                    <div className="col-9">
                      <label className="my-label">Enter Amount</label>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => selectedAgreement[0]?.attributes?.last_invoice_created === null ? this.handleChange(e) : ""}
                        value={selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.budgetRoleAmount || "") : (selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.budget_role_amount)}
                        className="form-control input-field text-field-input"
                        name="budgetRoleAmount"
                        type="number"

                        required
                        style={{ width: '135%' }}
                      />
                    </div>
                    <div className="col-4 invoice-currency">
                      <Select
                        placeholder="$"
                        options={this.state.currencyOptions}
                        onChange={(e) => this.handleChange(e, 'budgetRoleAmountCurrencyId')}
                        name="budgetRoleAmountCurrencyId"
                        value={this.state?.currencyOptions[selectedAgreement[0]?.attributes?.invoices?.data?.length > 0 && (selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.currency_id) - 1 || this.state?.budgetRoleAmountCurrencyId - 1]}
                        styles={customStyles}
                      />
                    </div>
                  </div>

                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Fees Agreed</p>
                    <div className="col-12">
                      <label className="my-label">Fee Type</label>

                      <Select
                        options={[{ value: 'Fixed %', label: 'Fixed %' }, { value: 'Fixed Fees', label: 'Fixed Fees' }]}
                        onChange={(e) => selectedAgreement[0]?.attributes?.last_invoice_created === null ? this.handleChange(e, 'feesAgreed') : ""}
                        // @ts-ignore
                        value={selectedAgreement[0]?.attributes?.last_invoice_created === null
                          ? (this.state.feesAgreed == 'Fixed Fees'
                            ?
                            { value: 'Fixed Fees', label: 'Fixed Fees' } :
                            { value: 'Fixed %', label: 'Fixed %' })
                          :
                          (selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === 'Fixed %'
                            ? { value: 'Fixed %', label: 'Fixed %' } :
                            { value: 'Fixed Fees', label: 'Fixed Fees' })
                        }
                        name="feesAgreed"

                        styles={customStyles}
                      />
                    </div>
                    <br /><br />
                    <div className="row mt-1 mb-3">
                      <div className="col-6">
                        <label className="my-label">{selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.feesAgreed == 'Fixed Fees' ? "Enter Fee Amount" : "Enter Fee % ") : (selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" ? "Enter Fee Amount" : "Enter Fee % ")}</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          variant="outlined"
                          color="primary"
                          onChange={(e) => selectedAgreement[0]?.attributes?.last_invoice_created === null ? this.handleChange(e) : ""}
                          value={selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.fixedPercentage || "") : (selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.fee_agreed_amount)}

                          className="form-control input-field text-field-input"
                          name="fixedPercentage"
                          type="text"
                        />
                      </div>
                      {this.state.fixedPercentage > 0 && this.state.budgetRoleAmount > 0 && <div className="col-6" style={{ marginTop: "40px" }}> Calculated Amount :&nbsp; {this.state.feesAgreed === "Fixed Fees" ? this.state.fixedPercentage : (this.state.budgetRoleAmount * this.state.fixedPercentage / 100)}
                      </div>}
                    </div>

                    <div className="row mt-1 mb-3">
                      <label className="my-label">Number of Invoices</label>
                      <div className="col-12">
                        <Select
                          options={[{ value: 3, label: 3 }, { value: 4, label: 4 }]}
                          // @ts-ignore
                          placeholder={selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.number_of_invoices || this.state.numberOfInvoices}
                          onChange={(e) => this.handleChange(e, 'numberOfInvoices')}

                          value={selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.numberOfInvoices || "") : (selectedAgreement[0]?.attributes?.invoices?.data[3]?.attributes?.number_of_invoices || selectedAgreement[0]?.attributes?.invoices?.data[2]?.attributes?.number_of_invoices || selectedAgreement[0]?.attributes?.invoices?.data[1]?.attributes?.number_of_invoices || selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.number_of_invoices)}
                          name={`numberOfInvoices`}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <div className="row mt-1 mb-3">
                      <p className="heading-subhead-sub">Retainer Advance</p>
                      <div className="col-6">
                        <label className="my-label">{selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.feesAgreed == 'Fixed Fees' ? "Enter Fee Amount" : "Enter Fee % ") : (selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" ? "Enter Fee Amount" : "Enter Fee % ")}</label>
                        <TextField
                          id="outlined-secondary"
                          required
                          variant="outlined"
                          color="primary"
                          onChange={(e) => selectedAgreement[0]?.attributes?.last_invoice_created === null ? this.handleChange(e) : ""}

                          value={selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.retainerAdvanceAmount || "") : (selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.retainer_amount)}
                          className="form-control input-field text-field-input"
                          name="retainerAdvanceAmount"
                          type="number"
                        />
                      </div>
                      {this.state.retainerAdvanceAmount > 0 && <div className="col-6" style={{ marginTop: "40px" }}>
                        Calculated Amount :&nbsp;{(selectedAgreement[0]?.attributes?.invoices?.data.length > 0 && selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" || this.state.feesAgreed == 'Fixed Fees') ? this.state.retainerAdvanceAmount : (this.state.budgetRoleAmount * this.state.fixedPercentage / 100 * this.state.retainerAdvanceAmount / 100)
                        }
                      </div>}
                    </div>

                    {!this.state.isLoadingEvent ? <div >
                      <div
                        // @ts-ignore
                        style={{ pointerEvents: selectedAgreement[0]?.attributes?.invoices?.data?.length !== 0 ? "none" : "" }}
                        onClick={() => this.raiseAgreementInvoice('first_invoice')}
                        className={`btn ${selectedAgreement[0]?.attributes?.invoices?.data?.length !== 0 ? 'disable-btn-color' : 'raise-button'}`}>
                        Raise First Invoice</div>
                    </div> : <CircularProgress style={{ width: '60px' }} />}

                    <br /><br />
                    <div className="row mt-1 mb-3">
                      <label className="my-label">Retainer Advance Paid Date</label>
                      <div className="col-12">
                        <TextField
                          id="outlined-secondary"
                          required
                          // label="DD/MM/YYYY"
                          InputProps={{ inputProps: { max: '9999-12-31' } }}
                          variant="outlined"
                          color="primary"
                          // onChange={(e) => {
                          //   this.handleChange(e)
                          // }}
                          value={selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.retainerAdvancePaidDate || "") : (selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.paid_date)}
                          className="form-control input-field text-field-input"
                          name="retainerAdvancePaidDate"
                          type="date"
                        />
                      </div>

                    </div>

                  </div>
                </div>


              </div>
            </div>

            {/* INVOICING*/}
            <div>
              <h1 className="compress-section-aggrement" id="invoicing" onClick={() => {
                this.changeSymbol('invoicing', 'invoicingSection')
                this.handleSectionHide('invoicingSection')
              }}>—</h1>
              <p className="heading-subhead">INVOICING</p>
              <div className="cover-page" id="invoicingSection">
                <div>
                  <div className="row mt-1 mb-3">
                    <div className="col-9">
                      <label className="my-label">Actual Compensation</label>
                      <TextField
                        id="outlined-secondary"
                        // label="Enter Exact Currency Amount"
                        variant="outlined"
                        color="primary"
                        onChange={(e) => { this.handleChange(e); this.handleSelectedAgreementInvoice() }}
                        className="form-control input-field text-field-input"
                        name="actualCompensation"
                        value={selectedAgreement[0]?.attributes?.last_invoice_created !== 'second_invoice' ? (this.state.actualCompensation || "") : selectedAgreement[0]?.attributes?.invoices?.data[1]?.attributes?.actual_compensation}
                        type="number"
                        required
                        style={{ width: '135%' }}
                      />
                    </div>
                    <div className="col-3 invoice-currency">

                      <Select
                        options={this.state.currencyOptions}
                        onChange={(e) => this.handleChange(e, 'budgetRoleAmountCurrencyId')}
                        name="budgetRoleAmountCurrencyId"
                        value={selectedAgreement[0]?.attributes?.invoices?.data?.length > 0 && this.state?.currencyOptions[selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.currency_id - 1 || this.state?.budgetRoleAmountCurrencyId - 1]}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Second Invoice</p>
                    <div className="col-6">
                      <label className="my-label">{selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.feesAgreed == 'Fixed Fees' ? "Second Invoice Amount" : "Second Invoice %") : (selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" ? "Second Invoice Amount" : "Second Invoice %")}</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e); this.handleSelectedAgreementInvoice()
                        }}
                        className="form-control input-field text-field-input"
                        name="secondInvoiceAmount"
                        value={selectedAgreement[0]?.attributes?.last_invoice_created !== 'second_invoice' ? (this.state.secondInvoiceAmount || "") : (selectedAgreement[0]?.attributes?.invoices?.data[1]?.attributes?.retainer_amount || this.state.secondInvoiceAmount)}
                        type="number"
                      />
                    </div>
                    {/* this.state.second_invoice_value */}
                    {this.state.secondInvoiceAmount > 0 && <div className="col-6" style={{ marginTop: "40px" }}> Calculated Amount :&nbsp; {(selectedAgreement[0]?.attributes?.invoices?.data.length && selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" || this.state.feesAgreed == 'Fixed Fees') ? this.state.secondInvoiceAmount : (
                      this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.secondInvoiceAmount / 100 +

                      ((this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.retainerAdvanceAmount / 100) - (this.state.budgetRoleAmount * this.state.secondInvoiceAmount / 100 * this.state.retainerAdvanceAmount / 100)))}</div>}

                  </div>
                  {!this.state.isLoadingEvent ?
                    <div
                      // @ts-ignore
                      style={{ pointerEvents: selectedAgreement[0]?.attributes?.last_invoice_created !== "first_invoice" ? "none" : "" }}
                      className={`btn  ${selectedAgreement[0]?.attributes?.last_invoice_created !== "first_invoice" ? 'disable-btn-color' : 'raise-button'}`}
                      onClick={() => this.raiseAgreementInvoice('second_invoice')}>
                      Raise Second Invoice
                    </div> : <CircularProgress />}
                </div>
                <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Third Invoice</p>
                    <div className="col-6">
                      <label className="my-label">{selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.feesAgreed == 'Fixed Fees' ? "Third Invoice Amount" : "Third Invoice %") : (selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" ? "Third Invoice Amount" : "Third Invoice %")}</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e); this.handleSelectedAgreementInvoice()
                        }}
                        className="form-control input-field text-field-input"
                        name="thirdInvoiceAmount"
                        value={selectedAgreement[0]?.attributes?.last_invoice_created !== 'second_invoice' ? (this.state.thirdInvoiceAmount || "") : selectedAgreement[0]?.attributes?.invoices?.data[2]?.attributes?.retainer_amount}
                        type="number"
                      />
                    </div>
                    {/* this.state.third_invoice_value */}
                    {this.state.thirdInvoiceAmount > 0 &&
                      <div className="col-6" style={{ marginTop: "40px" }}>
                        Calculated Amount :&nbsp; {(selectedAgreement[0]?.attributes?.invoices?.data.length && selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" || this.state.feesAgreed == 'Fixed Fees') ? this.state.thirdInvoiceAmount : this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.thirdInvoiceAmount / 100}
                      </div>}
                  </div>

                  {!this.state.isLoadingEvent ? <div >
                    <div
                      // @ts-ignore
                      style={{ pointerEvents: selectedAgreement[0]?.attributes?.last_invoice_created !== "second_invoice" ? "none" : "" }}
                      onClick={() => this.raiseAgreementInvoice('third_invoice')}
                      className={`btn ${selectedAgreement[0]?.attributes?.last_invoice_created !== "second_invoice" ? 'disable-btn-color' : 'raise-button'}`}>
                      Raise Third Invoice</div>
                  </div> : <CircularProgress />}
                </div>

                {(this.state.numberOfInvoices > 3 || selectedAgreement[0]?.attributes?.invoices?.data[0]?.attributes?.number_of_invoices == 4) && <div>
                  <div className="row mt-1 mb-3">
                    <p className="heading-subhead-sub">Fourth Invoice</p>
                    <div className="col-6">
                      <label className="my-label">{selectedAgreement[0]?.attributes?.last_invoice_created === null ? (this.state.feesAgreed == 'Fixed Fees' ? "Fourth Invoice Amount" : "Fourth Invoice %") : (selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" ? "Fourth Invoice Amount" : "Fourth Invoice %")}</label>
                      <TextField
                        id="outlined-secondary"
                        required
                        variant="outlined"
                        color="primary"
                        onChange={(e) => {
                          this.handleChange(e); this.handleSelectedAgreementInvoice();
                        }}
                        className="form-control input-field text-field-input"
                        name="fourthInvoiceAmount"
                        value={this.state.fourthInvoiceAmount || ""}
                        type="number"
                      />
                    </div>

                    {this.state.fourthInvoiceAmount > 0 &&
                      <div className="col-6" style={{ marginTop: "40px" }}>
                        Calculated Amount :&nbsp; {(selectedAgreement[0]?.attributes?.invoices?.data.length && selectedAgreement[0]?.attributes?.invoices.data[0].attributes.fees_type === "Fixed Fees" || this.state.feesAgreed == 'Fixed Fees') ? this.state.fourthInvoiceAmount : this.state.actualCompensation * this.state.fixedPercentage / 100 * this.state.fourthInvoiceAmount / 100}
                      </div>}


                  </div>
                  {!this.state.isLoadingEvent ? <div >
                    <div
                      // @ts-ignore
                      style={{ pointerEvents: (selectedAgreement[0]?.attributes?.last_invoice_created !== "third_invoice") ? "none" : "", }}

                      className={`btn ${(selectedAgreement[0]?.attributes?.last_invoice_created !== "third_invoice") ? 'disable-btn-color' : 'raise-button'}`}

                      onClick={() => this.raiseAgreementInvoice('fourth_invoice')}>Raise Fourth Invoice</div>
                  </div> : <CircularProgress />}
                </div>}

                {/*Error Section  */}
                <span className="error-msg-text">{(selectedAgreement[0]?.attributes?.dynamic_data?.scheduleFeeType === "Fixed Fees" || this.state.feesAgreed == 'Fixed Fees') ? "" :
                  (this.state.numberOfInvoices == 4 ?
                    (
                      parseInt(this.state.fourthInvoiceAmount) > 0 && ((parseInt(this.state.retainerAdvanceAmount) + parseInt(this.state.secondInvoiceAmount) + parseInt(this.state.thirdInvoiceAmount) + parseInt(this.state.fourthInvoiceAmount)) === 100 ? "" : "The Entered % not making to complete 100%")
                    )
                    :
                    (
                      parseInt(this.state.thirdInvoiceAmount) > 0 && ((parseInt(this.state.retainerAdvanceAmount) + parseInt(this.state.secondInvoiceAmount) + parseInt(this.state.thirdInvoiceAmount)) === 100 ? "" : "The Entered % not making to complete 100%")
                    )
                  )}</span>

              </div>
            </div>

          </form>
        </div>
        {/* end of div */}
      </div>
    )
  }

  render() {

    return (
      // Customizable Area Start
      // @ts-ignore
      !!this.props.companyAgreementSection ? <div className="view-rectangle-two view-rectangle-agreement">{this.Agreement()} </div> :
        <div className="wrapper">
          <SideNav data={this.state.currentUser} />
          <div className="kanban-content companies-content" >
            <TopNav data={this.state.currentUser} />

            {/* Static Graph part */}
            <div className="mb-4 mt-4">
              <div className="view-rectangle-one">
                <p className="heading-head">Add New Client</p>
                <br />
                <div className="row col-12">
                  <label className={this.state.menuType === 1 ? "col-4 main-menu-label-active" : "col-4 main-menu-label"} onClick={() => this.setState({ menuType: 1 })}>Agreement</label>
                  {/* @ts-igonre */}
                  <label className={this.state.menuType === 2 ? "col-4 main-menu-label-active" : "col-4 main-menu-label"} onClick={() => this.setState({ menuType: 2 })} style={{ textAlign: 'center' }}>Invoicing</label>
                  <label className={this.state.menuType === 3 ? "col-4 main-menu-label-active" : "col-4 main-menu-label"} onClick={() => this.setState({ menuType: 3 })} style={{ textAlign: 'right' }}><Link to={'/pqCapsule'}>Purple Quarter Capsule</Link></label>
                </div>
              </div>
              <div className="view-rectangle-two">
                {
                  this.state.menuType === 1 &&
                  (
                    this.Agreement()
                  )
                }
                {
                  this.state.menuType === 2 &&
                  (
                    this.Invoice()
                  )
                }
              </div>
            </div>
          </div>
        </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: 9999999
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    height: '44px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
// Customizable Area End
