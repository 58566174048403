Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskAllocator";
exports.labelBodyText = "TaskAllocator Body";
exports.getAllTasks = "bx_block_taskallocator/task_allocation"
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user";
exports.addTaskCall = 'bx_block_taskallocator/task_allocation';
exports.candidatesGetUrl = "bx_block_candidate/candidates";
exports.markTaskAsComplete = "bx_block_taskallocator/update_status/";
exports.getRemindersUrl = 'bx_block_notifications/reminders';
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE"
exports.addReminder = "bx_block_notifications/reminders"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End