Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AuditTrail";
exports.labelBodyText = "AuditTrail Body";

exports.btnExampleTitle = "CLICK ME";
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user"
exports.auditLogsAPI = "bx_block_log/logs"
exports.teamDetailsAPI = "bx_block_candidate/candidates/get_all_accounts"
exports.candidatesGetUrl = "bx_block_candidate/candidates"

// Customizable Area End