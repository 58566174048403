import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import VisualAnalyticsController, {
  Props,
  configJSON
} from "./VisualAnalyticsController";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
import './styles.css'
import Select from 'react-select'
//@ts-ignore
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { calendar, generated, signed, total, unsigned } from "./assets";
//@ts-ignore
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Modal from "react-modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment'
import { TextField } from '@material-ui/core';



export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getAllAgreements('All')
    this.getPqEmployees()
    // Customizable Area End
  }

  // Customizable Area Start
  handleDateSelect = (data: any) => {

    this.setState({
      dateRange: data
    })
    setTimeout(() => {
      this.getAllAgreements(this.state.selectedPqEmployeeName !== "" ? this.state.selectedPqEmployeeName : 'All')
    }, 1000)
  }
  openCalendarModal = () => {

    return <Modal
      isOpen={this.state.calendarView}
      contentLabel=""
      className="calander-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ calendarView: false }) }}><b>X</b></button>
            </div>
            <DateRangePicker
              ranges={this.state.dateRange}
              // onChange={(data: any) => this.handleDateSelect(data)}
              onChange={(item: any) => this.handleDateSelect([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"

            />
          </div>

          <div className="center-item-button">
            <button className="btn save-button" type="button" onClick={() => this.setState({ calendarView: false })}>Save</button>
          </div>
        </form>
      </div>
    </Modal >
  }

  handleSelect = (selectedOption: any, type: any) => {

    this.setState({
      isFilterStarted: true
    })

    if (type === 'pqEmployeeName') {



      this.getAllAgreements(selectedOption.value)
      if (type !== "All") {
        this.setState({
          selectedPqEmployeeName: selectedOption.value,
          selectedPqEmployee: selectedOption
        })
      }

    }

    if (type === 'selectedDate') {

      this.setState({
        selectedDateRange: selectedOption
      })

      this.setState({
        customDate: false
      })
      if (selectedOption.value === 'Today') {
        let dateRange = [{
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange,
        })
      }
      if (selectedOption.value === 'Yesterday') {

        let dateRange = [{
          startDate: moment().subtract(1, 'day'),
          endDate: moment().subtract(1, 'day'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'Last 7 Days') {

        let dateRange = [{
          startDate: moment().subtract(7, 'day'),
          endDate: new Date(),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'This Week') {

        let dateRange = [{
          startDate: moment().startOf('week'),
          endDate: moment().endOf('week'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'Last Week') {

        let dateRange = [{
          startDate: moment().startOf('week').subtract(7, 'day'),
          endDate: moment().endOf('week').subtract(7, 'day'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'This Month') {

        let dateRange = [{
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'Last Month') {

        let dateRange = [{
          startDate: moment().startOf('month').subtract(1, 'month'),
          endDate: moment().endOf('month').subtract(1, 'month'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'This Year') {

        let dateRange = [{
          startDate: moment().startOf('year'),
          endDate: moment().endOf('year'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }
      if (selectedOption.value === 'Last Year') {

        let dateRange = [{
          startDate: moment().startOf('year').subtract(1, 'year'),
          endDate: moment().endOf('year').subtract(1, 'year'),
          key: 'selection',
        }]

        this.setState({
          dateRange: dateRange
        })
      }

      if (selectedOption.value === 'Custom Date') {
        this.setState({
          customDate: true
        })
      } else {
        setTimeout(() => {
          this.getAllAgreements(this.state.selectedPqEmployeeName !== "" ? this.state.selectedPqEmployeeName : 'All')
        }, 1000)
      }
    }

    if (type === 'cusDate') {
      let dateRange = [{
        startDate: selectedOption,
        endDate: selectedOption,
        key: 'selection',
      }]

      this.setState({
        dateRange: dateRange
      })

      setTimeout(() => {
        this.getAllAgreements(this.state.selectedPqEmployeeName !== "" ? this.state.selectedPqEmployeeName : 'All')
      }, 1000)
    }

    // if (selectedOption.value === 'All') {
    //   this.setState({ filterDataByName: this.state.allAgreementsList })
    // } else {
    //   this.setState({
    //     filterDataByName: this.state.allAgreementsList.filter((ele: any) => ele.attributes.created_by == this.state.selectedPqEmployeeName)
    //   })
    // }

  }


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div className="wrapper">
        <SideNav data={this.state.currentUser} />
        <div className="kanban-content companies-content" >
          <TopNav data={this.state.currentUser} />
          <div className="mb-4 mt-4">
            <div className="view-rectangle-three">
              <div className="row">
                <div className="col-3" style={{ marginLeft: '40%' }}>
                  <Select
                    options={this.state.pqEmployeeArray}
                    // @ts-ignore
                    placeholder={'All'}
                    onChange={(selectedOption) => {
                      this.handleSelect(selectedOption, 'pqEmployeeName')
                    }}
                    name='pqEmployeeName'
                    styles={customStyles}
                    value={this.state.selectedPqEmployee}
                  />
                </div>
                <div className="col-2">
                  <Select
                    options={this.state.dateFilterArray}
                    // @ts-ignore
                    placeholder={'Select Date'}
                    onChange={(selectedOption) => {
                      this.handleSelect(selectedOption, 'selectedDate')
                    }}

                    name='selectedDate'
                    styles={customStyles}
                    value={this.state.selectedDateRange}
                  />

                  {/* <button className="btn"

                    style={{
                      //@ts-ignore
                      background: "#FBFBFB",
                      color: '#727F88',
                      fontWeight: 500,
                      borderColor: 'transparent',
                      borderRadius: '10px',
                      height: '44px',
                      width: '180px !important',
                      border: '0 !important',
                    }} onClick={() => this.setState({
                      calendarView: true
                    })}>Date Range <img src={calendar} /></button> */}
                  {
                    this.openCalendarModal()
                  }
                  {
                    this.state.customDate && (
                      <div>
                        <TextField
                          id="outlined-secondary"
                          variant="outlined"
                          color="primary"
                          style={{
                            background: "#FBFBFB",
                            fontWeight: 500,
                            borderColor: 'transparent',
                            borderRadius: '10px',
                            height: '44px',
                            width: '150px !important',
                            border: '0 !important',
                            // This line disable the blue border
                            boxShadow: '0 !important',

                          }}
                          className="mt-2"
                          onChange={(e) => this.handleSelect(e.target.value, 'cusDate')}
                          type="date"
                        />
                      </div>
                    )
                  }
                </div>
                <div className="col-1">
                  {
                    this.state.isFilterStarted &&
                    <button type="button" title="Clear All Filters" className="candidate-button btn" onClick={() => {
                      this.setState({
                        dateRange: [{
                          startDate: new Date(),
                          endDate: moment().subtract(7, 'days'),
                          key: 'selection',
                        }],
                        selectedDateRange: null,
                        selectedPqEmployee: null,
                        customDate: false,
                        isFilterStarted: false
                      })
                      this.getAllAgreements('All')
                    }} style={{ fontWeight: 'bolder', marginLeft: '10px' }}>X</button>
                  }

                </div>
              </div>
            </div>
          </div>
          {
            this.state.loader ?
              <div className="loader">
                <CircularProgress />
              </div>
              :



              < div >
                <div className="mb-4 mt-4">
                  <div className="view-rectangle-three">
                    <div className="row">
                      <div className="col-6 dashboard-cards-agreement">
                        <div className="center-item-circle">
                          <div className="view_Oval1">
                            <div className="">
                              {/* //@ts-ignore */}
                              <CircularProgressbar className=""
                                // @ts-ignore
                                value={this.state.totalAgreementCount} styles={buildStyles({
                                  pathColor: `#516dfe`,
                                })} />
                              <label className="textlabel_count">{this.state.totalAgreementCount}</label>
                            </div>
                          </div>
                        </div>
                        <div className="center-item-text">
                          <img src={total} />
                          <label className="textlabel_label">Total Agreements</label>
                        </div>
                      </div>
                      <div className="col-6 dashboard-cards-agreement">
                        <div className="center-item-circle">
                          <div className="view_Oval1">
                            <div className="">
                              {/* //@ts-ignore */}
                              <CircularProgressbar
                                className=""
                                // @ts-ignore
                                value={this.state.signedAgreementCount}
                                styles={buildStyles({
                                  pathColor: `#516dfe`,
                                })} />
                              <label className="textlabel_count">{this.state.signedAgreementCount}</label>
                            </div>

                          </div>
                        </div>
                        <div className="center-item-text">
                          <img src={signed} />

                          <label className="textlabel_label">Signed Agreements</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 dashboard-cards-agreement">
                        <div className="center-item-circle">
                          <div className="view_Oval1">
                            <div className="">
                              {/* //@ts-ignore1 */}
                              <CircularProgressbar className=""
                                // @ts-ignore
                                value={this.state.unSignedAgreementCount} styles={buildStyles({
                                  pathColor: `#fe5251`,
                                })} />
                              <label className="textlabel_count">{this.state.unSignedAgreementCount}</label>
                            </div>

                          </div>
                        </div>
                        <div className="center-item-text">
                          <img src={unsigned} />

                          <label className="textlabel_label">Unsigned Agreements</label>
                        </div>
                      </div>
                      <div className="col-6 dashboard-cards-agreement">
                        <div className="center-item-circle">
                          <div className="view_Oval1">
                            <div className="">
                              {/* //@ts-ignore1 */}
                              <CircularProgressbar className=""
                                // @ts-ignore
                                value={this.state.generatedAgreementCount} styles={buildStyles({
                                  pathColor: `#b006ae `,
                                })} />
                              <label className="textlabel_count">{this.state.generatedAgreementCount}</label>
                            </div>

                          </div>
                        </div>
                        <div className="center-item-text">
                          <img src={generated} />
                          <label className="textlabel_label">Generated Agreements</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {

                  <div className="mb-4 mt-4">
                    <div className="table-data-view">
                      {/* Display Data */}
                      <div>

                        {/* Table Heading */}
                        <div className="row" style={{ backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '15px' }}>
                          <div className="capsuleForm-display col-3"><b>Company Name</b></div>
                          <div className="capsuleForm-display col-2"><b>Creator</b></div>
                          <div className="capsuleForm-display col-2"><b>Generation Date</b></div>
                          <div className="capsuleForm-display col-2"><b>Signed Date</b></div>
                          <div className="capsuleForm-display col-3"><b>Current Status</b></div>
                          {/* <div className="capsuleForm-display col-2"><div onClick={()=> this.setState({docuSignModal: true})} className="Docusign-button"> DocuSign</div></div> */}

                        </div>
                        {/* Table Data */}
                        {this.state.errorMessage === null ? <div style={{ height: '600px', overflowY: 'scroll', overflowX: 'hidden' }}>
                          {
                            this.state?.filterDataByName.map((ele: any) =>
                              <div id={ele?.id} className="row" style={{ padding: '10px' }}>

                                <div title={ele.attributes?.dynamic_data?.agreementName !== undefined ? ele.attributes?.dynamic_data?.agreementName : "Agreement"} className="col-3" >{ele.attributes?.dynamic_data?.agreementName !== undefined ? `${ele.attributes?.dynamic_data?.agreementName?.slice(0, 16)}...` : "Agreement"}</div>

                                <div className="col-2">{ele.attributes.created_by}</div>
                                <div className="col-2">{ele.attributes?.created_at}</div>

                                <div className="col-2">{ele.attributes?.signed_date === null ? "-" : ele.attributes?.signed_date}</div>

                                <div className="col-3" style={{ textAlign: 'left' }}>{ele?.attributes?.status === null ? "Unsigned" : "Signed"}</div>
                              </div>
                            )
                          }
                        </div>
                          :
                          <div className="loader mt-2 mb-3">
                            {this.state.errorMessage}
                          </div>}
                      </div>

                    </div>
                  </div>

                }
              </div>

          }
        </div>
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    height: '44px',
    width: '150px !important',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
// Customizable Area End
