Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomForm";
exports.labelBodyText = "CustomForm Body";

exports.btnExampleTitle = "CLICK ME";
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user"
exports.companiesAPI = "bx_block_company/companies"
exports.getAllJobStatus ="bx_block_job/jobs/get_all_job_statuses";

// Customizable Area End