import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
// Customizable Area Start
import Select from 'react-select'
import { Formik } from 'formik'
import * as yup from "yup";
import { Link } from 'react-router-dom'
import { Input, TextField } from '@material-ui/core';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

export default class BasicDetails extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    basicDetailsSchema = () => {
        return yup.object().shape({
            fullName: yup
                .string()
                .min(3, "Minimum 3 characters are required")
                // .max(20, "Maximum 20 characters are allowed.")
                .matches(/^[a-zA-Z ]+$/, "Only letters are allowed.")
                .required("Full Name is required."),
            email: yup
                .string()
                .email("Please enter a valid email address.")
                .required("Email is required."),
            mobile: yup
                .string()
                .required("Mobile number is required.")
                .matches(
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/,
                    "Enter valid Mobile Number"
                ),
            alternateMobile: yup
                .string()
                .required("Mobile number is required.")
                .matches(
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/,
                    "Enter Valid Mobile Number"
                ),
            aadhar: yup
                .number()
                .typeError("Only numbers are allowed.")
                .min(100000000000, "Minimum 12 digits are required.")
                .max(999999999900, "Maximum 12 digits are allowed."),

        });
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4" style={{ height: 'fit-content', overflow: 'auto' }}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={
                                    {
                                        fullName: this.state.currentCandidateData?.attributes?.full_name ? this.state.currentCandidateData?.attributes?.full_name : "",
                                        mobile: this.state.currentCandidateData?.attributes?.full_phone_number ? this.state.currentCandidateData?.attributes?.full_phone_number : "",
                                        alternateMobile: this.state.currentCandidateData?.attributes?.alternate_phone_number ? this.state.currentCandidateData?.attributes?.alternate_phone_number : "",
                                        aadhar: this.state.currentCandidateData?.attributes?.aadhar_number ? this.state.currentCandidateData?.attributes?.aadhar_number : "",
                                        email: this.state.currentCandidateData?.attributes?.email ? this.state.currentCandidateData?.attributes?.email : "",
                                        highestEducation: this.state.currentCandidateData?.attributes?.highest_education ? this.state.currentCandidateData?.attributes?.highest_education : "",
                                        linkdinProfile: this.state.currentCandidateData?.attributes?.linkedin_profile_link ? this.state.currentCandidateData?.attributes?.linkedin_profile_link : "",
                                    }}
                                validationSchema={this.basicDetailsSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    this.handleBasicDetails(values)
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values, handleBlur }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <p className="heading-head">{window.location.href.includes('Edit-user') ? 'Edit Candidate' : 'Add New Candidate'}</p>
                                            <p className="heading"><b>BASIC DETAILS</b></p>
                                            <div style={{ textAlign: 'center' }} >
                                                {
                                                    <img src={this.state.preview || require('../../../components/src/img_avatar.png')} className="profile-image" />
                                                }
                                            </div>
                                            <div style={{ textAlign: 'center' }} className="mt-2 mb-3">
                                                <input type='file' id="img" onChange={(e) => this.onSelectFile(e)} style={{ display: 'none' }} />
                                                <label htmlFor="img" style={{ cursor: 'pointer' }}>Click here to upload Avatar</label>
                                                {/* {this.state.fileUploadError !== null && <div className="error">{this.state.fileUploadError}</div>} */}
                                            </div>
                                            <div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Full Name</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Full Name *"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-control input-field text-field-input"
                                                        value={values.fullName} name="fullName" type="text"
                                                    />

                                                    {errors.fullName && touched.fullName ? <div className="error">{errors.fullName}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Enter Mobile No</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Enter Mobile No*"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.mobile}
                                                        onBlur={handleBlur}
                                                        name="mobile"
                                                        type="text"
                                                    />

                                                    {errors.mobile && touched.mobile ? <div className="error">{errors.mobile}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Enter Alternate Mobile No</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Enter Alternate Mobile No"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.alternateMobile}
                                                        name="alternateMobile"
                                                        type="text"
                                                        onBlur={handleBlur}

                                                    />
                                                    {errors.alternateMobile && touched.alternateMobile ? <div className="error">{errors.alternateMobile}</div> : null}
                                                </div>
                                                {/* <div className="mt-1 mb-3">
                                                    <TextField
                                                        id="outlined-secondary"
                                                        label="Aadhar Number"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.aadhar}
                                                        name="aadhar"
                                                        type="text"
                                                    />


                                                    {errors.aadhar && touched.aadhar ? <div className="error">{errors.aadhar}</div> : null}
                                                </div> */}
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Email Address</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Email Address*"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.email}
                                                        name="email"
                                                        type="text"
                                                    />
                                                    {this.state.emailExistingError !== null && <div className="error mt-1 mb-1">{this.state.emailExistingError}</div>}
                                                    {errors.email && touched.email ? <div className="mt-1 mb-1 error">{errors.email}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Highest Education</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Higest Education"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.highestEducation}
                                                        name="highestEducation"
                                                        type="text"
                                                    />
                                                    {errors.highestEducation && touched.highestEducation ? <div className="error">{errors.highestEducation}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Linkedin Profile Link</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Linkedin Profile Link"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.linkdinProfile}
                                                        name="linkdinProfile"
                                                        type="text"
                                                    />
                                                    {errors.linkdinProfile && touched.linkdinProfile ? <div className="error">{errors.linkdinProfile}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Open to Work in</label>
                                                    <Select styles={customStyles} options={this.state.openToWorkOptions} className="" placeholder={this.state.currentCandidateData?.attributes?.open_to_work_in ? this.state.currentCandidateData?.attributes?.open_to_work_in : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'openToWork')} />
                                                    {this.state.openToWorkError !== null && <div className="error">{this.state.openToWorkError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Current Location</label>
                                                    <Select styles={customStyles} options={this.state.citiesOptions} className="" placeholder={this.state.currentCandidateData?.attributes?.current_location ? this.state.currentCandidateData?.attributes?.current_location : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'currentLocation')} />
                                                    {this.state.currentLocationError !== null && <div className="error">{this.state.currentLocationError}</div>}
                                                </div>
                                                <div>
                                                    <label className="my-label">Relocation Status</label>
                                                    {/* <p className="relocation-status">Relocation Status</p> */}
                                                    <div className="row">
                                                        <div className="col-4 mt-1 mb-3">
                                                            <Select styles={customStyles} options={this.state.citiesOptions} placeholder={this.state.currentCandidateData?.attributes?.relocation_status[0] ? this.state.currentCandidateData?.attributes?.relocation_status[0] : "Option 1"} onChange={(selectedOption) => this.handleSelect(selectedOption, 'option1')} />
                                                            {this.state.option1Error !== null && <div className="error">{this.state.option1Error}</div>}

                                                        </div>
                                                        <div className="col-4 mt-1 mb-3">

                                                            <Select styles={customStyles} options={this.state.citiesOptions} placeholder={this.state.currentCandidateData?.attributes?.relocation_status[1] ? this.state.currentCandidateData?.attributes?.relocation_status[1] : "Option 2"} onChange={(selectedOption) => this.handleSelect(selectedOption, 'option2')} />
                                                            {this.state.option2Error !== null && <div className="error">{this.state.option2Error}</div>}

                                                        </div>
                                                        <div className="col-4 mt-1 mb-3">
                                                            <Select styles={customStyles} options={this.state.citiesOptions} placeholder={this.state.currentCandidateData?.attributes?.relocation_status[2] ? this.state.currentCandidateData?.attributes?.relocation_status[2] : "Option 3"} onChange={(selectedOption) => this.handleSelect(selectedOption, 'option3')} />
                                                            {this.state.option3Error !== null && <div className="error">{this.state.option3Error}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-step row">
                                            <div className="row col-9">
                                                <Link to={window.location.href.includes('Edit-user') ? "/BasicDetails/Edit-user" : "/BasicDetails"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/DomainKnowledge/Edit-user" : "/DomainKnowledge"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/FamilyDetails/Edit-user" : "/FamilyDetails"} className="step-bar col-2"></Link>
                                            </div>
                                            <div className="row col-3">
                                                <button className="btn btn-sm candidate-button" type="submit">Save And Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
