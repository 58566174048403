import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Button,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
//@ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";
import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginBlockController.web";

import { Link, withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from "yup";
import './login.css'
// Customizable Area End

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  password: Yup.string()
    .required("Password is required."),
});

class EmailAccountLoginBlock extends EmailAccountLoginController {

  // Customizable Area Start
  async componentDidMount() {
    const email = localStorage.getItem('userEmailId') ? localStorage.getItem('userEmailId') : '';
    const password = localStorage.getItem('userPassword') ? localStorage.getItem('userPassword') : '';
    this.setState({ email, password });
  }

  rememberMe = () => {
    this.setState({ checkedRememberMe: !this.state.checkedRememberMe })
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {

    const styles = getStyles();
    const assets = require("./assets");

    return (
      // Customizable Area Start
      // Required for all blocks
      <div className="main">
        {/* <Loader loading={loader} /> */}
        <div className="center-item">
          <img src={assets.pqLogo} className="pq-logo" alt="pq-logo" />
          <div className="view-base-login">
            <div>
              <p className="textLabel_Heading">Let's Sign You In</p>
              <p className="textLabel_sub_Heading">Welcome back, you’ve been missed!</p>
              <Formik
                initialValues={{
                  email: localStorage.getItem('userEmailId') ? localStorage.getItem('userEmailId') : '',
                  password: localStorage.getItem('userPassword') ? localStorage.getItem('userPassword') : '',
                  rememberMeFlag: false,
                }}
                validationSchema={loginSchema}
                onSubmit={values => {
                  // @ts-ignore.
                  window.localStorage.setItem('userPassword', values.password);
                  this.handleLogin(values)
                }}
              >
                {({ errors, touched, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mt-1 mb-3">
                      <input onChange={handleChange} className="form-control input-field" name="email" type="email" placeholder={this.state.email ? this.state.email : "Enter Email"} />
                      {errors.email && touched.email ? <div className="error mt-1 error-msg">{errors.email}</div> : null}
                    </div>
                    <div className="mt-1 mb-3">
                      <input onChange={handleChange} className="form-control input-field mb-3" name="password" type={this.state.passwordView ? "text" : "password"} placeholder={this.state.password ? !this.state.passwordView ? '●●●●●●●●' : this.state.password : "Enter Password"} />
                      <div className="password-view">
                        {!this.state.passwordView ? <img width="70%" src={assets.imgPasswordVisible} alt="view" onClick={() => this.handlePasswordView()} /> : <img width="70%" src={assets.imgPasswordInVisible} onClick={() => this.handlePasswordView()} alt="view" />}
                      </div>
                      {errors.password && touched.password ? <div className="error mt-2 error-msg">{errors.password}</div> : null}
                    </div>
                    <div className="forget-password mt-3">
                      <Link className="forget-password" to="/ForgotPassword">Forgot Password?</Link>
                    </div>
                    <div className="check-box mb-4">
                      <input type="checkbox" name="rememberMeFlag" onChange={this.rememberMe} /> Remember Me
                    </div>
                    <button className="btn signin-button" style={{backgroundColor: '#b006ae!important' }} type="submit">Login</button>
                    {this.state.loginError !== null ? <div className="error mt-2 error-msg">{this.state.loginError}</div> : ""}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  bgOtherLoginButton: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "center",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    color: "#6200EE",
    fontWeight: "bold"
  },

  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  },

  labelTitle: {
    marginTop: 24,
    marginBottom: 32,
    fontSize: 32,
    textAlign: "left",
    marginVertical: 8,
    color: "#6200EE"
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},

  forgotPassword: {
    color: "#6200EE",
    fontWeight: "bold",
    marginBottom: 10,
    zIndex: -1
  },
  checkBoxContainerView: {
    flexDirection: "row",
    marginBottom: 10,
    marginLeft: -7,
    zIndex: -1
  },
  rememberMe: {
    color: "#6200EE",
    fontWeight: "bold",
    alignSelf: "center",
    zIndex: -1
  },
  orLabel: {
    color: "#00000000",
    fontWeight: "bold",
    alignSelf: "center",
    margin: 20
  }
});

// 1200px and up
const baseStyles = {
  container: {
    width: "100%",
    minHeight: "100%",
    flexDirection: "row"
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 12,
    letterSpacing: 0.4
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    marginTop: 12,
    backgroundColor: "#e45d33",
    borderColor: "#e45d33",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row"
  },
  buttonText: {
    fontSize: 17,
    color: "#ffffff"
  },
  leftContainer: {
    backgroundColor: "#ffffff",
    width: "50%",
    minHeight: "100%",
    paddingHorizontal: "5.5%",
    paddingVertical: "3%",
    justifyContent: "space-between"
  },
  logoImage: {
    width: 35,
    height: 35
  },
  leftTitle: {
    fontSize: 30,
    color: "#1a181d",
    marginTop: 50,
    fontWeight: 700
  },
  textInputContainer: {
    alignItems: "center",
    height: 56,
    marginTop: 56,
    borderColor: "rgba(0, 0, 0, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    flexDirection: "row",
  },
  plainTextInput: {
    width: "100%",
    marginBottom: 18
  },
  textInput: {
    width: "100%",
    height: "100%",
    fontSize: 17,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 50,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: 0.15
  },
  textInputIcon: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 15
  },
  textInputError: {
    borderColor: "#b00020",
  },
  textInputErrorMessageContainer: {
    minHeight: 18
  },
  textInputErrorMessage: {
    color: "#b00020",
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.4,
    marginTop: 3,
    position: "absolute"
  }
  ,
  rightContainer: {
    backgroundColor: "rgb(20,29,61)",
    width: "50%",
    minHeight: "100%"
  },
  rightSectionBackgroundImage: {
    width: "100%",
    height: "100%"
  },
  rightContentContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSectionPeopleImage: {
    width: "80%",
    height: "50%"
  },
  rightImageTitle: {
    color: "white",
    marginVertical: 20,
    fontSize: 22,
    fontWeight: "500",
    textAlign: "center",
    width: "50%"
  },
  rightImageDescription: {
    color: "white",
    fontSize: 12,
    textAlign: "center",
    width: "75%"
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 56
  },
  inputWrapper: {
    width: "49%"
  }
};

const getStyles = () => createStyles(
  baseStyles,
  // below 1200
  maxWidth(1200, {
  }),
  // below 992
  maxWidth(992, {
    leftContainer: {
      width: "100%"
    },
    rightContainer: {
      display: "none"
    }
  }),
  // below  768
  maxWidth(768, {
  }),
  // below  576
  maxWidth(576, {
    formRow: {
      flexDirection: "column"
    },
    textInputContainer: {
      width: "100%"
    },
    inputWrapper: {
      width: "100%"
    }
  }),
);
// @ts-ignore
export default withRouter(EmailAccountLoginBlock)
// Customizable Area End