import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
// Customizable Area Start
import { Formik } from 'formik'
import Select from 'react-select'
import Modal from 'react-modal';
import { Link } from 'react-router-dom'
import * as yup from "yup";
import { TextField } from '@material-ui/core';
import { uploadIcon2 } from "./assets";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

export default class DomainKnowledge extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    handleFileChange = (event: any) => {
        let name: any = '';
        let target: any = '';
        let value: any = '';
        event && event.preventDefault();
        target = event.target;
        value = {
            file: target.files[0],
            fileName: target.files[0].name
        }
        name = target.name;
        //@ts-ignore
        this.setState({ [name]: value });

    }
    domainKnowledgeSchama = () => {
        return yup.object().shape({
            spanOfPeople: yup
                .number()
                .required("No of People is required."),
            previousStints: yup
                .string()
                .required("Previous Stincts is required."),
            techbuilt: yup
                .string()
                .required("Tech Built is required."),
        });
    }
    addSchema = () => {
        return yup.object().shape({
            company_name: yup
                .string()
                .required("Company Name is required."),
            location: yup
                .string()
                .required("Location is required."),
            designation: yup
                .string()
                .required("Designation is required."),
            worked_from: yup
                .date()
                .required("From Date is required."),
            worked_till: yup
                .date()
                .required("End Date is required."),
            technologies_worked_upon: yup
                .string()
                .required("Worked on Technologies required."),
            no_of_people_managed: yup
                .number()
                .required("Number of People Manged is required."),
            compensation: yup
                .number()
                .required("Compensation is required."),
            reporting_manager: yup
                .string()
                .required("Reporting Manager is required."),
            reason_of_moving_out: yup
                .string()
                .required("Reason for moving out required."),
            company_description: yup
                .string()
                .required("About Company is required."),
            key_achievement: yup
                .string()
                .required("Key Achievements Required")
        });
    }
    openDropDownModalUI = () => {
        return <div style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}><ul className="ellipsis-dropdown1-ul" id="view-modal-dropdown">
            <li className="ellipsis-dropdown-li" onClick={() => this.setState({ domainModal: true, newCredential: false })}>View/Edit</li>
        </ul></div>
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    spanOfPeople: this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.highest_span_of_people_managed ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.highest_span_of_people_managed : "",
                                    previousStints: this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.learnings_from_previous_stints ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.learnings_from_previous_stints : "",
                                    techbuilt: this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.tech_built_to_scale ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.tech_built_to_scale : ""
                                }}
                                validationSchema={this.domainKnowledgeSchama}
                                onSubmit={values => {
                                    // same shape as initial values
                                    this.handleDomainKnowledge(values)
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <p className="heading-head">{window.location.href.includes('Edit-user') ? 'Edit Candidate' : 'Add New Candidate'}</p>
                                            <p className="heading"><b>Domain Knowledge</b></p>

                                            <div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Domain Exposure</label>

                                                    <Select isMulti styles={customStyles} options={this.state.domainExposureOptions} placeholder={this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.domain_exposures.length ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.domain_exposures.map((x: any) => { return `${x.name} x ` }) : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'domainExposure')} />
                                                    {this.state.domainExposureError !== null && <div className="error">{this.state.domainExposureError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Geographies Exposure</label>

                                                    <Select isMulti styles={customStyles} options={this.state.citiesOptions} placeholder={this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.geographies_exposure.length ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.geographies_exposure.map((x: any) => { return `${x} x ` }) : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'geoGraphicExposure')} />
                                                    {this.state.geoGraphicExposureError !== null && <div className="error">{this.state.geoGraphicExposureError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Scale of Growth</label>

                                                    <Select styles={customStyles} options={this.state.growthOptions} placeholder={this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.scale_of_growth ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.scale_of_growth : ""}
                                                        onChange={(selectedOption) => this.handleSelect(selectedOption, 'growth')} />
                                                    {this.state.growthError !== null && <div className="error">{this.state.growthError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Tech built to scale</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        //label="Tech built to scale "
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.techbuilt}
                                                        name="techbuilt"
                                                        type="text"
                                                    />
                                                    {errors.techbuilt && touched.techbuilt ? <div className="error">{errors.techbuilt}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Motivation</label>

                                                    <Select isMulti styles={customStyles} options={this.state.motivationOptions} placeholder={this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.motivation.length ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.motivation.map((x: any) => { return `${x} X ` }) : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'motivation')} />
                                                    {this.state.motivationError !== null && <div className="error">{this.state.motivationError}</div>}

                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Learnings from previous stints</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Learnings from previous stints"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.previousStints}
                                                        name="previousStints"
                                                        type="text"
                                                    />
                                                    {errors.previousStints && touched.previousStints ? <div className="error">{errors.previousStints}</div> : null}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label"> Candidate Type</label>
                                                    {/* Candidate Type */}
                                                    <Select styles={customStyles} options={this.state.technologiestOptions} placeholder={this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.candidate_type ? this.state.currentCandidateData?.attributes?.domain_knowledge?.data?.attributes?.candidate_type : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'candidateType')} />
                                                    {this.state.candidateTypeError !== null && <div className="error">{this.state.candidateTypeError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Highest span of people managed</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label="Highest span of people managed"
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.spanOfPeople}
                                                        name="spanOfPeople"
                                                        type="text"
                                                    />

                                                    {errors.spanOfPeople && touched.spanOfPeople ? <div className="error">{errors.spanOfPeople}</div> : null}
                                                </div>
                                                <div className="row mt-1 mb-3">
                                                    <div className="col-10">
                                                        <label className="my-label">Add Image of Geographics Worked In</label>
                                                        {/* <p className="relocation-status">Add Image of Geographics Worked In</p> */}
                                                    </div>

                                                    <div className="col-5" style={{ marginLeft: '10px' }}>
                                                        <label className="btn-upload">
                                                            {/* @ts-ignore */}
                                                            <input type='file' accept="image/png" name="uploadLocationMap" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                                                            <button type="button" className="btn">
                                                                <img src={uploadIcon2} className="upload-icon" />
                                                                Upload Location</button>
                                                        </label>
                                                    </div>
                                                    {/* @ts-ignore */}
                                                    <div className="col-5 file-label"><i> {this.state.uploadLocationMap !== null ? this.state.uploadLocationMap?.fileName : "No Image Uploaded"}</i></div>

                                                    {this.state.uploadLocationMap === "error" ? <div className="error">Please Upload Location Map</div> : null}
                                                </div>
                                                <div className="row" style={{ lineBreak: 'auto' }}>
                                                    <div className="col-10">
                                                        <label className="my-label">Employment Credentials</label>
                                                        <p className="relocation-status"> </p>
                                                    </div>
                                                    <div className="col-2">
                                                        <button type="button" onClick={() => {
                                                            this.openDomainModal()
                                                            this.setState({
                                                                modalType: 'create',
                                                                newCredential: true,
                                                                dataId: this.state.employmentCredentials.length + 1
                                                            })
                                                        }} className="btn candidate-button float-right" aria-required>Add</button>
                                                    </div>
                                                    {this.state.empCredError !== null && <div className="error">{this.state.empCredError}</div>}
                                                    <div className="row">

                                                        {
                                                            this.state.empCredentials && this.state.empCredentials.map((x: any) => {
                                                                return (
                                                                    <div className="view-mask m-2">
                                                                        <div className="row">
                                                                            <div className="col-2">
                                                                                <img src={x?.attributes?.logo || require('../../../components/src/img_avatar.png')} alt="" className="view-avatar" />
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="cred-main-text">
                                                                                    <p className="card-name">{x.attributes.company_name}</p>
                                                                                    <p className="card-company">{x.attributes.location}</p>
                                                                                    <p className="card-company">{new Date(x.attributes.worked_from).getFullYear()}-{new Date(x.attributes.worked_till).getFullYear()}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="col-2 ellipsis-item"
                                                                                    onClick={() => {
                                                                                        console.log(x)

                                                                                        this.setState(
                                                                                            {
                                                                                                isDropDownOpenId: this.state?.isDropDownOpenId === x?.id ? null : x?.id,
                                                                                                selectedCredentials: x,
                                                                                                empCredentialsId: x?.id,
                                                                                                modalType: 'update',
                                                                                                uploadCompanyLogo: "testImage",
                                                                                                compensationCurrency: { "value": x?.attributes?.currency_id !== undefined ? x?.attributes?.currency_id : x?.attributes?.compensationCurrency.value }
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                                                                                    {this.state?.isDropDownOpenId === x?.id && this.openDropDownModalUI()}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p style={{ color: '#171717' }}>{x.attributes.designation}</p>
                                                                        </div>
                                                                        <div>
                                                                            <table className="card-info2">
                                                                                <tr>
                                                                                    <td>Technical</td><td>{x.attributes.technologies_worked_upon}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Team Managed</td><td>{x.attributes.no_of_people_managed}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                        {
                                                            this.state.employmentCredentials && this.state.employmentCredentials.map((x: any, index: any) => {
                                                                return (

                                                                    <div className="view-mask m-2">
                                                                        <div className="row">                                                                     <div className="col-2">
                                                                            <img src={x?.attributes?.profile_picture || require('../../../components/src/img_avatar.png')} alt="Avatar" className="view-avatar" />
                                                                        </div>
                                                                            <div className="col-8">
                                                                                <div className="cred-main-text">
                                                                                    <p className="card-name">{x.company_name}</p>
                                                                                    <p className="card-company">{x.location}</p>
                                                                                    <p className="card-company">{new Date(x.worked_from).getFullYear()}-{new Date(x.worked_till).getFullYear()}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="col-2 ellipsis-item"
                                                                                    onClick={() => {
                                                                                        console.log(x)
                                                                                        this.setState(
                                                                                            {
                                                                                                isDropDownOpenId: this.state?.isDropDownOpenId === x?.dataId ? null : x?.dataId,
                                                                                                selectedCredentials: x,
                                                                                                // empCredentialsId: x?.id,
                                                                                                modalType: 'update',
                                                                                                uploadCompanyLogo: "testImage",
                                                                                                compensationCurrency: { "value": this.state.compensationCurrency.value }

                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                                                                                    {this.state?.isDropDownOpenId === x?.dataId && this.openDropDownModalUI()}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p style={{ color: '#171717' }}>{x.designation}</p>
                                                                        </div>
                                                                        <div>
                                                                            <table className="card-info2">
                                                                                <tr>
                                                                                    <td>Technical</td><td>{x.technologies_worked_upon}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Team Managed</td><td>{x.no_of_people_managed}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-step row">
                                            <div className="row col-9">
                                                <Link to={window.location.href.includes('Edit-user') ? "/BasicDetails/Edit-user" : "/BasicDetails"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/DomainKnowledge/Edit-user" : "/DomainKnowledge"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"} className="step-bar col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/FamilyDetails/Edit-user" : "/FamilyDetails"} className="step-bar col-2"></Link>

                                            </div>
                                            <div className="row col-3">
                                                <button className="btn candidate-button" type="submit">Save And Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.domainModal}
                        onRequestClose={this.closeDomainModal}
                        style={modalStyles}
                        contentLabel=""
                    >
                        <div className="">
                            <Formik
                                enableReinitialize={true}
                                initialValues={this.state.newCredential ? {
                                    company_name: "",
                                    location: "",
                                    designation: "",
                                    worked_from: "",
                                    worked_till: "",
                                    technologies_worked_upon: "",
                                    no_of_people_managed: "",
                                    compensation: "",
                                    reporting_manager: "",
                                    reason_of_moving_out: "",
                                    comment: "",
                                    company_description: "",
                                    key_achievement: ""
                                } :
                                    {
                                        company_name: this.state.selectedCredentials?.attributes?.company_name ? this.state.selectedCredentials?.attributes?.company_name : "" || this.state?.currentEmploymentCredentials?.company_name ? this.state?.currentEmploymentCredentials?.company_name : "",
                                        location: this.state.selectedCredentials?.attributes?.location ? this.state.selectedCredentials?.attributes?.location : "" || this.state?.currentEmploymentCredentials?.location ? this.state?.currentEmploymentCredentials?.location : "",
                                        designation: this.state.selectedCredentials?.attributes?.designation ? this.state.selectedCredentials?.attributes?.designation : "" || this.state?.currentEmploymentCredentials?.designation ? this.state?.currentEmploymentCredentials?.designation : "",
                                        worked_from: this.state.selectedCredentials?.attributes?.worked_from ? this.state.selectedCredentials?.attributes?.worked_from : "" || this.state?.currentEmploymentCredentials?.worked_from ? this.state?.currentEmploymentCredentials?.worked_from : "",
                                        worked_till: this.state.selectedCredentials?.attributes?.worked_till ? this.state.selectedCredentials?.attributes?.worked_till : "" || this.state?.currentEmploymentCredentials?.worked_till ? this.state?.currentEmploymentCredentials?.worked_till : "",
                                        technologies_worked_upon: this.state.selectedCredentials?.attributes?.technologies_worked_upon ? this.state.selectedCredentials?.attributes?.technologies_worked_upon : "" || this.state?.currentEmploymentCredentials?.technologies_worked_upon ? this.state?.currentEmploymentCredentials?.technologies_worked_upon : "",
                                        no_of_people_managed: this.state.selectedCredentials?.attributes?.no_of_people_managed ? this.state.selectedCredentials?.attributes?.no_of_people_managed : "" || this.state?.currentEmploymentCredentials?.no_of_people_managed ? this.state?.currentEmploymentCredentials?.no_of_people_managed : "",
                                        compensation: this.state.selectedCredentials?.attributes?.compensation ? this.state.selectedCredentials?.attributes?.compensation : "" || this.state?.currentEmploymentCredentials?.compensation ? this.state?.currentEmploymentCredentials?.compensation : "",
                                        reporting_manager: this.state.selectedCredentials?.attributes?.reporting_manager ? this.state.selectedCredentials?.attributes?.reporting_manager : "" || this.state?.currentEmploymentCredentials?.reporting_manager ? this.state?.currentEmploymentCredentials?.reporting_manager : "",
                                        reason_of_moving_out: this.state.selectedCredentials?.attributes?.reason_of_moving_out ? this.state.selectedCredentials?.attributes?.reason_of_moving_out : "" || this.state?.currentEmploymentCredentials?.reason_of_moving_out ? this.state?.currentEmploymentCredentials?.reason_of_moving_out : "",
                                        comment: this.state.selectedCredentials?.attributes?.comment ? this.state.selectedCredentials?.attributes?.comment : "" || this.state?.currentEmploymentCredentials?.comment ? this.state?.currentEmploymentCredentials?.comment : "",
                                        company_description: this.state.selectedCredentials?.attributes?.company_description ? this.state.selectedCredentials?.attributes?.company_description : "" || this.state?.currentEmploymentCredentials?.company_description ? this.state?.currentEmploymentCredentials?.company_description : "",
                                        key_achievement: this.state.selectedCredentials?.attributes?.key_achievement ? this.state.selectedCredentials?.attributes?.key_achievement : "" || this.state?.currentEmploymentCredentials?.key_achievement ? this.state?.currentEmploymentCredentials?.key_achievement : "",

                                    }}
                                validationSchema={this.addSchema}
                                onSubmit={values => {
                                    let data = {
                                        dataId: this.state.dataId,
                                        id: this.state.empCredentialsId,
                                        company_name: values.company_name,
                                        location: values.location,
                                        designation: values.designation,
                                        worked_from: values.worked_from,
                                        worked_till: values.worked_till,
                                        technologies_worked_upon: values.technologies_worked_upon,
                                        no_of_people_managed: values.no_of_people_managed,
                                        compensation: values.compensation,
                                        reporting_manager: values.reporting_manager,
                                        reason_of_moving_out: values.reason_of_moving_out,
                                        comment: values.comment,
                                        company_description: values.company_description,
                                        companyLogo: this.state.uploadCompanyLogo && this.state.uploadCompanyLogo,
                                        key_achievement: values.key_achievement,
                                        compensationCurrency: this.state.compensationCurrency
                                    }

                                    if (this.state.uploadCompanyLogo === null || data.compensationCurrency === undefined) {
                                        if (this.state.uploadCompanyLogo === null) {
                                            this.setState({
                                                uploadCompanyLogo: "error"
                                            })
                                        }
                                        if (data.compensationCurrency === undefined) {
                                            this.setState({
                                                compensationCurrencyError: "Please Select Currency"
                                            })
                                        }

                                    } else {
                                        this.handleEmploymentCredentials(data, this.state.modalType, this.state.newCredential)
                                        this.setState({
                                            uploadCompanyLogo: null,
                                            compensationCurrencyError: "",
                                            isDropDownOpenId: null
                                        })
                                    }
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Name of the Company</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="Name of the Company"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="company_name"
                                                    type="text"
                                                    value={values.company_name}
                                                />
                                                {errors.company_name && touched.company_name ? <div className="error">{errors.company_name}</div> : null}
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">About Company</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    //label="About Company"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="company_description"
                                                    type="text"
                                                    value={values.company_description}
                                                />
                                                {errors.company_description && touched.company_description ? <div className="error">{errors.company_description}</div> : null}
                                            </div>
                                            <div className="row mt-1 mb-3">
                                                <div className="col-5" style={{ marginLeft: '10px' }}>
                                                    <label className="btn-upload">
                                                        {/* @ts-ignore */}
                                                        <input type='file' accept="image/png" name="uploadCompanyLogo" placeholder="Upload Image" onChange={(e) => this.handleFileChange(e)} />
                                                        <button type="button" className="btn">
                                                            <img src={uploadIcon2} className="upload-icon" />
                                                            Upload Logo</button>
                                                    </label>
                                                </div>
                                                {/* @ts-ignore */}
                                                <div className="col-5 file-label"><i> {this.state.uploadCompanyLogo !== null ? this.state.uploadCompanyLogo?.fileName : "No Image Uploaded"}</i></div>

                                                {this.state.uploadCompanyLogo === "error" ? <div className="error">Please Upload Logo</div> : null}
                                            </div>


                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Location</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="Location"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="location"
                                                    type="text"
                                                    value={values.location}
                                                />
                                                {errors.location && touched.location ? <div className="error">{errors.location}</div> : null}
                                            </div>

                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Designation</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    //label="Designation"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="designation"
                                                    type="text"
                                                    value={values.designation}

                                                />
                                                {errors.designation && touched.designation ? <div className="error">{errors.designation}</div> : null}
                                            </div>
                                            <div className="row mt-1 mb-3">
                                                <label className="my-label">Worked</label>
                                                {/* <p className="worked-heading" style={{ marginLeft: '10px' }}>Worked</p> */}
                                                <div className="col-6">
                                                    <input
                                                        value={values.worked_from}
                                                        onChange={handleChange} type="text" onFocus={(e) => e.target.type = 'date'} max="9999-12-31" className="form-control input-field mt-1 mb-3" name="worked_from" placeholder="From Date" />
                                                    {errors.worked_from && touched.worked_from ? <div className="error">{errors.worked_from}</div> : null}
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        value={values.worked_till}
                                                        onChange={handleChange} className="form-control input-field mt-1 mb-3" name="worked_till" type="text" max="9999-12-31" onFocus={(e) => e.target.type = 'date'} placeholder="Till Date" />
                                                    {errors.worked_till && touched.worked_till ? <div className="error">{errors.worked_till}</div> : null}
                                                </div>
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Technologies worked Upon</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="Technologies worked Upon"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="technologies_worked_upon"
                                                    type="text"
                                                    value={values.technologies_worked_upon}
                                                />
                                                {errors.technologies_worked_upon && touched.technologies_worked_upon ? <div className="error">{errors.technologies_worked_upon}</div> : null}
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Number of the people managed</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    //  label="Number of the people managed"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="no_of_people_managed"
                                                    type="text"
                                                    value={values.no_of_people_managed}
                                                />
                                                {errors.no_of_people_managed && touched.no_of_people_managed ? <div className="error">{errors.no_of_people_managed}</div> : null}
                                            </div>
                                            <div className="row mt-1 mb-3">
                                                <label className="my-label">Compensation</label>
                                                <div className="col-12">
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label='Compensation'
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        name="compensation"
                                                        type="text"
                                                        value={values.compensation}
                                                    />
                                                    {errors.compensation && touched.compensation ? <div className="error">{errors.compensation}</div> : null}

                                                </div>
                                                {
                                                    this.state.compensationCurrency !== undefined ?
                                                        <>
                                                            <Select styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder={this.getCuurencyValue('compensationCurrency')?.label !== undefined ? this.getCuurencyValue('compensationCurrency')?.label : '$'} onChange={(selectedOption) => this.handleSelect(selectedOption, 'compensationCurrency')} />
                                                        </>
                                                        :
                                                        <>
                                                            <Select styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'compensationCurrency')} />
                                                        </>
                                                }
                                                {this.state.compensationCurrencyError !== "" ? <div className="error">{this.state.compensationCurrencyError}</div> : null}

                                                {/* <Select styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'compensationCurrency')} /> */}
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Reporting Manager</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="Reporting Manager"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="reporting_manager"
                                                    type="text"
                                                    value={values.reporting_manager}
                                                />
                                                {errors.reporting_manager && touched.reporting_manager ? <div className="error">{errors.reporting_manager}</div> : null}
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Reason of Moving Out</label>
                                                <TextField
                                                    id="outlined-secondary"
                                                    // label="Reason of Moving Out"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    className="form-control input-field text-field-input text-field-addCompany"
                                                    name="reason_of_moving_out"
                                                    type="text"
                                                    value={values.reason_of_moving_out}
                                                />
                                                {errors.reason_of_moving_out && touched.reason_of_moving_out ? <div className="error">{errors.reason_of_moving_out}</div> : null}
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <label className="my-label">Key Achievements</label>
                                                <TextField
                                                    multiline
                                                    rows={5}
                                                    id="outlined-secondary"
                                                    //  label="Key Achivements"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={(e) => handleChange(e)}
                                                    className="form-control input-field"
                                                    name="key_achievement"
                                                    value={values.key_achievement}
                                                />
                                                {errors.key_achievement && touched.key_achievement ? <div className="error">{errors.key_achievement}</div> : null}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-6" style={{ textAlign: 'right' }}>
                                                <button className="btn domain-model-buttons" style={{ marginRight: '25px' }} type="button" onClick={() => {
                                                    this.closeDomainModal()
                                                    this.setState({
                                                        selectedCredentials: ''
                                                    })
                                                }
                                                }>Cancel</button>
                                            </div>
                                            <div className="col-6" style={{ textAlign: 'left' }}>
                                                <button className="btn domain-model-buttons" type="submit" >Save</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Modal>

                </div >
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB !important",
        fontColor: '#727F88 !important',
        fontWeight: '500 !important',
        borderColor: 'transparent !important',
        borderRadius: '10px !important',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '80%',
        // color: 'lightsteelblue'
    },
    overlay: {
        backgroundColor: 'rgb(49 42 42 / 75%)'
    }
};
// Customizable Area End
