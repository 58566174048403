import React from "react";
// Customizable Area Start
import CircularProgress from "@material-ui/core/CircularProgress";
const searchIcon = require('../assets/image-search@2x.png');
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";
import { Link } from 'react-router-dom'

export default class ViewCandidate extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        window.localStorage.removeItem('candidateAction')
        // Customizable Area End
    }
    // Customizable Area Start
    openDropdownModal = (profileId: number) => {
        this.setState({
            ismodalOpenId: this.state.ismodalOpenId === profileId ? null : profileId
        })
    }

    openDropDownModalUI = (profileId: number) => {
        return <ul className="ellipsis-dropdown-ul" id="view-modal-dropdown">
            <li onClick={() => this.getEditUser(profileId)} className="ellipsis-dropdown-li"><Link to={`CandidateProfile?id=${profileId}`}>View Profile</Link></li>
            <li onClick={() => this.getEditUser(profileId)}><Link to={`BasicDetails/Edit-user`}>Edit Profile</Link></li>
        </ul>
    }

    getEditUser = (profileId: number) => {
        window.localStorage.setItem('candidateAction','Edit')
        window.localStorage.setItem('candidateId', profileId.toString())
    }
    filterSearchResults = (e: any) => {
        this.setState({ searchText: e.target.value })
    }

    getCandidateList = () => {
        return (this.state.searchText.length ? this.state.candidatesData.filter((item: any) => {
            return ((item?.attributes?.full_name?.toLowerCase()?.includes(this.state.searchText.toLowerCase())
                || item?.attributes?.current_location?.toLowerCase().includes(this.state.searchText.toLowerCase())
                || item?.attributes?.email?.toLowerCase().includes(this.state.searchText.toLowerCase())
                || item?.attributes?.full_phone_number?.toLowerCase().includes(this.state.searchText.toLowerCase())
                || item?.attributes?.open_to_work_in?.toLowerCase().includes(this.state.searchText.toLowerCase())
                || item?.attributes?.highest_education?.toLowerCase().includes(this.state.searchText.toLowerCase())
                || item?.attributes?.linkedin_profile_link?.toLowerCase().includes(this.state.searchText.toLowerCase()))
            )
        }) : this.state.candidatesData)
    }
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <div className="p-3">
                <div className="form-group">
                    <div className="input-group">
                        <div className="search-icon">
                            <input type="text" className="candidate-search" placeholder="Search Here" onChange={(e: any) => this.filterSearchResults(e)} />
                        </div>
                    </div>
                </div>

                <div style={{ height: '578px', overflowX: 'hidden', overflowY: 'auto', padding: '5px' }}>
                    <div className="row dashboard-candidate" >
                        {

                            this.getCandidateList().length === 0 ?

                                <div className="loader">
                                    {
                                        this.state.candidateLoader ?
                                            <CircularProgress />
                                            :
                                            "No Data Found"

                                    }
                                </div>
                                :
                                //@ts-ignore
                                (this.getCandidateList())?.filter((x: any) => x.attributes?.domain_knowledge?.data?.attributes?.candidate_type === this.props.candidateType).map((y: any) => {
                                    return (

                                        <div className="view-mask m-2" style={{ cursor: 'pointer' }} >
                                            <div className="card_background" />
                                            <div className="row" style={{ 'marginTop': '12px' }}>
                                                <div className="col-2" onClick={() => {
                                                    window.location.href = `CandidateProfile?id=${y.id}`
                                                    this.getEditUser(y.id)
                                                }
                                                }>
                                                    <img src={y.attributes?.profile_picture || require('../../../components/src/img_avatar.png')} alt="" className="view-avatar" />
                                                </div>
                                                <div className="col-8" onClick={() => {
                                                    window.location.href = `CandidateProfile?id=${y.id}`
                                                    this.getEditUser(y.id)
                                                }}>
                                                    <p className="card-name">{y.attributes?.full_name}</p>
                                                    <p className="card-company">{y.attributes?.domain_knowledge?.data?.attributes?.current_employment_detail?.designation}

                                                        {y.attributes?.domain_knowledge?.data?.attributes?.current_employment_detail?.company_name?.length ? ", " : ''}

                                                        {y.attributes?.domain_knowledge?.data?.attributes?.current_employment_detail?.company_name}</p>

                                                </div>
                                                <div className="col-2 ellipsis-item" onClick={() => this.openDropdownModal(y.id)}>
                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />

                                                    <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                                                    {this.state.ismodalOpenId === y.id && this.openDropDownModalUI(y.id)}
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                this.getEditUser(y.id)
                                                window.location.href = `CandidateProfile?id=${y.id}`
                                            }}>
                                                <table className="card-info">
                                                    <tr>
                                                        <td>Total Experience</td><td>{y.attributes?.total_experience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Highest Education</td><td>{y.attributes?.highest_education}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current CTC</td><td>{y?.attributes?.expected_compensation?.data?.attributes?.total_compensation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current Location</td><td>{y.attributes?.current_location}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div style={{ padding: '10px' }} onClick={() => {
                                                window.location.href = `CandidateProfile?id=${y.id}`
                                                this.getEditUser(y.id)

                                            }}>
                                                <div className="row" style={{ 'float': 'right' }}>
                                                    <button className="btn btn-lastupdated" title={y.attributes?.created === "Today" || y.attributes?.created === "Yesterday" ? y.attributes?.created : `${y.attributes?.created}`}><img src={require('../../../components/src/image-time.png')} className="p-2" />{y.attributes?.created === "Today" || y.attributes?.created === "Yesterday" ? y.attributes?.created : `${y.attributes?.created}`}</button>
                                                    <button className="btn btn-profile-status" title={y.attributes?.profile_status !== " / " ? y.attributes?.profile_status : "Profile Status"}><img src={require('../../../components/src/image-award.png')} className="p-2" /><a href="/Dashboard">{y.attributes?.profile_status !== " / " ? y.attributes?.profile_status : "Profile Status"}</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
