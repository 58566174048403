import React from "react";
// Customizable Area Start

// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";
import ViewCandidate from "./ViewCandidate.web";


export default class AllCandidate extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        localStorage.removeItem('candidateId')
        // Customizable Area End
    }
    // Customizable Area Start
    handleSearchChange = (e: any) => {
        console.log(e.target.value);
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div>
                    <nav className="navbar2 navbar-expand-lg">
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <div className="navbar-text-form">
                                    <span className={this.state.viewCandidateType === 'Brahma' ? "p-3 active-filter-text" : "filter-text p-3"} onClick={() => this.setCandidateViewType('Brahma')}>Brahma</span>
                                    <span className={this.state.viewCandidateType === 'Vishnu' ? "p-3 active-filter-text" : "filter-text p-3"} onClick={() => this.setCandidateViewType('Vishnu')}>Vishnu</span>
                                    <span className={this.state.viewCandidateType === 'Mahesh' ? "p-3 active-filter-text" : "filter-text p-3"} onClick={() => this.setCandidateViewType('Mahesh')}>Mahesh</span>
                                </div>

                            </div>
                        </div>
                    </nav>
                    <div className="container">
                        {/* @ts-ignore */}
                        <ViewCandidate candidateType={this.state.viewCandidateType} />

                    </div>
                </div>
                <div>

                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
