import React from "react";
import ReactToPrint from "react-to-print";
import './dashboard.css';
import SimpleMap from "./map";
const locations = require("./locations.json");
const fullPage = require('../../../components/src/full-page-image.png');
const pq_logo = require('../../../components/src/purpleQuarter.svg');

export function PreviewResume(closeModal, values) {
  const options = {
    orientation: 'landscape', 
    unit: 'in',
    format: [4, 2]
  };
  var month= ["January","February","March","April","May","June","July",
            "August","September","October","November","December"];

  const getDateFormat = (date) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];;
    var now = new Date(date);
    return (months[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear())
  }
 

  return (
    <div > 
      <div onClick={closeModal()} className="btn btn-sm close-button" > Close</div>
        <div className="">
          <ReactToPrint
            pageStyle='@page { margin: 9mm !important;} @media print { body { -webkit-print-color-adjust: exact; } }'
            trigger={() => <div className="btn btn-sm download-button" type="submit">Download
            </div>}
            content= {() =>{
              const tableStat = document.getElementById('preview-resume-ref');
              const PrintElem = document.createElement('div');
              const footer = 
                `<div class="page-footer"><img src="${pq_logo}" alt="purple quarter" class="pq-capsule-footer"/> Purple Quarter</div>`;
              PrintElem.innerHTML = footer;
              PrintElem.appendChild(tableStat);
              return PrintElem;
            }}
          />
        </div>
      <div onClick={closeModal()} className="btn btn-sm close-button" > Close</div>
      
      <div id="preview-resume-ref" options={options}>
        <div className="page full-page-resume">
          
          <div className="row" style={{padding: '20px'}}>

            {/* Basic information */}
            <div className="col-3">
              <img src={values.profile_picture || require('../../../components/src/img_avatar.png')} className="resume-profile-photo"/>
            </div>
            <div className="col-4">
              <h2>{values.full_name}</h2>
               {values.current_position}<br/>
              <a href={values.linkedin_profile_link}>linkedIn</a>
            </div>
            {/* <div className="col-1"/> */}
            <div className="col-5" style={{borderLeft: '1px dotted'}}>
              <img src={require('../../../components/src/phone-icon.svg')} className="resume-icon"/>&nbsp; {values.full_phone_number}<br/>
              <img src={require('../../../components/src/email-icon.svg')} className="resume-icon"/>&nbsp;{values.email}
            </div>
          </div>
          
          {/* Time line */}
          <div className="row bottom-border" >
            <b className="resume-heading">TIME-LINE</b>
            <br/>
            {values?.employment_credentials?.data.map((ele,index) => 
            <div className="col-3">
              <b>{ele.attributes.worked_from?.split('-')[0]}</b>
              <div className={`resume-des border-color${index % 5}`}>
                <div style={{borderLeft: '1px dotted', padding: '5px'}}>
                  <b>{ele?.attributes.designation}</b><br/>
                  <span style={{fontSize: 'small'}}>{ele.attributes.company_name}<br/>
                  <span className={`color${index % 5}`}>{ele.attributes.worked_from && (`${ele.attributes.worked_from?.split('-')[0]} ${month[ele.attributes.worked_from?.split('-')[1] -1 ]}` +  '-' + `${ele.attributes.worked_till?.split('-')[0]} ${month[ele.attributes.worked_till?.split('-')[1] -1 ]}` )} </span>             
                  </span>
                </div>
              </div>

            </div>

              )}

          </div>
          
          {/* Executive summary */}
          <div className="row bottom-border">
            <b className="resume-heading">EXECUTIVE SUMMARY</b>
            <p>
              {values.executive_summary}
            </p>
          </div>

          {/* Industry, core competencies */}
          <div className="row bottom-border">
            <div className="col-4" >
            <b className="resume-heading">INDUSTRY WORKED IN</b>
            {values.industries?.map(ele => <li key={ele}>{ele}</li>)}
            </div>
            <div className="col-2" style={{borderLeft: '1px dotted'}}/>
            <div className="col-5">
              <b className="resume-heading">CORE COMPETENCIES</b>
              {values.core_competencies?.map(ele => <li key={ele}>{ele}</li>)}
            </div>
          </div>
          <br/>
          <div className="row bottom-border" >
            <b className="resume-heading">GEOGRAPHIES WORKED IN</b>
            <div className="col-3">
            {values.geographies?.map(ele => <li key={ele}>{ele}</li>)}
            </div>
            <div className="col-1"/>
            <div className="col-8">
            <img className="location-image" src={values.location_image}/>
            </div>
            {/* <div className="col-12"> */}
              {/* <SimpleMap locations={values.locations} /> */}

            {/* </div> */}
          </div>
              <br/>
          <div className="row bottom-border">
            <b className="resume-heading">WORK EXPERINCE</b>
            {values?.employment_credentials?.data?.map(ele => <div className="row work-experince-resume">
              <div className="col-4">
                <b>{getDateFormat(ele.attributes.worked_from)} - {getDateFormat(ele.attributes.worked_till)}</b>
              </div>
              <div className="col-1"/>
              
              <div className="col-7">
                <img src={ele.attributes.logo} style={{height: '60px', width: '60px'}}/>
                <br/>
                <br/>
                <b>{ele.attributes.company_name}</b>
                <br/>
                {ele.attributes.company_description}
                <br/>
                <div className="key-achievement-heading">KEY ACHIEVEMENTS</div>
                {ele.attributes.key_achievement}
              </div>
            </div>)}
          </div>

        </div>

        <div className="page full-page" style={{ backgroundColor: values.confidentiality_color }}>
        <img className="last-page-resume" src={fullPage}/>
{/* 
            <img style={{height: '600px', overflow: 'hidden',marginTop: '300px', width:'100%'}} src={confidentialityImage}  />
          </div> */}
        </div>

      </div>
    </div >
  );
}

