import React from "react";
import ReactToPrint from "react-to-print";

const pq_logo = require('../../../components/src/purpleQuarter.svg');

export function PreviewCompanyPdf(closeModal, values) {
  const options = {
    orientation: 'landscape',
    unit: 'in',
  };

  return (
    <div style={{ margin: '0px', fontSize: '14px' }}>
      <div onClick={closeModal()} className="btn btn-sm close-button" > Close</div>
      <div className="">
        <ReactToPrint
          trigger={() => <div className="btn btn-sm download-button" type="submit">Download
          </div>}
          pageStyle='@page { margin: 10mm !important;} @media print { body { -webkit-print-color-adjust: exact; } }'

          // pageStyle='@page { margin: 9mm !important;} @media print { body { -webkit-print-color-adjust: exact; } }'
          content={() => {
            const tableStat = document.getElementById('preview-company-ref');
            const PrintElem = document.createElement('div');
            const footer =
              `<div class="page-footer"><img src="${pq_logo}" alt="purple quarter" class="pq-capsule-footer-pq"/></div>`;
              
            PrintElem.innerHTML = footer;
            PrintElem.appendChild(tableStat);
            return PrintElem;
          }}
        />
      </div>
      <div id="preview-company-ref" options={options} style={{ lineBreak: "auto" }}>
        <div className="page">
          <div className="mb-3 mt-5 first-box" style={{ width: '98%' }}>
            <div className="row" style={{borderBottom:'1px solid'}}>
              <div className="col-2 m-2">
                <label className="my-label">Entity</label>
              </div>
              <div className="col-2 m-2">
                <label className="my-label">Date</label>
              </div>

              <div className="col-2 m-2">
                <label className="my-label">Change Type</label>
              </div>
              <div className="col-2 m-2">
                <label className="my-label">Description</label>
              </div>
              <div className="col-2 m-2">
                <label className="my-label">Changed by</label>
              </div>
              {/* <hr style={{width:'82%'}}/> */}
            </div>

          </div>

          <div id="download-log" className="">
            {values?.slice(0)?.reverse()?.map(x => {
              return (
                <div className="row" style={{ paddingTop: '20px', marginLeft: '' }}>
                  <div className="col-2 m-2 table-border">
                    <label className="my-label">{x?.attributes?.entity === "PqCapsule" ? 'Purple Quarter Capsule' : x?.attributes?.entity}</label>
                  </div>
                  <div className="col-2 table-border">
                    <label className="my-label">{x?.attributes?.date}</label>
                  </div>
                  <div className="col-2 m-2 table-border">
                    <label className="my-label change-type" style={x?.attributes?.change_type === 'Creation' ? {
                      // backgroundColor: '#d7fbe8',
                      color: '#009345'
                    } : x?.attributes?.change_type === 'Edit' ? {
                      // backgroundColor: '#fdf7d7',
                      color: '#c4a200'
                    } : {

                      // backgroundColor: '#ffdcdc',
                      color: '#c70202'
                    }}>{x?.attributes?.change_type}</label>
                  </div>
                  <div className="col-2 m-2 table-border">
                    <label className="my-label" style={{ lineBreak: 'auto' }}>{x?.attributes?.description}</label>
                  </div>
                  <div className="col-2 m-2 table-border">
                    <label className="my-label">{x?.attributes?.changed_by}</label>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>

    </div >
  );
}

