import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import NotificationsController, {
  Props,
  configJSON
} from "./NotificationsController";
//   @ts-ignore
import { ActionCableConsumer } from 'react-actioncable-provider';
import Modal from "react-modal";
import moment from 'moment'

//@ts-ignore


export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleReceived = this.handleReceived.bind(this);
    this.getAllNotifications()
    // Customizable Area End
  }

  // Customizable Area Start
  handleReceived(message: any) {
    console.log('__++++++##########______________________________ notifications Recieved', message, '=================================')
  }

  // Customizable Area End

  render() {
    console.log('_____________+++++++++++++++++++++++++**************************', this.state.allNotifications)
    return (
      // Customizable Area Start
      <Modal
        onRequestClose={() => this.props.updateNotificationModal()}
        isOpen={this.props.isOpenNotificationModal}
        contentLabel=""
        className="customStyles"
        style={{
          overlay: {
            backgroundColor: 'rgb(51 51 51 / 90%)'
          },
          content: {
            color: 'Black'
          }
        }}
      >
        <div className="wrapper">
          {/* @ts-ignore */}

          <div className="notification-place" style={{ display: `${this.props.isOpenNotificationModal ? '' : 'none'}` }}>
            {/* <h3>Notifications</h3> */}
            {this.state.allNotifications.map((ele: any) => <div className="notification-list">
              <b>{ele.attributes.headings.length > 30 ? `${ele.attributes.headings.slice(0, 28)}..` : ele.attributes.headings}</b>
              <br />
              {ele.attributes.contents.length > 35 ? `${ele.attributes.contents.slice(0, 32)}..` : ele.attributes.contents}
              <br />
              <label style={{
                position: 'relative',
                left: '72%'
              }}>{moment(ele.attributes.created_at).startOf('day').fromNow()}</label>
            </div>)}
          </div>

          {/* <ActionCableConsumer
          channel="NotificationChannel"
          onConnected={(e :any) => console.log('------connected Notification-------',e)} 
          onDisconnected={(e:any)=> console.log('-------------Disconnected notifications------')}
          onInitialized={(e:any)=> console.log('------------Initialized notifications------',e)}
          onRejected = {(e:any)=> console.log('------------Rejected notifications------')}
          onReceived={(e: any) => this.handleReceived(e)}
        /> */}
        </div >
      </Modal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    // background: "black",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    height: '44px',
    width: '150px !important',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}
// Customizable Area End
