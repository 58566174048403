import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  currentUser: any
  ismodalOpenId: any
  allCompanies: any
  viewCompany: any
  viewCompanyId: any
  singleCompanyData: any
  companyMenuType: any
  allAggrementsList: any
  searchFilter: any
  jobStatusType: any
  filterData: any
  addCompanyDomain: any,
  addCompanyStatus: any,
  addCompanyRole: any
  isfilterApplied: boolean,
  filteredCompanyData: any
  listOfDomain: any
  listOfRoles: any
  newArray: any
  loader: any
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCurrentUserApiCallId: string = "";
  GetCompaniesApiCallId: string = "";
  GetAllAggrementsAPICall: string = "";
  GetJobStatusApi: string = "";
  GetSingleCompanyApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentUser: "",
      ismodalOpenId: "",
      allCompanies: [],
      viewCompany: false,
      viewCompanyId: "",
      singleCompanyData: "",
      companyMenuType: "agreements",
      allAggrementsList: "",
      searchFilter: false,
      jobStatusType: "",
      filterData: {},
      addCompanyDomain: "",
      addCompanyStatus: "",
      addCompanyRole: "",
      isfilterApplied: false,
      filteredCompanyData: [],
      listOfDomain: [],
      listOfRoles: [],
      newArray: [],
      loader: false
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            currentUser: responseJson?.data?.attributes,
          })
        }
        //get companiesData
        if (apiRequestCallId === this.GetCompaniesApiCallId) {

          responseJson?.data?.map((ele: any) => ele?.attributes?.role?.map((item: any) => this.state.newArray.push(item)))

          this.setState({
            allCompanies: responseJson?.data,
            listOfDomain: responseJson?.data?.map((ele: any) => ele.attributes.domain).map((x: any) => {
              return {
                value: x, label: x
              }
            }).map((item: any) => item.label)
              .filter((value: any, index: any, self: any) => self.indexOf(value) === index).map((x: any) => {
                return {
                  label: x, value: x
                }
              }),
            listOfRoles: this.state.newArray && this.state.newArray.map((x: any) => {
              return {
                value: x, label: x
              }
            }).map((item: any) => item.label)
              .filter((value: any, index: any, self: any) => self.indexOf(value) === index).map((x: any) => {
                return {
                  label: x, value: x
                }
              }),
            loader: false
          })



        }
        //get agreementsData
        if (apiRequestCallId === this.GetCompaniesApiCallId) {
          this.setState({
            allAggrementsList: responseJson?.data,
          })
        }
        //get status Type
        if (apiRequestCallId === this.GetJobStatusApi) {
          this.setState({
            jobStatusType: responseJson?.job_statuses?.map((x: any) => {
              return {
                value: x.id, label: x.name
              }
            })
          })
        }
        //get single company Type
        if (apiRequestCallId === this.GetSingleCompanyApiCallID) {
          console.log(responseJson?.data)
          this.setState({
            singleCompanyData: responseJson?.data
          })
        }

      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getCurrentUser();
    await this.getCompanies();
    await this.getJobStatsues();
    await this.checkId()
    let presentPath: any = window.location.href.split('=')[1];
    presentPath = presentPath === '2' ? 2 : 1;
  }


  handleCompanyMenu = (type: any) => {
    this.setState({
      companyMenuType: type
    })
  }

  checkId = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    if (id !== null) {
      this.setState({
        viewCompany: true
      })
      this.getSingleCompanyData(id)
    }
  }

  getSingleCompanyData = (id: any) => {
    // let data = this.state.allCompanies.filter((x: any) => { return x.id === id })
    // this.setState({
    //   singleCompanyData: data[0]
    // })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetSingleCompanyApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companiesAPI}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }
  //getCurrentUser
  getCurrentUser = (): boolean => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCurrentUser
  getCompanies = (): boolean => {

    this.setState({
      loader: true
    })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCompaniesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companiesAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  //getAllAgreements
  getAllAggrements = () => {
    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllAggrementsAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_template/templates"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true

  }
  //getAllAgreements
  getJobStatsues = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetJobStatusApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllJobStatus
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }
  // Customizable Area End

}
