import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { cities } from './assets';
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  viewCandidateType: any;
  profileMenuType: any;
  candidateLoader: boolean;
  candidatesData: any
  currentUser: any
  defaultSrc: any
  preview: any
  selectedFile: any
  openToWork: any
  option1: any
  option2: any
  option3: any
  currentLocation: any
  openToWorkOptions: any
  citiesOptions: any
  comptencieOptions: any
  codingProficiencyOptions: any
  technologiestOptions: any
  coreValues: any
  codeValues: any
  technologistType: any
  domainModal: any
  domainExposureOptions: any
  growthOptions: any
  motivationOptions: any
  domainExposure: any
  geoGraphicExposure: any
  motivation: any
  candidateType: any
  growth: any
  familyNumberoptions: any
  martialStatusOptions: any
  maritialStatus: any
  familyNumbers: any
  currencyOptions: any
  stockOptions: any
  vestingOptions: any
  liqOptions: any
  stocksValue: any
  baseCurrencyValue: any,
  variableCurrencyValue: any
  vestingValue: any
  liqEventValue: any
  cashCompenstaion: any
  fileUploadError: any
  coreCompetenciesError: any
  technologistTypeError: any
  codingProficiencyError: any
  openToWorkError: any
  currentLocationError: any
  option1Error: any
  option2Error: any
  option3Error: any
  candidateTypeError: any
  motivationError: any
  growthError: any
  geoGraphicExposureError: any
  domainExposureError: any
  maritialStatusError: any
  familyNumbersError: any
  baseCurrencyError: any
  vairableCurrencyError: any
  stocksError: any
  vestingOptionsError: any
  liqEventError: any
  cashCompenstaionError: any
  employmentCredentials: any
  empCredError: any
  currentCandidateData: any
  currentCandidateId: any
  bonusCurrencyValue: any
  bonusCurrencyError: any
  cashCurrencyValue: any
  cashCurrencyError: any
  currencyValue: any
  ismodalOpenId: number | null
  emailExistingError: any
  searchText: any | null
  resumeDetails: any
  isPreviewResume: boolean
  candidateDocument: any
  candidateDocumentError: any
  currentUserId: any
  candidateDocumentArray: any
  isDropDownOpenId: any
  candidatesNames: any
  pqEmployeeArray: any
  pqEmployeeName: any
  noOfConnections: any
  connectionType: any
  retainerAdvance: any
  SPOCObeservations: any
  reportingMangerComment: any
  getCandidateComments: any
  uploadCompanyLogo: any
  aboutCompany: any
  compensationCurrency: any
  selectedCredentials: any
  empCredentialsId: any
  selectedEmploymentCredentials: any
  modalType: any
  currentEmploymentCredentials: any
  candidateProfileComments: any
  enteredCommentDescription: any
  uploadLocationMap: any
  timeLineData: any
  compensationCurrencyError: any
  expectedstocks: any
  expectedstocksError: any
  newCredential: any
  empCredentials: any
  dataId: any
  loaderStatus: any
  viewEdit: any
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  GetCandidatesApiCallId: string = "";
  GetCurrentUserApiCallId: string = "";
  comptenicesApiCallId: string = "";
  GetDomainExposureApiCallId: string = "";
  basicDetailsApiCallId: string = "";
  competenciesApiCallId: string = "";
  domainKnowledgeApiCallid: string = "";
  familyDetailsApiCallId: string = "";
  compenstaionApiCallId: string = "";
  expectedCompenstaionApiCallId: string = "";
  employementCredentialsApiCalllId: string = "";
  currentCandidateApiCallId: string = "";
  currencyApiCallId: string = "";
  getResumeDetailsApiCall: string = "";
  candidateDocumentApiCallId: string = "";
  getCandidateDocumentApiCallId: string = "";
  deleteCandidateDocumentApiCallId: string = "";
  createCommentApiCallId: string = "";
  getPqEmployeeApiCallId: string = "";
  getCandidateCommentsApiCallId: string = "";
  GetCandidateProfileComments: string = "";
  POSTprofileCommentAPICall: string = "";
  getTimeLineApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      viewCandidateType: "Brahma",
      profileMenuType: "basicDetails",
      candidateLoader: false,
      candidatesData: [],
      currentUser: "",
      defaultSrc: "https://www.pngkit.com/png/full/301-3012694_account-user-profile-avatar-comments-fa-user-circle.png",
      preview: "",
      selectedFile: "",
      openToWork: "",
      option1: "",
      option2: "",
      option3: "",
      currentLocation: "",
      openToWorkOptions: [
        { value: 'Early stage Start-up', label: 'Early stage Start-up' },
        { value: 'Mid Sized Start-up', label: 'Mid Sized Start-up' },
        { value: 'Late stage start-up', label: 'Late stage start-up' },
        { value: 'MNC', label: 'MNC' },
        { value: 'US entity in India', label: 'US entity in India' }
      ],
      citiesOptions: cities.map((x: any) => { return { value: x, label: x } }),
      comptencieOptions: [],
      codingProficiencyOptions: [
        { value: 'Still Codes', label: 'Still Codes' },
        { value: 'Only reviews code', label: 'Only reviews code' },
      ],
      technologiestOptions: [
        { value: 'Brahma', label: 'Brahma' },
        { value: 'Vishnu', label: 'Vishnu' },
        { value: 'Mahesh', label: 'Mahesh' },
      ],
      coreValues: [],
      codeValues: "",
      technologistType: "",
      domainModal: false,
      domainExposureOptions: [],
      growthOptions: [
        { value: '0-1x', label: '0-1x' },
        { value: '0-1x', label: '0-10x' },
        { value: 'Enterprise', label: 'Enterprise' },
      ],
      motivationOptions: [
        { value: 'Money', label: 'Money' },
        { value: 'Impact', label: 'Impact' },
        { value: 'Wealth', label: 'Wealth' },
        { value: 'Creation', label: 'Creation' },
        { value: 'Fame', label: 'Fame' },
        { value: 'Title', label: 'Title' },
        { value: 'Domain', label: 'Domain' }
      ],
      domainExposure: "",
      geoGraphicExposure: "",
      motivation: "",
      candidateType: "",
      growth: "",
      familyNumberoptions: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: '10+', label: '10+' },
      ],
      martialStatusOptions: [
        { value: 'married', label: 'Married' },
        { value: 'single', label: 'Single' },
      ],
      maritialStatus: "",
      familyNumbers: "",
      currencyOptions: [],
      stockOptions: [
        { value: 'ESOP', label: 'ESOP' },
        { value: 'RSU', label: 'RSU' },
        { value: 'SARS', label: 'SARS' },
        { value: 'RSA', label: 'RSA' },
        { value: 'Equity', label: 'Equity' },
        { value: 'Phantom Stocks', label: 'Phantom Stocks' },
        { value: 'LTIP', label: 'LTIP' },
      ],
      vestingOptions: [
        { value: 'Monthly', label: 'Monthly' },
        { value: 'Quarterly', label: 'Quarterly' },
        { value: 'Bi-Annually', label: 'Bi-Annually' },
        { value: 'Annually', label: 'Annually' }
      ],
      liqOptions: [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' }
      ],
      stocksValue: "",
      baseCurrencyValue: "",
      variableCurrencyValue: "",
      vestingValue: "",
      liqEventValue: "",
      cashCompenstaion: "",
      fileUploadError: '',
      coreCompetenciesError: '',
      technologistTypeError: '',
      codingProficiencyError: '',
      openToWorkError: '',
      currentLocationError: '',
      option1Error: '',
      option2Error: '',
      option3Error: '',
      candidateTypeError: '',
      motivationError: '',
      growthError: '',
      geoGraphicExposureError: '',
      domainExposureError: '',
      maritialStatusError: '',
      familyNumbersError: '',
      baseCurrencyError: '',
      vairableCurrencyError: '',
      stocksError: '',
      vestingOptionsError: '',
      liqEventError: '',
      cashCompenstaionError: '',
      employmentCredentials: [],
      empCredError: '',
      currentCandidateData: [],
      currentCandidateId: '',
      bonusCurrencyValue: '',
      bonusCurrencyError: '',
      cashCurrencyValue: '',
      cashCurrencyError: '',
      currencyValue: '',
      ismodalOpenId: null,
      emailExistingError: '',
      searchText: '',
      resumeDetails: '',
      isPreviewResume: false,
      candidateDocument: "",
      candidateDocumentError: "",
      currentUserId: "",
      candidateDocumentArray: "",
      isDropDownOpenId: "",
      candidatesNames: "",
      pqEmployeeArray: "",
      pqEmployeeName: "",
      noOfConnections: "",
      connectionType: "",
      retainerAdvance: "",
      SPOCObeservations: "",
      reportingMangerComment: "",
      getCandidateComments: "",
      uploadCompanyLogo: null,
      aboutCompany: "",
      compensationCurrency: "",
      selectedCredentials: "",
      empCredentialsId: "",
      selectedEmploymentCredentials: '',
      modalType: "",
      currentEmploymentCredentials: "",
      candidateProfileComments: [],
      enteredCommentDescription: "",
      uploadLocationMap: null,
      timeLineData: "",
      compensationCurrencyError: "",
      expectedstocks: "",
      expectedstocksError: "",
      newCredential: false,
      empCredentials: [],
      dataId: "",
      loaderStatus: false,
      viewEdit: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCoreCompetencies();
    this.getDomainExposure()
    this.getCandidates();
    this.getCurrentUser();
    this.getToken();

    document.addEventListener("mousedown", this.handleClickOutside)

    //Comments
    let userId = new URLSearchParams(window.location.search).get('id');

    userId && this.getCandidateProfileComments();

    if (localStorage.getItem('candidateId') !== null) {
      await this.getCurrentCandidate()
    }
    this.getCurrency()
    // Customizable Area End

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;


  }

  setCandidateViewType = (type: any) => {
    this.setState({
      viewCandidateType: type
    })
  }

  handleProfileMenu = (type: any) => {
    this.setState({
      profileMenuType: type
    })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        // getCandidateComments Data
        if (apiRequestCallId === this.getCandidateCommentsApiCallId) {
          this.setState({
            getCandidateComments: responseJson?.data
          })
        }
        // getCandidateDocument Data
        if (apiRequestCallId === this.getPqEmployeeApiCallId) {
          this.setState({
            pqEmployeeArray: responseJson?.pq_employee?.map((x: any) => {
              return {
                label: x.first_name, value: x.first_name
              }
            })
          })
        }

        // getCandidateDocument Data
        if (apiRequestCallId === this.getCandidateDocumentApiCallId) {
          this.setState({
            candidateDocumentArray: responseJson?.data
          })
        }

        // getCandidateDocument Data
        if (apiRequestCallId === this.deleteCandidateDocumentApiCallId) {
          this.getCandidateDocument()
        }

        //save candidateDocument Data
        if (apiRequestCallId === this.candidateDocumentApiCallId) {
          this.setState({
            candidateDocument: ""
          })
          this.getCandidateDocument()
        }

        //save candidateDocument Data
        if (apiRequestCallId === this.createCommentApiCallId) {
          window.location.reload()
          this.getCandidateComment()
          this.setState({
            retainerAdvance: "",
            connectionType: "",
            noOfConnections: "",
            pqEmployeeName: "",
            SPOCObeservations: "",
            reportingMangerComment: ""
          })
        }

        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            loaderStatus: false,
            currentUser: responseJson?.data?.attributes,
            currentUserId: responseJson?.data?.id,
          })
        }

        //get dashboarddata
        if (apiRequestCallId === this.dashboardApiCallId) {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false
          });
        }

        // get candidatesData 
        if (apiRequestCallId === this.GetCandidatesApiCallId) {
          this.setState({
            candidatesData: responseJson?.data,
            candidateLoader: false,
          })

          let optionList = [];
          let firstEle: any = { value: this.state.currentUserId, label: 'Myself' }

          optionList = responseJson?.data?.map((x: any) => {
            return { value: x?.full_name, label: x?.attributes?.full_name }
          });
          optionList = [firstEle].concat(optionList)

          this.setState({
            candidatesNames: optionList
          })
        }

        // get cuurencyData 
        if (apiRequestCallId === this.currencyApiCallId) {
          this.setState({
            currencyOptions: responseJson?.currency.map((x: any) => {
              return {
                label: x.currency_symbol, value: x.id
              }
            })
          })
        }

        // get currentCandidatesData 
        if (apiRequestCallId === this.currentCandidateApiCallId) {
          this.setState({
            currentCandidateData: responseJson?.data,
            fileUploadError: null,
            coreCompetenciesError: null,
            technologistTypeError: null,
            codingProficiencyError: null,
            openToWorkError: null,
            currentLocationError: null,
            option1Error: null,
            option2Error: null,
            option3Error: null,
            candidateTypeError: null,
            motivationError: null,
            growthError: null,
            geoGraphicExposureError: null,
            domainExposureError: null,
            maritialStatusError: null,
            familyNumbersError: null,
            baseCurrencyError: null,
            vairableCurrencyError: null,
            stocksError: null,
            vestingOptionsError: null,
            liqEventError: null,
            cashCompenstaionError: null,
            empCredError: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data?.length > 0 ? "" : null,
            bonusCurrencyError: null,
            cashCurrencyError: null,
            preview: responseJson?.data?.attributes?.profile_picture,
            // uploadLocationMap: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.location_image,
            openToWork: { label: responseJson?.data?.attributes?.open_to_work_in, value: responseJson?.data?.attributes?.open_to_work_in },
            currentLocation: { label: responseJson?.data?.attributes?.current_location, value: responseJson?.data?.attributes?.current_location },
            option1: { label: responseJson?.data?.attributes?.relocation_status[0], value: responseJson?.data?.attributes?.relocation_status[0] },
            option2: { label: responseJson?.data?.attributes?.relocation_status[1], value: responseJson?.data?.attributes?.relocation_status[1] },
            option3: { label: responseJson?.data?.attributes?.relocation_status[2], value: responseJson?.data?.attributes?.relocation_status[2] },
            codeValues: { label: responseJson?.data?.attributes?.competency?.data?.attributes?.coding_proficiency, value: responseJson?.data?.attributes?.competency?.data?.attributes?.coding_proficiency },
            technologistType: { label: responseJson?.data?.attributes?.competency?.data?.attributes?.type_of_technologist, value: responseJson?.data?.attributes?.competency?.data?.attributes?.type_of_technologist },
            coreValues: responseJson?.data?.attributes?.competency?.data?.attributes?.core_competency.map((x: any) => {
              return { label: x.name, value: x.id }
            }),
            geoGraphicExposure: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.geographies_exposure.map((x: any) => {
              return { label: x, value: x }
            }),
            growth: { label: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.scale_of_growth, value: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.scale_of_growth },
            candidateType: { label: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.candidate_type, value: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.candidate_type },
            domainExposure: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.domain_exposures.map((x: any) => {
              return { label: x.name, value: x.id }
            }),
            motivation: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.motivation.map((x: any) => {
              return { label: x, value: x }
            }),
            familyNumbers: { label: responseJson?.data?.attributes?.family_detail?.data?.attributes?.total_member, value: responseJson?.data?.attributes?.family_detail?.data?.attributes?.total_member },
            maritialStatus: { label: responseJson?.data?.attributes?.family_detail?.data?.attributes?.marital_status, value: responseJson?.data?.attributes?.family_detail?.data?.attributes?.marital_status },
            vestingValue: { label: responseJson?.data?.attributes?.compensation?.data?.attributes?.vesting_schedule, value: responseJson?.data?.attributes?.compensation?.data?.attributes?.vesting_schedule },
            liqEventValue: { label: responseJson?.data?.attributes?.compensation?.data?.attributes?.liquidation_event_at_current_org === true ? "Yes" : "No", value: responseJson?.data?.attributes?.compensation?.data?.attributes?.liquidation_event_at_current_org === true ? 1 : 0 },
            empCredentials: responseJson?.data?.attributes?.domain_knowledge?.data?.attributes?.employment_credentials?.data
          })


          if (responseJson?.data?.attributes?.domain_knowledge?.data === null) {
            window.localStorage.setItem('step1', 'one')
          } else {
            window.localStorage.removeItem('step1')
          }


          if (window.location.pathname === '/ExpectedCompensation' || window.location.pathname === '/ExpectedCompensation/Edit-user') {
            this.setState({
              currencyValue: { label: "", value: responseJson?.data?.attributes?.expected_compensation?.data?.attributes?.currency_id },
              expectedstocks: { label: responseJson?.data?.attributes?.expected_compensation?.data?.attributes?.stocks, value: responseJson?.data?.attributes?.expected_compensation?.data?.attributes?.stocks },
            })
          }
          if (window.location.pathname === '/Compensation' || window.location.pathname === '/Compensation/Edit-user') {
            this.setState({
              currencyValue: { label: "", value: responseJson?.data?.attributes?.compensation?.data?.attributes?.currency_id },
              stocksValue: { label: responseJson?.data?.attributes?.compensation?.data?.attributes?.stocks, value: responseJson?.data?.attributes?.compensation?.data?.attributes?.stocks },
            })
          }

        }

        if (apiRequestCallId === this.comptenicesApiCallId) {
          this.setState({
            comptencieOptions: responseJson?.core_competencies?.map((x: any) => { return { value: x.id, label: x.name } }),
          })
        }

        // get domainExposureData 
        if (apiRequestCallId === this.GetDomainExposureApiCallId) {
          this.setState({
            domainExposureOptions: responseJson?.domein_exposure.map((x: any) => { return { value: x.id, label: x.name } }),
          })
        }
        // Add Basic Details 
        if (apiRequestCallId === this.basicDetailsApiCallId) {
          if (responseJson?.errors?.length > 0) {
            this.setState({ emailExistingError: "Email has already been taken" })
          }
          if (responseJson?.data) {
            this.setState({ emailExistingError: null })
            this.setState({ loading: false })
            window.localStorage.setItem('candidateId', responseJson?.data?.id)
            window.location.href = window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies";
          }
        }
        // Add competencies Details 
        if (apiRequestCallId === this.competenciesApiCallId) {
          if (responseJson?.data) {
            window.location.href = window.location.href = window.location.href.includes('Edit-user') ? '/DomainKnowledge/Edit-user' : '/DomainKnowledge'
          }
        }

        // Add domainKnowledge Details 
        if (apiRequestCallId === this.domainKnowledgeApiCallid) {
          if (responseJson?.data) {
            window.location.href = window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"
          }
        }

        // Add familyDetails Details 
        if (apiRequestCallId === this.familyDetailsApiCallId) {
          if (responseJson?.data) {
            // window.localStorage.removeItem('candidateId')
            window.location.href = '/candidateDocuments'
          }
        }

        // Add compenstaion Details 
        if (apiRequestCallId === this.compenstaionApiCallId) {
          if (responseJson?.data) {
            window.location.href = window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"
          }
        }

        // Add expectedCompenstaion Details 
        if (apiRequestCallId === this.expectedCompenstaionApiCallId) {
          if (responseJson?.data) {
            window.location.href = window.location.href.includes('Edit-user') ? '/FamilyDetails/Edit-user' : '/FamilyDetails'
          }
        }

        if (apiRequestCallId === this.getResumeDetailsApiCall) {
          if (responseJson?.data) {
            this.setState({ resumeDetails: responseJson.data.attributes, isPreviewResume: true });
          }
        }

        // Candidate Profile Comments
        if (apiRequestCallId === this.GetCandidateProfileComments) {
          if (responseJson?.data) {
            this.setState({ candidateProfileComments: responseJson.data })
          } else {
            console.log("some Error Occur in loading comments");
          }
        }

        //Save Comment
        if (apiRequestCallId === this.POSTprofileCommentAPICall) {
          if (responseJson?.data) {
            this.setState({ candidateProfileComments: responseJson.data, enteredCommentDescription: "" });
            this.getCandidateProfileComments()
          } else {
            console.log("Some Error occurs");
          }
        }


        //timeline
        if (apiRequestCallId === this.getTimeLineApiCallId) {

          if (responseJson?.data) {
            this.setState({
              timeLineData: responseJson?.data
            })
          } else {
            console.log("Some Error occurs");
          }
        }



      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // @ts-ignore
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e?: any) => {
    // @ts-ignore
    if (document.getElementById("view-modal-dropdown") && !document.getElementById("view-modal-dropdown").contains(e.target)) {
      this.setState({ isDropDownOpenId: null, ismodalOpenId: null });
    }
  };


  //getPqEmployee
  getPqEmployees = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPqEmployeeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidatesGetUrl}/get_pq_employee`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getPqEmployee
  getTimeLine = () => {
    let userId = new URLSearchParams(window.location.search).get('id');
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTimeLineApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_candidate/candidates/get_timeline?id=${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  //getCandidateDocument
  getCandidateDocument = (): boolean => {
    let userId = new URLSearchParams(window.location.search).get('id') || window.localStorage.getItem('candidateId');

    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCandidateDocumentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidateDocumentApiEndPoint}?candidate_id=${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //delete document
  deleteDocument = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCandidateDocumentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidateDocumentApiEndPoint}/${this.state.isDropDownOpenId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardDeleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  //candidate file upload
  candidateFileUpload = () => {

    if (this.state.candidateDocument === "") {
      this.setState({
        candidateDocumentError: "Please Select File"
      })
    } else {
      this.setState({
        candidateDocumentError: ""
      })
      var formData = new FormData()
      if (this.state.candidateDocument.type.substring(0, 5) === "image") {
        formData.append('image', this.state.candidateDocument)
      } else {
        formData.append('file', this.state.candidateDocument)
      }
      formData.append('name', this.state.candidateDocument.name)
      //@ts-ignore
      formData.append('candidate_id', window.localStorage.getItem('candidateId'))
      formData.append('account_id', this.state.currentUserId)
      const header = {
        'token': window.localStorage.getItem('authToken')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.candidateDocumentApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.candidateDocumentApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardApiPostMethodType
      )

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true

    }
  }

  createComment = (e: any) => {
    e.preventDefault()
    var formData = new FormData()

    //@ts-ignore
    formData.append('comment_to_id', window.localStorage.getItem('candidateId'))
    formData.append('account_id', this.state.currentUserId)
    formData.append('mode_of_connection', this.state.connectionType)
    formData.append('no_of_connects', this.state.noOfConnections)
    formData.append('profile_observation', this.state.SPOCObeservations)
    formData.append('mangaer_comment', this.state.reportingMangerComment)
    formData.append('advance_date', this.state.retainerAdvance)


    this.state.pqEmployeeName.map((x: any, i: any) => {
      formData.append('people_connected[]', x.value)
    })

    const header = {
      'token': window.localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidateComment
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiPostMethodType
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //getCandidateComment 
  getCandidateComment = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCandidateCommentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidateComment}?candidate_id=${window.localStorage.getItem('candidateId')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleCurrentCandidate = (value: any) => {
    this.setState({
      currentCandidateId: value
    })
  }

  getCuurencyValue = (value: any) => {
    if (value === 'baseCurrency') {
      let x = this.state.currencyOptions?.filter((x: any) => { return x.value === this.state.currencyValue.value })
      return x && x[0]
    }
    if (value === 'compensationCurrency') {
      let x = this.state.currencyOptions?.filter((x: any) => { return x.value === this.state.compensationCurrency.value })
      return x && x[0]
    }
  }

  onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({ selectedFile: undefined })
      return
    }
    this.setState({
      fileUploadError: null
    })
    // I've kept this example simple by using the first image instead of multiple
    this.setState({ selectedFile: e.target.files[0] })

    const objectUrl = URL.createObjectURL(e.target.files[0])
    this.setState({ preview: objectUrl })
  }

  handleSelect = (selectedOption: any, type: any) => {

    if (type === 'multipleCandidates') {
      console.log(selectedOption)
      this.setState({
        pqEmployeeName: selectedOption
      })

    }
    if (type === 'openToWork') {
      this.setState({
        openToWork: selectedOption
      })
      this.setState({ openToWorkError: null })

    }
    if (type === 'option1') {
      this.setState({
        option1: selectedOption
      })
      this.setState({ option1Error: null })

    }
    if (type === 'option2') {
      this.setState({
        option2: selectedOption
      })
      this.setState({ option2Error: null })
    }
    if (type === 'option3') {
      this.setState({
        option3: selectedOption
      })
      this.setState({ option3Error: null })

    }
    if (type === 'currentLocation') {
      this.setState({
        currentLocation: selectedOption
      })
      this.setState({ currentLocationError: null })
    }
    if (type === "coreCompetencies") {
      this.setState({
        coreValues: selectedOption
      })
      this.setState({ coreCompetenciesError: null })
    }
    if (type === "codingProficiency") {
      this.setState({
        codeValues: selectedOption ? selectedOption : ''
      })
      this.setState({ codingProficiencyError: null })

    }
    if (type === "technologistType") {
      this.setState({
        technologistType: selectedOption
      })
      this.setState({ technologistTypeError: null })

    }
    if (type === "domainExposure") {
      this.setState({
        domainExposure: selectedOption
      })
      this.setState({ domainExposureError: null })
    }
    if (type === "geoGraphicExposure") {
      this.setState({
        geoGraphicExposure: selectedOption
      })
      this.setState({ geoGraphicExposureError: null })
    }
    if (type === 'motivation') {
      this.setState({
        motivation: selectedOption
      })
      this.setState({ motivationError: null })
    }
    if (type === 'candidateType') {
      this.setState({
        candidateType: selectedOption
      })
      this.setState({ candidateTypeError: null })

    }
    if (type === 'growth') {
      this.setState({
        growth: selectedOption
      })
      this.setState({ growthError: null })

    }
    if (type === "maritialStatus") {
      this.setState({ maritialStatus: selectedOption })
      this.setState({ maritialStatusError: null })
    }
    if (type === 'familyNumbers') {
      this.setState({ familyNumbers: selectedOption })
      this.setState({ familyNumbersError: null })
    }
    if (type === "baseCurrency") {
      this.setState({ currencyValue: selectedOption })
      this.setState({ baseCurrencyValue: selectedOption })
      this.setState({ variableCurrencyValue: selectedOption })
      this.setState({ bonusCurrencyValue: selectedOption })
      this.setState({ cashCurrencyValue: selectedOption })


      this.setState({ baseCurrencyError: null })
      this.setState({ vairableCurrencyError: null })
      this.setState({ bonusCurrencyError: null })
      this.setState({ cashCurrencyError: null })


    }
    if (type === 'vairableCurrency') {
      this.setState({ currencyValue: selectedOption })

      this.setState({ baseCurrencyValue: selectedOption })
      this.setState({ variableCurrencyValue: selectedOption })
      this.setState({ bonusCurrencyValue: selectedOption })
      this.setState({ cashCurrencyValue: selectedOption })


      this.setState({ baseCurrencyError: null })
      this.setState({ vairableCurrencyError: null })
      this.setState({ bonusCurrencyError: null })
      this.setState({ cashCurrencyError: null })
    }
    if (type === "bonusCurrency") {
      this.setState({ currencyValue: selectedOption })

      this.setState({ baseCurrencyValue: selectedOption })
      this.setState({ variableCurrencyValue: selectedOption })
      this.setState({ bonusCurrencyValue: selectedOption })
      this.setState({ cashCurrencyValue: selectedOption })


      this.setState({ baseCurrencyError: null })
      this.setState({ vairableCurrencyError: null })
      this.setState({ bonusCurrencyError: null })
      this.setState({ cashCurrencyError: null })
    }
    if (type === 'cashCurrency') {
      this.setState({ currencyValue: selectedOption })

      this.setState({ baseCurrencyValue: selectedOption })
      this.setState({ variableCurrencyValue: selectedOption })
      this.setState({ bonusCurrencyValue: selectedOption })
      this.setState({ cashCurrencyValue: selectedOption })


      this.setState({ baseCurrencyError: null })
      this.setState({ vairableCurrencyError: null })
      this.setState({ bonusCurrencyError: null })
      this.setState({ cashCurrencyError: null })
    }

    if (type === 'stocks') {
      this.setState({ stocksValue: selectedOption })
      this.setState({ stocksError: null })
    }
    if (type === 'expectedstocks') {
      this.setState({ expectedstocks: selectedOption })
      this.setState({ expectedstocksError: null })
    }
    if (type === 'vestingOptions') {
      this.setState({ vestingValue: selectedOption })
      this.setState({ vestingOptionsError: null })
    }
    if (type === 'liqEvent') {
      this.setState({ liqEventValue: selectedOption })
      this.setState({ liqEventError: null })
    }
    if (type === 'cashCompenstaion') {
      this.setState({ cashCompenstaion: selectedOption })
      this.setState({ cashCompenstaionError: null })
    }
    if (type === "compensationCurrency") {
      this.setState({
        compensationCurrency: selectedOption
      })
    }
  }

  handleBasicDetails = (values: any) => {

    this.setState({
      loading: true
    })

    var formData = new FormData();
    formData.append('profile_picture', this.state.selectedFile);
    formData.append('full_name', values.fullName);
    formData.append('full_phone_number', values.mobile);
    formData.append('alternate_phone_number', values.alternateMobile);
    formData.append('email', values.email);
    formData.append('highest_education', values.highestEducation);
    formData.append('linkedin_profile_link', values.linkdinProfile);
    formData.append('open_to_work_in', this.state.openToWork.value !== undefined ? this.state.openToWork.value : "");
    formData.append('current_location', this.state.currentLocation.value !== undefined ? this.state.currentLocation.value : "");
    formData.append('relocation_status[]', this.state.option1.value !== undefined ? this.state.option1.value : "");
    formData.append('relocation_status[]', this.state.option2.value !== undefined ? this.state.option2.value : "");
    formData.append('relocation_status[]', this.state.option3.value !== undefined ? this.state.option3.value : "");
    formData.append('role', 'candidate');
    formData.append('aadhar_number', values.aadhar);

    const header = {
      'token': window.localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.basicDetailsApiCallId = requestMessage.messageId;

    if (localStorage.getItem('candidateId') === null) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addBasicDetailsApiEndPoint
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateBasicDetailsApiEndPoint}/${localStorage.getItem('candidateId')}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    if (localStorage.getItem('candidateId') === null) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardApiPostMethodType
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardPUTApiMethodType
      );
    }


    runEngine.sendMessage(requestMessage.id, requestMessage);


    return true
    // }
  }

  handleCompetencies = (values: any) => {
    if (this.state.coreCompetenciesError !== null || this.state.technologistTypeError !== null || this.state.codingProficiencyError !== null) {

      if (this.state.coreCompetenciesError !== null) {
        this.setState({ coreCompetenciesError: "Select Core Competencies" })
      }
      if (this.state.technologistTypeError !== null) {
        this.setState({ technologistTypeError: "Select Technologist Type" })
      } if (this.state.codingProficiencyError !== null) {
        this.setState({ codingProficiencyError: "Select Coding Prodiciency" })
      }
    } else {

      var formData = new FormData();

      var candidateId = window.localStorage.getItem('candidateId')
      // @ts-ignore
      formData.append('account_id', candidateId);
      formData.append('coding_proficiency', (this.state.codeValues !== undefined && this.state.codeValues.value !== undefined) ? this.state.codeValues.value : "");
      formData.append('pq_observations', values.pqObservations);
      formData.append('type_of_technologist', this.state.technologistType.value !== undefined ? this.state.technologistType.value : "");
      formData.append('executive_summary', values.executiveSummary);

      this.state?.coreValues?.map((x: any) => {
        return formData.append('core_competency_ids[]', x.value);
      })

      const header = {
        'token': window.localStorage.getItem('authToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.competenciesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addCompetenciesApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true
    }
  }

  handleDomainKnowledge = (values: any) => {

    if (this.state.candidateTypeError !== null || this.state.candidateTypeError !== null || this.state.motivationError !== null || this.state.growthError !== null || this.state.geoGraphicExposureError !== null || this.state.domainExposureError !== null || this.state.empCredError === null) {
      if (this.state.candidateTypeError !== null) {
        this.setState({ candidateTypeError: "Please Select Candidate Type" })
      }
      if (this.state.motivationError !== null) {
        this.setState({ motivationError: "Please Select Motivation" })
      }
      if (this.state.growthError !== null) {
        this.setState({ growthError: "Please Select Growth" })
      }
      if (this.state.geoGraphicExposureError !== null) {
        this.setState({ geoGraphicExposureError: "Please Select Geo Graphic Exposure" })
      }
      if (this.state.domainExposureError !== null) {
        this.setState({ domainExposureError: "Please Select Domain Exposure" })
      }
      if (this.state.empCredError === null) {
        this.setState({ empCredError: "Please Fill Employment Credentials" })
      }
    } else {

      this.setState({
        empCredError: ""
      })

      var formData = new FormData();

      var candidateId = window.localStorage.getItem('candidateId')
      // @ts-ignore
      formData.append('account_id', candidateId);
      // formData.append('geographies_exposure', this.state.geoGraphicExposure.value !== undefined ? this.state.geoGraphicExposure.value : "");
      formData.append('scale_of_growth', this.state.growth.value !== undefined ? this.state.growth.value : "");
      formData.append('tech_built_to_scale', values.techbuilt);
      formData.append('candidate_type', this.state.candidateType.value !== undefined ? this.state.candidateType.value : "");
      formData.append('learnings_from_previous_stints', values.previousStints);
      formData.append('highest_span_of_people_managed', values.spanOfPeople);
      if (this.state.uploadLocationMap !== null) {
        formData.append('location_image', this.state.uploadLocationMap?.file);
      }



      if (this.state.empCredentialsId !== "") {
        this.state.selectedEmploymentCredentials.map((x: any, i: any) => {
          return (
            formData.append(`employment_credentials_attributes[${i}][company_name]`, x.company_name),
            formData.append(`employment_credentials_attributes[${i}][company_description]`, x.company_description),
            formData.append(`employment_credentials_attributes[${i}][location]`, x.location),
            formData.append(`employment_credentials_attributes[${i}][designation]`, x.designation),
            formData.append(`employment_credentials_attributes[${i}][worked_from]`, x.worked_from),
            formData.append(`employment_credentials_attributes[${i}][worked_till]`, x.worked_till),
            formData.append(`employment_credentials_attributes[${i}][technologies_worked_upon]`, x.technologies_worked_upon),
            formData.append(`employment_credentials_attributes[${i}][no_of_people_managed]`, x.no_of_people_managed),
            formData.append(`employment_credentials_attributes[${i}][compensation]`, x.compensation),
            formData.append(`employment_credentials_attributes[${i}][reporting_manager]`, x.reporting_manager),
            formData.append(`employment_credentials_attributes[${i}][reason_of_moving_out]`, x.reason_of_moving_out),
            formData.append(`employment_credentials_attributes[${i}][comment]`, x.comment),
            formData.append(`employment_credentials_attributes[${i}][_destroy]`, "false"),
            x.companyLogo?.file !== undefined &&
            formData.append(`employment_credentials_attributes[${i}][logo]`, x.companyLogo?.file)
            ,
            formData.append(`employment_credentials_attributes[${i}][key_achievement]`, x.key_achievement),
            formData.append(`employment_credentials_attributes[${i}][currency_id]`, x.compensationCurrency.value),
            formData.append(`employment_credentials_attributes[${i}][id]`, this.state.empCredentialsId)
          )
        })
      } else {
        this.state.employmentCredentials.map((x: any, i: any) => {
          return (
            formData.append(`employment_credentials_attributes[${i}][company_name]`, x.company_name),
            formData.append(`employment_credentials_attributes[${i}][company_description]`, x.company_description),
            formData.append(`employment_credentials_attributes[${i}][location]`, x.location),
            formData.append(`employment_credentials_attributes[${i}][designation]`, x.designation),
            formData.append(`employment_credentials_attributes[${i}][worked_from]`, x.worked_from),
            formData.append(`employment_credentials_attributes[${i}][worked_till]`, x.worked_till),
            formData.append(`employment_credentials_attributes[${i}][technologies_worked_upon]`, x.technologies_worked_upon),
            formData.append(`employment_credentials_attributes[${i}][no_of_people_managed]`, x.no_of_people_managed),
            formData.append(`employment_credentials_attributes[${i}][compensation]`, x.compensation),
            formData.append(`employment_credentials_attributes[${i}][reporting_manager]`, x.reporting_manager),
            formData.append(`employment_credentials_attributes[${i}][reason_of_moving_out]`, x.reason_of_moving_out),
            formData.append(`employment_credentials_attributes[${i}][comment]`, x.comment),
            formData.append(`employment_credentials_attributes[${i}][_destroy]`, "false"),
            formData.append(`employment_credentials_attributes[${i}][logo]`, x.companyLogo?.file),
            formData.append(`employment_credentials_attributes[${i}][key_achievement]`, x.key_achievement),
            formData.append(`employment_credentials_attributes[${i}][currency_id]`, x.compensationCurrency.value)
          )
        })
      }

      this.state?.geoGraphicExposure?.map((x: any) => {
        return formData.append('geographies_exposure[]', x.value);
      })

      this.state?.domainExposure?.map((x: any) => {
        return formData.append('domain_exposure_ids[]', x.value);
      })

      this.state?.motivation?.map((x: any) => {
        return formData.append('motivation[]', x.value);
      })

      const header = {
        'token': window.localStorage.getItem('authToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.domainKnowledgeApiCallid = requestMessage.messageId;

      if (window.localStorage.getItem('step1') === 'one') {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.addDomainKnowledgeApiEndPoint
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateDomainKnowledgeApiEndPoint
        );
      }



      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

      if (window.localStorage.getItem('step1') === 'one') {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboardApiPostMethodType
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboardPUTApiMethodType
        );
      }



      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true
    }
  }

  handleFamily = (values: any) => {
    if (this.state.familyNumbersError !== null || this.state.maritialStatusError !== null) {
      if (this.state.familyNumbersError !== null) {
        this.setState({ familyNumbersError: "Please Select No of family memebers" })
      }
      if (this.state.maritialStatusError !== null) {
        this.setState({ maritialStatusError: "Please Select Maritial Status" })
      }
    } else {
      var formData = new FormData();

      var candidateId = window.localStorage.getItem('candidateId')
      // @ts-ignore
      formData.append('account_id', candidateId);
      formData.append('total_member', this.state.familyNumbers.value !== undefined ? this.state.familyNumbers.value : "");
      formData.append('marital_status', this.state.maritialStatus.value !== undefined ? this.state.maritialStatus.value : "");
      formData.append('spouse_name', this.state.maritialStatus.value !== 'single' ? values.spouse : "");
      formData.append('other_details', values.other);
      formData.append('date_of_birth', values.dob);

      const header = {
        'token': window.localStorage.getItem('authToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.familyDetailsApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.familyDetailsApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true
    }
  }

  handleCompansation = (values: any) => {

    if (this.state.baseCurrencyError !== null || this.state.vairableCurrencyError !== null || this.state.stocksError !== null || this.state.vestingOptionsError !== null || this.state.liqEventError !== null) {
      if (this.state.baseCurrencyError !== null) {
        this.setState({ baseCurrencyError: "Please Select Base Currency" })
      }
      if (this.state.vairableCurrencyError !== null) {
        this.setState({ vairableCurrencyError: "Please Select Variable Currency" })
      }
      if (this.state.bonusCurrencyError !== null) {
        this.setState({ bonusCurrencyError: "Please Select Bonus Currency" })
      }
      if (this.state.cashCurrencyError !== null) {
        this.setState({ cashCurrencyError: "Please Select Cash Currency" })
      }
      if (this.state.stocksError !== null) {
        this.setState({ stocksError: "Please Select Stock" })
      } if (this.state.vestingOptionsError !== null) {
        this.setState({ vestingOptionsError: "Please Select Vesting Options" })
      } if (this.state.liqEventError !== null) {
        this.setState({ liqEventError: "Please Select LiqEvent" })
      }
    } else {

      var formData = new FormData();

      var candidateId = window.localStorage.getItem('candidateId')
      // @ts-ignore
      formData.append('account_id', candidateId);
      formData.append('base', `${values.base}`);
      formData.append('variable', `${values.variable}`);
      formData.append('bonus', `${values.bonus}`);
      formData.append('total_cash_compensation', `${values.totalCashCompensation}`);
      formData.append('stocks', this.state.stocksValue.value !== undefined ? this.state.stocksValue.value : "");
      formData.append('stocks_value', values.selectedStockValue);
      formData.append('vested', values.vested);
      formData.append('unvested', values.unvested);
      formData.append('vesting_schedule', this.state.vestingValue.value !== undefined ? this.state.vestingValue.value : "");
      formData.append('exercise_price', values.exercisePrice);
      formData.append('liquidation_event_at_current_org', this.state.liqEventValue.value !== undefined ? this.state.liqEventValue.value : "");
      formData.append('additional_comments', values.additionalComments);
      formData.append('currency_id', this.state.currencyValue.value !== undefined ? this.state.currencyValue.value : "")

      const header = {
        'token': window.localStorage.getItem('authToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.compenstaionApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.compensationApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true
    }
  }

  handleExpectedCompensation = (values: any) => {

    if (this.state.baseCurrencyError !== null || this.state.vairableCurrencyError !== null || this.state.stocksError !== null || this.state.cashCompenstaionError !== null || this.state.bonusCurrencyError !== null) {
      if (this.state.baseCurrencyError !== null) {
        this.setState({ baseCurrencyError: "Please Select Base Currency" })
      }
      if (this.state.bonusCurrencyError !== null) {
        this.setState({ bonusCurrencyError: "Please Select Bonus Currency" })
      }
      if (this.state.vairableCurrencyError !== null) {
        this.setState({ vairableCurrencyError: "Please Select Variable Currency" })
      }
      if (this.state.stocksError !== null) {
        this.setState({ stocksError: "Please Select Stock" })
      }
      if (this.state.cashCompenstaionError !== null) {
        this.setState({ cashCompenstaionError: "Please Select Cash Compenstaion" })
      }

    } else {

      var formData = new FormData();

      var candidateId = window.localStorage.getItem('candidateId')
      // @ts-ignore
      formData.append('account_id', candidateId);
      formData.append('base', `${values.base}`);
      formData.append('variable', `${values.variable}`);
      formData.append('bonus', `${values.bonus}`);
      formData.append('total_compensation', `${values.totalCashCompensation}`);
      formData.append('stocks', this.state.expectedstocks.value !== undefined ? this.state.expectedstocks.value : "");
      formData.append('stocks_value', values.selectedStockValue);
      formData.append('hike_expected', values.hike);
      formData.append('equity', values.equity);
      formData.append('currency_id', this.state.currencyValue.value !== undefined ? this.state.currencyValue.value : "")

      const header = {
        'token': window.localStorage.getItem('authToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.expectedCompenstaionApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.expectedCompensationApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboardApiPostMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true
    }
  }

  handleEmploymentCredentials = (values: any, type: any, isNew: any) => {
    if (!isNew) {
      if (type === 'update') {

        var users = this.state.empCredentials

        var data = {
          "id": values.id,
          "type": "employement_credential",
          "attributes": values
        }

        users = this.state.empCredentials.map((u: any) => u.id !== values.id ? u : data);

        console.log(users)

        this.setState(prevState => ({
          selectedEmploymentCredentials: [...prevState.selectedEmploymentCredentials, values],
          empCredentials: users
        }))
        this.setState({
          empCredError: ""
        })
        this.closeDomainModal()

      } else {
        this.setState(prevState => ({
          employmentCredentials: [...prevState.employmentCredentials, values]
        }))

        this.setState({
          currentEmploymentCredentials: values
        })
        this.setState({
          empCredError: ""
        })
      }
    } else {
      this.setState(prevState => ({
        employmentCredentials: [...prevState.employmentCredentials, values]
      }))

      this.setState({
        currentEmploymentCredentials: values
      })
      this.setState({
        empCredError: ""
      })
    }
    this.closeDomainModal()
  }

  openDomainModal = () => {
    this.setState({
      domainModal: true
    })
  }

  closeDomainModal = () => {
    this.setState({
      domainModal: false
    })
  }

  //getCurrentUser
  getCurrentUser = (): boolean => {
    this.setState({
      loaderStatus: true
    })
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCurrency
  getCurrency = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currencyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currencyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get Core Competenices
  getCoreCompetencies = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.comptenicesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.competenicesGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //get Domain exposure Competenices
  getDomainExposure = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetDomainExposureApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.domainExposureGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // get Candidates
  getCandidates = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };
    this.setState({
      candidateLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidatesGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // get Current Candidate
  getCurrentCandidate = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const candidateId = window.localStorage.getItem('candidateId')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currentCandidateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidatesGetUrl + `/${candidateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Get Resume Data
  getResumeDocument = () => {
    const headers = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getResumeDetailsApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_candidate/candidates/get_resume_data?account_id=' + document.URL.split("=")[1]
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //Candidate comments at profile
  getCandidateProfileComments = () => {
    const headers = {
      token: localStorage.getItem("authToken")
    };

    let userId = new URLSearchParams(window.location.search).get('id');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateProfileComments = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_candidate/candidate_comments/?candidate_id=' + userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  saveProfileComment = () => {
    let userId = new URLSearchParams(window.location.search).get('id');

    var formData = new FormData();
    formData.append('comment', this.state.enteredCommentDescription);
    // @ts-ignore
    formData.append('comment_to_id', `${userId}`);
    formData.append('comment_by_id', `${this.state.currentUserId}`);

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.POSTprofileCommentAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_candidate/candidate_comments'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true

  }
  // Customizable Area End

}
