Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboardContentType = "application/json";
exports.dashboardApiMethodType = "GET";
exports.dashboardPUTApiMethodType = "PUT";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboardUserAgent = "PostmanRuntime/7.26.5";
exports.dashboardAccept = "*/*";
exports.dashboardAcceptEncoding = "gzip, deflate, br";
exports.dashboardConnection = "keep-alive";
exports.dashboardtoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.candidatesGetUrl = "bx_block_candidate/candidates"
exports.currentUserAPI = "bx_block_candidate/candidates/show_current_user"
exports.competenicesGetUrl = "bx_block_candidate/candidates/get_core_competencies"
exports.domainExposureGetUrl = "bx_block_candidate/candidates/get_domain_exposure"
exports.dashboardApiPostMethodType = "POST";
exports.addBasicDetailsApiEndPoint = "bx_block_candidate/candidates"
exports.addCompetenciesApiEndPoint = "bx_block_candidate/candidates/save_competency"
exports.addDomainKnowledgeApiEndPoint = "bx_block_candidate/candidates/save_domain_knowledge"
exports.updateDomainKnowledgeApiEndPoint = "bx_block_candidate/candidates/update_domain_knowledge"
exports.familyDetailsApiEndPoint = "bx_block_candidate/candidates/save_family_detail"
exports.compensationApiEndPoint = "bx_block_candidate/candidates/save_compensation"
exports.expectedCompensationApiEndPoint = "bx_block_candidate/candidates/save_expected_compensation"
exports.currencyApiEndPoint = "bx_block_candidate/candidates/get_currency"
exports.updateBasicDetailsApiEndPoint = "bx_block_candidate/candidates"
exports.candidateDocumentApiEndPoint = "bx_block_candidate/candidate_documents"
exports.dashboardDeleteApiMethodType = "DELETE"
exports.candidateComment = "bx_block_candidate/profile_comment"
// Customizable Area End