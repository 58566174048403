import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  candidateLoader: any
  selectedDashboardFilter: string
  currentUser: any
  JobData: any
  isShowJobModal: boolean
  isShowMyTaskModal: boolean
  isShowReminderModal: boolean
  taskComment: any
  taskSubject: string
  taskDate: any
  taskAssign: any
  reminderSubject: any
  reminderDate: any
  reminderTime: any
  reminderComment: any
  candidatesData: any
  tasksData: any,
  jobStatusType: any
  registerJobCompany: any
  registerJobTitle: any
  registerJobLocation: any
  registerJobOpening: any
  registerJobStartDate: any
  registerJobExclusivityPeriod: any
  registerJobExclusivityEndDate: any
  registerJobDescription: any
  registerJobWebsite: any
  registerJobDecisionMaker: any,
  registerJobDesigerMakerEmail: any,
  registerJobSOPName: any,
  registerJobSOPEmail: any,
  allRemindersList: any,
  allTasksList: any,
  currentUserId: number
  createTaskError: boolean,
  reminderError: boolean,
  jobLoader: boolean,
  elements: any,
  addJobClick: any,
  candidateStatuTypes: any,
  isCandidateSection: any,
  presentKanbanBoardData: any,
  registerJobTitle1: any,
  registerJobLocation1: any,
  registerJobTitle2: any,
  registerJobLocation2: any,
  registerJobTitle3: any,
  registerJobLocation3: any,
  registerJobTitle4: any,
  registerJobLocation4: any,
  isCandidateModal: boolean
  companyName: any,
  allCandidateList: any,
  jobAddError: any,
  selectedArrayIds: any,
  noCandidateSelectedError: any,
  presentJobIdSelected: any,
  searchText: any,
  registerJobInterviewRounds: any
  registerJobInterviewRounds1: any,
  registerJobInterviewRounds2: any,
  registerJobInterviewRounds3: any,
  registerJobInterviewRounds4: any,
  viewTaskModal: any,
  viewReminderModal: any
  assigneeName: any
  reminderId: any
  taskCardId: any
  profileId: any
  listOfCandidatesOfSelectedJob: any
  isJobSubModalOpenId: any
  viewJobModal: any
  changeStatusModal: any,
  statusId: any
  candidateStatusType: any
  candidateId: any
  currentJobStatus: any
  jobOpeningId: any
  isshowCandidateComment: boolean
  candiateCommentsList: any
  companiesData: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class KanbanBoardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCurrentUserApiCallId: string = "";
  GetJobsApiCallId: string = '';
  GetAddTaskApiCallId: string = "";
  GetAllTasksApiCallId: string = "";
  SaveReminderApiCallId: string = "";
  GetCandidatesApiCallId: string = "";
  GetJobStatusApi: string = "";
  UpdateJobStatusApi: string = "";
  GetAllRemindersListApi: string = "";
  GetAllTasksListApi: string = "";
  GetCandidateStatusAPICall: string = "";
  GetAddCandidateToJobsAPICall: string = "";
  SaveNewJobApiCallId: string = ""
  GetUpdateReminderApiCallId: string = ""
  GetMarkAsTaskComplete: string = ""
  GetUpdateTaskApiCallId: string = "";
  GetCandidatesOfSelectedJob: String = "";
  GetCurrentJobApiCallId: string = "";
  GetCandidateJobStatus: string = "";
  updateCandidateStatusApiCallId: string = "";
  updateJobApiCallId: string = "";
  GetCandidateComments: String = "";
  GetCompaniesApiCallId: String = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      candidateLoader: false,
      currentUser: "",
      selectedDashboardFilter: 'All Jobs',
      JobData: [],
      isShowJobModal: false,
      isShowMyTaskModal: false,
      isShowReminderModal: false,
      taskAssign: '',
      taskComment: '',
      taskDate: '',
      taskSubject: '',
      reminderComment: '',
      reminderDate: '',
      reminderSubject: '',
      reminderTime: '',
      candidatesData: [],
      tasksData: [],
      jobStatusType: '',
      registerJobCompany: '',
      registerJobTitle: '',
      registerJobLocation: '',
      registerJobTitle1: '',
      registerJobLocation1: '',
      registerJobTitle2: '',
      registerJobLocation2: '',
      registerJobTitle3: '',
      registerJobLocation3: '',
      registerJobTitle4: '',
      registerJobLocation4: '',
      registerJobOpening: 0,
      registerJobStartDate: '',
      registerJobExclusivityPeriod: 0,
      registerJobExclusivityEndDate: '',
      registerJobDescription: '',
      registerJobWebsite: '',
      registerJobDecisionMaker: '',
      registerJobDesigerMakerEmail: '',
      registerJobSOPName: '',
      registerJobSOPEmail: '',
      allRemindersList: [],
      allTasksList: [],
      currentUserId: 0,
      createTaskError: false,
      reminderError: false,
      jobLoader: false,
      elements: [],
      addJobClick: 0,
      candidateStatuTypes: [],
      isCandidateSection: false,
      presentKanbanBoardData: [],
      isCandidateModal: false,
      companyName: "",
      allCandidateList: [],
      jobAddError: false,
      selectedArrayIds: [],
      noCandidateSelectedError: false,
      presentJobIdSelected: '',
      searchText: "",
      registerJobInterviewRounds: '',
      registerJobInterviewRounds1: '',
      registerJobInterviewRounds2: '',
      registerJobInterviewRounds3: '',
      registerJobInterviewRounds4: '',
      viewTaskModal: false,
      viewReminderModal: false,
      assigneeName: '',
      reminderId: "",
      taskCardId: "",
      profileId: "",
      listOfCandidatesOfSelectedJob: [],
      isJobSubModalOpenId: false,
      viewJobModal: false,
      changeStatusModal: false,
      statusId: "",
      candidateStatusType: [],
      candidateId: "",
      currentJobStatus: "",
      jobOpeningId: "",
      isshowCandidateComment: false,
      candiateCommentsList: [],
      companiesData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );



      if (apiRequestCallId != null) {

        //get companies 
        if (apiRequestCallId === this.GetCompaniesApiCallId) {
          this.setState({
            companiesData: responseJson?.companies?.map((x: any) => {
              return {
                value: x.id, label: x.name
              }
            })
          })
        }

        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            currentUser: responseJson?.data?.attributes,
            currentUserId: responseJson?.data?.id
          })
        }

        // get jobsdata 
        if (apiRequestCallId === this.GetJobsApiCallId) {
          this.setState({
            JobData: responseJson?.data,
            jobLoader: false,
            presentKanbanBoardData: responseJson?.data
          })
        }

        // get current jobsdata 
        if (apiRequestCallId === this.GetCurrentJobApiCallId) {
          this.setState({
            registerJobCompany: responseJson?.data?.attributes?.company_name,
            registerJobOpening: responseJson?.data?.attributes?.company_name,
            registerJobTitle: responseJson?.data?.attributes?.title,
            registerJobLocation: responseJson?.data?.attributes?.location,
            registerJobInterviewRounds: responseJson?.data?.attributes?.job_opening?.data?.attributes?.no_of_openings,
            registerJobDesigerMakerEmail: responseJson?.data?.attributes?.job_opening?.data?.attributes?.decision_maker_email,
            registerJobSOPName: responseJson?.data?.attributes?.job_opening?.data?.attributes?.spoc_name,
            registerJobSOPEmail: responseJson?.data?.attributes?.job_opening?.data?.attributes?.spoc_email,
            registerJobDescription: responseJson?.data?.attributes?.job_opening?.data?.attributes?.description,
            registerJobDecisionMaker: responseJson?.data?.attributes?.job_opening?.data?.attributes?.decision_maker_name,
            registerJobWebsite: responseJson?.data?.attributes?.job_opening?.data?.attributes?.website,
            registerJobExclusivityPeriod: responseJson?.data?.attributes?.job_opening?.data?.attributes?.exclusivity_period,
            registerJobStartDate: responseJson?.data?.attributes?.job_opening?.data?.attributes?.exclusivity_start_date,
            registerJobExclusivityEndDate: responseJson?.data?.attributes?.job_opening?.data?.attributes?.exclusivity_end_date,
            currentJobStatus: responseJson?.data?.attributes?.status,
            jobOpeningId: responseJson?.data?.attributes?.job_opening?.data?.id
          })
        }

        // get alltasks 
        if (apiRequestCallId === this.GetAllTasksApiCallId) {
          this.setState({
            tasksData: responseJson?.data?.filter((ele: any) => ele.attributes.my_task)
          })
        }

        //get status Type
        if (apiRequestCallId === this.GetJobStatusApi) {
          this.setState({
            jobStatusType: responseJson?.job_statuses
          })
        }
        //get status Type
        if (apiRequestCallId === this.GetCandidateJobStatus) {
          this.setState({
            candidateStatusType: responseJson?.candidate_job_statuses
          })
        }

        //Update job status API
        if (apiRequestCallId == this.UpdateJobStatusApi) {
          // window.location.reload();
          this.state.isCandidateSection ? this.getCandidatesOfSelectedJob(this.state.presentJobIdSelected) : this.getJobsData(true);
        }

        //Task created modal
        if (apiRequestCallId === this.GetAddTaskApiCallId) {
          this.setState({
            isShowMyTaskModal: false,
            taskSubject: "",
            taskAssign: "",
            taskDate: "",
            taskComment: ""
          });
          // window.location.reload();
          this.getAllTasks()
        }

        //Reminder Created modal
        if (apiRequestCallId === this.SaveReminderApiCallId) {
          console.log("Reminder Created");
          this.setState({
            isShowReminderModal: false,
            reminderSubject: "",
            reminderDate: "",
            reminderTime: "",
            reminderComment: ""
          })

          this.allRemindersList()
        }

        if (apiRequestCallId === this.SaveNewJobApiCallId) {
          this.setState({ isShowJobModal: false, registerJobSOPEmail: '', registerJobCompany: '', registerJobTitle: '', registerJobSOPName: '', registerJobOpening: 0, registerJobLocation: '', registerJobExclusivityEndDate: '', addJobClick: 0, elements: [] });
          this.getJobsData();
        }

        // get candidatesData 
        if (apiRequestCallId === this.GetCandidatesApiCallId) {
          let optionList = [];
          let firstEle: any = { value: this.state.currentUserId, label: 'Myself' }

          optionList = responseJson?.data?.map((x: any) => {
            return { value: x?.id, label: x?.attributes?.full_name }
          });
          optionList = [firstEle].concat(optionList)

          this.setState({
            candidatesData: optionList,
            allCandidateList: responseJson?.data
          })
        }

        //get all Reminders list
        if (apiRequestCallId === this.GetAllRemindersListApi) {
          this.setState({ allRemindersList: responseJson?.data })
        }

        //get candidate status type
        if (apiRequestCallId === this.GetCandidateStatusAPICall) {
          this.setState({ candidateStatuTypes: responseJson?.candidate_job_statuses })
        }

        //Candidates are added to the the jobs
        if (apiRequestCallId === this.GetAddCandidateToJobsAPICall) {
          this.setState({ isCandidateModal: false, selectedArrayIds: [], searchText: "" });
          this.getCandidatesOfSelectedJob(this.state.presentJobIdSelected)
        }
        //updated Created modal
        if (apiRequestCallId === this.GetUpdateReminderApiCallId) {
          console.log("Reminder Updated");
          this.setState({
            viewReminderModal: false,
            reminderSubject: "",
            reminderDate: "",
            reminderTime: "",
            reminderComment: ""
          });
          // window.location.reload();
          this.allRemindersList()
        }
        //Mark Task As complete
        if (apiRequestCallId === this.GetMarkAsTaskComplete) {
          this.setState({
            viewTaskModal: false,
            taskSubject: "",
            taskAssign: "",
            taskDate: "",
            taskComment: ""
          })
          this.getAllTasks()
        }
        //Task created modal
        if (apiRequestCallId === this.GetUpdateTaskApiCallId) {
          this.setState({
            viewTaskModal: false,
            taskSubject: "",
            taskAssign: "",
            taskDate: "",
            taskComment: ""
          });
          this.getAllTasks()
        }

        if (apiRequestCallId === this.updateCandidateStatusApiCallId) {
          this.setState({
            changeStatusModal: false,
            statusId: ''
          });
          this.getCandidatesOfSelectedJob(this.state.presentJobIdSelected);
        }

        if (apiRequestCallId === this.updateJobApiCallId) {
          this.setState({
            viewJobModal: false
          });
          this.getJobsData()
        }

        //get Candidates of selected job
        if (apiRequestCallId === this.GetCandidatesOfSelectedJob) {
          if (responseJson?.data) {
            this.setState({ listOfCandidatesOfSelectedJob: responseJson?.data, presentKanbanBoardData: responseJson.data });
          }
        }

        //Candidate comments
        if (apiRequestCallId === this.GetCandidateComments) {
          if (responseJson.error) {
            alert('Something went wrong');
          } else {
            this.setState({ isshowCandidateComment: true, candiateCommentsList: responseJson.data })
          }
        }

      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getCurrentUser()
    await this.getJobsData()
    await this.getAllTasks()
    await this.getCandidates()
    await this.getDashboardJobStatus()
    await this.allRemindersList()
    await this.getCandidateStatus()
    await this.getCompanies();
    await this.getCandidateJobStatus()

    document.addEventListener("mousedown", this.handleClickOutside)
  }

  // @ts-ignore
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e?: any) => {
    // @ts-ignore
    if (document.getElementById("drop-downDashboard") && !document.getElementById("drop-downDashboard").contains(e.target)) {
      this.setState({ isJobSubModalOpenId: null });
    }
  };


  newJob = (e: any) => {
    const target: any = e.target;
    const value: any = target.value;
    const name: any = target.name;
    //@ts-ignore
    this.setState({ [name]: value, jobAddError: false });

    if (name === 'registerJobOpening' && this.state.registerJobOpening != value) {
      this.setState({ elements: [], addJobClick: 0 })
    }
  }

  handleSelect = (selectedOption: any, type: any) => {
    if (type === 'taskAssign') {
      this.setState({ taskAssign: selectedOption.value })
    }
    if (type === 'registerJobCompany') {
      this.setState({
        registerJobCompany: selectedOption.value
      })
    }
  }

  setCompanyView = (data: any) => {
    let id = this.state.companiesData.filter((x: any) => { return x.label === data.attributes.company_name }).map((y: any) => { return y.value })
    window.location.replace(`/companies?id=${id}`)

  }

  //getCurrentUser
  getCurrentUser = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    this.setState({
      candidateLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCompanies
  getCompanies = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    this.setState({
      candidateLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCompaniesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompanies
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  // get Candidates
  getCandidates = (): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };
    this.setState({
      candidateLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidatesGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // get JobsData
  getJobsData = (isJobUpdateOnly: any = false) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };
    !isJobUpdateOnly && this.setState({
      jobLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetJobsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCurrentJobdata
  getCurrentJobDetail = (id: any): boolean => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };
    this.setState({
      jobLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentJobApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobGetUrl}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Add task 
  handleAddTask = () => {
    let task = {
      task: {
        "subject": this.state.taskSubject,
        "task_date": this.state.taskDate,
        "assignee_id": this.state.currentUserId,
        "assign_to_id": this.state.taskAssign,
        "task_comment": this.state.taskComment,
        "my_task": this.state.currentUserId === this.state.taskAssign
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAddTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTaskCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(task));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //Update task 
  handleUpdateTask = () => {
    let task = {
      task: {
        "subject": this.state.taskSubject,
        "task_date": this.state.taskDate,
        "assignee_id": this.state.currentUserId,
        "assign_to_id": this.state.taskAssign,
        "task_comment": this.state.taskComment,
        "my_task": this.state.currentUserId == this.state.taskAssign
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetUpdateTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markTaskAsComplete + this.state.taskCardId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(task));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //Add Reminder 
  handleAddReminder = () => {

    let reminder = {
      reminder: {
        "subject": this.state.reminderSubject,
        "reminder_date": this.state.reminderDate,
        "reminder_time": this.state.reminderTime,
        "reminder_comment": this.state.reminderComment
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SaveReminderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReminder
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reminder));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //update Reminder 
  handleUpdateReminder = () => {

    let reminder = {
      reminder: {
        "subject": this.state.reminderSubject,
        "reminder_date": this.state.reminderDate,
        "reminder_time": this.state.reminderTime,
        "reminder_comment": this.state.reminderComment
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetUpdateReminderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addReminder}/${this.state.reminderId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reminder));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //markAsComplete
  markAsCompleteTask = (taskCardId: number) => {
    {
      let task = {
        "task": {
          "status": "complete"
        }
      }
      const headers = {
        'token': window.localStorage.getItem('authToken'),
        "Content-Type": configJSON.validationApiContentType
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.GetMarkAsTaskComplete = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markTaskAsComplete + taskCardId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(task)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    };
  }


  // get all Tasks
  getAllTasks = () => {
    const headers = {
      token: localStorage.getItem("authToken")
    };

    const formData = new FormData();

    formData.append('page', '1')
    formData.append('per_page', '3')
    formData.append('task_category', 'my tasks')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTasks
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //update on Drag the card
  updateCardOnDrag = (cardId: number, laneId: number, job_id?: number) => {
    let jobStatus = {
      job_status_id: laneId
    }

    let candidateStatus = {
      candidate_job_status_id: laneId,
      candidate_id: cardId
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateJobStatusApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !this.state.isCandidateSection ? `${configJSON.updateJobStatus}/${cardId}` : `${configJSON.updateCandidateDragStatus}${job_id}/update_candidate_job_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(this.state.isCandidateSection ? candidateStatus : jobStatus));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //get Dashboard job Status
  getDashboardJobStatus = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetJobStatusApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllJobStatus
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //get candidate job Status
  getCandidateJobStatus = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateJobStatus = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateJobStatus
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //get Reminder list
  allRemindersList = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllRemindersListApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRemindersUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //add New Job 
  saveNewJob = () => {

    let formData = new FormData()

    //@ts-ignore
    formData.append("company_id", this.state.registerJobCompany)
    //@ts-ignore
    formData.append("no_of_openings", this.state.registerJobOpening)
    //@ts-ignore
    formData.append("exclusivity_start_date", this.state.registerJobStartDate)
    //@ts-ignore
    formData.append("exclusivity_period", this.state.registerJobExclusivityPeriod)
    //@ts-ignore
    formData.append("exclusivity_end_date", this.state.registerJobExclusivityEndDate)
    //@ts-ignore
    formData.append("description", this.state.registerJobDescription)
    //@ts-ignore
    formData.append("website", this.state.registerJobWebsite)
    //@ts-ignore
    formData.append("decision_maker_name", this.state.registerJobDecisionMaker)
    //@ts-ignore
    formData.append("decision_maker_email", this.state.registerJobDesigerMakerEmail)
    //@ts-ignore
    formData.append("spoc_name", this.state.registerJobSOPName)
    //@ts-ignore
    formData.append("spoc_email", this.state.registerJobSOPEmail)
    //@ts-ignore
    formData.append("jobs_attributes[0][title]", this.state.registerJobTitle)
    //@ts-ignore
    formData.append("jobs_attributes[0][location]", this.state.registerJobLocation)
    //@ts-ignore
    formData.append("jobs_attributes[0][interview_round]", this.state.registerJobInterviewRounds)
    //@ts-ignore
    formData.append("jobs_attributes[0][job_creater_id]", this.state.currentUserId)
    //@ts-ignore
    formData.append("jobs_attributes[0][job_status_id]", 1)
    if (this.state.registerJobTitle1 !== "" || this.state.registerJobLocation1) {
      //@ts-ignore
      formData.append("jobs_attributes[1][title]", this.state.registerJobTitle1)
      //@ts-ignore
      formData.append("jobs_attributes[1][location]", this.state.registerJobLocation1)
      //@ts-ignore
      formData.append("jobs_attributes[1][interview_round]", this.state.registerJobInterviewRounds1)
      //@ts-ignore
      formData.append("jobs_attributes[1][job_creater_id]", this.state.currentUserId)
      //@ts-ignore
      formData.append("jobs_attributes[1][job_status_id]", 1)
    }
    if (this.state.registerJobTitle2 !== "" || this.state.registerJobLocation2) {
      //@ts-ignore
      formData.append("jobs_attributes[2][title]", this.state.registerJobTitle2)
      //@ts-ignore
      formData.append("jobs_attributes[2][location]", this.state.registerJobLocation2)
      //@ts-ignore
      formData.append("jobs_attributes[2][interview_round]", this.state.registerJobInterviewRounds2)
      //@ts-ignore
      formData.append("jobs_attributes[2][job_creater_id]", this.state.currentUserId)
      //@ts-ignore
      formData.append("jobs_attributes[2][job_status_id]", 1)
    }
    if (this.state.registerJobTitle3 !== "" || this.state.registerJobLocation3) {
      //@ts-ignore
      formData.append("jobs_attributes[3][title]", this.state.registerJobTitle3)
      //@ts-ignore
      formData.append("jobs_attributes[3][location]", this.state.registerJobLocation3)
      //@ts-ignore
      formData.append("jobs_attributes[3][interview_round]", this.state.registerJobInterviewRounds3)
      //@ts-ignore
      formData.append("jobs_attributes[3][job_creater_id]", this.state.currentUserId)
      //@ts-ignore
      formData.append("jobs_attributes[3][job_status_id]", 1)
    }
    if (this.state.registerJobTitle4 !== "" || this.state.registerJobLocation4) {
      //@ts-ignore
      formData.append("jobs_attributes[4][title]", this.state.registerJobTitle4)
      //@ts-ignore
      formData.append("jobs_attributes[4][location]", this.state.registerJobLocation4)
      //@ts-ignore
      formData.append("jobs_attributes[4][interview_round]", this.state.registerJobInterviewRounds4)
      //@ts-ignore
      formData.append("jobs_attributes[4][job_creater_id]", this.state.currentUserId)
      //@ts-ignore
      formData.append("jobs_attributes[4][job_status_id]", 1)
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      // "Content-Type": configJSON.exampleAPiMethod
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SaveNewJobApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewJobAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //get candidates status
  getCandidateStatus = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateStatusAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCandidateStatus
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //handle Add Candidate To jobs
  handleAddCandidateTojob = () => {

    var id = this.state.candidateStatusType?.filter((x: any) => {
      return x.name.toLowerCase() === 'new'
    })

    let formData = new FormData();

    if (this.state.selectedArrayIds.length) {
      this.state.selectedArrayIds.map((ele: any) => {
        formData.append("candidates_ids[]", ele)
      })
      formData.append('candidate_job_status_id', id[0]?.id)
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAddCandidateToJobsAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allocateCandiate + this.state.presentJobIdSelected + '/allocate_candidates'}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  //get candidates of selected job
  getCandidatesOfSelectedJob = (jobId: any) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidatesOfSelectedJob = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCandidateDragStatus + jobId + '/get_all_candidates'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //update Reminder 
  updateCandidateStatusApi = () => {


    let formData = new FormData()

    formData.append('candidate_job_status_id', this.state.statusId)
    formData.append('candidate_id', this.state.candidateId)

    const header = {
      'token': window.localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCandidateStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCandidateJobStatus}/${this.state.presentJobIdSelected}/update_candidate_job_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //update Reminder 
  updateSelectedJob = () => {

    let jobStatus = this.state.jobStatusType.filter((x: any) => x.name === this.state.currentJobStatus).map((x: any) => {
      return x.id
    })

    let id = this.state.companiesData.filter((x: any) => { return x.label === this.state.registerJobCompany }).map((y: any) => { return y.value })


    let formData = new FormData()
    //@ts-ignore
    formData.append("company_id", id[0] || this.state.registerJobCompany)
    //@ts-ignore
    formData.append("no_of_openings", this.state.registerJobOpening)
    //@ts-ignore
    formData.append("exclusivity_start_date", this.state.registerJobStartDate)
    //@ts-ignore
    formData.append("exclusivity_period", this.state.registerJobExclusivityPeriod)
    //@ts-ignore
    formData.append("exclusivity_end_date", this.state.registerJobExclusivityEndDate)
    //@ts-ignore
    formData.append("description", this.state.registerJobDescription)
    //@ts-ignore
    formData.append("website", this.state.registerJobWebsite)
    //@ts-ignore
    formData.append("decision_maker_name", this.state.registerJobDecisionMaker)
    //@ts-ignore
    formData.append("decision_maker_email", this.state.registerJobDesigerMakerEmail)
    //@ts-ignore
    formData.append("spoc_name", this.state.registerJobSOPName)
    //@ts-ignore
    formData.append("spoc_email", this.state.registerJobSOPEmail)
    //@ts-ignore
    formData.append("jobs_attributes[0][title]", this.state.registerJobTitle)
    //@ts-ignore
    formData.append("jobs_attributes[0][location]", this.state.registerJobLocation)
    //@ts-ignore
    formData.append("jobs_attributes[0][interview_round]", this.state.registerJobInterviewRounds)
    //@ts-ignore
    formData.append("jobs_attributes[0][job_creater_id]", this.state.currentUserId)
    //@ts-ignore
    formData.append("jobs_attributes[0][job_status_id]", jobStatus[0])
    //@ts-ignore
    formData.append("jobs_attributes[0][id]", this.state.presentJobIdSelected)
    //@ts-ignore
    formData.append("job_opening_id", this.state.jobOpeningId)



    const header = {
      'token': window.localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateJobApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCandidateJobStatus}/${this.state.presentJobIdSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  getCandidateComments = (candidateId: number) => {
    const headers = {
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateComments = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_candidate/candidate_comments/?candidate_id=' + candidateId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Customizable Area End

}
