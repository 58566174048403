import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  allTaskList: any;
  presentTaskList: any
  selectedTaskSection: any
  candidateLoader: any
  currentUser: any
  isShowMyTaskModal: boolean
  taskAssign: any,
  taskComment: any,
  taskDate: any,
  taskSubject: any,
  currentUserId: any,
  candidatesData: any,
  createTaskError: any,
  showTaskDropDown: any,
  currentOpenTaskModal: any,
  allRemindersList: any,
  reminderSubject: any,
  reminderDate: any,
  reminderTime: any,
  reminderComment: any,
  isShowReminderModal: boolean,
  reminderError: boolean,
  activePage: any
  taskPagination: any
  taskPerPage: any
  viewTaskModal: any
  profileId: any,
  assignName: any,
  searchText: any,
  viewReminderModal: any
  reminderId: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start 
  // Customizable Area End
}

export default class TaskAllocatorController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetAllTasksApiCallId: string = "";
  GetCurrentUserApiCallId: string = "";
  GetAddTaskApiCallId: string = "";
  GetCandidatesApiCallId: string = "";
  GetMarkAsTaskComplete: string = "";
  GetAllRemindersListApi: string = "";
  GetAddReminderApiCallId: string = "";
  GetUpdateTaskApiCallId: string = "";
  GetUpdateReminderApiCallId: string = "";
  deleteReminderApiCall: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      allTaskList: [],
      selectedTaskSection: 1,
      presentTaskList: [],
      candidateLoader: false,
      currentUser: [],
      isShowMyTaskModal: false,
      taskAssign: '',
      taskComment: '',
      taskDate: '',
      taskSubject: '',
      currentUserId: '',
      candidatesData: '',
      createTaskError: false,
      showTaskDropDown: false,
      currentOpenTaskModal: '',
      allRemindersList: [],
      reminderSubject: '',
      reminderDate: '',
      reminderTime: '',
      reminderComment: "",
      isShowReminderModal: false,
      reminderError: false,
      activePage: 1,
      taskPagination: "",
      taskPerPage: 5,
      viewTaskModal: false,
      profileId: "",
      assignName: "",
      searchText: "",
      viewReminderModal: false,
      reminderId: ""
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        //Task created modal
        if (apiRequestCallId === this.GetAllTasksApiCallId) {
          this.setState({ allTaskList: responseJson?.data, taskPagination: responseJson?.meta?.pagination_details });
          this.selectedTaskSection(this.state.selectedTaskSection);
        }

        //get currentCandidateData
        if (apiRequestCallId === this.GetCurrentUserApiCallId) {
          this.setState({
            currentUser: responseJson?.data?.attributes,
            currentUserId: responseJson?.data?.id
          })
        }

        //Task created modal
        if (apiRequestCallId === this.GetAddTaskApiCallId) {
          this.setState({ isShowMyTaskModal: false });
          // window.location.reload();
          this.getAllTasks()

        }

        //Task created modal
        if (apiRequestCallId === this.GetUpdateTaskApiCallId) {
          this.setState({
            viewTaskModal: false,
            taskSubject: "",
            taskAssign: "",
            taskDate: "",
            taskComment: ""
          });
          // window.location.reload();
          this.getAllTasks()

        }

        // get candidatesData 
        if (apiRequestCallId === this.GetCandidatesApiCallId) {
          let optionList = [];
          let firstEle: any = { value: this.state.currentUserId, label: 'Myself' }

          optionList = responseJson?.data?.map((x: any) => {
            return { value: x?.id, label: x?.attributes?.full_name }
          });
          optionList = [firstEle].concat(optionList)

          this.setState({
            candidatesData: optionList
          })
        }

        //Mark Task As complete
        if (apiRequestCallId === this.GetMarkAsTaskComplete) {
          // window.location.reload();
          this.selectedTaskSection(this.state.selectedTaskSection);
          this.setState({
            viewTaskModal: false,
            taskSubject: "",
            taskAssign: "",
            taskDate: "",
            taskComment: ""
          })
          this.getAllTasks()

        }

        //get all Reminders list
        if (apiRequestCallId === this.GetAllRemindersListApi) {
          this.setState({ allRemindersList: responseJson?.data })
        }

        //Reminder Created modal
        if (apiRequestCallId === this.GetAddReminderApiCallId) {
          console.log("Reminder Created");
          this.setState({
            isShowReminderModal: false,
            reminderSubject: "",
            reminderDate: "",
            reminderTime: "",
            reminderComment: ""
          });
          // window.location.reload();
          this.allRemindersList()
        }
        //updated Created modal
        if (apiRequestCallId === this.GetUpdateReminderApiCallId) {
          console.log("Reminder Updated");
          this.setState({
            viewReminderModal: false,
            reminderSubject: "",
            reminderDate: "",
            reminderTime: "",
            reminderComment: ""
          });
          // window.location.reload();
          this.allRemindersList()
        }

        //delete Reminder
        if (apiRequestCallId === this.deleteReminderApiCall) {
          if (responseJson?.message) {
            window.alert("Deleted Successfully");
            this.allRemindersList();
          } else {
            window.alert("Some error Occurs");
          }
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getAllTasks();
    await this.getCurrentUser();
    await this.getCandidates();
    await this.allRemindersList();
    document.addEventListener("mousedown", this.handleClickOutside)

  }

  // @ts-ignore
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e?: any) => {
    // @ts-ignore
    if (document.getElementById("view-modal-dropdown") && !document.getElementById("view-modal-dropdown").contains(e.target)) {
      this.setState({ currentOpenTaskModal: null });
    }
  };

  selectedTaskSection = (section: number = this.state.selectedTaskSection) => {
    this.setState({ selectedTaskSection: section, activePage: 1 })
    if (section === 1) {
      this.setState({ presentTaskList: this.state?.allTaskList?.filter((ele: any) => (ele.attributes?.my_task && ele?.attributes?.status === 'pending')) })
    } else {
      if (section === 2) {
        this.setState({ presentTaskList: this.state?.allTaskList?.filter((ele: any) => ele.attributes.status !== 'complete') })
      } else {
        this.setState({ presentTaskList: this.state?.allTaskList?.filter((ele: any) => ele.attributes.status !== 'pending') })
      }
    }
  }

  handleSelect = (selectedOption: any, type: any) => {
    if (type === 'taskAssign') {
      this.setState({ taskAssign: selectedOption.value })
    }
  }

  // get all Tasks
  getAllTasks = () => {
    const headers = {
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTasks
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(taskAttributes));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //getCurrentUser
  getCurrentUser = (): boolean => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    this.setState({
      candidateLoader: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCurrentUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Add task 
  handleAddTask = () => {
    let task = {
      task: {
        "subject": this.state.taskSubject,
        "task_date": this.state.taskDate,
        "assignee_id": this.state.currentUserId,
        "assign_to_id": this.state.taskAssign,
        "task_comment": this.state.taskComment,
        "my_task": this.state.currentUserId === this.state.taskAssign
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAddTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTaskCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(task));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //Update task 
  handleUpdateTask = () => {
    let task = {
      task: {
        "subject": this.state.taskSubject,
        "task_date": this.state.taskDate,
        "assignee_id": this.state.currentUserId,
        "assign_to_id": this.state.taskAssign,
        "task_comment": this.state.taskComment,
        "my_task": this.state.currentUserId == this.state.taskAssign
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetUpdateTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markTaskAsComplete + this.state.profileId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(task));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //get Candidate 
  getCandidates = (): boolean => {
    const headers = {
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidatesGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //markAsComplete
  markAsCompleteTask = (taskCardId: number) => {
    {
      let task = {
        "task": {
          "status": "complete"
        }
      }
      const headers = {
        'token': window.localStorage.getItem('authToken'),
        "Content-Type": configJSON.validationApiContentType
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.GetMarkAsTaskComplete = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markTaskAsComplete + taskCardId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(task)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    };
  }

  //get Reminder list
  allRemindersList = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllRemindersListApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRemindersUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //Add Reminder 
  handleAddReminder = () => {

    let reminder = {
      reminder: {
        "subject": this.state.reminderSubject,
        "reminder_date": this.state.reminderDate,
        "reminder_time": this.state.reminderTime,
        "reminder_comment": this.state.reminderComment
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAddReminderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReminder
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reminder));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //update Reminder 
  handleUpdateReminder = () => {

    let reminder = {
      reminder: {
        "subject": this.state.reminderSubject,
        "reminder_date": this.state.reminderDate,
        "reminder_time": this.state.reminderTime,
        "reminder_comment": this.state.reminderComment
      }
    }

    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetUpdateReminderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRemindersUrl}/${this.state.reminderId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reminder));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  //delete Reminder
  deleteReminder = (reminderID: any) => {
    const header = {
      'token': window.localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReminderApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_notifications/reminders/' + reminderID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

  }

  // Customizable Area End

}
