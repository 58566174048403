// @ts-nocheck
import React from "react";

import { withRouter, Link } from "react-router-dom";
import Select from 'react-select'
import { Slider } from '@material-ui/core';
import { useEffect } from "react";
import Modal from 'react-modal'

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontColor: '#727F88',
    fontWeight: '500',
    borderColor: 'transparent',
    borderRadius: '10px',
    height: '44px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }
  }),
}


const SideNav = (props: any, history: any) => {


  const [navbar, setNavBar] = React.useState(false)
  const [show, setShow] = React.useState(false);
  const [fundingRange, setFundingRange] = React.useState([0, 1000])
  const [data, setData] = React.useState({})
  const [logoutModal, setLogoutModal] = React.useState(false)

  useEffect(() => {
    if (window.location.href.includes('reports') || window.location.href.includes('auditLogs') || window.location.href.includes('teamVisibility')) {
      setShow(true);
    }
    return () => {

    }
  }, [])

  const handleNavbar = () => {
    setNavBar(!navbar)
  }

  const handleChange = (event, value) => {
    setData({
      ...data, positions: value
    })
  }

  const handleLogout = () => {
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('candidateId');
    window.localStorage.removeItem('candidateAction');
    window.location.href = '/';
  }

  const handleFundingRange = (event: Event, newValue: number | number[]) => {
    setFundingRange(newValue as number[]);
    setData({
      ...data, fundingRange: fundingRange
    })
  };


  function valuetext(value: number) {
    return `$${value}`;
  }

  React.useEffect(() => {
    if (props.searchFilter) {
      props.filterData(data)
    }
  }, [data])

  return (

    props.searchFilter ?
      < nav id="sidebar" className={navbar ? "active" : ""} >
        <div className="sidebar-header">
          <Link to="/Dashboard"><img style={{ cursor: 'pointer' }} src={require('./pq-logo.png')} width="200" /></Link>
        </div>
        <ul className="list-unstyled components">
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }} className="row">
            <label className="p-1 col-9">FILTERS</label>
            <label className="p-1 col-3" style={{ float: 'right' }} onClick={() => props.handleFilter()}>X</label>
          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Status</label>
            <Select
              options={[
                { label: "Inactive", value: 1 },
                { label: "Active", value: 1 }
              ]}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                setData({
                  ...data, status: selectedOption
                })
              }}
              styles={customStyles}
            />
          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Domain</label>
            <Select
              options={props.listOfDomain}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                setData({
                  ...data, domain: selectedOption
                })
              }}
              styles={customStyles}
            />
          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Role</label>
            <Select
              options={props.listOfRoles}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                setData({
                  ...data, jobType: selectedOption
                })
              }}
              styles={customStyles}
            />
          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Location</label>
            <Select
              options={[
                { label: 1, value: 1 },
                { label: 1, value: 1 }
              ]}
              // @ts-ignore
              placeholder={'Select'}
              onChange={(selectedOption) => {
                setData({
                  ...data, location: selectedOption
                })
              }}

              styles={customStyles}
            />
          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Agreement</label>

          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Open Positions</label>
            <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handleChange} />
          </li>
          <li style={{ padding: '5px', marginLeft: '10px', fontWeight: 500 }}>
            <label className="p-1">Funding</label>
            <Slider
              getAriaLabel={() => 'Funding range'}
              value={fundingRange}
              onChange={handleFundingRange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
            />
          </li>
        </ul>
      </nav >
      :
      < nav id="sidebar" className={navbar ? "active" : ""} >
        <div className="sidebar-header">
          <a to="/Dashboard"><img style={{ cursor: 'pointer' }} src={require('./pq-logo.png')} width="200" /></a>
        </div>
        <ul className="list-unstyled components">
          <li style={{ padding: '5px' }}>
            <a href="/Dashboard" className={`${window.location.href.includes('Dashboard') || window.location.href.includes('user-tasks') || window.location.href.includes('reminder') ? 'present-selected' : ''}`}>{window.location.href.includes('Dashboard') || window.location.href.includes('user-tasks') || window.location.href.includes('reminder') ? <img src={require('./dashboard-purple.png')} className="p-1" /> : <img src={require('./dashboard.png')} className="p-1" />}<span className="p-1">Dashboard</span></a>
          </li>
          <li style={{ padding: '5px' }}>
            <a href="/Candidates" className={`${window.location.href.includes('Candidates') || window.location.href.includes('/CandidateProfile?id') || window.location.href.includes('BasicDetails') || window.location.href.includes('Competencies') || window.location.href.includes('DomainKnowledge') || window.location.href.includes('Compensation') || window.location.href.includes('ExpectedCompensation') || window.location.href.includes('FamilyDetails') || window.location.href.includes('candidateDocuments') || window.location.href.includes('candidateCreateProfile') ? 'present-selected' : ''}`}>
              {(window.location.href.includes('Candidates') || window.location.href.includes('/CandidateProfile?id') || window.location.href.includes('BasicDetails')) || window.location.href.includes('Competencies') || window.location.href.includes('DomainKnowledge') || window.location.href.includes('Compensation') || window.location.href.includes('ExpectedCompensation') || window.location.href.includes('FamilyDetails') || window.location.href.includes('candidateDocuments') || window.location.href.includes('candidateCreateProfile') ? <img src={require('./candidate-purple.png')} className="p-1" /> : <img src={require('./candidates.png')} className="p-1" />}
              <span className="p-1">Candidates</span></a>
          </li>
          <li style={{ padding: '5px' }}>
            <a href="/companies" >
              <img src={window.location.href.includes('pqCapsule') || window.location.href.includes('newclient') || window.location.href.includes('companies') || window.location.href.includes('companies') ? require('./company-purple.png') : require('./companies.png')} className="p-1" />
              <span className={`${window.location.href.includes('pqCapsule') || window.location.href.includes('newclient') || window.location.href.includes('companies') || window.location.href.includes('companies') ? 'present-selected p-1' : 'p-1'}`}>Companies</span>
            </a>

          </li>
          <li style={{ padding: '5px' }}>
            <a data-toggle="collapse" onClick={() => setShow(!show)}
              aria-expanded="false" className="dropdown-toggle">
              <img src={window.location.href.includes('reports') ? require('./reports.png') : require('./reports.png')} className="p-1" />
              <span className={`${window.location.href.includes('reports') ? 'present-selected p-1' : 'p-1'}`}>Reports</span>
            </a>
            <ul className="collapse list-unstyled" id="pageSubmenu" style={show ? { display: "block", position: 'absolute' } : { display: 'none' }}>
              <li className={window.location.href.includes('reports') ? 'present-selected p-1' : 'p-1'}> <Link to="/reports">Reports</Link></li>
              <li className={window.location.href.includes('auditLogs') ? 'present-selected p-1' : 'p-1'}> <Link to="/auditLogs">Audit Logs </Link></li>
              <li className={window.location.href.includes('teamVisibility') ? 'present-selected p-1' : 'p-1'}> <Link to="/teamVisibility">Team Visibility </Link></li>
            </ul>
          </li>
        </ul>

        <ul className="list-unstyled CTAs">
          <li className="logout-button">
            <button
              className="btn logout"
              onClick={() => setLogoutModal(true)}>
              <div className="logout-image-section">
                <img src={require('./image-logout.png')} className="p-1 logout-img" />
                <span className="p-1 logout-btn">
                  Logout
                  <p className="logout-userName">@{props?.data?.first_name}!</p>
                </span>
              </div>
            </button>
          </li>
        </ul>
        <Modal
          onRequestClose={() => setLogoutModal(false)}
          isOpen={logoutModal}
          contentLabel=""
          className="logout-modal"
          style={{
            overlay: {
              backgroundColor: 'rgb(49 42 42 / 85%)',
              zIndex: 999999999999,
            },
            content: {
              // color: 'lightsteelblue'
            }
          }}
        >
          <div>
            <form noValidate autoComplete="off">
              <div className="row">
                <div className="col-11">
                  {/* <label className="task-label">View Task</label> */}
                </div>
                <div className="col-1">
                  {/* <button className="btn cross-btn" onClick={() => { setLogoutModal(false) }}><b>X</b></button> */}
                </div>
              </div>
              <div className="row ">
                <center className="mt-1 mb-3">
                  <img src={require('./image-logout.png')} className="p-1 logout-img" style={{ width: '50px', height: '50px' }} />
                  <br />
                  <br />
                  <label className="task-label">Are you sure, you want to logout?</label>
                </center>
                <div className="col-6" style={{ textAlign: 'end' }}>
                  <button type="button" onClick={() => { handleLogout() }} style={{ width: '-webkit-fill-available' }} className="btn auth-button">Yes</button>
                </div>
                <div className="col-6">
                  <button type="button" className="btn auth-button" style={{ width: '-webkit-fill-available' }} onClick={() => { setLogoutModal(false) }}>No</button>
                </div>
              </div>
            </form>
          </div>

        </Modal>
      </nav >

  );
};



export default withRouter(SideNav);
