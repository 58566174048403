import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";

// Customizable Area Start
import DashboardController, { Props } from "./DashboardController.web";
import './dashboard.css'
import { TextField } from '@material-ui/core';
import Select from 'react-select'
import { Link } from 'react-router-dom'
// Customizable Area End


export default class CandidateCreateProfile extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.getPqEmployees()
        this.getCandidateComment()
        // Customizable Area End
    }

    // Customizable Area Start
    handleChange = (event: any, isSelectFields: any = false) => {
        let name: any = '';
        let target: any = '';
        let value: any = '';

        if (isSelectFields) {
            name = isSelectFields;
            value = event.value;
        } else {
            event && event.preventDefault();
            target = event.target;
            value = target.value;
            name = target.name;
        }

        //@ts-ignore
        this.setState({ ...this.state, [name]: value });
    }
    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4">
                            <div className="view-rectangle">
                                <form onSubmit={(e) => this.createComment(e)}>
                                    {
                                        window.localStorage.getItem('candidateAction') === "Edit" ?
                                            <p className="heading-head">Edit Candidate</p>
                                            :
                                            <p className="heading-head">Add New Candidate</p>

                                    }
                                    <p className="heading"><b>Candidate Connects</b></p>
                                    <div>
                                        <div className="row mt-1 mb-3">
                                            <div className="col-6">
                                                <label className="p-1">Select Date</label>
                                                <input
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                    className="form-control input-field text-field-input"
                                                    name="retainerAdvance"
                                                    type="date"
                                                    max='9999-12-31'
                                                    value={this.state.retainerAdvance}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="p-1">Mode of Connection</label>

                                                <Select
                                                    options={[
                                                        { value: 'Video Call', label: 'Video Call' },
                                                        { value: 'Voice Call', label: 'Voice Call' },
                                                        { value: 'Face to Face', label: 'Face to Face' }
                                                    ]}
                                                    // @ts-ignore
                                                    placeholder={''}
                                                    onChange={(e) => {
                                                        this.handleChange(e, 'connectionType')
                                                    }}
                                                    name={`connectionType`}
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-3">
                                            <label className="my-label">No of Connects Made</label>

                                            <div className="col-12">
                                                {/* <label className="p-1">No of Connects Made</label> */}

                                                <Select
                                                    options={[
                                                        { value: 1, label: 1 },
                                                        { value: 2, label: 2 },
                                                        { value: 3, label: 3 },
                                                        { value: 4, label: 4 },
                                                        { value: 5, label: 5 },
                                                    ]}
                                                    // @ts-ignore
                                                    placeholder={''}
                                                    onChange={(e) => {
                                                        this.handleChange(e, 'noOfConnections')
                                                    }}
                                                    name={`noOfConnections`}
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-3">
                                            <div className="col-12">
                                                <label className="my-label">People who connected with the candidate</label>

                                                <Select
                                                    isMulti
                                                    options={this.state.pqEmployeeArray}
                                                    // @ts-ignore
                                                    placeholder={''}
                                                    onChange={(selectedOption) => {
                                                        this.handleSelect(selectedOption, 'multipleCandidates')
                                                    }}
                                                    name={`pqEmployeeName`}
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-3">
                                            <label className="my-label">SPOC Observations</label>

                                            <div className="col-12">
                                                <TextField
                                                    id="outlined-secondary"
                                                    required
                                                    // label="SPOC Observations"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}
                                                    className="form-control input-field text-field-input"
                                                    name="SPOCObeservations"
                                                    type="text"
                                                    value={this.state.SPOCObeservations}


                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-3">
                                            <label className="my-label">Reporting Manager's Comments</label>

                                            <div className="col-12">
                                                <TextField
                                                    multiline
                                                    rows={5}
                                                    id="outlined-secondary"
                                                    //label="Reporting Manager's Comments"
                                                    variant="outlined"
                                                    color="primary"
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    className="form-control input-field"
                                                    name="reportingMangerComment"
                                                    value={this.state.reportingMangerComment}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <button className="btn candidate-button" type="submit">Create Profile</button>
                                    </div>
                                </form>
                                {/* comment card */}
                                {
                                    this.state.getCandidateComments.length > 0 &&
                                    <div className="comment-rectangle">
                                        {
                                            this.state.getCandidateComments.length > 0 && this.state.getCandidateComments?.map((x: any) => {
                                                return (
                                                    <div className="comment-card">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label style={{
                                                                    color: '#FF1D1D',
                                                                    marginLeft: '10px'
                                                                }}>{x.attributes.comment_to}</label>
                                                            </div>
                                                            <div className="col-3">
                                                                <label>Mode of Connection: </label><label style={{
                                                                    color: '#FF1D1D',
                                                                    marginLeft: '10px'
                                                                }}>{x.attributes.mode_of_connection}</label>
                                                            </div>
                                                            <div className="col-3">
                                                                <label>No of Connects: </label><label style={{
                                                                    color: '#FF1D1D',
                                                                    marginLeft: '10px'
                                                                }}> {x.attributes.no_of_connects}</label>
                                                            </div>
                                                            <div className="col-3">

                                                                <label>Date: </label><label style={{
                                                                    color: '#FF1D1D',
                                                                    marginLeft: '10px'
                                                                }}> {x.attributes.date}</label>
                                                            </div>
                                                        </div>
                                                        <hr className="comment-line" />
                                                        <div className="row" style={{ padding: '10px', margin: '4px !important' }}>
                                                            <div className="col-3">
                                                                <label>SPOC</label>
                                                                <br />
                                                                <label> Observation:</label>
                                                            </div>
                                                            <div className="col-9">
                                                                {x.attributes.profile_observation}
                                                            </div>
                                                        </div>
                                                        <hr className="comment-line" />
                                                        <div className="row" style={{ padding: '10px' }}>
                                                            <div className="col-3">
                                                                <label>Manager's</label>
                                                                <br />
                                                                <label> Comments</label>
                                                            </div>
                                                            <div className="col-9">
                                                                {x.attributes.mangaer_comment}
                                                            </div>
                                                        </div>
                                                        <hr className="comment-line" />

                                                        <div className="row" style={{ padding: '10px' }}>
                                                            <div className="col-3">
                                                                <label>People who've</label>
                                                                <label>Connected so far:</label>
                                                            </div>
                                                            <div className="col-9">
                                                                {x.attributes?.people_connected?.map((x: any) => {
                                                                    return x
                                                                }).join(', ')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                }
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <Link to="/Candidates" className="btn candidate-button" type="button">Save And Continue</Link>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
            // Customizable Area End
        );
    }
}
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        height: '44px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
