import React from "react";
import SideNav from "../../../components/src/SideNav.web";
import TopNav from "../../../components/src/TopNav.web";
// Customizable Area Start
import { Formik } from "formik";
import Select from 'react-select'
import { Link } from 'react-router-dom'
import * as yup from "yup";
import { TextField } from '@material-ui/core';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

export default class ExpectedCompensation extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    expectedCompensationSchema = () => {
        return yup.object().shape({
            base: yup
                .number()
                .required("Base is required."),
            hike: yup
                .number()
                .required("Hike is required."),
            variable: yup
                .number()
                .required("Variable is required."),
            bonus: yup
                .number()
                .required("Bonus is required."),
            totalCashCompensation: yup
                .number()
                .required("Total Cash Compensation is required."),
            selectedStockValue: yup
                .number()
                .required("Stock Value is required."),
        });
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <div className="wrapper">
                    <SideNav data={this.state.currentUser} />
                    <div className="content">
                        <TopNav data={this.state.currentUser} />
                        <div className="mb-4 mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    hike: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.base ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.base : "",
                                    base: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.base ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.base : "",
                                    variable: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.variable ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.variable : "",
                                    bonus: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.bonus ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.bonus : "",
                                    totalCashCompensation: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.total_compensation ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.total_compensation : "",
                                    selectedStockValue: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.stocks_value ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.stocks_value : "",
                                    equity: this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.equity ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.equity : "",
                                }}
                                validationSchema={this.expectedCompensationSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    this.handleExpectedCompensation(values);
                                }}
                            >
                                {({ errors, touched, handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="view-rectangle">
                                            <p className="heading-head">{window.location.href.includes('Edit-user') ? 'Edit Candidate' : 'Add New Candidate'}</p>
                                            <p className="heading"><b>Expected Compensation</b></p>

                                            <div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Hike Expected</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label='Hike Expected'
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.hike}
                                                        name="hike"
                                                        type="text"
                                                    />

                                                    {errors.hike && touched.hike ? <div className="error">{errors.hike}</div> : null}
                                                </div>
                                                <div className="row mt-1 mb-3">
                                                    <label className="my-label">Base</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            // label='Base'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.base}
                                                            name="base"
                                                            type="text"
                                                        />
                                                        {errors.base && touched.base ? <div className="error">{errors.base}</div> : null}

                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'baseCurrency')} />
                                                                {this.state.baseCurrencyError !== null && <div className="error">{this.state.baseCurrencyError}</div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ label: this.state.baseCurrencyValue.label, value: this.state.baseCurrencyValue.value }} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'baseCurrency')} />
                                                                {this.state.baseCurrencyError !== null && <div className="error">{this.state.baseCurrencyError}</div>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                                <div className="row mt-1 mb-3">
                                                    <label className="my-label">Variable</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            // label='Variable'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.variable}
                                                            name="variable"
                                                            type="text"
                                                        />


                                                        {errors.variable && touched.variable ? <div className="error">{errors.variable}</div> : null}

                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'vairableCurrency')} />
                                                                {this.state.vairableCurrencyError !== null && <div className="error">{this.state.vairableCurrencyError}</div>}
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ label: this.state.variableCurrencyValue.label, value: this.state.variableCurrencyValue.value }} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'vairableCurrency')} />
                                                                {this.state.vairableCurrencyError !== null && <div className="error">{this.state.vairableCurrencyError}</div>}
                                                            </>
                                                    }
                                                </div>
                                                <div className="row mt-1 mb-3">
                                                    <label className="my-label">Bonus</label>
                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            //label='Bonus'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.bonus}
                                                            name="bonus"
                                                            type="text"
                                                        />

                                                        {errors.bonus && touched.bonus ? <div className="error">{errors.bonus}</div> : null}

                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')}
                                                                    styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'bonusCurrency')} />
                                                                {this.state.bonusCurrencyError !== null && <div className="error">{this.state.bonusCurrencyError}</div>}
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ label: this.state.baseCurrencyValue.label, value: this.state.baseCurrencyValue.value }}
                                                                    styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'bonusCurrency')} />
                                                                {this.state.bonusCurrencyError !== null && <div className="error">{this.state.bonusCurrencyError}</div>}
                                                            </>
                                                    }
                                                </div>
                                                <div className="row mt-1 mb-3">
                                                    <label className="my-label">Total Cash Compensation</label>

                                                    <div className="col-12">
                                                        <TextField
                                                            id="outlined-secondary"
                                                            //label='Total Cash Compensation'
                                                            variant="outlined"
                                                            color="primary"
                                                            onChange={handleChange}
                                                            className="form-control input-field text-field-input"
                                                            value={values.totalCashCompensation}
                                                            name="totalCashCompensation"
                                                            type="text"
                                                        />

                                                        {errors.totalCashCompensation && touched.totalCashCompensation ? <div className="error">{errors.totalCashCompensation}</div> : null}

                                                    </div>
                                                    {
                                                        this.state.currencyValue.value !== "" ?
                                                            <>
                                                                <Select value={this.getCuurencyValue('baseCurrency')} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'cashCurrency')} />
                                                                {this.state.cashCurrencyError !== null && <div className="error">{this.state.cashCurrencyError}</div>}
                                                            </>
                                                            :
                                                            <>
                                                                <Select value={{ value: this.state.cashCurrencyValue.value, label: this.state.cashCurrencyValue.label }} styles={customStyles} options={this.state.currencyOptions} className="col-2 cash-currency" placeholder="$" onChange={(selectedOption) => this.handleSelect(selectedOption, 'cashCurrency')} />
                                                                {this.state.cashCurrencyError !== null && <div className="error">{this.state.cashCurrencyError}</div>}
                                                            </>
                                                    }
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">Stocks</label>
                                                    <Select styles={customStyles} options={this.state.stockOptions} placeholder={this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.stocks ? this.state.currentCandidateData?.attributes?.expected_compensation?.data?.attributes?.stocks : ""} onChange={(selectedOption) => this.handleSelect(selectedOption, 'expectedstocks')} />
                                                    {this.state.expectedstocksError !== null && <div className="error">{this.state.expectedstocksError}</div>}
                                                </div>
                                                <div className="mt-1 mb-3">
                                                    <label className="my-label">{`Enter ${this.state.expectedstocks?.value !== undefined ? this.state.expectedstocks?.value : ""}`}</label>
                                                    <TextField
                                                        id="outlined-secondary"
                                                        // label={`Enter ${this.state.stocksValue?.value !== undefined ? this.state.stocksValue?.value : ""}`}
                                                        variant="outlined"
                                                        color="primary"
                                                        onChange={handleChange}
                                                        className="form-control input-field text-field-input"
                                                        value={values.selectedStockValue}
                                                        name="selectedStockValue"
                                                        type="text"
                                                    />

                                                    {errors.selectedStockValue && touched.selectedStockValue ? <div className="error">{errors.selectedStockValue}</div> : null}
                                                </div>
                                                {/* <div className="mt-1 mb-3">
                                                    <input value={values.equity} onChange={handleChange} className="form-control input-field" name="equity" type="text" placeholder="Equity" />
                                                    {errors.selectedStockValue && touched.selectedStockValue ? <div className="error">{errors.selectedStockValue}</div> : null}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="main-step row">
                                            <div className="row col-9">
                                                <Link to={window.location.href.includes('Edit-user') ? "/BasicDetails/Edit-user" : "/BasicDetails"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Competencies/Edit-user" : "/Competencies"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/DomainKnowledge/Edit-user" : "/DomainKnowledge"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/Compensation/Edit-user" : "/Compensation"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/ExpectedCompensation/Edit-user" : "/ExpectedCompensation"} className="step-bar step-bar-active col-2"></Link>
                                                <Link to={window.location.href.includes('Edit-user') ? "/FamilyDetails/Edit-user" : "/FamilyDetails"} className="step-bar col-2"></Link>

                                            </div>
                                            <div className="row col-3">
                                                <button className="btn candidate-button" type="submit">Save And Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "#FBFBFB",
        fontColor: '#727F88',
        fontWeight: '500',
        borderColor: 'transparent',
        borderRadius: '10px',
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
}
// Customizable Area End
