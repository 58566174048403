import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import SideNav from "../../../components/src/SideNav.web";
import Modal from "react-modal";
import TopNav from "../../../components/src/TopNav.web";
// @ts-ignore
import Board from "react-trello";
import { Link } from "react-router-dom";
import { Input, TextField } from '@material-ui/core';
import Select from 'react-select';
import './taskAllocation.css'
import TaskAllocatorController, { Props } from "../../TaskAllocator/src/TaskAllocatorController";
const sortImage = require('../../dashboard/assets/icon-settings@2x.png');
// @ts-ignore
import Pagination from "react-js-pagination";
import { borderRadius } from "react-select/src/theme";
import moment from 'moment'

export default class TaskAllocator extends TaskAllocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleTaskChange = (event: any) => {
    const target: any = event.target;
    const value: any = target.value;
    const name: any = target.name;
    //@ts-ignore
    this.setState({ [name]: value });
  }

  callSaveTaskApi = (type: any) => {
    if (type === 'save') {
      if (this.state.taskSubject.length && this.state.taskAssign.length && this.state.taskDate.length) {
        this.handleAddTask();
        this.setState({ createTaskError: false })
      } else {
        this.setState({ createTaskError: true })
      }
    } else {
      this.handleUpdateTask();
    }
  }

  handleTextFieldArea = (eleId: string) => {
    let textarea: any = document.getElementById(eleId);
    let limit = 200
    textarea.oninput = function () {
      textarea.style.height = "";
      textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
    };
  }

  handleViewTaskModal = (data: any) => {
    console.log(data)
    this.setState({ viewTaskModal: true })
    this.viewTaskModal()
  }

  openMyTaskModal = () => {
    return <Modal
      isOpen={this.state.isShowMyTaskModal}
      contentLabel=""
      className="mytask-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-11">
              <label className="task-label">Create New Task</label>
            </div>
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ isShowMyTaskModal: false, createTaskError: false, taskAssign: '', taskSubject: '', taskDate: '', taskComment: '' }) }}><b>X</b></button>
            </div>
          </div>
          <div className="add-task-form col-12">
            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Subject</label>
              <TextField
                id="outlined-secondary"
                // label="Subject"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleTaskChange(e)}
                className="form-control input-field text-field-input"
                name="taskSubject"
                value={this.state.taskSubject}
              />
              {/* <input type="text" className="form-control input-field" style={{ height: '44px' }} placeholder="Subject" name="taskSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
            </div>
            <div className="mt-2 mb-2 row p-3">
              <div className="col-6">
                <label className="my-label">Date</label>
                <input type="text" placeholder="Date" max ="9999-12-31" style={{ height: '44px' }} onFocus={(e) => e.target.type = 'date'} className="form-control input-field" min={new Date().toISOString().split('T')[0]} name="taskDate" onChange={(e) => this.handleTaskChange(e)} />
              </div>
              <div className="col-6">
                {/* <b>Assign</b> */}
                <label className="my-label">Assign To</label>
                <Select styles={customStyles} options={this.state.candidatesData} placeholder="" onChange={(selectedOption) => this.handleSelect(selectedOption, 'taskAssign')}
                />
              </div>
            </div>

            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Add Comments</label>
              <TextField
                multiline
                rows={5}
                id="outlined-secondary Navadd-Task-comments"
                // label="Add Comments"
                variant="outlined"
                color="primary"
                onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea("outlined-secondary Navadd-Task-comments") }}
                className="form-control input-field"
                name="taskComment"
                value={this.state.taskComment}
              />
              {/* <textarea placeholder="Add Comments" className="form-control input-field" name="taskComment" id="task-textArea-comment" style={{ height: '108px' }} onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea('task-textArea-comment') }} /> */}
            </div>
          </div>
          <div>{this.state.createTaskError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
          <div className="center-item-button">
            <button className="btn save-button" type="button" onClick={() => this.callSaveTaskApi('save')}>Save</button>
          </div>
        </form>
      </div>
    </Modal >
  }


  handleViewTask = (profile: any, ele: any) => {
    this.setState({ viewTaskModal: true, profileId: profile })
    this.state?.presentTaskList?.filter((x: any) => { return x.id === profile }).map((y: any) => {
      return this.setState({ taskSubject: y.attributes.subject, taskDate: y.attributes.task_date, taskComment: y.attributes.task_comment, assignName: ele.attributes.assigned_to ? ele.attributes.assigned_to : ele.attributes.assign_to_id === ele.attributes.assignee_id ? 'Myself' : '', taskAssign: y.attributes.assign_to_id })
    })
  }


  openDropDownModalUI = (ele: any) => {
    return <div className="ellipsis-dropdown-ul-task" id="view-modal-dropdown">
      <span className="ellipsis-dropdown-li-task" onClick={() => this.markAsCompleteTask(ele.id)}>Mark as Complete</span><br />
      <span className="ellipsis-dropdown-li-task" onClick={() => this.handleViewTask(ele.id, ele)}>Edit Details</span>
    </div>
  }

  callAddReminderApi = () => {
    if (this.state.reminderSubject.length && this.state.reminderDate.length && this.state.reminderTime.length) {
      this.setState({ reminderError: false });
      this.handleAddReminder();
    } else {
      this.setState({ reminderError: true })
    }
  }

  updateReminderApi = () => {
    if (this.state.reminderSubject.length && this.state.reminderDate.length && this.state.reminderTime.length) {
      this.setState({ reminderError: false });
      this.handleUpdateReminder();
    } else {
      this.setState({ reminderError: true })
    }
  }

  openReminderModal = () => {
    return <Modal
      isOpen={this.state.isShowReminderModal}
      contentLabel=""
      className="mytask-reminder"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <form noValidate autoComplete="off">
        <div className="row">
          <div className="col-11">
            <label className="task-label">Add New Reminder</label>
          </div>
          <div className="col-1">
            <button className="btn cross-btn" onClick={() => { this.setState({ isShowReminderModal: false, reminderError: false }) }}><b>X</b></button>
          </div>
        </div>
        <div className="">
          <div className="mt-2 mb-3 p-3">
            <label className="my-label">Subject</label>
            <TextField
              id="outlined-secondary"
              // label="Subject"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleTaskChange(e)}
              className="form-control input-field text-field-input"
              name="reminderSubject"
              value={this.state.reminderSubject}
            />
            {/* <input type="text" className="form-control input-field" placeholder="Subject" name="reminderSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
          </div>
          <div className="row mt-2 mb-3 p-3">
            <div className="col-6">
              <label className="my-label">Date</label>
              <input type="text" onFocus={(e) => e.target.type = 'date'} className="form-control input-field" placeholder="Date" max ="9999-12-31" name="reminderDate" min={new Date().toISOString().split('T')[0]} onChange={(e) => this.handleTaskChange(e)} />
            </div>

            <div className="col-6">
              <label className="my-label">Add Time</label>
              <input type="time" className="form-control input-field" placeholder="Time" name="reminderTime" onChange={(e) => this.handleTaskChange(e)} />
            </div>
          </div>
          <div className="mt-2 mb-3 p-3">
            <label className="my-label">Add Comments</label>
            <TextField
              multiline
              rows={5}
              id="outlined-secondary reminderCommentpage"
              // label="Add Comments"
              variant="outlined"
              color="primary"
              onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea("outlined-secondary reminderCommentpage") }}
              className="form-control input-field"
              name="reminderComment"
              value={this.state.reminderComment}
            />
            {/* <textarea className="form-control input-field" style={{ height: '108px' }} placeholder="Add comments" name="reminderComment" id="text-reminderComment" onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea("text-reminderComment") }} /> */}
          </div>
        </div>
        <div>{this.state.reminderError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
        <div className="center-item-button">
          <button className="btn save-button" id="text-area-callReminderAPI" type="button" onClick={this.callAddReminderApi}>Save</button>
        </div>
      </form>
    </Modal>
  }

  handlePageChange(pageNumber: any) {
    this.setState({ activePage: pageNumber });
  }

  viewTaskModal = () => {
    return <Modal
      isOpen={this.state.viewTaskModal}
      contentLabel=""
      className="mytask-modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <div>
        < form noValidate autoComplete="off">
          <div className="row">
            <div className="col-11">
              <label className="task-label">Task Details</label>
            </div>
            <div className="col-1">
              <button className="btn cross-btn" onClick={() => { this.setState({ viewTaskModal: false, createTaskError: false, taskAssign: '', taskSubject: '', taskDate: '', taskComment: '' }) }}><b>X</b></button>
            </div>
          </div>
          <div className="add-task-form col-12">

            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Subject</label>
              <TextField
                id="outlined-secondary"
                // label="Subject"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleTaskChange(e)}
                className="form-control input-field text-field-input"
                name="taskSubject"
                value={this.state.taskSubject}
              />
              {/* <input value={this.state.taskSubject} style={{ height: '44px', color: 'black', backgroundColor: 'transparent !importanat' }} type="text" className="form-control input-field view-task-input" placeholder="Subject" name="taskSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
            </div>
            <div className="mt-2 mb-2 row p-3">
              <div className="col-6">
                <label className="my-label">Date</label>
                <input value={this.state.taskDate} style={{ height: '44px' }} max ="9999-12-31" type="text" placeholder="Date" onFocus={(e) => e.target.type = 'date'} className="form-control input-field" min={new Date().toISOString().split('T')[0]} name="taskDate" onChange={(e) => this.handleTaskChange(e)} />
              </div>
              <div className="col-6">
                <label className="my-label">Assign To</label>
                <Select styles={customStyles} style={{ height: '44px' }} options={this.state.candidatesData} placeholder={this.state.assignName} onChange={(selectedOption) => this.handleSelect(selectedOption, 'taskAssign')} />
              </div>
            </div>

            <div className="mt-2 mb-2 p-3">
              <label className="my-label">Add Comments</label>
              <TextField
                multiline
                rows={5}
                id="outlined-secondary"
                // label="Add Comments"
                variant="outlined"
                color="primary"
                onChange={(e) => this.handleTaskChange(e)}
                className="form-control input-field"
                name="taskComment"
                value={this.state.taskComment}
              />
              {/* <textarea value={this.state.taskComment} style={{ height: '108px', color: 'black' }} placeholder="Add comments" className="form-control input-field view-task-input" name="taskComment" id="addReminderComment" onChange={(e) => { this.handleTaskChange(e); this.handleTextFieldArea('addReminderComment') }} /> */}
            </div>
          </div>
          <div>{this.state.createTaskError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
          <div className="flex">
            <button className="btn save-button-1 float-left" type="button" onClick={() => this.callSaveTaskApi('update')}>Update</button>
            <button className="btn save-button-2 float-right" type="button" onClick={() => this.markAsCompleteTask(this.state.profileId)}>Mark as Completed</button>
          </div>
        </form>

      </div>
    </Modal >
  }

  filterSearchResults = (e: any) => {
    this.setState({ searchText: e.target.value })
  }

  getReminderList = () => {
    return (this.state.searchText.length ? this.state.allRemindersList.filter((item: any) => {
      return ((item?.attributes?.reminder_date?.toLowerCase()?.includes(this.state.searchText.toLowerCase())
        || item?.attributes?.subject?.toLowerCase().includes(this.state.searchText.toLowerCase())
        || item?.attributes?.reminder_comment?.toLowerCase().includes(this.state.searchText.toLowerCase())
      )
      )
    }) : this.state.allRemindersList)
  }


  viewReminderModal = () => {
    return <Modal
      isOpen={this.state.viewReminderModal}
      contentLabel=""
      className="mytask-reminder"
      style={{
        overlay: {
          backgroundColor: 'rgb(49 42 42 / 85%)'
        },
        content: {
          // color: 'lightsteelblue'
        }
      }}
    >
      <form noValidate autoComplete="off">
        <div className="row">
          <div className="col-11">
            <label className="task-label">View Reminder</label>
          </div>
          <div className="col-1">
            <button className="btn cross-btn" onClick={() => { this.setState({ viewReminderModal: false, reminderError: false, reminderComment: '', reminderDate: '', reminderId: '', reminderSubject: '', reminderTime: '' }) }}><b>X</b></button>
          </div>
        </div>
        <div className="">
          <div className="mt-2 mb-3 p-3">
            <label className="my-label">Subject</label>
            <TextField
              id="outlined-secondary"
              // label="Subject"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleTaskChange(e)}
              className="form-control input-field text-field-input"
              name="reminderSubject"
              value={this.state.reminderSubject}
            />
            {/* <input type="text" value={this.state.reminderSubject} className="form-control input-field" placeholder="Subject" name="reminderSubject" onChange={(e) => this.handleTaskChange(e)} /> */}
          </div>
          <div className="row mt-2 mb-3 p-3">
            <div className="col-6">
              <label className="my-label">Date</label>
              <input type="date" max ="9999-12-31" value={this.state.reminderDate} onFocus={(e) => e.target.type = 'date'} className="form-control input-field" placeholder="Date" name="reminderDate" min={new Date().toISOString().split('T')[0]} onChange={(e) => this.handleTaskChange(e)} />
            </div>
            <div className="col-6">
              <label className="my-label">Time</label>
              <input type="text" onBlur={(e) => e.target.type = 'text'} onFocus={(e) => e.target.type = 'time'} value={this.tConvert(this.state.reminderTime?.split('+')[0]).replace(":00", " ")} className="form-control input-field" placeholder={this.tConvert(this.state.reminderTime?.split('+')[0]).replace(":00", " ")} name="reminderTime" onChange={(e) => this.handleTaskChange(e)} />
            </div>
          </div>
          <div className="mt-2 mb-3 p-3">
            <label className="my-label">Add Comments</label>
            <TextField
              multiline
              rows={5}
              id="outlined-secondary"
              // label="Add Comments"
              variant="outlined"
              color="primary"
              onChange={(e) => this.handleTaskChange(e)}
              className="form-control input-field"
              name="reminderComment"
              value={this.state.reminderComment}
            />
            {/* <textarea value={this.state.reminderComment} className="form-control input-field" style={{ height: '108px' }} placeholder="Add comments" name="reminderComment" onChange={(e) => this.handleTaskChange(e)} /> */}
          </div>
        </div>
        <div>{this.state.reminderError ? <div style={{ 'color': 'red', 'position': 'absolute' }}>*All Fields are required</div> : ''}</div>
        <div className="center-item-button">
          <button className="btn save-button" type="button" onClick={this.updateReminderApi}>Update</button>
        </div>
      </form>
    </Modal>
  }

  tConvert(time: any) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  // Customizable Area End

  render() {
    function tConvert(time: any) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }

    // Logic for displaying current todos
    let currentPage = this.state.activePage;
    let indexOfLastTask = currentPage * this.state.taskPerPage;
    let indexOfFirstTask = indexOfLastTask - this.state.taskPerPage;
    let currentTasks = this.state.presentTaskList?.slice(indexOfFirstTask, indexOfLastTask);

    let getTaskList = () => {
      return (this.state.searchText.length ? currentTasks.filter((item: any) => {
        return ((item?.attributes?.task_date?.toLowerCase()?.includes(this.state.searchText.toLowerCase())
          || item?.attributes?.subject?.toLowerCase().includes(this.state.searchText.toLowerCase())
          || item?.attributes?.task_comment?.toLowerCase().includes(this.state.searchText.toLowerCase())
          || item?.attributes?.assignee?.toLowerCase().includes(this.state.searchText.toLowerCase())
          || item?.attributes?.assigned_to?.toLowerCase().includes(this.state.searchText.toLowerCase())
        )
        )
      }) : currentTasks)
    }
    return (
      // Customizable Area Start

      <div className="wrapper">
        <SideNav data={this.state.currentUser} />
        <div className="content">
          <TopNav data={this.state.currentUser} myModal={() => this.setState({
            isShowMyTaskModal: !this.state.isShowMyTaskModal,
            taskSubject: "",
            taskDate: "",
            taskAssign: "",
            taskComment: "",
          })} />

          {window.location.href.includes('tasks') ? <div>
            <div className="dashboard-section">
              <h6>Dashboard / Tasks</h6>
              <div className="search-my-task">
                {/* <span><img src={sortImage} className="setting-icon" /></span> */}
                <input type="text" className="task-searchField" placeholder="Search..." onChange={(e) => this.filterSearchResults(e)} />
              </div>
            </div>
            <div className="all-tasks">
              <div className="task-selection">
                <span style={{ "marginTop": "20px", "cursor": 'pointer' }} className={this.state.selectedTaskSection === 1 ? 'underline-task' : ''} onClick={() => this.selectedTaskSection(1)}>My Tasks</span>
                <span className={`sub-task-section ${this.state.selectedTaskSection === 2 ? 'underline-task' : ''}`} onClick={() => this.selectedTaskSection(2)}> All Tasks</span>
                <span className={`sub-task-section ${this.state.selectedTaskSection === 3 ? 'underline-task' : ''}`} onClick={() => this.selectedTaskSection(3)}> Completed Tasks</span>
              </div>
              <hr className="underline-hr" />
              {this.openMyTaskModal()}
              {this.viewTaskModal()}
              <div className="task-list">
                {currentTasks?.length ?
                  getTaskList().map((ele: any) =>
                    <div id={ele.id} className="task-card row">
                      <div className="col-10">

                        <div className="task-section-upper ">
                          <div className="task-subject">Task Title : {ele.attributes.subject.length < 90 ? ele.attributes.subject : `${ele.attributes.subject.slice(0, 89)}...`}</div>
                          <br />
                        </div>
                        <div className="task-date">Due : {moment(ele.attributes?.task_date).format('DD/MM/YYYY')}</div>
                        <br />
                        <span className="task-comment">{ele.attributes.task_comment.length < 300 ? ele.attributes.task_comment : `${ele.attributes.task_comment.slice(0, 299)}...`}</span><br />
                        <br />
                        <b>Assigned By :</b> <span className="task-assinee">{ele.attributes.assignee}</span><br />
                        <div className="task_assinee_to-head"><b>Assigned To :</b> <span className="task_assinee_to">{ele.attributes.assigned_to ? ele.attributes.assigned_to : ele.attributes.assign_to_id === ele.attributes.assignee_id ? 'Myself' : ''}</span>
                        </div>
                      </div>
                      {
                        ele.attributes.status !== "complete" && (
                          <div className="col-2">
                            <div className="ellipsis-item-task"
                              onClick={() => {
                                this.setState({ currentOpenTaskModal: this.state.currentOpenTaskModal === ele.id ? null : ele.id })
                              }}>
                              <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                              <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                              <img src={require('../../../components/src/oval_black.png')} alt="ellipse" className="ellipse-image" />
                              {this.state.currentOpenTaskModal === ele?.id && <div>{this.openDropDownModalUI(ele)}</div>}
                            </div>
                          </div>
                        )
                      }

                    </div>
                  ) : <div className="no-task">{'No Task'}</div>}
              </div>
              {currentTasks?.length > 0 &&
                <div className="pagination-main">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.taskPerPage}
                    totalItemsCount={this.state.presentTaskList?.length}
                    pageRangeDisplayed={20}
                    onChange={this.handlePageChange.bind(this)}
                    itemClass="pagination-item"
                    linkClass="pagination-link-item"
                    activeLinkClass="pagination-active-item"
                    hideFirstLastPages={true}
                    prevPageText="<"
                    nextPageText=">"
                  />
                </div>}

            </div>

          </div> : window.location.href.includes('reminder')
            ?
            <div>
              <div className="dashboard-section">
                <h6>Dashboard / Reminder</h6>
                <div className="search-my-task">
                  {/* <span><img src={sortImage} className="setting-icon" /></span> */}
                  <input type="text" className="task-searchField" placeholder="Search..." onChange={(e: any) => this.filterSearchResults(e)} />
                </div>
              </div>

              <div className="all-tasks">
                <div className="task-selection">
                  <span style={{ "marginTop": "20px", "cursor": 'pointer' }} className='underline-task' >Reminders</span>
                  <div className="add-reminder-btn" onClick={() => this.setState({ isShowReminderModal: true })}>Add Reminder</div>
                </div>
                <hr className="underline-hr" />
                {this.openReminderModal()}
                {this.openMyTaskModal()}
                {this.viewReminderModal()}
                <div className="reminder-list">
                  {this.state.allRemindersList?.length ?
                    (this.getReminderList())?.map((ele: any) =>
                      <div id={ele.id} className="task-card reminder-cards row" >
                        <div onClick={() => {
                          this.setState({
                            viewReminderModal: true,
                            reminderSubject: ele?.attributes?.subject,
                            reminderDate: ele.attributes.reminder_date,
                            reminderTime: ele.attributes.reminder_time,
                            reminderComment: ele.attributes.reminder_comment,
                            reminderId: ele.id
                          })
                        }}>
                          <div className="">
                            <span className="reminder-subject">{ele?.attributes.subject.length < 32 ? ele.attributes.subject : `${ele.attributes.subject.slice(0, 32)}...`}</span>
                            <br />

                            <div className="reminder-dates">{moment(ele.attributes.reminder_date).format('DD/MM/YYYY')} <span style={{ 'marginLeft': '10px' }}>{tConvert(ele.attributes.reminder_time?.split('+')[0]).replace(":00", " ")}</span></div>
                          </div>
                          <br />
                          <span className="task-comment">{ele.attributes.reminder_comment.length < 180 ? ele.attributes.reminder_comment : `${ele.attributes.reminder_comment.slice(0, 178)}...`}</span><br />
                        </div>
                        <div style={{ textAlign: 'end' }}>
                          <img style={{ height: '30px', width: '30px' }} onClick={() => this.deleteReminder(ele.id)} src={require('../../../components/src/delete-icon.png')} />
                        </div>
                      </div>
                    ) : <div className="no-task">{'No Reminders'}</div>}
                </div>
              </div>


            </div>
            : ''}

        </div>
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#FBFBFB",
    fontWeight: '500',
    borderColor: 'transparent',
    height: '42px',
    borderRadius: '10px',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important'
    }

  }),
  option: (provided: any) => ({
    ...provided,
    color: 'black'
  }),
}
// Customizable Area End
