import React, { useEffect, useState } from "react";
import axios from 'axios'

import { withRouter, Link } from "react-router-dom";
import Notifications from "../../blocks/Notifications/src/Notifications.web";
// @ts-ignore
import { ActionCableProvider } from 'react-actioncable-provider';

const TopNav = (props: any, history: any) => {
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false)


  const handleAddCandidate = () => {
    window.localStorage.removeItem('candidateId') 
    window.localStorage.setItem('candidateAction', 'addCandidate')
  }

  return (
    <div>
      {/* API call       `notifications/notifications` */}
      {/* https://crelatelikeappphase1-71088-ruby.b71088.dev.eastus.az.svc.builder.cafe */}
      {/* <ActionCableProvider  */}
        {/* url = {`https://crelatelikeappphase1-71088-ruby.b71088.dev.eastus.az.svc.builder.cafe/cable?token=${localStorage.getItem('authToken')}`}
        > */}
        {/* @ts-ignore */}
          <Notifications updateNotificationModal={()=>setIsOpenNotificationModal(!isOpenNotificationModal)} isOpenNotificationModal={isOpenNotificationModal}/>
      {/* </ActionCableProvider> */}


      <nav className="navbar navbar-expand-lg">
        {/* <button type="button" id="sidebarCollapse" className="btn btn-info"><i className="fa fa-align-justify"></i> </button> <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button> */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <div className="navbar-text-form">
              <span className="welcome-text"><label><img src={require('./hand.png')} style={{ width: '26px', margin: '6px' }} /></label> Hi {props?.data?.first_name}!</span>

            </div>
            <div className="navbar-notifications-form">
              {
                (props.location.pathname === '/Dashboard' || props.location.pathname === '/user-tasks') && (
                  <>
                    {/* @ts-ignore */}
                    {props.location.pathname === '/Dashboard' && <input type="text" className="header-searchField" onChange={(ele) => props.handleSearchText(ele.target.value)} placeholder="Search Here" />}
                    <button onClick={() => props.myModal()} className="btn candidate-button add-task-btn">Add Task</button>
                  </>
                )
              }
              {
                props.location.pathname === '/Candidates' && (
                  <Link onClick={() => handleAddCandidate()} to='/BasicDetails' className="btn candidate-button">Add New Candidate</Link>
                )
              }
              {
                (props.location.pathname === '/pqCapsule' || window.location.href.includes('newclient')) && (
                  <>
                    {/* <input type="text" className="header-searchField" placeholder="Search Here" /> */}
                    <Link to='/newclient' className="btn candidate-button">Add New Client</Link>
                  </>
                )
              }
              {
                (props.location.pathname === '/reports' || window.location.href.includes('reports') || window.location.href.includes('companies')) && (
                  <>
                    {/* <input type="text" className="header-searchField" placeholder="Search Here" /> */}
                    <Link to='/newclient' className="btn candidate-button">Add New Client</Link>
                  </>
                )
              }
              {
                (props.location.pathname === '/auditLogs' || props.location.pathname === '/teamVisibility') && (
                  <>
                    <input type="text" className="header-searchField" placeholder="Search Here" onChange={(ele) => props.handleSearchText(ele.target.value)} />
                    <Link to='/newclient' className="btn candidate-button">Add New Client</Link>
                  </>
                )
              }
              {/* <button className="btn btn-round btn-default">Sign in</button> */}
              <img src={require('./bell.png')} onClick={()=>setIsOpenNotificationModal(!isOpenNotificationModal)} className="notifications" />
              <img src={props?.data?.profile_picture} alt="Avatar" className="avatar" />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};


//@ts-ignore
export default withRouter(TopNav);
